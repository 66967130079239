import React, { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../environment.js";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
//import { Link } from "react-router-dom";

import Loader from "../Components/Loader.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const CustomerUsers = ({ history, match, props }) => {
  const [dap_customer_users, setCustomerUsers] = useState([]);

  const [loader, setLoader] = useState(true);

  const dap_id = match.params.id;

  useEffect(() => {
    // axios
    //   .get(environment.apiUrl + `entityGroup/` + dap_id, {
    //     headers: {
    //       "X-Authorization": "Bearer " + localStorage.getItem("token"),
    //     },
    //   })

    //   .then((res) => {
    //     // const dap_users = res.data.data;
    //     setCustomerUsers(res);
    //     console.log(res);
    //     //console.log(this.state.dap_users);
    //   })
    //   .catch(function (error) {
    //     window.alert(error.message);
    //   });

    axios
      .get(
        environment.apiUrl +
          `customer/` +
          dap_id +
          `/users?pageSize=200&page=0`,
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )

      .then((res) => {
        //const dap_users = res.data.data;
        setCustomerUsers(res.data.data);
        setLoader(false);
        console.log("dap_customer_id:", dap_id);
      })
      .catch(function (err) {
        MySwal.fire({
          title: <p>{err?.response?.data?.message}</p>,
          type: "error",
        });
      });
  }, []);

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(function (result) {
      if (result.isConfirmed) {
        axios
          .delete(environment.apiUrl + "user/" + id, {
            headers: {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log(res.data.data);
            MySwal.fire({
              title: <p>User has been successfully deleted!</p>,
              type: "success",
            });
            //history.push(`/customerUsers/${dap_id}`);
            axios
              .get(
                environment.apiUrl +
                  `customer/` +
                  dap_id +
                  `/users?pageSize=100&page=0`,
                {
                  headers: {
                    "X-Authorization":
                      "Bearer " + localStorage.getItem("token"),
                  },
                }
              )

              .then((res) => {
                //const dap_users = res.data.data;
                setCustomerUsers(res.data.data);
                setLoader(false);
                console.log("dap_customer_id:", dap_id);
              })
              .catch(function (error) {
                MySwal.fire({
                  title: <p>User couldn't be deleted!</p>,
                  type: "error",
                });
              });
          })
          .catch((err) => {
            MySwal.fire({
              title: <p>User couldn't be deleted!</p>,
              type: "error",
            });
          });
      }
    });
  };
  return loader ? (
    <Loader />
  ) : (
    <div>
      <>
        <div className='row' style={{ marginTop: "72px" }}>
          <div className='col-lg-12'>
            <LinkContainer
              to={`/customerUser/create/${dap_id}`}
              style={{
                // marginLeft: "80%",
                // verticalAlign: "bottom",
                // paddingTop: "18px",
                marginBottom: "20px",
                float: "right",
              }}
            >
              <Button variant='light' className='btn btn-light my-3'>
                Add User
              </Button>
            </LinkContainer>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 grid-margin stretch-card'>
            <div className='card'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table table-striped table-bordered'>
                    <thead>
                      <tr>
                        <th> FirstName </th>
                        <th> LastName </th>
                        <th> Email </th>
                        <th> Description </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dap_customer_users.map((dap_user) => (
                        <tr key={dap_user.id.id}>
                          <td>{dap_user.firstName}</td>
                          <td>{dap_user.lastName}</td>
                          <td>{dap_user.email}</td>
                          <td>{dap_user.additionalInfo.description}</td>

                          <td>
                            <Button
                              variant='danger'
                              className='btn-sm'
                              onClick={() => deleteHandler(dap_user.id.id)}
                            >
                              delete <i className='fa fa-trash-o'></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default CustomerUsers;
