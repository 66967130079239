import React from "react";

import "./search.css";

const Search = (props) => {
  return (
    <>
      <form onSubmit={props.loadweather}>
        <div className="row">
          <div className="col-md-3 offset-md-2">
            <input
              type="text"
              className="form-control"
              name="city"
              autoComplete="off"
              placeholder="City"
            />
          </div>

          {/* <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              name="country"
              autoComplete="off"
              placeholder="Country"
            />
          </div> */}
          <div className="col-md-3 mt-md-0 py-2 text-md-left">
            <button className="btn btn-primary">Get Weather</button>
          </div>
        </div>
        <div>{props.error ? error() : null}</div>
      </form>
    </>
  );
};

function error() {
  return (
    <div className="alert alert-danger mx-5" role="alert">
      Please enter City.
    </div>
  );
}

export default Search;
