import React, { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../environment.js";
import { Form, Button, Modal } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
//import { deleteDevice } from "../services/deleteDevice";
import { Dropdown } from "react-bootstrap";
//import { assignDevice } from "../services/assignDevice.js";

import Loader from "../Components/Loader.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const ConnectDevice = ({ history }) => {
  const [dap_devices, setDapDevices] = useState([]);
  const [dap_customers, setDapCustomers] = useState([]);
  const [loader, setLoader] = useState(true);
  const [wifiName, setWifiName] = useState("");
  const [wifiPassword, setWifiPassword] = useState("");
  const [dap_connectionResponse, setConnectionResponse] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const connectWifi = (wifiName, wifiPassword) => {
  //   console.log(wifiName, wifiPassword);
  // };

  useEffect(() => {
    handleShow();
  }, []);

  const connectWifi = async () => {
    axios
      .get(
        "http://192.168.4.1/?wifi_ssid=" +
          wifiName +
          "&wifi_password=" +
          wifiPassword
      )
      .then((res) => {
        console.log(res.data);
        let response = JSON.stringify(res.data);
        setConnectionResponse(response);
        if (res.status == 200) {
          MySwal.fire({
            title: <p>Connected Successfully!</p>,
            type: "success",
          });
        }
        // if(res.status==500){
        //     console.log("hgsdfcjhsg")
        // }
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          title: <p>Connection Failed!</p>,
          type: "error",
        });
      });
  };

  return (
    <>
      <div className='row' style={{ marginTop: "40px" }}>
        <div className='col-lg-3'></div>
        <div className='col-lg-6'>
          <Button variant='primary' onClick={handleShow}>
            Go To Steps <i className='mdi mdi-arrow-right'></i>
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Steps To Connect to WiFi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {" "}
                <span style={{ color: "blue" }}>
                  Step 1 <i className='mdi mdi-arrow-right'></i>
                </span>{" "}
                Search for the device name in the wifi module of your system.
                The wifi name will be same as the name shown on the device.
              </p>
              <p>
                <span style={{ color: "blue" }}>
                  Step 2 <i className='mdi mdi-arrow-right'></i>
                </span>{" "}
                Enter the Wi-Fi SSID (Name) and Password of the Wi-Fi.
              </p>
              <p>
                <span style={{ color: "blue" }}>
                  Step 3 <i className='mdi mdi-arrow-right'></i>
                </span>{" "}
                Press on Connect{" "}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='info' onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-3'></div>
        <div className='col-lg-6'>
          <Form className='mx-auto'>
            <Form.Group controlId='wifi'>
              <Form.Label>WiFi Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='WifiName'
                value={wifiName}
                onChange={(e) => setWifiName(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='password'>
              <Form.Label> Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Password'
                value={wifiPassword}
                onChange={(e) => setWifiPassword(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
          </Form>
          <div className='my-2 d-flex justify-content-between align-items-center mx-auto'>
            <div className=' mx-auto'>
              <Button
                type='submit'
                className='btn btn-block btn-light btn-lg font-weight-medium auth-form-btn'
                onClick={() => {
                  connectWifi(wifiName, wifiPassword);
                }}
              >
                Connect to wifi
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectDevice;
