import axios from "axios";
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { environment } from "../../environment";
//import { createUser } from "../services/createUser";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const AddCustomerUser = ({ history, match }) => {
  const [dap_firstName, setFirstName] = useState("");
  const [dap_lastName, setLastName] = useState("");
  const [dap_description, setDescription] = useState("");
  const [dap_email, setEmail] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [emailErrors, setEmailErrors] = useState({});
  const [nameErrors, setNameErrors] = useState({});

  //const [loader, setLoader] = useState(true);
  const handleFormValidation = (dap_email, dap_firstName, dap_lastName) => {
    let formErrors = {};
    let formIsValid = true;

    //Student name
    if (!dap_firstName) {
      formIsValid = false;
      formErrors["nameError"] = "Name is required.";
    }

    //Email
    if (!dap_email) {
      formIsValid = false;
      formErrors["emailIdErr"] = "Email id is required.";
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(dap_email)
    ) {
      formIsValid = false;
      formErrors["emailIdErr"] = "Invalid email id.";
    }

    //Phone number
    // if (!phoneNumber) {
    //   formIsValid = false;
    //   formErrors["phoneNumberErr"] = "Phone number is required.";
    // } else {
    //   var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;
    //   if (!mobPattern.test(phoneNumber)) {
    //     formIsValid = false;
    //     formErrors["phoneNumberErr"] = "Invalid phone number.";
    //   }
    // }

    //this.setState({ formErrors: formErrors });

    setFormErrors(formErrors);
    return formIsValid;
  };

  const c_id = localStorage.getItem("c_id");

  const dap_user_id = match.params.id;

  const formValidation = () => {
    const emailErrors = {};
    const nameErrors = {};
    let isValid = true;

    if (!dap_email) {
      emailErrors.noEmail = "* Email address is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(dap_email)) {
      emailErrors.invalidEmail = "* Email address is invalid";
      isValid = false;
    }
    if (!dap_firstName) {
      nameErrors.noName = "* First name is required";
      isValid = false;
    } else if (dap_firstName.trim().length < 2) {
      nameErrors.shortName = "* First name is too short";
      isValid = false;
    } else if (dap_firstName.trim().length > 10) {
      nameErrors.shortName = "* First name is too long";
      isValid = false;
    }
    setEmailErrors(emailErrors);

    setNameErrors(nameErrors);
    return isValid;
  };

  const submitHandler = (e) => {
    const isValid = formValidation();
    e.preventDefault();
    if (isValid) {
      axios
        .post(
          environment.apiUrl + "user?sendActivationMail=true",
          {
            email: dap_email,
            firstName: dap_firstName,
            lastName: dap_lastName,
            additionalInfo: {
              description: dap_description,
              defaultDashboardId: null,
              defaultDashboardFullscreen: false,
              homeDashboardId: null,
              homeDashboardHideToolbar: true,
            },
            authority: "CUSTOMER_USER",
            // customerId: {
            //   entityType: "CUSTOMER",
            //   id: dap_user_id,
            // },
          },
          {
            headers: {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data.data);
          MySwal.fire({
            title: <p>Customer User has been successfully created!</p>,
            type: "success",
          });
          history.push(`/customerUsers/${dap_user_id}`);
        })
        .catch((err) => {
          MySwal.fire({
            title: (
              <p>
                {err?.response?.data?.message || "Customer creation failed"}
              </p>
            ),
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <div>
        <div className='row'>
          <div className='col-lg-3'></div>
          <div className='col-md-6 grid-margin stretch-card'>
            <div className='card'>
              <div className='card-body'>
                <h1 className='text-center'>ADD USER</h1>
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId='title'>
                    <Form.Label>* Email</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter Email'
                      value={dap_email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                    <div>
                      {Object.keys(emailErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>{emailErrors[key]}</div>
                        );
                      })}
                    </div>
                  </Form.Group>

                  <Form.Group controlId='fistName'>
                    <Form.Label>* First Name</Form.Label>
                    <Form.Control
                      type='firstname'
                      placeholder='Enter First Name'
                      value={dap_firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    ></Form.Control>
                    <div>
                      {Object.keys(nameErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>{nameErrors[key]}</div>
                        );
                      })}
                    </div>
                  </Form.Group>

                  <Form.Group controlId='lastName'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type='lastName'
                      placeholder='Enter Last Name'
                      value={dap_lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='description'>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type='desctption'
                      placeholder='Add Description'
                      value={dap_description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <div className='mt-3'>
                    <Button
                      type='submit'
                      className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
                      disabled={disableBtn}
                    >
                      ADD USER
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className='col-lg-3'></div>
        </div>
      </div>
    </>
  );
};

export default AddCustomerUser;
