import axios from "axios";
import { environment } from "../../environment.js";

export const updateCustomer = (
  title,
  name,
  country,
  state,
  address,
  email,
  phone,
  history
) => {
  axios
    .post(environment.apiUrl + "/customer", {
      title: title,
      name,
      country,
      state,
      address,
      email,
      phone,
    })
    .then((res) => {
      console.log(res.data.data);
    })
    .catch((err) => console.log(err.message));
};
