import React, { Component } from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import { Sparklines, SparklinesBars } from "react-sparklines";
import Rating from "react-rating";
import axios from "axios";
import { useState } from "react";
import { Chart } from "react-google-charts";
// import LowerDashboard from "../Components/LowerDashboard";
import C3Chart from "react-c3js";
import "c3/c3.css";
import { environment } from "../../environment";
import "./dashboard.css";
import Loader from "../Components/Loader";
import MapComponent from "./UserMap";
import { logout } from "../services/logout";
import { deviceCoordinateService } from "../services/deviceCoordinateService";
import Spinner from "../Components/Spinner";
import { Trans } from "react-i18next";
import WeatherByCity from "../Components/WeatherByCity";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const TenantDashboard = ({ history }) => {
  let salesOptions;
  let id = 1;
  let dashboardDataArray = [];
  let dashboardDeviceArray = [];
  let noOfActiveDevices = 0;
  let co2 = [];
  let tvoc = [];
  let device_iaq_val = [];
  const co2colourArray = [];

  const [dap_currentDay, setCurrentDay] = useState("");

  const [dap_allTenantDevice, setAllTenantDevice] = useState([]);
  const [dap_allTenantCustomer, setAllTenantCustomer] = useState([]);
  const [dap_pm10ChartData, setpm10ChartData] = useState([]);
  const [dap_pm2ChartData, setpm2ChartData] = useState([]);
  const [dap_tvocChartData, settvocChartData] = useState([]);
  const [dap_humidityChartData, sethumidityChartData] = useState([]);
  const [dap_co2ChartData, setco2ChartData] = useState([]);
  const [alert, setAlert] = useState("");
  const [barColour, setChartBarColour] = useState([]);
  const [deviceAqi, setDeviceAqi] = useState([]);

  const [loader, setLoader] = useState(true);
  const [dap_city, setCity] = useState("");
  const [dap_country, setCountry] = useState("");
  const [dap_region, setRegion] = useState("");
  const [dap_pressure, setPressure] = useState("");
  // const [dap_activeDeviceCount, setActiveDeviceCount] = useState("");

  const [lastUpdated, setLastUpdated] = useState("");

  const [dap_humidity, setHumidity] = useState("");
  const [dap_temperature, setTemperature] = useState("");
  const [dap_windspeed, setWindSpeed] = useState("");
  const [dap_WeatherData, setWeatherData] = useState({
    dap_co: 0,
    dap_pm2_5: 0,
    dap_no2: 0,
    dap_pm10: 0,
    humidity: 0,
    condition: "",
    imageUrl: "",
  });
  const [dap_dashboardArray, setDashboardArray] = useState([]);
  const [dap_dashboardDevices, setDashboardDevices] = useState([]);
  const [dap_nillDashboardDevices, setnillDashboardDevices] = useState([]);

  const [dap_emptyDeviceArray, setEmptyDeviceArray] = useState([]);
  const [dap_DeviceArray, setDeviceArray] = useState([]);

  const [dap_iaqColor, setIaqColorClassName] = useState("");
  const [dap_iaqColor_1, setIaq_ColorClassName_1] = useState("");
  const [dap_iaqname, setIaq_ColorIaqname] = useState("");
  const [dap_iaqxval, setIaq_ColorIaqXval] = useState("");
  const [isMapToCalled, setIsMapToCalled] = useState(false);
  const [dap_emptyDeviceColor, setEmptyDeviceColor] = useState("");

  const [latest_aqi, setLatestAqi] = useState(0);
  const [latest_pm2, setLAtestPm2] = useState(0);

  const [latest_pm10, setLAtestPm10] = useState(0);
  const [latest_humidity, setLAtesthumidity] = useState(0);
  const [latest_temp, setLatestTemp] = useState(0);

  const [dap_userId, setEmail] = useState("");
  const [dap_password, setUserDetails] = useState("");
  const [data, setData] = useState(false);
  const [dap_pm2, setpm2] = useState([]);
  const [dap_co2, setCo2] = useState([]);
  const [dap_pm10, setPm10] = useState([]);
  const [dap_tvoc, setTvoc] = useState([]);
  let inActiveDeviceArray = [];
  let activeDeviceArray = [];
  let inactiveDashboardDevice = [];
  let activeDashboardDevice = [];

  let unique = [];

  const [dap_dashboadrdData, setDashboardData] = useState({
    co2: 0,
    pm10: 0,
    pm25: 0,
    temp: 0,
    tvoc: 0,
    rh: 0,
  });
  const [dap_deviceId, setDeviceId] = useState("");

  const [dap_aqi_1, setAqi] = useState(46);
  const [dap_aqi, setDapAqi] = useState("");

  const [showMore, setShowMore] = useState(false);
  const [showMoreBtn, setShowMoreBtn] = useState(false);

  //const [showInactiveDevices, setShowInactiveDevices] = useState(false);

  let dap_airqIndex = {
    good: "Good (0-50)",
    satisfactory: "Satisfactory (51-100)",
    moderate: "Moderate (101-200)",
    poor: "Poor (201-300)",
    very_poor: "Very Poor (301-400)",
    severe: "Severe > 400",
  };

  let userDetails = {};
  var firstName;

  // let spline_pm10_array = [];

  const splineData_pm10 = {
    columns: dap_pm10ChartData,
    type: "bar",
    legend: {
      show: false,
    },
  };
  const splineData_pm25 = {
    columns: dap_pm2ChartData,
    type: "bar",
    colors: {
      data1: "#00de3b",
      data2: "#00de3b",
      data3: "#00de3b",
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };
  React.useEffect(() => {
    console.log(Date.now(), "cuurent timestamp");

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date();
    var dayName = days[d.getDay()];
    var month = monthNames[d.getMonth()];
    console.log(dayName, month);
    setCurrentDay(
      dayName + ", " + d.getDate() + " " + month + " " + d.getFullYear()
    );
    userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    console.log(userDetails);
    firstName = userDetails.data.firstName;
    console.log(firstName);
    setUserDetails(firstName);
    getAllDevicesForTenant();
    getAllUsersForTenant();
    getCurrentCity();
    getDeviceLocation();
    getFirstDeviceLocation();
    stopDeviceLocation();
    setTimeout(() => {
      setIsMapToCalled(true);
    }, 5000);

    //stopDeviceLocation();

    setLoader(false);

    // getDevicePerUser();
    // dap_weather();
    // const timer = setTimeout(() => console.log("Hello, World!"), 3000);
    // return () => clearTimeout(timer);

    // var hours = 1; // to clear the localStorage after 1 hour(if someone want to clear after 8hrs simply change hours=8)
    // var now = new Date().getTime();
    // var setupTime = localStorage.getItem("setupTime");
    // if (setupTime == null) {
    //   localStorage.setItem("setupTime", now);
    // } else {
    //   if (now - setupTime > 3000) {
    //     // localStorage.clear();
    //     // localStorage.setItem("setupTime", now);
    //     // history.push("/");
    //     timer();
    //   }
    // }
    var hours = 1;
    const interval = setInterval(() => {
      logout();
    }, hours * 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const getDeviceLocation = () =>
    setInterval(() => {
      deviceCoordinateService();
    }, 3000000);

  const getFirstDeviceLocation = () =>
    setTimeout(() => {
      deviceCoordinateService();
    }, 3000);

  const stopDeviceLocation = () => {
    clearInterval(getDeviceLocation);
  };

  const getCurrentCity = async () => {
    let lat;
    let lon;
    // This has been copied to your clipboard.This has been copied to your clipboard.https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=37.42159&longitude=-122.0837&localityLanguage=en
    await navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("position is :", position);
      lon = parseFloat(position.coords.longitude);
      lat = parseFloat(position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      axios
        .get(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=` +
            lat +
            `&longitude=` +
            lon +
            `&localityLanguage=en`
        )
        .then((res) => {
          console.log(res.data);
          dap_weather(res.data.localityInfo.administrative[4].name);
          setInterval(
            () => dap_weather(res.data.localityInfo.administrative[4].name),
            6000 * 50
          );
        })
        .catch(function (error) {
          //window.alert("get current city", error.message);
          console.log("error");
        });
    });
  };
  const getAllUsersForTenant = async () => {
    axios
      .get(environment.apiUrl + `customers?pageSize=100&page=0`, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setAllTenantCustomer(res.data.data);
      })
      .catch(function (error) {
        //window.alert("get all users", error.message);
        console.log("object");
      });
  };

  const getAllDevicesForTenant = async () => {
    axios
      .get(environment.apiUrl + `tenant/devices?pageSize=200&page=0`, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.data);
        console.log(res.data.data.length);
        if (res.data.data.length > 7) {
          setShowMore(true);
          setShowMoreBtn(true);
        }
        setAllTenantDevice(res.data.data);
        localStorage.setItem("listDevices", JSON.stringify(res.data.data));
        //deviceCoordinateService();
        // setTimeout(() => {
        //   setIsMapToCalled(true);
        // }, 2000);
        //  dashboardDataArray.length=res.data.data.length
        res.data.data.forEach((device, index) => {
          console.log("each device", device);
          getDashboardDataForDevice(device.id.id, device.name);
          showDevicesOnDashboard(device.id.id, device.name);
          //showInactiveDevicesOnDashboard(device.id.id, device.name);
          if (index < 20) {
            chartHandler(device.id.id, device.name);
          }
          //getAqiDataForDevice(device.id.id, device.name);
        });
        // for (let i = 0; i < 7; i++) {
        //   showDevicesOnDashboard();
        // }
        setInterval(() => {
          console.log(dashboardDataArray.length, noOfActiveDevices);
          if (dashboardDataArray.length >= noOfActiveDevices) {
            dashboardDataArray = [];
          }
          if (co2.length >= noOfActiveDevices) {
            co2 = [];
          }
          if (tvoc.length >= noOfActiveDevices) {
            tvoc = [];
          }
          res.data.data.forEach((device, index) => {
            showDevicesOnDashboard(device.id.id, device.name);
            getDashboardDataForDevice(device.id.id, device.name);
            console.log(index);
            console.log(device);
            if (index < 20) {
              chartHandler(device.id.id, device.name);
            }
            //showInactiveDevicesOnDashboard(device.id.id, device.name);

            //getAqiDataForDevice(device.id.id, device.name);
            console.log("call dashboard device");
          });
        }, 600000);

        //  setInterval(() => getDashboardDataForDevice(res.data.data[1].id.id), 1000 *30);

        // console.log(this.state.dap_devices);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const chartHandler = async (deviceId, deviceName) => {
    let token = localStorage.getItem("token");
    let objToPush = {};
    let dataToPush = {};
    await axios
      .get(
        environment.apiUrl +
          "plugins/telemetry/DEVICE/" +
          deviceId +
          "/values/timeseries?keys=iaq,pm25,pm10,co2,tvoc,temp,rh,bat,mode&startTs=" +
          (Date.now() - 10 * 60 * 60 * 500) +
          "&endTs=" +
          Date.now() +
          "&interval=60000&limit=10",
        { headers: { "X-Authorization": "Bearer " + token } }
      )
      .then((res) => {
        if (res.data.pm25[0] && res.data.pm25[1] && res.data.pm25[2]) {
          if (dap_pm2ChartData.length < 10) {
            dataToPush = {
              pm2ChartData: [
                deviceName,
                res.data.pm25[0].value,
                res.data.pm25[1].value,
                res.data.pm25[2].value,
              ],
            };
            setpm2ChartData([dataToPush.pm2ChartData]);
          }
        }

        if (res.data.pm10[0] && res.data.pm10[1] && res.data.pm10[2]) {
          dataToPush = {
            pm10ChartData: [
              deviceName,
              res.data.pm10[0].value,
              res.data.pm10[1].value,
              res.data.pm10[2].value,
            ],
          };
          setpm10ChartData([dataToPush.pm10ChartData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showDevicesOnDashboard = async (dap_deviceId, deviceName) => {
    console.log("showDevicesOnDashboard");
    let token = localStorage.getItem("token");
    let objToPush = {};
    console.log(dap_deviceId);
    console.log(deviceName);
    await axios
      .get(
        environment.apiUrl +
          "plugins/telemetry/DEVICE/" +
          dap_deviceId +
          "/values/timeseries?keys=iaq,pm25,pm10,co2,tvoc,temp,rh,bat,mode&startTs=" +
          (Date.now() - 10 * 60 * 60 * 500) +
          "&endTs=" +
          Date.now() +
          "&interval=60000&limit=10",
        { headers: { "X-Authorization": "Bearer " + token } }
      )
      .then((res) => {
        console.log(res.data);
        if (
          res.data.co2 &&
          inactiveDashboardDevice.length + activeDashboardDevice.length < 7
        ) {
          if (!activeDashboardDevice.includes(deviceName)) {
            activeDashboardDevice.push(deviceName);

            if (Object.keys(res.data).length > 0) {
              if (
                res.data.pm25 &&
                res.data.pm10 &&
                res.data.co2 &&
                res.data.tvoc &&
                res.data.temp &&
                res.data.rh &&
                res.data.iaq
              ) {
                objToPush = {
                  device_id: dap_deviceId,
                  device_name: deviceName,
                  device_co2: res.data.co2[0].value,
                  device_iaq: res.data.iaq[0].value,
                  device_pm25: res.data.pm25[0].value,
                  device_pm10: res.data.pm10[0].value,
                  device_rh: res.data.rh[0].value,
                  device_temp: res.data.temp[0].value,
                  device_tvoc: res.data.tvoc[0].value,
                  device_last_updated:
                    "Last updated: " +
                    new Date(res.data.iaq[0].ts).toLocaleTimeString(),
                  bat: res.data?.bat[0]?.value,
                  mode: res.data?.mode[0]?.value,
                };
              }

              if (res.data.iaq[0].value >= 401) {
                objToPush.iaqColorClassName = "aqi-color-severe";
                objToPush.iaqColorClassName2 = "aqi-color-severe-1";
                objToPush.colorIaqName = "severe";
                objToPush.iaqXval = 13.2;
              } else if (
                res.data.iaq[0].value >= 301 &&
                res.data.iaq[0].value <= 400
              ) {
                objToPush.dap_iaqColor = "aqi-color-very-poor";
                objToPush.dap_iaqColor_1 = "aqi-color-very-poor-1";
                objToPush.dap_iaqname = "Very poor";
                objToPush.dap_iaqxval = 11.2;
              } else if (
                res.data.iaq[0].value >= 201 &&
                res.data.iaq[0].value <= 300
              ) {
                objToPush.dap_iaqColor = "aqi-color-poor";
                objToPush.dap_iaqColor_1 = "aqi-color-poor-1";
                objToPush.dap_iaqname = "Poor";
                objToPush.dap_iaqxval = 14.2;
              } else if (
                res.data.iaq[0].value >= 101 &&
                res.data.iaq[0].value <= 200
              ) {
                objToPush.dap_iaqColor = "aqi-color-Moderate";
                objToPush.dap_iaqColor_1 = "aqi-color-Moderate-1";
                objToPush.dap_iaqname = "Moderate";
                objToPush.dap_iaqxval = 11.5;
              } else if (
                res.data.iaq[0].value >= 51 &&
                res.data.iaq[0].value <= 100
              ) {
                objToPush.dap_iaqColor = "aqi-color-satisfactory";
                objToPush.dap_iaqColor_1 = "aqi-color-satisfactory-1";
                objToPush.dap_iaqname = "Satisfactory";
                objToPush.dap_iaqxval = 10;
              } else if (
                res.data.iaq[0].value > 0 &&
                res.data.iaq[0].value <= 50
              ) {
                objToPush.dap_iaqColor = "aqi-color-good";
                objToPush.dap_iaqColor_1 = "aqi-color-good-1";
                objToPush.dap_iaqname = "Good";
                objToPush.dap_iaqxval = 14.2;
              }

              id = id + 1;
              objToPush.id = id;

              dashboardDeviceArray.push(objToPush);
              // setIaq_ColorClassName_1(objToPush.iaqColorClassName2)

              console.log(dashboardDeviceArray);
            }
          }
        }

        console.log(dashboardDeviceArray.length);

        console.log("active devices", activeDashboardDevice);

        setDashboardDevices([...dashboardDeviceArray]);

        console.log(dashboardDeviceArray);

        showInactiveDevicesOnDashboard(dap_deviceId, deviceName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showInactiveDevicesOnDashboard = (dap_deviceId, deviceName) => {
    let token = localStorage.getItem("token");

    axios
      .get(
        environment.apiUrl +
          "plugins/telemetry/DEVICE/" +
          dap_deviceId +
          "/values/timeseries?keys=iaq,pm25,pm10,co2,tvoc,temp,rh,bat,mode&startTs=" +
          (Date.now() - 10 * 60 * 60 * 500) +
          "&endTs=" +
          Date.now() +
          "&interval=60000&limit=10",
        { headers: { "X-Authorization": "Bearer " + token } }
      )
      .then((res) => {
        let i = 0;
        console.log(res.data);
        console.log("hj", inactiveDashboardDevice.length);
        console.log("7-hj", 7 - dashboardDeviceArray.length);
        if (
          !res.data.co2 &&
          inactiveDashboardDevice.length < 7 - dashboardDeviceArray.length
        ) {
          if (!inactiveDashboardDevice.includes(deviceName)) {
            inactiveDashboardDevice.push(deviceName);
          }
        }

        console.log(inactiveDashboardDevice.length);
        console.log(inactiveDashboardDevice);
        console.log("inactive devices", inactiveDashboardDevice);
        setnillDashboardDevices(inactiveDashboardDevice);
        setEmptyDeviceColor("inactive-device");
      })

      .catch((err) => {});
  };

  const getDashboardDataForDevice = async (dap_deviceId, deviceName) => {
    //  dashboardDataArray
    console.log("getDashboardDataForDevice");

    let token = localStorage.getItem("token");
    let objToPush = {};
    let dataToPush = {};
    await axios
      .get(
        environment.apiUrl +
          "plugins/telemetry/DEVICE/" +
          dap_deviceId +
          "/values/timeseries?keys=iaq,pm25,pm10,co2,tvoc,temp,rh,bat,mode&startTs=" +
          (Date.now() - 10 * 60 * 60 * 500) +
          "&endTs=" +
          Date.now() +
          "&interval=60000&limit=10",
        { headers: { "X-Authorization": "Bearer " + token } }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.co2) {
          if (!activeDeviceArray.includes(deviceName)) {
            activeDeviceArray.push(deviceName);
          }
        }

        // if (!res.data.co2) {
        //   inActiveDeviceArray.push({ dev_name: deviceName });
        // }

        if (!res.data.co2) {
          inActiveDeviceArray.push({ dev_name: deviceName });
          unique = [
            ...new Set(inActiveDeviceArray.map((item) => item.dev_name)),
          ];
        }

        // if (
        //   !res.data.co2 &&
        //   inActiveDeviceArray.includes({ deviceName }) === false
        // ) {
        //   inActiveDeviceArray.push({ dev_name: deviceName });
        // }

        console.log("unique", unique);

        console.log(inActiveDeviceArray);

        // const removeDuplicates = (inActiveDeviceArray) => {
        //   let unique = [];
        //   inActiveDeviceArray.forEach((element) => {
        //     if (unique.includes(element)) {
        //       unique.push({ dev_name: deviceName });
        //     }
        //   });
        //   return unique;
        // };

        // console.log(removeDuplicates(inActiveDeviceArray));

        // if (!res.data.co2) {
        //   let arrayLen = inActiveDeviceArray.length;
        //   if (arrayLen === 0) {
        //     inActiveDeviceArray.push({ dev_name: deviceName });
        //   } else {
        //     for (let i = 0; i < inActiveDeviceArray.length; i++) {
        //       if (inActiveDeviceArray[i] !== deviceName) {
        //         inActiveDeviceArray.push({ dev_name: deviceName });
        //       }
        //     }
        //   }
        // }

        // if (!res.data.co2) {
        //   //inActiveDeviceArray.push({ dev_name: deviceName });
        //   if (inActiveDeviceArray.indexOf(deviceName) === -1) {
        //     inActiveDeviceArray.push({ dev_name: deviceName });
        //     console.log(deviceName);
        //   }
        // }

        // if (!res.data.co2) {
        //   let array, i, j;
        //   if (inActiveDeviceArray.length === 0) {
        //     inActiveDeviceArray.push({ dev_name: deviceName });
        //   } else {
        //     for (i = 0; i < inActiveDeviceArray.length; ++i) {
        //       array = inActiveDeviceArray[i];
        //       for (j = 0; j < array.length; ++j) {
        //         if (array[j] !== deviceName) {
        //           inActiveDeviceArray.push({ dev_name: deviceName });
        //         }
        //       }
        //     }
        //   }
        // }

        // if (!res.data.co2) {
        console.log(inActiveDeviceArray, "inActiveDeviceArray");
        console.log(activeDeviceArray, "activeDeviceArray");
        // }
        setEmptyDeviceArray(unique);
        setDeviceArray(activeDeviceArray);

        setEmptyDeviceColor("inactive-device");
        if (res.data.co2) {
          co2.push(res.data.co2[0].value);
        }
        if (res.data.tvoc) {
          tvoc.push(res.data.tvoc[0].value);
        }
        if (res.data.iaq) {
          device_iaq_val.push(res.data.iaq[0].value);
        }
        // for (let i = 0; i < 9; i++) {
        // pm2.push(res.data.pm25[i].value);
        // pm10.push(res.data.pm10[i].value);
        // tvoc.push(res.data.tvoc[i].value);
        //  pm2.push(res.data.pm25[i].value)
        // }
        if (res.data.pm25) {
          console.log("pm25");
        }

        if (Object.keys(res.data).length > 0) {
          if (dashboardDataArray.length < noOfActiveDevices) {
            noOfActiveDevices += 1;
          }
          // setLAtestPm10(res.data.pm10[0].value);
          // if (res.data.pm25[0].value) {
          // }
          // const pm25ChartData = []
          // for (let i = 0; i < noOfActiveDevices; i++) {
          //   pm25ChartData.push [deviceName, res.data.pm25[i].value]
          // }

          if (res.data.tvoc[0]) {
            dataToPush = {
              tvocChartData: [deviceName, res.data.tvoc[0].value],
            };
            settvocChartData([dataToPush.tvocChartData]);
          }

          if (res.data.co2[0]) {
            dataToPush = {
              tvocChartData: [deviceName, res.data.co2[0].value],
            };
            setco2ChartData([dataToPush.co2ChartData]);
          }

          if (
            res.data.pm25 &&
            res.data.pm10 &&
            res.data.co2 &&
            res.data.tvoc &&
            res.data.temp &&
            res.data.rh &&
            res.data.iaq
          ) {
            objToPush = {
              // pm2ChartData: [
              //   deviceName,
              //   res.data.pm25[0].value,
              //   res.data.pm25[1].value,
              //   res.data.pm25[2].value,
              // ],
              // tvocChartData: [deviceName, res.data.tvoc[0].value],
              // tempChartData: [deviceName, res.data.temp[0].value],
              // humidityChartData: [deviceName, res.data.rh[0].value],
              // co2ChartData: [deviceName, res.data.co2[0].value],
              // pm10ChartData: [
              //   deviceName,
              //   res.data.pm10[0].value,
              //   res.data.pm10[1].value,
              //   res.data.pm10[2].value,
              // ],
              device_id: dap_deviceId,
              device_name: deviceName,
              device_co2: res.data.co2[0].value,
              device_iaq: res.data.iaq[0].value,
              device_pm25: res.data.pm25[0].value,
              device_pm10: res.data.pm10[0].value,
              device_rh: res.data.rh[0].value,
              device_temp: res.data.temp[0].value,
              device_tvoc: res.data.tvoc[0].value,
              device_last_updated:
                "Last updated: " +
                new Date(res.data.iaq[0].ts).toLocaleTimeString(),
              bat: res.data?.bat[0]?.value,
              mode: res.data?.mode[0]?.value,
            };
          }

          if (res.data.iaq[0].value >= 401) {
            objToPush.iaqColorClassName = "aqi-color-severe";
            objToPush.iaqColorClassName2 = "aqi-color-severe-1";
            objToPush.colorIaqName = "severe";
            objToPush.iaqXval = 13.2;
          } else if (
            res.data.iaq[0].value >= 301 &&
            res.data.iaq[0].value <= 400
          ) {
            objToPush.dap_iaqColor = "aqi-color-very-poor";
            objToPush.dap_iaqColor_1 = "aqi-color-very-poor-1";
            objToPush.dap_iaqname = "Very poor";
            objToPush.dap_iaqxval = 11.2;
          } else if (
            res.data.iaq[0].value >= 201 &&
            res.data.iaq[0].value <= 300
          ) {
            objToPush.dap_iaqColor = "aqi-color-poor";
            objToPush.dap_iaqColor_1 = "aqi-color-poor-1";
            objToPush.dap_iaqname = "Poor";
            objToPush.dap_iaqxval = 14.2;
          } else if (
            res.data.iaq[0].value >= 101 &&
            res.data.iaq[0].value <= 200
          ) {
            objToPush.dap_iaqColor = "aqi-color-Moderate";
            objToPush.dap_iaqColor_1 = "aqi-color-Moderate-1";
            objToPush.dap_iaqname = "Moderate";
            objToPush.dap_iaqxval = 11.5;
          } else if (
            res.data.iaq[0].value >= 51 &&
            res.data.iaq[0].value <= 100
          ) {
            objToPush.dap_iaqColor = "aqi-color-satisfactory";
            objToPush.dap_iaqColor_1 = "aqi-color-satisfactory-1";
            objToPush.dap_iaqname = "Satisfactory";
            objToPush.dap_iaqxval = 10;
          } else if (res.data.iaq[0].value > 0 && res.data.iaq[0].value <= 50) {
            objToPush.dap_iaqColor = "aqi-color-good";
            objToPush.dap_iaqColor_1 = "aqi-color-good-1";
            objToPush.dap_iaqname = "Good";
            objToPush.dap_iaqxval = 14.2;
          }

          id = id + 1;
          objToPush.id = id;
          dashboardDataArray.push(objToPush);
          // setIaq_ColorClassName_1(objToPush.iaqColorClassName2)

          console.log(dashboardDataArray);

          setDashboardArray([...dashboardDataArray]);
        }
        setCo2(co2);
        setTvoc(tvoc);
        setDapAqi(device_iaq_val);
        console.log(tvoc);
        console.log(device_iaq_val);

        console.log(device_iaq_val.length);
        for (let i = 0; i < device_iaq_val.length; i++) {
          if (device_iaq_val[i] > 401) {
            co2colourArray.push("severe_gradient");
          } else if (device_iaq_val[i] > 301 && device_iaq_val[i] <= 400) {
            co2colourArray.push("verypoor_gradient");
          } else if (device_iaq_val[i] > 201 && device_iaq_val[i] <= 300) {
            co2colourArray.push("aqi-color-poor");
          } else if (device_iaq_val[i] > 101 && device_iaq_val[i] <= 200) {
            co2colourArray.push("aqi-color-Moderate");
          } else if (device_iaq_val[i] > 51 && device_iaq_val[i] <= 100) {
            co2colourArray.push("aqi-color-satisfactory");
          } else if (device_iaq_val[i] > 0 && device_iaq_val[i] <= 50) {
            co2colourArray.push("aqi-color-good");
          } else {
            co2colourArray.push("aqi-color-severe");
          }
        }

        console.log(co2colourArray);
        setChartBarColour(co2colourArray);

        // device_iaq_val.forEach(i => {

        // })
        // setpm10ChartData()

        // newValue.push(objToPush.device_name)
        // newValue.push(objToPush.device_pm10)

        // let pm2 = [];
        // let co2 = [];
        // let pm10 = [];
        // let tvoc = [];

        // for (let i = 0; i < 20; i++) {
        //   pm2.push(res.data.pm25[i].value);
        //   co2.push(res.data.co2[i].value);
        //   pm10.push(res.data.pm10[i].value);
        //   tvoc.push(res.data.tvoc[i].value);
        // }
        // setLAtestPm2(res.data.pm25[0].value);
        // setLAtestPm10(res.data.pm10[0].value);
        // setLAtesthumidity(res.data.rh[0].value);
        // setLatestAqi(res.data.iaq[0].value);

        // setpm2(pm2);
        // setCo2(co2);
        // setPm10(pm10);
        // setTvoc(tvoc);
      })
      .catch((err) => {
        console.log(err);
        // MySwal.fire({
        //   title: <p>Something went wrong</p>,
        //   type: "error",
        // });
        //setLoader(true)
      });
  };

  const setChartData = (dap_deviceId, deviceName) => {
    let dataToPush = {};
    let token = localStorage.getItem("token");
    axios
      .get(
        environment.apiUrl +
          "plugins/telemetry/DEVICE/" +
          dap_deviceId +
          "/values/timeseries?keys=iaq,pm25,pm10,co2,tvoc,temp,rh,bat,mode&startTs=" +
          (Date.now() - 10 * 60 * 60 * 500) +
          "&endTs=" +
          Date.now() +
          "&interval=60000&limit=10",
        { headers: { "X-Authorization": "Bearer " + token } }
      )
      .then((res) => {
        if (res.data.pm25[0] && res.data.pm25[1] && res.data.pm25[2]) {
          if (dap_pm2ChartData.length < 10) {
            dataToPush = {
              pm2ChartData: [
                deviceName,
                res.data.pm25[0].value,
                res.data.pm25[1].value,
                res.data.pm25[2].value,
              ],
            };
            setpm2ChartData([dataToPush.pm2ChartData]);
          }
        }

        if (res.data.pm10[0] && res.data.pm10[1] && res.data.pm10[2]) {
          dataToPush = {
            pm10ChartData: [
              deviceName,
              res.data.pm10[0].value,
              res.data.pm10[1].value,
              res.data.pm10[2].value,
            ],
          };
          setpm10ChartData([dataToPush.pm10ChartData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dap_weather = async (location) => {
    console.log("inside weather");
    await axios
      .get(
        // environment.dap_weatherUrl +
        //   "current.json?key=fee0b93daf7744fb85871040211108&q=" +
        //   location +
        //   "&aqi=yes"

        environment.dap_weatherUrl +
          "current.json?key=fee0b93daf7744fb85871040211108&q=" +
          location +
          "&aqi=yes"
      )
      .then((res) => {
        setCity(res.data.location.name);
        setRegion(res.data.location.region);
        setCountry(res.data.location.country);
        setHumidity(res.data.current.humidity);
        setPressure(res.data.current.pressure_in);
        setTemperature(res.data.current.temp_c);
        setWindSpeed(res.data.current.wind_mph);
        //setTimeout(console.log(dap_city), 3000);
        console.log("weather data", res.data);

        console.log(dap_city);
        console.log(res.data.location.name);
        setWeatherData({
          dap_co: res.data.current.air_quality.co,
          dap_no2: res.data.current.air_quality.no2,
          dap_pm10: res.data.current.air_quality.pm10,
          dap_pm2_5: res.data.current.air_quality.pm2_5,
          humidity: res.data.current.humidity,
          condition: res.data.current.condition.text,
          imageUrl: res.data.current.condition.icon,
        });
      })
      .catch((err) => console.log(err));
  };
  const goToChildDashboard = (device) => {
    console.log(device);
    // /getSingleDeviceData/:id
    history.push(
      `/getSingleDeviceData/` + device.device_id + "/" + device.device_name
    );
  };

  // const deviceCoordinateService = () => {
  //   console.log("jhg");
  //   let deviceList = [];
  //   let deviceCoordinateArray = [];
  //   deviceList = JSON.parse(localStorage.getItem("listDevices"));
  //   console.log(deviceList);
  //   let token = localStorage.getItem("token");
  //   deviceList.forEach((element) => {
  //     console.log(element.name);
  //     axios
  //       .get(
  //         environment.apiUrl +
  //           "plugins/telemetry/DEVICE/" +
  //           element.id.id +
  //           "/values/timeseries?keys=pm25,iaq,pm10,co2,tvoc,temp,lat,lon,rh&startTs=" +
  //           (Date.now() - 10 * 60 * 60 * 1000) +
  //           "&endTs=" +
  //           Date.now() +
  //           "&interval=60000&limit=10",
  //         { headers: { "X-Authorization": "Bearer " + token } }
  //       )
  //       .then((res) => {
  //         console.log(res);
  //         deviceCoordinateArray.push({
  //           device_name: element.name,
  //           lat: res.data.lat[0].value,
  //           lon: res.data.lon[0].value,
  //         });
  //         console.log(deviceCoordinateArray);
  //         localStorage.setItem(
  //           "deviceCoordinateArray",
  //           JSON.stringify(deviceCoordinateArray)
  //         );
  //       });
  //   });
  // };
  const getDevicePerUser = () => {
    let token = localStorage.getItem("token");

    axios
      .get(
        environment.apiUrl +
          "customer/" +
          userDetails.data.customerId.id +
          "/devices?pageSize=10&page=0",
        { headers: { "X-Authorization": "Bearer " + token } }
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.data[0].id.id);
        setDeviceId(res.data.data[0].id.id);
        localStorage.setItem("listDevices", JSON.stringify(res.data.data));
        console.log(dap_deviceId);
        // getDashboardData(res.data.data[0].id.id);
        // setInterval(() => getDashboardData(res.data.data[0].id.id), 2000);
        localStorage.setItem("cid:", res);
      })
      .catch((err) => {
        console.log(err.Error);
      });
  };

  salesOptions = {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          barPercentage: 0.4,
          ticks: {
            display: false,
            beginAtZero: true,
            fontColor: "rgba(0, 0, 0, 1)",
          },
          gridLines: {
            display: false,
            drawBorder: false,
            color: "transparent",
            zeroLineColor: "#eeeeee",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            display: false,
            color: "#eeeeee",
          },
          categoryPercentage: 0.5,
          ticks: {
            display: false,
            beginAtZero: true,
            stepSize: 10,
            max: 80,
            fontColor: "rgba(0, 0, 0, 1)",
          },
        },
      ],
    },
  };

  let splimepm10options = {
    legend: {
      display: false,
    },
  };

  const tvocBarData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const good_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    good_gradient.addColorStop(0, "rgba(5, 230, 31, 1)");
    good_gradient.addColorStop(1, "rgba(134, 219, 144,0.5)");

    const satisfactory_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    satisfactory_gradient.addColorStop(0, "rgba(223, 223, 0, 1)");
    satisfactory_gradient.addColorStop(1, "rgba(223, 223, 0,0.5)");

    const moderate_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    moderate_gradient.addColorStop(0, "rgba(255, 153, 0, 1)");
    moderate_gradient.addColorStop(1, "rgba(242, 176, 78,0.5)");

    const poor_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    poor_gradient.addColorStop(0, "rgba(255, 0, 0, 1)");
    poor_gradient.addColorStop(1, "rgba(255, 0, 0,0.5)");

    const verypoor_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    verypoor_gradient.addColorStop(0, "rgba(112, 48, 160, 1)");
    verypoor_gradient.addColorStop(1, "rgba(112, 48, 160,0.5)");

    const severe_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    severe_gradient.addColorStop(0, "rgba(153, 0, 51, 1)");
    severe_gradient.addColorStop(1, "rgba(153, 0, 51,0.5)");

    const tvocColourArray = [];
    console.log(dap_tvoc.length);
    for (let i = 0; i < dap_tvoc.length; i++) {
      console.log(dap_tvoc[i]);
      if (dap_tvoc[i] > 5) {
        tvocColourArray.push(severe_gradient);
      } else if (dap_tvoc[i] > 4 && dap_tvoc[i] <= 5) {
        tvocColourArray.push(verypoor_gradient);
      } else if (dap_tvoc[i] > 3 && dap_tvoc[i] <= 4) {
        tvocColourArray.push(poor_gradient);
      } else if (dap_tvoc[i] > 2 && dap_tvoc[i] <= 3) {
        tvocColourArray.push(moderate_gradient);
      } else if (dap_tvoc[i] > 1 && dap_tvoc[i] <= 2) {
        tvocColourArray.push(satisfactory_gradient);
      } else if (dap_tvoc[i] > 0 && dap_tvoc[i] <= 1) {
        tvocColourArray.push(good_gradient);
      } else {
        tvocColourArray.push(good_gradient);
      }
    }

    return {
      labels: dap_DeviceArray,
      datasets: [
        {
          fill: "start",
          label: "TVOC",
          backgroundColor: tvocColourArray, // Put the gradient here as a fill color
          //borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
          // pointBackgroundColor: "#5271ff",
          // pointBorderWidth: 1,
          // pointColor: "#fff",
          // pointBorderWidth: 1,
          // pointStrokeColor: "#ff6c23",
          // pointHighlightFill: "#fff",
          // pointHighlightStroke: "#ff6c23",
          data: dap_tvoc,
        },
      ],
    };
  };

  let tvoc_bar_options = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },

          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: "In Units (ppb)",
          },
        },
      ],
      xAxes: [
        {
          // categoryPercentage: 1.0,
          // barPercentage: 1.0,
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Devices",
          },
        },
      ],
    },

    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  let co2_bar_options = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },

          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: "In Units (ppm)",
          },
        },
      ],
      xAxes: [
        {
          // categoryPercentage: 1.0,
          // barPercentage: 1.0,
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Devices",
          },
        },
      ],
    },

    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  const co2BarData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const good_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    good_gradient.addColorStop(0, "rgba(5, 230, 31, 1)");
    good_gradient.addColorStop(1, "rgba(134, 219, 144,0.5)");

    const satisfactory_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    satisfactory_gradient.addColorStop(0, "rgba(223, 223, 0, 1)");
    satisfactory_gradient.addColorStop(1, "rgba(223, 223, 0,0.5)");

    const moderate_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    moderate_gradient.addColorStop(0, "rgba(255, 153, 0, 1)");
    moderate_gradient.addColorStop(1, "rgba(242, 176, 78,0.5)");

    const poor_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    poor_gradient.addColorStop(0, "rgba(255, 0, 0, 1)");
    poor_gradient.addColorStop(1, "rgba(245, 105, 105,0.5)");

    const verypoor_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    verypoor_gradient.addColorStop(0, "rgba(112, 48, 160, 1)");
    verypoor_gradient.addColorStop(1, "rgba(112, 48, 160,0.5)");

    const severe_gradient = ctx.createLinearGradient(0, 0, 0, 150);
    severe_gradient.addColorStop(0, "rgba(153, 0, 51, 1)");
    severe_gradient.addColorStop(1, "rgba(153, 0, 51,0.5)");

    const co2colourArray = [];
    console.log(dap_co2.length);
    for (let i = 0; i < dap_co2.length; i++) {
      console.log(dap_co2[i]);
      if (dap_co2[i] > 4000) {
        co2colourArray.push(severe_gradient);
      } else if (dap_co2[i] > 3001 && dap_co2[i] <= 4000) {
        co2colourArray.push(verypoor_gradient);
      } else if (dap_co2[i] > 2001 && dap_co2[i] <= 3000) {
        co2colourArray.push(poor_gradient);
      } else if (dap_co2[i] > 1001 && dap_co2[i] <= 2000) {
        co2colourArray.push(moderate_gradient);
      } else if (dap_co2[i] > 501 && dap_co2[i] <= 1000) {
        co2colourArray.push(satisfactory_gradient);
      } else if (dap_co2[i] > 0 && dap_co2[i] <= 500) {
        co2colourArray.push(good_gradient);
      }
    }
    // device_iaq_val.forEach((i) => {
    //   if (device_iaq_val[i] > 401) {
    //     co2colourArray.push(severe_gradient);
    //   } else if (device_iaq_val[i] > 301 && device_iaq_val[i] <= 400) {
    //     co2colourArray.push(verypoor_gradient);
    //   } else if (device_iaq_val[i] > 201 && device_iaq_val[i] <= 300) {
    //     co2colourArray.push(poor_gradient);
    //   } else if (device_iaq_val[i] > 101 && device_iaq_val[i] <= 200) {
    //     co2colourArray.push(moderate_gradient);
    //   } else if (device_iaq_val[i] > 51 && device_iaq_val[i] <= 100) {
    //     co2colourArray.push(satisfactory_gradient);
    //   } else if (device_iaq_val[i] > 0 && device_iaq_val[i] <= 50) {
    //     co2colourArray.push(good_gradient);
    //   } else {
    //     co2colourArray.push(severe_gradient);
    //   }
    // });

    return {
      labels: dap_DeviceArray,
      datasets: [
        {
          fill: "start",
          label: "hera",
          backgroundColor: co2colourArray, // Put the gradient here as a fill color
          //borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
          pointBackgroundColor: "#5271ff",
          pointBorderWidth: 1,
          pointColor: "#fff",
          pointBorderWidth: 1,
          pointStrokeColor: "#ff6c23",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#ff6c23",
          data: dap_co2,
        },
      ],
    };
  };

  const showdevice = () => {
    setShowMore(!showMore);
    //setShowInactiveDevices(!showInactiveDevices);
  };

  return loader ? (
    <Loader />
  ) : (
    <div>
      {/* <SettingsPanel /> */}

      <div className='row'>
        <div
          className='col-md-6 col-lg-4 d-flex flex-column'
          style={{ marginBottom: "20px" }}
        >
          <div className='row'>
            <div className='col-lg-12'>
              <div
                className='card'
                style={{
                  boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                  borderRadius: "20px",
                  height: "100%",
                }}
              >
                <div className='card-body'>
                  {/* <h6 className="card-title">Air Quality Index</h6> */}
                  <div className='mx-auto' style={{ width: "62%" }}>
                    <div className='d-flex justify-content-between text-center'>
                      <h3
                        style={{
                          color: "#5864e8",
                          margin: "auto",
                          paddingBottom: "20px",
                          color: "#5271FF",
                          fontSize: "1rem",
                        }}
                      >
                        <Trans>AIR QUALITY INDEX</Trans>
                        {/* {deviceLoader ? <Loader /> : { dap_selectedDeviceName }} */}
                      </h3>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div
                        className='donut-legend  card'
                        style={{
                          backgroundColor: "#F8F8F8",
                          display: "block",
                          //flexDirection: 'column',
                          height: "100%",
                          width: "100%",
                          borderRadius: "6%",
                          padding: "2% 15% 5% 30%",
                          //marginBottom: '-32%',
                          justifyContent: "center",
                          textAlign: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <span>
                          <span
                            style={{
                              backgroundColor: "#00B050",
                              borderRadius: "0px",
                            }}
                          >
                            &nbsp;
                          </span>
                          <Trans>{dap_airqIndex.good}</Trans>
                        </span>

                        <span>
                          <span
                            style={{
                              backgroundColor: "#DFDF00",
                              borderRadius: "0px",
                            }}
                          >
                            &nbsp;
                          </span>
                          <Trans>{dap_airqIndex.satisfactory}</Trans>
                        </span>

                        <span>
                          <span
                            style={{
                              backgroundColor: "#FF9900",
                              borderRadius: "0px",
                            }}
                          >
                            &nbsp;
                          </span>
                          <Trans>{dap_airqIndex.moderate}</Trans>
                        </span>

                        <span>
                          <span
                            style={{
                              backgroundColor: "#FF0000",
                              borderRadius: "0px",
                            }}
                          >
                            &nbsp;
                          </span>
                          <Trans>{dap_airqIndex.poor}</Trans>
                        </span>

                        <span>
                          <span
                            style={{
                              backgroundColor: "#7030A0",
                              borderRadius: "0px",
                            }}
                          >
                            &nbsp;
                          </span>
                          <Trans>{dap_airqIndex.very_poor}</Trans>
                        </span>

                        <span>
                          <span
                            style={{
                              backgroundColor: "#990033",
                              borderRadius: "0px",
                            }}
                          >
                            &nbsp;
                          </span>
                          <Trans>{dap_airqIndex.severe}</Trans>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              {" "}
              <div
                className='card'
                style={{
                  boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                  borderRadius: "20px",
                  marginTop: "20px",
                }}
              >
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      {" "}
                      <h6 className='card-title' style={{ color: "#5271FF" }}>
                        PM 2.5
                      </h6>
                    </div>
                    <div className='col-lg-8'>
                      {" "}
                      {/* <p style={{ textAlign: "center", color: "#AFAFAF" }}>
                        Current Value: {latest_pm10} ppm
                      </p> */}
                    </div>
                  </div>
                  <div className='demo-chart' style={{ height: "100%" }}>
                    <C3Chart
                      data={splineData_pm25}
                      style={{ height: "187px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='col-md-6 col-lg-8 d-flex flex-column'
          style={{ marginBottom: "20px" }}
        >
          <div className='row'>
            {showMore ? (
              <>
                {dap_dashboardDevices.map((product) => (
                  <div
                    className='col-md-3 col-lg-3 stretch-card aqi-card-tenant-dashboard'
                    key={product.id}
                  >
                    <div
                      className='card'
                      onClick={() => goToChildDashboard(product)}
                      style={{
                        height: "93%",
                        borderRadius: "5%",
                        cursor: "pointer",
                      }}
                    >
                      <div className='card-body'>
                        <div
                          className='wrapper'
                          style={{ textAlign: "center" }}
                        >
                          {/* <h4>584</h4> */}
                          <h6 className='text-muted'>{product.device_name}</h6>
                        </div>
                        {/* <h6 className="card-title">Air Quality Index</h6> */}
                        <div
                          className='w-150 mx-auto'
                          style={{ marginTop: "10%" }}
                        >
                          <div className='d-flex justify-content-between text-center'></div>
                          {/* <div className="mt-5"> */}
                          <div
                            className='single-chart'
                            style={{ width: "80%" }}
                          >
                            <svg
                              viewBox='0 0 36 36'
                              style={{
                                borderRadius: "50%",
                                boxShadow: " 0 0 10px 3px #ccc",
                                width: "100%",
                                //height: "inherit",
                              }}
                            >
                              <path
                                //className={dap_iaqColor_1}
                                className={product.dap_iaqColor}
                                d='M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831'
                                //fill="#c7cdd6"
                              />
                              <path
                                className='circle'
                                strokeDasharray='100, 100'
                                d='M18 2.0845
                       a 10.9155 15.9155 0 0 1 0 31.831
                           a 15.9155 15.9155 0 0 1 0 -31.831'
                              />
                              <text
                                x='18'
                                y='14'
                                className={`percentage ${product.dap_iaqColor_1}`}
                                style={{ fontWeight: "bold" }}
                              >
                                {product.device_iaq}
                              </text>
                              <text
                                x='5.8'
                                y='20.35'
                                className='percentage-1'
                                style={{ fontSize: "0.20em" }}
                              >
                                Indoor Air Quality
                              </text>
                              <text
                                x={product.dap_iaqxval}
                                y='24.35'
                                className={`percentage-1 ${product.dap_iaqColor_1}`}
                                // className="percentage-1"
                                style={{
                                  fontSize: "0.20em",
                                  fontWeight: "bold",
                                }}
                                // className={dap_iaqColor_1}
                              >
                                {product.dap_iaqname}
                              </text>
                            </svg>
                          </div>
                          {/* </div> */}
                        </div>
                        <p
                          className='wrapper'
                          style={{
                            textAlign: "center",
                            marginTop: "5%",
                            fontSize: "small",
                          }}
                        >
                          {product.device_last_updated}
                        </p>
                        <p
                          className='wrapper'
                          style={{
                            textAlign: "center",
                            fontSize: "small",
                          }}
                        >
                          <i className='mdi mdi-battery'></i>
                          {product.bat}%
                        </p>
                        <p
                          className='wrapper'
                          style={{
                            textAlign: "center",
                            fontSize: "small",
                          }}
                        >
                          <i className='mdi mdi-wifi'></i>
                          {product.mode}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                {dap_nillDashboardDevices.map((product) => (
                  <div
                    className='col-md-3 col-lg-3 stretch-card aqi-card-tenant-dashboard'
                    key={product.id}
                  >
                    <div
                      className='card'
                      style={{ height: "93%", borderRadius: "5%" }}
                    >
                      <div className='card-body'>
                        <div
                          className='wrapper'
                          style={{ textAlign: "center" }}
                        >
                          {/* <h4>584</h4> */}
                          <h6 className='text-muted'>{product}</h6>
                        </div>
                        {/* <h6 className="card-title">Air Quality Index</h6> */}
                        <div
                          className='w-150 mx-auto'
                          style={{ marginTop: "10%" }}
                        >
                          <div className='d-flex justify-content-between text-center'></div>
                          {/* <div className="mt-5"> */}
                          <div
                            className='single-chart'
                            style={{ width: "80%" }}
                          >
                            <svg
                              viewBox='0 0 36 36'
                              style={{
                                borderRadius: "50%",
                                boxShadow: " 0 0 10px 3px #ccc",
                                width: "100%",
                              }}
                            >
                              <path
                                className={dap_emptyDeviceColor}
                                className={dap_emptyDeviceColor}
                                d='M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831'
                              />
                              <path
                                className='circle'
                                strokeDasharray='100, 100'
                                d='M18 2.0845
                       a 10.9155 15.9155 0 0 1 0 31.831
                           a 15.9155 15.9155 0 0 1 0 -31.831'
                                className={dap_emptyDeviceColor}
                              />
                              <text
                                x='18'
                                y='14'
                                className={`percentage ${product.dap_iaqColor_1}`}
                                style={{
                                  fontSize: "0.20rem",
                                  fontWeight: "bold",
                                }}
                              >
                                Inactive
                              </text>
                              <text
                                x='5.8'
                                y='20.35'
                                className='percentage-1'
                                style={{ fontSize: "0.20em" }}
                              >
                                Indoor Air Quality
                              </text>
                              <text
                                x={product.dap_iaqxval}
                                y='24.35'
                                className={`percentage-1 ${product.dap_iaqColor_1}`}
                                // className="percentage-1"
                                style={{
                                  fontSize: "0.20em",
                                  fontWeight: "bold",
                                }}
                                // className={dap_iaqColor_1}
                              >
                                {product.dap_iaqname}
                              </text>
                            </svg>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {dap_dashboardArray.map((product) => (
                  <div
                    className='col-md-3 col-lg-3 stretch-card aqi-card-tenant-dashboard'
                    key={product.id}
                  >
                    <div
                      className='card'
                      onClick={() => goToChildDashboard(product)}
                      style={{
                        height: "93%",
                        borderRadius: "5%",
                        cursor: "pointer",
                      }}
                    >
                      <div className='card-body'>
                        <div
                          className='wrapper'
                          style={{ textAlign: "center" }}
                        >
                          {/* <h4>584</h4> */}
                          <h6 className='text-muted'>{product.device_name}</h6>
                        </div>
                        {/* <h6 className="card-title">Air Quality Index</h6> */}
                        <div
                          className='w-150 mx-auto'
                          style={{ marginTop: "10%" }}
                        >
                          <div className='d-flex justify-content-between text-center'></div>
                          {/* <div className="mt-5"> */}
                          <div
                            className='single-chart'
                            style={{ width: "80%" }}
                          >
                            <svg
                              viewBox='0 0 36 36'
                              style={{
                                borderRadius: "50%",
                                boxShadow: " 0 0 10px 3px #ccc",
                                width: "100%",
                                //height: "inherit",
                              }}
                            >
                              <path
                                //className={dap_iaqColor_1}
                                className={product.dap_iaqColor}
                                d='M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831'
                                //fill="#c7cdd6"
                              />
                              <path
                                className='circle'
                                strokeDasharray='100, 100'
                                d='M18 2.0845
                       a 10.9155 15.9155 0 0 1 0 31.831
                           a 15.9155 15.9155 0 0 1 0 -31.831'
                              />
                              <text
                                x='18'
                                y='14'
                                className={`percentage ${product.dap_iaqColor_1}`}
                                style={{ fontWeight: "bold" }}
                              >
                                {product.device_iaq}
                              </text>
                              <text
                                x='5.8'
                                y='20.35'
                                className='percentage-1'
                                style={{ fontSize: "0.20em" }}
                              >
                                Indoor Air Quality
                              </text>
                              <text
                                x={product.dap_iaqxval}
                                y='24.35'
                                className={`percentage-1 ${product.dap_iaqColor_1}`}
                                // className="percentage-1"
                                style={{
                                  fontSize: "0.20em",
                                  fontWeight: "bold",
                                }}
                                // className={dap_iaqColor_1}
                              >
                                {product.dap_iaqname}
                              </text>
                            </svg>
                          </div>
                          {/* </div> */}
                        </div>
                        <p
                          className='wrapper'
                          style={{
                            textAlign: "center",
                            marginTop: "5%",
                            fontSize: "small",
                          }}
                        >
                          {product.device_last_updated}
                        </p>
                        <p
                          className='wrapper'
                          style={{
                            textAlign: "center",
                            fontSize: "small",
                          }}
                        >
                          <i className='mdi mdi-battery'></i>
                          {product.bat}%
                        </p>
                        <p
                          className='wrapper'
                          style={{
                            textAlign: "center",
                            fontSize: "small",
                          }}
                        >
                          <i className='mdi mdi-wifi'></i>
                          {product.mode}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                {dap_emptyDeviceArray.map((product) => (
                  <div
                    className='col-md-3 col-lg-3 stretch-card aqi-card-tenant-dashboard'
                    key={product.id}
                  >
                    <div
                      className='card'
                      style={{ height: "93%", borderRadius: "5%" }}
                    >
                      <div className='card-body'>
                        <div
                          className='wrapper'
                          style={{ textAlign: "center" }}
                        >
                          {/* <h4>584</h4> */}
                          <h6 className='text-muted'>{product}</h6>
                        </div>
                        {/* <h6 className="card-title">Air Quality Index</h6> */}
                        <div
                          className='w-150 mx-auto'
                          style={{ marginTop: "10%" }}
                        >
                          <div className='d-flex justify-content-between text-center'></div>
                          {/* <div className="mt-5"> */}
                          <div
                            className='single-chart'
                            style={{ width: "80%" }}
                          >
                            <svg
                              viewBox='0 0 36 36'
                              style={{
                                borderRadius: "50%",
                                boxShadow: " 0 0 10px 3px #ccc",
                                width: "100%",
                              }}
                            >
                              <path
                                className={dap_emptyDeviceColor}
                                className={dap_emptyDeviceColor}
                                d='M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831'
                              />
                              <path
                                className='circle'
                                strokeDasharray='100, 100'
                                d='M18 2.0845
                       a 10.9155 15.9155 0 0 1 0 31.831
                           a 15.9155 15.9155 0 0 1 0 -31.831'
                                className={dap_emptyDeviceColor}
                              />
                              <text
                                x='18'
                                y='14'
                                className={`percentage ${product.dap_iaqColor_1}`}
                                style={{
                                  fontSize: "0.20rem",
                                  fontWeight: "bold",
                                }}
                              >
                                Inactive
                              </text>
                              <text
                                x='5.8'
                                y='20.35'
                                className='percentage-1'
                                style={{ fontSize: "0.20em" }}
                              >
                                Indoor Air Quality
                              </text>
                              <text
                                x={product.dap_iaqxval}
                                y='24.35'
                                className={`percentage-1 ${product.dap_iaqColor_1}`}
                                // className="percentage-1"
                                style={{
                                  fontSize: "0.20em",
                                  fontWeight: "bold",
                                }}
                                // className={dap_iaqColor_1}
                              >
                                {product.dap_iaqname}
                              </text>
                            </svg>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className='col-md-3 col-lg-3'>
              {showMoreBtn ? (
                <>
                  {" "}
                  <div
                    className='btn'
                    onClick={() => showdevice()}
                    style={{
                      height: "93%",
                      borderRadius: "5%",
                    }}
                  >
                    <div>
                      {showMore ? (
                        <h4
                          style={{
                            textAlign: "center",
                            color: "#2f54eb",
                            //marginTop: "210%",
                          }}
                        >
                          Show More{" "}
                          <span id='showmore'>
                            <i className='fa fa-plus'></i>
                          </span>
                        </h4>
                      ) : (
                        <h4
                          style={{
                            textAlign: "center",
                            color: "#2f54eb",
                          }}
                        >
                          Show Less{" "}
                          <span id='showmore'>
                            <i className='fa fa-minus'></i>
                          </span>
                        </h4>
                      )}
                    </div>
                  </div>{" "}
                </>
              ) : (
                <> </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-sm-12 col-lg-4 grid-margin stretch-card'>
          <div
            className='card'
            style={{
              boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
              borderRadius: "20px",
            }}
          >
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-4'>
                  {" "}
                  <h6 className='card-title' style={{ color: "#5271FF" }}>
                    PM 10
                  </h6>
                </div>
                <div className='col-lg-8'>
                  {" "}
                  {/* <p style={{ textAlign: "center", color: "#AFAFAF" }}>
                    Current Value: {latest_pm10} ppm
                  </p> */}
                </div>
              </div>
              <div className='demo-chart' style={{ height: "100%" }}>
                {/* <Line
                  data={dap_pm10ChartData}
                  options={pm10_options}
                  //height={150}
                /> */}

                <C3Chart data={splineData_pm10} style={{ height: "187px" }} />

                {/* <C3Chart
                  data={{
                    columns: dap_pm10ChartData,
                    type: "bar",
                    // e69a8d
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 col-sm-12 col-lg-4 grid-margin stretch-card'>
          <div
            className='card'
            style={{
              boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
              borderRadius: "20px",
            }}
          >
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-4'>
                  {" "}
                  <h6 className='card-title' style={{ color: "#5271FF" }}>
                    Co2
                  </h6>
                </div>
                <div className='col-lg-8'>
                  {" "}
                  {/* <p style={{ textAlign: "center", color: "#AFAFAF" }}>
                    Current Value: {latest_co2} mg/m3
                  </p> */}
                </div>
              </div>

              <div className='demo-chart' style={{ height: "100%" }}>
                {/* <Bar
                  data={co2BarData}
                  options={co2_bar_options}
                  //height={150}
                /> */}
                <Bar
                  data={co2BarData}
                  options={co2_bar_options}
                  //height={150}
                  style={{ height: "187px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 col-sm-12 col-lg-4 grid-margin stretch-card'>
          <div
            className='card'
            style={{
              boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
              borderRadius: "20px",
            }}
          >
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-4'>
                  {" "}
                  <h6 className='card-title' style={{ color: "#5271FF" }}>
                    TVOC
                  </h6>
                </div>
                <div className='col-lg-8'>
                  {" "}
                  {/* <p style={{ textAlign: "center", color: "#AFAFAF" }}>
                    Current Value: {latest_tvoc} ppb
                  </p> */}
                </div>
              </div>

              <div className='demo-chart' style={{ height: "100%" }}>
                <Bar
                  data={tvocBarData}
                  options={tvoc_bar_options}
                  style={{ height: "187px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 col-lg-4'>
          {/* temperature */}
          <div
            className='card'
            style={{
              paddingTop: "40px",
              boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
              border: "none",
              background: "linear-gradient(to bottom, #5271FF, #293980)",
              height: "100%",
              margin: "0px 20px 0px 20px",
              borderRadius: "8%",
            }}
          >
            <h1
              className='card-title'
              style={{ color: "white", textAlign: "center" }}
            >
              <Trans>TEMPERATURE</Trans>
            </h1>
            <div className='card-body'>
              <h4
                className='city'
                style={{ textAlign: "center", fontSize: "30px" }}
              >
                {dap_city}
              </h4>
              <p style={{ color: "white", textAlign: "center" }}>
                {dap_currentDay}
              </p>

              {/* <div className="row">
                <div className="col-lg-6">
                  <h6
                    className="outdoor"
                    style={{ textAlign: "center", color: "white" }}
                  >
                    OUTDOOR
                  </h6>
                  <h4 className="city" style={{ textAlign: "center" }}>
                    {dap_temperature} <sup className="unit">°C</sup>
                  </h4>
                  <p className="td-value">HUMIDITY: {dap_humidity}</p>
                </div>
                <div className="col-lg-6">
                  <h6
                    className="outdoor"
                    style={{ textAlign: "center", color: "white" }}
                  >
                    INDOOR
                  </h6>
                  <h4 className="city" style={{ textAlign: "center" }}>
                    {dap_temperature} <sup className="unit">°C</sup>
                  </h4>
                  <p className="td-value">HUMIDITY: {latest_humidity}</p>
                </div>
              </div> */}
              <hr></hr>
              <div className='row'>
                <div className='col-lg-4 mx-auto'>
                  <div className='icon'>
                    <img
                      src={dap_WeatherData.imageUrl}
                      alt='weathericon'
                      //style={{ marginLeft: "30%", width: "120%" }}
                      id='weathericon'
                    />
                  </div>
                </div>
                <div className='col-lg-8 mx-auto '>
                  <h4
                    className='city'
                    style={{
                      textAlign: "center",
                      fontSize: "50px",
                      marginTop: "20%",
                    }}
                  >
                    {dap_temperature} <sup className='unit'>°C</sup>
                  </h4>
                </div>
              </div>

              <div className='row mx-auto'>
                <div className='col-lg-6 '>
                  {" "}
                  <p className='td-value' style={{ fontSize: "14px" }}>
                    {" "}
                    PRESSURE: {dap_pressure} hPa
                  </p>
                </div>
                <div className='col-lg-6 '>
                  {" "}
                  <p className='td-value' style={{ fontSize: "14px" }}>
                    WINDSPEED: {dap_windspeed} km/h
                  </p>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-6 mx-auto ">
                  <p className="td-value"> PRESSURE: {dap_pressure}</p>
                </div>
                <div className="col-lg-6 mx-auto td-value">
                  <p className="td-value">WINDSPEED: {dap_windspeed}</p>
                </div>
              </div> */}
            </div>
            {/* trial ends */}
          </div>
        </div>
        <div className='col-md-8 col-lg-8'>
          {/*map */}
          <div className='row'>
            <div
              class=' card col-md-12'
              // style={{
              //   padding: "10px",
              //   margin: "0px 20px 0px 20px",
              //   borderRadius: "8%",
              //   border: "none",
              // }}
            >
              {isMapToCalled ? (
                <MapComponent> </MapComponent>
              ) : (
                <>
                  <Spinner />
                </>
              )}
              {/* {isMapToCalled ? <MapComponent> </MapComponent> : <></>} */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-md-6 col-lg-4 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-md-center">
                <i className="mdi mdi-laptop icon-lg text-success"></i>
                <div className="ml-3">
                  <p className="mb-0">NO OF DEVICES</p>
                  <h6>{dap_allTenantDevice.length}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-md-center">
                <i className="mdi mdi-rocket icon-lg text-warning"></i>
                <div className="ml-3">
                  <p className="mb-0">NO OF ACTIVE DEVICES</p>
                  <h6>{dap_dashboardArray.length}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-md-center">
                <i className="mdi mdi-account icon-lg text-info"></i>
                <div className="ml-3">
                  {dap_dashboardArray.dap_iaqname}

                  <p className="mb-0">NO OF CUSTOMERS</p>
                  <h6>{dap_allTenantCustomer.length}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <WeatherByCity /> */}

      {/* endddddd */}
    </div>
  );
};

export default TenantDashboard;
