import cogoToast from "cogo-toast";
import Alert from "react-bootstrap/Alert";
import axios from "axios";

import { environment } from "../../environment.js";

import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Line, Bar, Doughnut, Pie, Scatter } from "react-chartjs-2";
import C3Chart from "react-c3js";
import { MultiSelect } from "react-multi-select-component";
import Loader from "../Components/Loader.js";
import "./HistoricalData.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
//import { ObjectsToCsv } from "objects-to-csv";
import CsvDownload from "react-json-to-csv";

import "react-datepicker/dist/react-datepicker.css";

const MySwal = withReactContent(Swal);

const HistoricalData = () => {
  const [dap_deviceList, setDapDevices] = useState([]);
  const [dap_deviceNames, setDeviceNames] = useState([]);
  const [loader, setLoader] = useState(false);
  const [interval, setdapInterval] = useState(0);
  const [limit, setLimit] = useState(0);
  const [dap_selectedDevice, setSelectedDeviceName] = useState("");
  const [deviceDataCo2, setDeviceDataCo2] = useState([]);
  const [deviceDataPm25, setDeviceDataPm25] = useState([]);
  const [deviceDataPm10, setDeviceDataPm10] = useState([]);
  const [deviceDataTemp, setDeviceDataTemp] = useState([]);
  const [deviceDataTvoc, setDeviceDataTvoc] = useState([]);
  const [deviceDataIaq, setDeviceDataIaq] = useState([]);
  const [dap_pm25chartData, setpm25chartData] = useState([]);
  const [dap_pm10chartData, setpm10chartData] = useState([]);
  const [dap_co2chartData, setco2chartData] = useState([]);
  const [dap_tvocchartData, settvocchartData] = useState([]);
  const [dap_tempchartData, settempchartData] = useState([]);
  const [dap_iaqchartData, setiaqchartData] = useState([]);
  const [multiDevice, setMultiDevice] = useState([]);
  const [compareDeviceTemp, setCompareDeviceTemp] = useState([]);
  const [compareDeviceTvoc, setCompareDeviceTvoc] = useState([]);
  const [compareDeviceCo2, setCompareDeviceCo2] = useState([]);
  const [compareDevicePm25, setCompareDevicePm25] = useState([]);
  const [compareDevicePm10, setCompareDevicePm10] = useState([]);
  const [compareDeviceIaq, setCompareDeviceIaq] = useState([]);
  const [allDevicedata, setAllDevicedata] = useState([]);
  const [showData, setShowData] = useState(false);
  const [showCompare, setShowCompare] = useState(false);
  const [enable, setEnable] = useState(false);
  const [dataInCSV, setDataInCSV] = useState({});

  const [showDownload, setShowDownload] = useState(false);
  const [iaqData, setIaqData] = useState([]);
  const [CO2spikeData, setCO2SpikeData] = useState([]);
  const [PM25spikeData, setPM25SpikeData] = useState([]);
  const [PM10spikeData, setPM10SpikeData] = useState([]);
  const [TEMPspikeData, setTEMPSpikeData] = useState([]);
  const [TVOCspikeData, setTVOCSpikeData] = useState([]);
  const [dap_time_array, setTimeArray] = useState([]);

  const [searchLoading, setSearchLoading] = useState(false);
  const [compareLoading, setCompareLoading] = useState(false);
  const [convertCsv, setConvertCsv] = useState(false);

  const [startTs1, setStartTs1] = useState(new Date(Date.now() - 86400000));
  const [endTs1, setEndTs1] = useState(new Date(Date.now() - 8640000));

  const [startTs2, setStartTs2] = useState(new Date(Date.now() - 86400000));
  const [endTs2, setEndTs2] = useState(new Date(Date.now() - 8640000));

  let dashboardDataArray = [];

  let iaqDataArray = [];

  let CO2spikeDataArray = [];

  let PM25spikeDataArray = [];

  let PM10spikeDataArray = [];

  let TVOCspikeDataArray = [];

  let TEMPspikeDataArray = [];

  let data = {
    labels: ["2013", "2014", "2014", "2015", "2016", "2017"],
    datasets: [
      {
        label: "# of Votes",
        data: compareDeviceIaq,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  let options = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: "In Units (mg/m3)  ",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Time Period (in sec)",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 1.5,
      },
    },
  };

  const pm25ChartDataa = {
    labels: dap_time_array,
    datasets: [
      {
        label: "PM 25",
        data: dap_pm25chartData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const pm10ChartDataa = {
    labels: dap_time_array,
    datasets: [
      {
        label: "PM 10",
        data: dap_pm10chartData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const co2ChartDataa = {
    labels: dap_time_array,
    datasets: [
      {
        label: "CO2",
        data: dap_co2chartData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const tvocChartDataa = {
    labels: dap_time_array,
    datasets: [
      {
        label: "TVOC",
        data: dap_tvocchartData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const tempChartDataa = {
    labels: dap_time_array,
    datasets: [
      {
        label: "TEMP",
        data: dap_tempchartData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const iaqChartDataa = {
    labels: dap_time_array,
    datasets: [
      {
        label: "IAQ",
        data: dap_iaqchartData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const splineDatapm25 = {
    columns: dap_pm25chartData,
    type: "spline",
  };

  const splineDatapm10 = {
    columns: dap_pm10chartData,
    type: "spline",
  };

  const splineDataco2 = {
    columns: dap_co2chartData,
    type: "spline",
  };

  const splineDatatvoc = {
    columns: dap_tvocchartData,
    type: "spline",
  };

  const splineDatatemp = {
    columns: dap_tempchartData,
    type: "spline",
  };

  const splineDataiaq = {
    columns: dap_iaqchartData,
    type: "spline",
  };

  const compareTemp = {
    columns: compareDeviceTemp,
    type: "spline",
    colors: {
      data1: "#00de3b",
      data2: "#00de3b",
      data3: "#00de3b",
    },
  };

  const compareTvoc = {
    columns: compareDeviceTvoc,
    type: "spline",
    colors: {
      data1: "#00de3b",
      data2: "#00de3b",
      data3: "#00de3b",
    },
  };

  const compareCo2 = {
    columns: compareDeviceCo2,
    type: "spline",
    colors: {
      data1: "#00de3b",
      data2: "#00de3b",
      data3: "#00de3b",
    },
  };

  const comparePm25 = {
    columns: compareDevicePm25,
    type: "spline",
    colors: {
      data1: "#00de3b",
      data2: "#00de3b",
      data3: "#00de3b",
    },
  };

  const comparePm10 = {
    columns: compareDevicePm10,
    type: "spline",
    colors: {
      data1: "#00de3b",
      data2: "#00de3b",
      data3: "#00de3b",
    },
  };

  const compareIaq = {
    columns: compareDeviceIaq,
    type: "spline",
    colors: {
      data1: "#00de3b",
      data2: "#00de3b",
      data3: "#00de3b",
    },
  };

  const headers = [
    { label: "ts", key: "TIMESTAMP" },
    { label: "time", key: "TIME" },
    { label: "iaq value", key: "IAQ" },
    { label: "co2 value", key: "CO2_ppm" },
    { label: "pm2.5 value", key: "PM2.5_ppm" },
    { label: "pm 10 value", key: "PM10_ppm" },
    { label: "tvoc value", key: "TVOC_ppb" },
    { label: "temp value", key: "TEMPERATURE_Celsius" },
    { label: "rh value", key: "HUMIDITY_rh" },
    { label: "lat", key: "LAT" },
    { label: "lon", key: "LON" },
  ];

  const spike_headers = [
    { label: "ts", key: "TIMESTAMP" },
    { label: "time", key: "TIME" },
    { label: "iaq value", key: "IAQ" },
    { label: "co2 value", key: "CO2_ppm" },
    { label: "pm2.5 value", key: "PM25_ppm" },
    { label: "pm 10 value", key: "PM10_ppm" },
    { label: "tvoc value", key: "TVOC_ppb" },
    { label: "temp value", key: "TEMPERATURE_Celsius" },
    { label: "rh value", key: "HUMIDITY_rh" },
  ];

  //const createCsvFileName = () => `data_${moment().format()}.csv`;

  let salesOptions = {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          barPercentage: 0.4,
          ticks: {
            display: false,
            beginAtZero: true,
            fontColor: "rgba(0, 0, 0, 1)",
          },
          gridLines: {
            display: false,
            drawBorder: false,
            color: "transparent",
            zeroLineColor: "#eeeeee",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            display: false,
            color: "#eeeeee",
          },
          categoryPercentage: 0.5,
          ticks: {
            display: false,
            beginAtZero: true,
            stepSize: 10,
            max: 80,
            fontColor: "rgba(0, 0, 0, 1)",
          },
        },
      ],
    },
  };

  useEffect(() => {
    //setLoader(true);
    axios
      .get(environment.apiUrl + `tenant/devices?pageSize=1000&page=0`, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })

      .then((res) => {
        // const devices = res.data.data;
        setLoader(false);

        setDapDevices(res.data.data);
        setDeviceNames(res.data.data.name);
        //setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const changeDevice = (device) => {
    console.log(device);
    setSelectedDeviceName(device);

    // dashboardService.sendMessage(device.value);
    // getDashboardData();
  };

  const changeFromDate = (e) => {
    console.log(e);
    // setSelectedDeviceName(device);

    // dashboardService.sendMessage(device.value);
    // getDashboardData();
  };
  const changeToDate = (e) => {
    console.log(e);
    // setSelectedDeviceName(device);

    // dashboardService.sendMessage(device.value);
    // getDashboardData();
  };

  const compareData = () => {
    setShowCompare(false);
    let token = localStorage.getItem("token");

    let multidevicetemp = [];
    let multidevicetvoc = [];
    let multideviceco2 = [];
    let multidevicepm25 = [];
    let multidevicepm10 = [];
    let multideviceiaq = [];

    let tempdata = [];
    console.log("multidevice", multiDevice);
    console.log(multiDevice.length);
    for (let i = 0; i < multiDevice.length; i++) {}
    let multiDeviceIds = [];
    let allDeviceArray = [];
    console.log(multiDeviceIds, allDeviceArray);

    if (multiDevice.length > 0) {
      compareDataFunction();
    } else {
      MySwal.fire({
        title: <p>Please enter device </p>,
        type: "error",
      });
    }

    console.log(allDeviceArray);
    console.log(Object.keys(allDeviceArray).length);

    console.log(multiDeviceIds);
    //console.log(compareDeviceTemp.length);
    console.log("temp", multidevicetemp);
    console.log("tvoc", multidevicetvoc);
    console.log("pm25", multidevicepm25);
    console.log("pm10", multidevicepm10);
    console.log("co2", multideviceco2);
    console.log("iaq", multideviceiaq);
    // setCompareDeviceTemp(multidevicetemp)
    // setCompareDeviceTvoc(multidevicetvoc)
    console.log(typeof multidevicetemp);
    console.log(multidevicetemp.length);
  };

  const compareDataFunction = () => {
    let token = localStorage.getItem("token");
    let objToPush = {};
    let dataToPush = {};

    let multidevicetemp = [];
    let multidevicetvoc = [];
    let multideviceco2 = [];
    let multidevicepm25 = [];
    let multidevicepm10 = [];
    let multideviceiaq = [];

    let tempdata = [];
    console.log("multidevice", multiDevice);
    console.log(multiDevice.length);
    for (let i = 0; i < multiDevice.length; i++) {}
    let multiDeviceIds = [];
    let allDeviceArray = [];
    setCompareLoading(true);
    //setLoader(true);
    setShowData(false);

    multiDevice.forEach((i) => {
      console.log(i.value);
      console.log(i.label);
      multiDeviceIds.push(i.value);

      axios
        .get(
          environment.apiUrl +
            "plugins/telemetry/DEVICE/" +
            i.value +
            "/values/timeseries?keys=iaq,pm25,pm10,co2,tvoc,temp,rh&startTs=" +
            //(Date.now() - 10 * 60 * 60 * 5000) +
            Date.parse(startTs2) +
            "&endTs=" +
            // Date.now() +
            Date.parse(endTs2) +
            "&interval=600000&limit=20",
          { headers: { "X-Authorization": "Bearer " + token } }
        )
        .then((res) => {
          setCompareLoading(false);
          //setLoader(false);
          let singleData = { deviceName: i.label, deviceData: res.data };
          allDeviceArray.push(singleData);
          setAllDevicedata(allDeviceArray);

          console.log(res);
          console.log(res.data.temp);
          setCompareDeviceTemp(res.data.temp);
          //        console.log(compareDeviceTemp)
          multidevicetemp.push(res.data.temp);
          multidevicetvoc.push(res.data.tvoc);
          multidevicepm25.push(res.data.pm25);
          multidevicepm10.push(res.data.pm10);
          multideviceco2.push(res.data.co2);
          multideviceiaq.push(res.data.iaq);

          if (Object.keys(res.data).length > 0) {
            // setLAtestPm10(res.data.pm10[0].value);
            // if (res.data.pm25[0].value) {
            // }
            // const pm25ChartData = []
            // for (let i = 0; i < noOfActiveDevices; i++) {
            //   pm25ChartData.push [deviceName, res.data.pm25[i].value]
            // }
            // let n = 0;
            // for (n = 0; n < Object.keys(res.data).length; n++) {
            //   dataToPush = {
            //     pm25ChartData: [i.label, res.data.pm25[n].value],
            //   };
            // }
            if (
              (res.data.pm25,
              res.data.pm10,
              res.data.co2,
              res.data.tvoc,
              res.data.temp,
              res.data.iaq)
            ) {
            }
            objToPush = {
              pm25ChartData: [
                i.label,
                res.data.pm25[0].value,
                res.data.pm25[1].value,
                res.data.pm25[2].value,
                res.data.pm25[3].value,
                res.data.pm25[4].value,
                res.data.pm25[5].value,
                res.data.pm25[6].value,
                res.data.pm25[7].value,
                res.data.pm25[8].value,
                res.data.pm25[9].value,
                res.data.pm25[10].value,
                res.data.pm25[11].value,
                res.data.pm25[12].value,
                res.data.pm25[13].value,
                res.data.pm25[14].value,
                res.data.pm25[15].value,
                res.data.pm25[16].value,
                res.data.pm25[17].value,
                res.data.pm25[18].value,
                res.data.pm25[19].value,
              ],

              pm10ChartData: [
                i.label,
                res.data.pm10[0].value,
                res.data.pm10[1].value,
                res.data.pm10[2].value,
                res.data.pm10[3].value,
                res.data.pm10[4].value,
                res.data.pm10[5].value,
                res.data.pm10[6].value,
                res.data.pm10[7].value,
                res.data.pm10[8].value,
                res.data.pm10[9].value,
                res.data.pm10[10].value,
                res.data.pm10[11].value,
                res.data.pm10[12].value,
                res.data.pm10[13].value,
                res.data.pm10[14].value,
                res.data.pm10[15].value,
                res.data.pm10[16].value,
                res.data.pm10[17].value,
                res.data.pm10[18].value,
                res.data.pm10[19].value,
              ],
              tempChartData: [
                i.label,
                res.data.temp[0].value,
                res.data.temp[1].value,
                res.data.temp[2].value,
                res.data.temp[3].value,
                res.data.temp[4].value,
                res.data.temp[5].value,
                res.data.temp[6].value,
                res.data.temp[7].value,
                res.data.temp[8].value,
                res.data.temp[9].value,
                res.data.temp[10].value,
                res.data.temp[11].value,
                res.data.temp[12].value,
                res.data.temp[13].value,
                res.data.temp[14].value,
                res.data.temp[15].value,
                res.data.temp[16].value,
                res.data.temp[17].value,
                res.data.temp[18].value,
                res.data.temp[19].value,
              ],

              tvocChartData: [
                i.label,
                res.data.tvoc[0].value,
                res.data.tvoc[1].value,
                res.data.tvoc[2].value,
                res.data.tvoc[3].value,
                res.data.tvoc[4].value,
                res.data.tvoc[5].value,
                res.data.pm25[6].value,
                res.data.tvoc[7].value,
                res.data.tvoc[8].value,
                res.data.tvoc[9].value,
                res.data.tvoc[10].value,
                res.data.tvoc[11].value,
                res.data.tvoc[12].value,
                res.data.tvoc[13].value,
                res.data.tvoc[14].value,
                res.data.tvoc[15].value,
                res.data.pm25[16].value,
                res.data.tvoc[17].value,
                res.data.tvoc[18].value,
                res.data.tvoc[19].value,
              ],
              co2ChartData: [
                i.label,
                res.data.co2[0].value,
                res.data.co2[1].value,
                res.data.co2[2].value,
                res.data.co2[3].value,
                res.data.co2[4].value,
                res.data.co2[5].value,
                res.data.co2[6].value,
                res.data.co2[7].value,
                res.data.co2[8].value,
                res.data.co2[9].value,
                res.data.co2[10].value,
                res.data.co2[11].value,
                res.data.co2[12].value,
                res.data.co2[13].value,
                res.data.co2[14].value,
                res.data.co2[15].value,
                res.data.co2[16].value,
                res.data.co2[17].value,
                res.data.co2[18].value,
                res.data.co2[19].value,
              ],

              iaqChartData: [
                i.label,
                res.data.iaq[0].value,
                res.data.iaq[1].value,
                res.data.iaq[2].value,
                res.data.iaq[3].value,
                res.data.iaq[4].value,
                res.data.iaq[5].value,
                res.data.iaq[6].value,
                res.data.iaq[7].value,
                res.data.iaq[8].value,
                res.data.iaq[9].value,
                res.data.iaq[10].value,
                res.data.iaq[11].value,
                res.data.iaq[12].value,
                res.data.iaq[13].value,
                res.data.iaq[14].value,
                res.data.iaq[15].value,
                res.data.iaq[16].value,
                res.data.iaq[17].value,
                res.data.iaq[18].value,
                res.data.iaq[19].value,
              ],
            };

            dashboardDataArray.push(objToPush);
            // setIaq_ColorClassName_1(objToPush.iaqColorClassName2)

            console.log(dashboardDataArray);
            setCompareDevicePm25([objToPush.pm25ChartData]);
            setCompareDevicePm10([objToPush.pm10ChartData]);
            setCompareDeviceTemp([objToPush.tempChartData]);
            setCompareDeviceTvoc([objToPush.tvocChartData]);
            setCompareDeviceCo2([objToPush.co2ChartData]);
            setCompareDeviceIaq([objToPush.iaqChartData]);

            console.log([objToPush.iaqChartData]);
            console.log(compareDeviceIaq);
          }

          //setCompareDeviceTemp([objToPush.tempChartData])
        })
        .catch((err) => {
          MySwal.fire({
            title: <p>Comparing Device Data.. </p>,
            type: "error",
          });
        });

      setShowCompare(true);
      //setMultiDevice([]);
    });
  };

  const searchData = () => {
    if (dap_selectedDevice) {
      setSearchLoading(true);
      setShowCompare(false);
      setLoader(true);
      let token = localStorage.getItem("token");

      console.log(dap_selectedDevice);
      axios
        .get(
          environment.apiUrl +
            "plugins/telemetry/DEVICE/" +
            dap_selectedDevice.value +
            "/values/timeseries?keys=iaq,pm25,pm10,co2,tvoc,temp,rh&startTs=" +
            //   (Date.now() - 10 * 60 * 60 * 5000) +
            Date.parse(startTs1) +
            "&endTs=" +
            //   Date.now() +
            Date.parse(endTs1) +
            "&interval=60000&limit=20",
          // interval +
          // "&limit=" +
          // limit,
          { headers: { "X-Authorization": "Bearer " + token } }
        )
        .then((res) => {
          setSearchLoading(false);
          console.log("device data", res.data.iaq);
          //const csv = new ObjectsToCsv(res.data);

          console.log(JSON.parse(JSON.stringify(res.data)));
          downloadCSV();
          // res.data.iaq.forEach((element) => {
          //   //iaqDataArray.push({ ts: element.ts, iaq_value: element.value });

          //   let date = new Date(element.ts);
          //   console.log(new Date(element.ts).getTime());
          //   let iaqtime =
          //     date.getDate() +
          //     "/" +
          //     (date.getMonth() + 1) +
          //     "/" +
          //     date.getFullYear() +
          //     " " +
          //     date.getHours() +
          //     ":" +
          //     date.getMinutes() +
          //     ":" +
          //     date.getSeconds();
          //   console.log(iaqtime);
          //   iaqDataArray.push({
          //     ts: element.ts,
          //     time: iaqtime,
          //     iaq_value: element.value,
          //   });
          // });
          // console.log(res.data.co2);

          // res.data.co2.forEach((element) => {
          //   iaqDataArray.forEach((item) => {
          //     console.log(item.co2, element.value);
          //     if (item.ts == element.ts) {
          //       item.co2_value = element.value;
          //     }
          //   });
          // });

          // res.data.pm25.forEach((element) => {
          //   iaqDataArray.forEach((item) => {
          //     console.log(item.pm25, element.value);
          //     if (item.ts == element.ts) {
          //       item.pm25_value = element.value;
          //     }
          //   });
          // });

          // res.data.pm10.forEach((element) => {
          //   iaqDataArray.forEach((item) => {
          //     console.log(item.pm10, element.value);
          //     if (item.ts == element.ts) {
          //       item.pm10_value = element.value;
          //     }
          //   });
          // });

          // res.data.temp.forEach((element) => {
          //   iaqDataArray.forEach((item) => {
          //     console.log(item.temp, element.value);
          //     if (item.ts == element.ts) {
          //       item.temp_value = element.value;
          //     }
          //   });
          // });

          // res.data.rh.forEach((element) => {
          //   iaqDataArray.forEach((item) => {
          //     console.log(item.rh, element.value);
          //     if (item.ts == element.ts) {
          //       item.rh_value = element.value;
          //     }
          //   });
          // });
          // console.log(iaqDataArray);

          // setIaqData(iaqDataArray);

          // setDataInCSV(JSON.stringify(res.data));
          setShowDownload(true);
          //setDataInCSV(Object.keys(res.data));
          console.log(res.data);
          setLoader(false);
          setShowData(true);
          //console.log(res.data.co2.ts.getDate());
          var x = [];
          //x.push("pm2.5");
          res.data.pm25.forEach((element) => {
            x.push(parseInt(element.value));
          });
          setpm25chartData(x);
          console.log([x]);
          console.log(x);

          var y = [];
          //y.push("pm10");
          res.data.pm10.forEach((element) => {
            y.push(parseInt(element.value));
          });
          setpm10chartData(y);

          var z = [];
          //z.push("co2");
          res.data.co2.forEach((element) => {
            z.push(parseInt(element.value));
          });
          setco2chartData(z);

          var a = [];
          //a.push("tvoc");
          res.data.tvoc.forEach((element) => {
            a.push(parseInt(element.value));
          });
          settvocchartData(a);

          var b = [];

          res.data.temp.forEach((element) => {
            b.push(parseInt(element.value));
          });
          settempchartData(b);

          var c = [];

          res.data.iaq.forEach((element) => {
            c.push(parseInt(element.value));
          });
          setiaqchartData(c);

          var time = [];

          res.data.iaq.forEach((element) => {
            time.push(
              new Date(element.ts).getHours() +
                ":" +
                new Date(element.ts).getMinutes()
            );
          });
          setTimeArray(time);

          console.log(time);
          console.log(x);
          setDeviceDataCo2(res.data.co2);
          setDeviceDataIaq(res.data.iaq);
          setDeviceDataPm25(res.data.pm25);
          setDeviceDataPm10(res.data.pm10);
          setDeviceDataTemp(res.data.temp);
          setDeviceDataTvoc(res.data.tvoc);
        })
        .catch((error) => {
          console.log(error);
          // MySwal.fire({
          //   title: <p>Something went wrong!</p>,
          //   type: "error",
          // });
        });
    } else {
      MySwal.fire({
        title: <p>Enter Device Name</p>,
        type: "error",
      });
    }

    //setLoader(false);
    // console.log(device);
    // setSelectedDeviceName(device.label);
    // dashboardService.sendMessage(device.value);
    // getDashboardData();
  };
  const handleChange = (e) => {
    changeDevice(e);
    setEnable(true);
  };

  const downloadCSV = (e) => {
    let token = localStorage.getItem("token");
    setConvertCsv(true);

    axios
      .get(
        environment.apiUrl +
          "plugins/telemetry/DEVICE/" +
          dap_selectedDevice.value +
          "/values/timeseries?keys=iaq,pm25,pm10,co2,tvoc,temp,rh,lat,lon&startTs=" +
          //   (Date.now() - 10 * 60 * 60 * 5000) +
          Date.parse(startTs1) +
          "&endTs=" +
          //   Date.now() +
          Date.parse(endTs1) +
          "&interval=60000&limit=100000000",
        // interval +
        // "&limit=" +
        // limit,
        { headers: { "X-Authorization": "Bearer " + token } }
      )
      .then((res) => {
        console.log("device data", res.data.iaq);
        //const csv = new ObjectsToCsv(res.data);

        console.log(JSON.parse(JSON.stringify(res.data)));

        res.data.iaq.forEach((element) => {
          //iaqDataArray.push({ ts: element.ts, iaq_value: element.value });

          let date = new Date(element.ts);
          console.log(new Date(element.ts).getTime());
          let iaqtime =
            date.getDate() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getFullYear() +
            " " +
            date.getHours() +
            ":" +
            date.getMinutes() +
            ":" +
            date.getSeconds();
          console.log(iaqtime);
          iaqDataArray.push({
            TIMESTAMP: element.ts,
            DATE_TIME: iaqtime,
            IAQ: element.value,
          });
          //spikeDataArray.push({ TIMESTAMP: element.ts, TIME: iaqtime });
        });
        console.log(res.data.co2);

        res.data.co2.forEach((element) => {
          iaqDataArray.forEach((item) => {
            if (item.TIMESTAMP == element.ts) {
              item.CO2_ppm = element.value;
            }
          });

          if (element.value == 0 || element.value > 1000) {
            console.log(new Date(element.ts).getTime());
            CO2spikeDataArray.push({
              DATE_TIME:
                new Date(element.ts).getDate() +
                "/" +
                (new Date(element.ts).getMonth() + 1) +
                "/" +
                new Date(element.ts).getFullYear() +
                " " +
                new Date(element.ts).toLocaleTimeString(),
              CO2_ppm: element.value,
            });
          }
        });

        res.data.pm25.forEach((element) => {
          iaqDataArray.forEach((item) => {
            if (item.TIMESTAMP == element.ts) {
              item.PM_2_5_ppm = element.value;
            }
          });
          if (element.value > 200) {
            console.log(new Date(element.ts).getTime());
            PM25spikeDataArray.push({
              DATE_TIME:
                new Date(element.ts).getDate() +
                "/" +
                (new Date(element.ts).getMonth() + 1) +
                "/" +
                new Date(element.ts).getFullYear() +
                " " +
                new Date(element.ts).toLocaleTimeString(),
              PM_2_5_ppm: element.value,
            });
          }
        });

        res.data.pm10.forEach((element) => {
          iaqDataArray.forEach((item) => {
            if (item.TIMESTAMP == element.ts) {
              item.PM_10_ppm = element.value;
            }
          });

          if (element.value > 200) {
            console.log(new Date(element.ts).getTime());
            PM10spikeDataArray.push({
              DATE_TIME:
                new Date(element.ts).getDate() +
                "/" +
                (new Date(element.ts).getMonth() + 1) +
                "/" +
                new Date(element.ts).getFullYear() +
                " " +
                new Date(element.ts).toLocaleTimeString(),
              PM_10_ppm: element.value,
            });
          }
        });

        res.data.temp.forEach((element) => {
          iaqDataArray.forEach((item) => {
            if (item.TIMESTAMP == element.ts) {
              item.TEMPERATURE_Celsius = element.value;
            }
          });

          if (element.value > 50) {
            console.log(new Date(element.ts).getTime());
            TEMPspikeDataArray.push({
              DATE_TIME:
                new Date(element.ts).getDate() +
                "/" +
                (new Date(element.ts).getMonth() + 1) +
                "/" +
                new Date(element.ts).getFullYear() +
                " " +
                new Date(element.ts).toLocaleTimeString(),
              TEMP: element.value,
            });
          }
        });

        res.data.rh.forEach((element) => {
          iaqDataArray.forEach((item) => {
            if (item.TIMESTAMP == element.ts) {
              item.HUMIDITY_rh = element.value;
            }
          });
        });

        res.data.tvoc.forEach((element) => {
          iaqDataArray.forEach((item) => {
            if (item.TIMESTAMP == element.ts) {
              item.TVOC_ppb = element.value;
            }
          });
          if (element.value > 10) {
            console.log(new Date(element.ts).getTime());
            TVOCspikeDataArray.push({
              DATE_TIME:
                new Date(element.ts).getDate() +
                "/" +
                (new Date(element.ts).getMonth() + 1) +
                "/" +
                new Date(element.ts).getFullYear() +
                " " +
                new Date(element.ts).toLocaleTimeString(),
              TVOC_ppm: element.value,
            });
          }
        });

        res.data.lat.forEach((element) => {
          iaqDataArray.forEach((item) => {
            if (item.TIMESTAMP == element.ts) {
              item.LAT = element.value;
            }
          });
        });

        res.data.lon.forEach((element) => {
          iaqDataArray.forEach((item) => {
            if (item.TIMESTAMP == element.ts) {
              item.LON = element.value;
            }
          });
        });

        console.log(iaqDataArray);
        console.log(CO2spikeDataArray);
        setIaqData(iaqDataArray.reverse());
        setCO2SpikeData(CO2spikeDataArray.reverse());
        setTEMPSpikeData(TEMPspikeDataArray.reverse());
        setPM25SpikeData(PM25spikeDataArray.reverse());
        setPM10SpikeData(PM10spikeDataArray.reverse());
        setTEMPSpikeData(TEMPspikeDataArray.reverse());
        setTVOCSpikeData(TVOCspikeDataArray.reverse());

        setDataInCSV(JSON.stringify(res.data));
        setConvertCsv(false);
        setShowDownload(true);
      })
      .catch((err) => {
        setConvertCsv(false);
        console.log("object");
      });
  };

  const csvReport = {
    data: iaqData,
    headers: headers,
    filename: dap_selectedDevice.label + ".csv",
  };

  const CO2SpikeReport = {
    data: CO2spikeData,
    headers: spike_headers,
    filename: `${dap_selectedDevice.label}_CO2_spikes` + ".csv",
  };

  const PM25SpikeReport = {
    data: PM25spikeData,
    headers: spike_headers,
    filename: `${dap_selectedDevice.label}_PM2.5_spikes` + ".csv",
  };

  const PM10SpikeReport = {
    data: PM10spikeData,
    headers: spike_headers,
    filename: `${dap_selectedDevice.label}_PM10_spikes` + ".csv",
  };

  const TEMPSpikeReport = {
    data: TEMPspikeData,
    headers: spike_headers,
    filename: `${dap_selectedDevice.label}_TEMP_spikes` + ".csv",
  };

  const TVOCSpikeReport = {
    data: TVOCspikeData,
    headers: spike_headers,
    filename: `${dap_selectedDevice.label}_TVOC_spikes` + ".csv",
  };

  return (
    <>
      <>
        <div className='row'>
          <div className='col-md-6 grid-margin stretch-card'>
            <div className='card' style={{ paddingTop: "5%" }}>
              <div className='card-body'>
                <h4 className='card-title'>View Historical Data</h4>
                <p className='card-description'></p>
                <div className='row'>
                  <div className='col-md-6'>
                    <Select
                      options={dap_deviceList.map((listDevices) => ({
                        value: listDevices.id.id,
                        label: listDevices.name,
                      }))}
                      onChange={(e) => changeDevice(e)}
                      // defaultValue={dap_deviceList[0].name}
                      defaultValue='hi there'
                      placeholder={dap_selectedDevice.label}
                      //onchange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className='row' style={{ paddingTop: "15px" }}>
                  <div className='col-md-3'>
                    <h5> From Date</h5>
                    {/* <input
                  type="date"
                  onChange={(e) => changeFromDate(setStartTs(e.target.value))}
                /> */}
                    <DatePicker
                      selected={startTs1}
                      onChange={(startTs1) => setStartTs1(startTs1)}
                      //minDate={minStartDate}
                      //maxDate={startTs1}
                      className='react-datepicker__input-container'
                      dateFormat='dd-MM-yyyy'
                    />
                  </div>
                  <div className='col-md-3'>
                    <h5> To Date</h5>
                    {/* <input
                  type="date"
                  onChange={(e) => changeToDate(setEndTs(e.target.value))}
                /> */}
                    <DatePicker
                      selected={endTs1}
                      onChange={(endTs1) => setEndTs1(endTs1)}
                      //maxDate={endTs1}
                      className='react-datepicker__input-container'
                      dateFormat='dd-MM-yyyy'
                    />
                  </div>
                </div>
                {/* <div className="row" style={{ paddingTop: "15px" }}>
                  <div className="col-md-3">
                    <h5>Interval in hour</h5>

                    <input
                      type="number"
                      onChange={(e) =>
                        setdapInterval(e.target.value * 60 * 60 * 1000)
                      }
                      style={{ border: "1px solid hsl(0,0%,80%)" }}
                    />
                  </div>

                  <div className="col-md-3">
                    <h5> Limit</h5>
                    <input
                      type="number"
                      onChange={(e) => setLimit(e.target.value)}
                      style={{ border: "1px solid hsl(0,0%,80%)" }}
                    />
                  </div>
                </div> */}

                <div className='row'>
                  <div className='col-6'>
                    <Button
                      onClick={searchData}
                      style={{ marginTop: "5%" }}
                      disabled={searchLoading}
                    >
                      Search{" "}
                      {searchLoading && (
                        <i
                          className='fa fa-refresh fa-spin'
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {searchLoading && <span></span>}
                      {!searchLoading && <span></span>}
                    </Button>
                  </div>
                  <div className='col-6'>
                    <div style={{ marginTop: "5%" }}>
                      {showDownload && (
                        <>
                          <CsvDownload
                            className='btn btn-primary  add-group-btn'
                            {...csvReport}
                            disabled={convertCsv}
                          >
                            {/* Export to CSV{" "} */}
                            {convertCsv && (
                              <i
                                className='fa fa-refresh fa-spin'
                                style={{ marginRight: "5px" }}
                              />
                            )}
                            {convertCsv && <span> Exporting to CSV...</span>}
                            {!convertCsv && <span>Download Full Data</span>}
                          </CsvDownload>

                          {CO2spikeData.length > 0 ? (
                            <>
                              <CsvDownload
                                className='btn btn-light  btn-sm'
                                {...CO2SpikeReport}
                                disabled={convertCsv}
                                style={{ marginTop: "5%" }}
                              >
                                {/* Export to CSV{" "} */}
                                {convertCsv && (
                                  <i
                                    className='fa fa-refresh fa-spin'
                                    style={{ marginRight: "5px" }}
                                  />
                                )}
                                {convertCsv && (
                                  <span> Exporting to CSV...</span>
                                )}
                                {!convertCsv && <span>Download CO2 spike</span>}
                              </CsvDownload>
                            </>
                          ) : null}

                          {PM25spikeData.length > 0 ? (
                            <>
                              <CsvDownload
                                className='btn btn-light  btn-sm'
                                {...PM25SpikeReport}
                                disabled={convertCsv}
                                style={{ marginTop: "5%" }}
                              >
                                {/* Export to CSV{" "} */}
                                {convertCsv && (
                                  <i
                                    className='fa fa-refresh fa-spin'
                                    style={{ marginRight: "5px" }}
                                  />
                                )}
                                {convertCsv && (
                                  <span> Exporting to CSV...</span>
                                )}
                                {!convertCsv && (
                                  <span>Download PM25 spike</span>
                                )}
                              </CsvDownload>
                            </>
                          ) : null}

                          {PM10spikeData.length > 0 ? (
                            <>
                              <CsvDownload
                                className='btn btn-light  btn-sm'
                                {...PM10SpikeReport}
                                disabled={convertCsv}
                                style={{ marginTop: "5%" }}
                              >
                                {/* Export to CSV{" "} */}
                                {convertCsv && (
                                  <i
                                    className='fa fa-refresh fa-spin'
                                    style={{ marginRight: "5px" }}
                                  />
                                )}
                                {convertCsv && (
                                  <span> Exporting to CSV...</span>
                                )}
                                {!convertCsv && (
                                  <span>Download PM10 spike</span>
                                )}
                              </CsvDownload>
                            </>
                          ) : null}

                          {TEMPspikeData.length > 0 ? (
                            <>
                              <CsvDownload
                                className='btn btn-light btn-sm'
                                {...TEMPSpikeReport}
                                disabled={convertCsv}
                                style={{ marginTop: "5%" }}
                              >
                                {/* Export to CSV{" "} */}
                                {convertCsv && (
                                  <i
                                    className='fa fa-refresh fa-spin'
                                    style={{ marginRight: "5px" }}
                                  />
                                )}
                                {convertCsv && (
                                  <span> Exporting to CSV...</span>
                                )}
                                {!convertCsv && (
                                  <span>Download TEMP spike</span>
                                )}
                              </CsvDownload>
                            </>
                          ) : null}

                          {TVOCspikeData.length > 0 ? (
                            <>
                              <CsvDownload
                                className='btn btn-primary  add-group-btn'
                                {...TVOCSpikeReport}
                                disabled={convertCsv}
                                style={{ marginTop: "5%" }}
                              >
                                {/* Export to CSV{" "} */}
                                {convertCsv && (
                                  <i
                                    className='fa fa-refresh fa-spin'
                                    style={{ marginRight: "5px" }}
                                  />
                                )}
                                {convertCsv && (
                                  <span> Exporting to CSV...</span>
                                )}
                                {!convertCsv && (
                                  <span>Download TVOC spike</span>
                                )}
                              </CsvDownload>
                            </>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div>
                {showDownload ? (
                  <a
                    href={`data:text/csv;charset=utf-8,${escape(csvData)}`}
                    download='iaqdata.csv'
                  >
                    download
                  </a>
                ) : (
                  <></>
                )}
              </div> */}
            </div>
          </div>

          <div className='col-md-6 grid-margin stretch-card'>
            <div className='card' style={{ paddingTop: "5%" }}>
              <div className='card-body'>
                <h4 className='card-title'>Compare Device Data</h4>
                <div className='row'>
                  <div className='col-md-6'>
                    <MultiSelect
                      options={dap_deviceList.map((listDevices) => ({
                        value: listDevices.id.id,
                        label: listDevices.name,
                      }))}
                      value={multiDevice}
                      onChange={setMultiDevice}
                      // defaultValue={dap_deviceList[0].name}
                      defaultValue='hi there'
                      placeholder={dap_selectedDevice.label}
                      labelledBy='Select'
                    />
                  </div>
                </div>
                <div className='row' style={{ paddingTop: "15px" }}>
                  <div className='col-md-3'>
                    <h5>From Date</h5>
                    <DatePicker
                      selected={startTs2}
                      //maxDate={startTs2}
                      onChange={(date) => setStartTs2(date)}
                      className='react-datepicker__input-container'
                      dateFormat='dd-MM-yyyy'
                    />
                  </div>
                  <div className='col-md-3'>
                    <h5> To Date</h5>
                    <DatePicker
                      selected={endTs2}
                      //maxDate={endTs2}
                      onChange={(date) => setEndTs2(date)}
                      className='react-datepicker__input-container'
                      // customStyles={{
                      //   backgroundColor: "#B0B0B000",
                      //   borderTopColor: "#B0B0B000",
                      //   borderBottomColor: "#B0B0B000",
                      //   borderTopWidth: 0,
                      //   borderBottomWidth: 0,
                      //   border: "1px solid hsla(0, 88%, 53%, 0.76)",
                      // }}
                      dateFormat='dd-MM-yyyy'
                    />
                  </div>
                </div>

                <Button
                  onClick={compareData}
                  style={{ marginTop: "5%" }}
                  disabled={compareLoading}
                >
                  Compare data{" "}
                  {compareLoading && (
                    <i
                      className='fa fa-refresh fa-spin'
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {compareLoading && <span></span>}
                  {!compareLoading && <span></span>}
                </Button>
              </div>
            </div>
          </div>
        </div>
        {!showData ? null : (
          <>
            {loader ? (
              <Loader />
            ) : (
              <>
                <div className='col-md-12 grid-margin stretch-card'>
                  <div className='card'>
                    <div className='card-body'>
                      <h4
                        className='card-title'
                        style={{ marginLeft: "45%", color: "blue" }}
                      >
                        {" "}
                        VIEW HISTORICAL DATA
                      </h4>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-2 '>
                    {/* <div className='card'>
        <div className='card-body'> */}
                    <div className='table-responsive'>
                      <table className='table  table-striped '>
                        <thead>
                          <tr>
                            <th> Date and Time </th>
                            {/* <th>
                <span> Value co2</span>{' '}
              </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {deviceDataCo2.map((data) => (
                            <tr>
                              <td>
                                {new Date(data.ts).getDate() +
                                  "/" +
                                  (new Date(data.ts).getMonth() + 1) +
                                  "/" +
                                  new Date(data.ts).getFullYear() +
                                  " " +
                                  new Date(data.ts).getHours() +
                                  ":" +
                                  new Date(data.ts).getMinutes()}{" "}
                                {new Date(data.ts).getHours() >= 12
                                  ? "PM"
                                  : "AM"}
                              </td>
                              {/* <td>{data.value}</td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* </div>
      </div> */}
                  </div>

                  <div className='col-lg-2 '>
                    <div className='table-responsive'>
                      <table className='table  table-striped'>
                        <thead>
                          <tr>
                            <th> IAQ </th>
                          </tr>
                        </thead>
                        <tbody>
                          {deviceDataIaq.map((data) => (
                            <tr>
                              <td>{data.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className='col-lg-2 '>
                    {/* <div className='card'>
        <div className='card-body'> */}
                    <div className='table-responsive'>
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th> CO2 (ppm) </th>
                          </tr>
                        </thead>
                        <tbody>
                          {deviceDataCo2.map((data) => (
                            <tr>
                              {/* <td>{data.value}</td> */}

                              {/* <td>
                      {new Date(data.ts).getDate() +
                        new Date(data.ts).getHours() +
                        ":" +
                        new Date(data.ts).getMinutes()}{" "}
                      {new Date(data.ts).getHours() >= 12 ? "PM" : "AM"}
                    </td> */}
                              <td>{data.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* </div>
      </div> */}
                  </div>

                  <div className='col-lg-2 '>
                    <div className='table-responsive'>
                      <table className='table  table-striped'>
                        <thead>
                          <tr>
                            <th> PM 2.5 (μg/m3) </th>
                          </tr>
                        </thead>
                        <tbody>
                          {deviceDataPm25.map((data) => (
                            <tr>
                              <td>{data.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className='col-lg-2 '>
                    {/* <div className='card'>
        <div className='card-body'> */}
                    <div className='table-responsive'>
                      <table className='table  table-striped'>
                        <thead>
                          <tr>
                            <th>PM 10 (μg/m3)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {deviceDataPm10.map((data) => (
                            <tr>
                              {/* <td>
                      {new Date(data.ts).getDate() +
                        new Date(data.ts).getHours() +
                        ":" +
                        new Date(data.ts).getMinutes()}{" "}
                      {new Date(data.ts).getHours() >= 12 ? "PM" : "AM"}
                    </td> */}
                              <td>{data.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* </div>
      </div> */}
                  </div>

                  <div className='col-lg-2 '>
                    {/* <div className='card'>
        <div className='card-body'> */}
                    <div className='table-responsive'>
                      <table className='table  table-striped'>
                        <thead>
                          <tr>
                            <th>TVOC (ppb) </th>
                          </tr>
                        </thead>
                        <tbody>
                          {deviceDataTvoc.map((data) => (
                            <tr>
                              {/* <td>
                      {new Date(data.ts).getDate() +
                        new Date(data.ts).getHours() +
                        ":" +
                        new Date(data.ts).getMinutes()}{" "}
                      {new Date(data.ts).getHours() >= 12 ? "PM" : "AM"}
                    </td> */}
                              <td>{data.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* </div>
      </div> */}
                  </div>

                  {/* <div className='col-lg-2 '>
                    <div className='table-responsive'>
                      <table className='table  table-striped'>
                        <thead>
                          <tr>
                            <th> TEMP (°C) </th>
                          </tr>
                        </thead>
                        <tbody>
                          {deviceDataTemp.map((data) => (
                            <tr>
                              <td>{data.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div> */}
                </div>

                <div className='row'>
                  <div className='col-md-6 grid-margin stretch-card'>
                    <div className='card'>
                      <div className='card-body'>
                        <h4 className='card-title'>PM 2.5</h4>
                        {/* <C3Chart
                          data={splineDatapm25}
                          options={salesOptions}
                          style={{ height: "187px" }}
                        /> */}

                        <Line data={pm25ChartDataa} />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 grid-margin stretch-card'>
                    <div className='card'>
                      <div className='card-body'>
                        <h4 className='card-title'>PM 10</h4>
                        {/* <C3Chart
                          data={splineDatapm10}
                          options={salesOptions}
                          style={{ height: "187px" }}
                        /> */}
                        <Line data={pm10ChartDataa} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 grid-margin stretch-card'>
                    <div className='card'>
                      <div className='card-body'>
                        <h4 className='card-title'>CO2 </h4>
                        {/* <C3Chart
                          data={splineDataco2}
                          options={salesOptions}
                          style={{ height: "187px" }}
                        /> */}
                        <Line data={co2ChartDataa} />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 grid-margin stretch-card'>
                    <div className='card'>
                      <div className='card-body'>
                        <h4 className='card-title'>TEMP</h4>
                        {/* <C3Chart
                          data={splineDatatemp}
                          options={salesOptions}
                          style={{ height: "187px" }}
                        /> */}
                        <Line data={tempChartDataa} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 grid-margin stretch-card'>
                    <div className='card'>
                      <div className='card-body'>
                        <h4 className='card-title'>TVOC</h4>
                        {/* <C3Chart
                          data={splineDatatvoc}
                          options={salesOptions}
                          style={{ height: "187px" }}
                        /> */}
                        <Line data={tvocChartDataa} />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 grid-margin stretch-card'>
                    <div className='card'>
                      <div className='card-body'>
                        <h4 className='card-title'>IAQ</h4>
                        {/* <C3Chart
                          data={splineDataiaq}
                          options={salesOptions}
                          style={{ height: "187px" }}
                        /> */}
                        <Line data={iaqChartDataa} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {/* COMPARE DEVICE DATA */}
        {!showCompare ? null : (
          <>
            {loader ? (
              <Loader />
            ) : (
              <>
                <div className='col-md-12 grid-margin stretch-card'>
                  <div className='card'>
                    <div className='card-body'>
                      <h4
                        className='card-title'
                        style={{ marginLeft: "45%", color: "blue" }}
                      >
                        {" "}
                        COMPARE DEVICE DATA
                      </h4>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    {" "}
                    <div
                      className='card'
                      style={{
                        boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                        borderRadius: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {" "}
                            <h6
                              className='card-title'
                              style={{ color: "#5271FF" }}
                            >
                              PM 2.5
                            </h6>
                          </div>
                        </div>
                        <div className='demo-chart' style={{ height: "100%" }}>
                          <C3Chart
                            data={comparePm25}
                            style={{ height: "187px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    {" "}
                    <div
                      className='card'
                      style={{
                        boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                        borderRadius: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {" "}
                            <h6
                              className='card-title'
                              style={{ color: "#5271FF" }}
                            >
                              PM 10
                            </h6>
                          </div>
                        </div>
                        <div className='demo-chart' style={{ height: "100%" }}>
                          <C3Chart
                            data={comparePm10}
                            style={{ height: "187px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    {" "}
                    <div
                      className='card'
                      style={{
                        boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                        borderRadius: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {" "}
                            <h6
                              className='card-title'
                              style={{ color: "#5271FF" }}
                            >
                              CO2
                            </h6>
                          </div>
                        </div>
                        <div className='demo-chart' style={{ height: "100%" }}>
                          <C3Chart
                            data={compareCo2}
                            style={{ height: "187px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    {" "}
                    <div
                      className='card'
                      style={{
                        boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                        borderRadius: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {" "}
                            <h6
                              className='card-title'
                              style={{ color: "#5271FF" }}
                            >
                              TVOC
                            </h6>
                          </div>
                        </div>
                        <div className='demo-chart' style={{ height: "100%" }}>
                          <C3Chart
                            data={compareTvoc}
                            style={{ height: "187px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    {" "}
                    <div
                      className='card'
                      style={{
                        boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                        borderRadius: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {" "}
                            <h6
                              className='card-title'
                              style={{ color: "#5271FF" }}
                            >
                              TEMPERATURE
                            </h6>
                          </div>
                        </div>
                        <div className='demo-chart' style={{ height: "100%" }}>
                          <C3Chart
                            data={compareTemp}
                            style={{ height: "187px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    {" "}
                    <div
                      className='card'
                      style={{
                        boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                        borderRadius: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {" "}
                            <h6
                              className='card-title'
                              style={{ color: "#5271FF" }}
                            >
                              IAQ
                            </h6>
                          </div>
                        </div>
                        <div className='demo-chart' style={{ height: "100%" }}>
                          <C3Chart
                            data={compareIaq}
                            style={{ height: "187px" }}
                          />
                          {/* <Line data={data} options={options} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

export default HistoricalData;
