import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
//import { createDevice } from "../services/createDevice";
import Box from "@mui/material/Box";
import Editable from "react-bootstrap-editable";
import axios from "axios";
import Select from "react-select";
import { environment } from "../../environment";
import CustomListDropDown from "../Components/CustomListDropDown";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Country, State, City } from "country-state-city";
import Multiselect from "multiselect-react-dropdown";
import { Redirect } from "react-router-dom";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { useEffect } from "react";
import Container, { TableContainer, TextField } from "@mui/material";

const MySwal = withReactContent(Swal);

const AddShipment = () => {
  const [dap_deviceList, setDapDevices] = useState([]);
  const [dap_notes, setNotes] = useState("");
  const [dap_phone, setPhone] = useState("");
  const [dap_address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [dap_countries, setDapCountries] = useState("");
  const [dap_states, setDapState] = useState([]);
  const [countrycode, setCountryCode] = useState("");
  const [postcountry, setPostCountry] = useState("");
  const [poststate, setPostState] = useState("");
  const [postcity, setPostCity] = useState("");
  const [dap_city, setDapCity] = useState([]);
  const [device_label, setLabel] = useState("");
  const [inputfields, setInputFields] = useState([
    {
      deviceName: "",
      deviceLabel: "",
      isInstalled: false,
      shipmentIdMongo: "",
    },
  ]);

  const handleChangeInput = (index, event) => {
    if (event.target.name == "deviceLabel") {
      callApi(index, event);
    }
    const values = [...inputfields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };

  useEffect(() => {
    //setLoader(true);
    // console.log('token1');
    // console.log(localStorage.getItem("token"));
    axios
      .get(environment.dap_url + `externalApi/getAllDeviceWithTenantId`, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // const devices = res.data.data;
        console.log("devices");
        setDapDevices(res.data);
        console.log(dap_deviceList);
        // setDeviceNames(res.data.data.name);
        //setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  let countries = [];
  let states = [];
  countries = Country.getAllCountries();
  states = State.getAllStates();
  const selectCountry = (e) => {
    setDapCountries(Country.getAllCountries());
    setDapState(State.getStatesOfCountry(e.id));
    setCountryCode(e.id);
    setPostCountry(e.label);
  };

  const selectRegion = (e) => {
    setDapCity(City.getCitiesOfState(countrycode, e.id));
    setPostState(e.label);
  };
  const selectCity = (e) => {
    setPostCity(e.label);
  };
  //const [loader, setLoader] = useState(true);
  // const selectCountry = (val) => {
  //   console.log("india");
  //   console.log(val);
  //   setDapCountries(val);
  // };
  const callApi = (index, event) => {
    // alert(event.target.value);
    axios
      .get(
        environment.dap_url +
          `dapDevice/findDeviceByLabel?label=` +
          event.target.value
      )
      .then((res) => {
        // const devices = res.data.data;
        console.log(res);
        // document.getElementById("mui-2").value = res.data.deviceLabel;
        const values = [...inputfields];
        values[index]["deviceName"] = res.data.deviceName;
        values[index]["deviceIdMongo"] = res.data._id;
        setInputFields(values);
        // setDeviceNames(res.data.data.name);
        //setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // const selectRegion = (val) => {
  //   setDapState(val);
  // };
  const addInput = () => {};
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    console.log("inputfields");
    console.log(inputfields);
    // createDevice(
    //   dap_name,
    //   dap_label,
    //   dap_transport_type,
    //   dap_description,
    //   dap_profileName
    // );
    // console.log(e.target[0].value);
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    // console.log(e.target[0].value);
    // console.log(userdetails.data.id.id);
    // console.log(dap_countries);
    // console.log(dap_states);
    // console.log(dap_city);
    // console.log(dap_notes);
    // console.log(dap_phone);

    axios
      .post(environment.dap_url + "shipmentApi/createShipment", {
        consignorId: userdetails.data.id.id,
        consigneeId: e.target[0].value,
        consignmentCity: postcity,
        consignmentState: poststate,
        consignmentCountry: postcountry,
        address: dap_address,
        deviceList: inputfields,
        phone: dap_phone,
        notes: dap_notes,
      })
      .then((res) => {
        MySwal.fire({
          title: <p>Shipment has been successfully created!</p>,
          type: "success",
        });
        setNotes("");
        setPhone("");
        setAddress("");
        setLoading("");
        setDapCountries([]);
        setDapState([]);
        setDapCity([]);
        return <Redirect to="/shipments" />;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        MySwal.fire({
          title: <p>{err?.response?.data?.message}</p>,
          type: "error",
        });
      });
  };
  const handleAddClick = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputfields,
      {
        deviceName: "",
        deviceLabel: "",
        isInstalled: false,
      },
    ]);
  };
  const handleRemoveClick = (index, e) => {
    e.preventDefault();
    const values = [...inputfields];
    values.splice(index, 1);
    setInputFields(values);
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-3"></div>
        <Link to="/shipments" className="btn btn-light my-3">
          Go Back
        </Link>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <h1 className="text-center">ADD Shipment</h1>
              <div className="card-body">
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId="name">
                    <Form.Label>* Consignee</Form.Label>
                    <CustomListDropDown />
                  </Form.Group>
                  <div className="row">
                    <div className="col-4">
                      <Form.Group controlId="name">
                        <Form.Label>Country</Form.Label>
                        <Select
                          options={countries.map((element) => ({
                            id: element.isoCode,
                            value: element.name,
                            label: element.name,
                          }))}
                          onChange={(e) => selectCountry(e)}
                          // defaultValue={dap_deviceList[0].name}
                          defaultValue="hi there"
                          placeholder="Country"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="name">
                        <Form.Label>State</Form.Label>
                        <Select
                          options={dap_states.map((element) => ({
                            id: element.isoCode,
                            value: element.name,
                            label: element.name,
                          }))}
                          onChange={(e) => selectRegion(e)}
                          // defaultValue={dap_deviceList[0].name}
                          defaultValue="hi there"
                          placeholder="State"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-4">
                      <Form.Group controlId="name">
                        <Form.Label>City</Form.Label>
                        <Select
                          options={dap_city.map((element) => ({
                            id: element.isoCode,
                            value: element.name,
                            label: element.name,
                          }))}
                          onChange={(e) => selectCity(e)}
                          // defaultValue={dap_deviceList[0].name}
                          defaultValue="hi there"
                          placeholder="City"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <Form.Group controlId="name">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="address"
                          placeholder="Enter address"
                          value={dap_address}
                          onChange={(e) => setAddress(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="name">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="Phone"
                          placeholder="Enter Phone No."
                          value={dap_phone}
                          onChange={(e) => setPhone(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="name">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                          type="Phone"
                          placeholder="Enter Notes"
                          value={dap_notes}
                          onChange={(e) => setNotes(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-4">
                      <Form.Group controlId="name">
                        <Form.Label>Device Label</Form.Label>
                        <Form.Control
                          type="label"
                          placeholder="Enter Label"
                          // value={device_label}
                          onChange={(e) => callApi(e)}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                  </div> */}
                  {/* <Form.Label>Add Devices</Form.Label>
                  <Multiselect
                    options={objectArray}
                    showCheckbox={true}
                    displayValue="key"        
                    />                  */}
                  <TableContainer>
                    {inputfields.map((inputField, index) => (
                      <div key={index}>
                        <TextField
                          name="deviceLabel"
                          label="Device Label"
                          // variant="filled"
                          style={{
                            margin: 5,
                          }}
                          value={inputField.deviceLabel}
                          onChange={(event) => handleChangeInput(index, event)}
                        />

                        <TextField
                          name="deviceName"
                          label="Device Name"
                          // variant="filled"
                          style={{
                            margin: 5,
                          }}
                          value={inputField.deviceName}
                          onChange={(event) => handleChangeInput(index, event)}
                        />
                        <TextField
                          name="MongoId"
                          label=" Id"
                          // variant="filled"
                          style={{
                            display: "none",
                          }}
                          value={inputField.shipmentIdMongo}
                        />
                        <button
                          className="btn btn-danger btn-sm"
                          style={{
                            margin: 10,
                          }}
                          onClick={(e) => handleRemoveClick(index, e)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-dash-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                          </svg>
                        </button>
                        <button
                          className="btn btn-primary btn-sm"
                          style={{
                            margin: 10,
                          }}
                          onClick={(e) => handleAddClick(e)}
                        >
                          <svg
                            // xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-plus-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </TableContainer>
                  <div className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      disabled={loading}
                    >
                      ADD SHIPMENT
                      {loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span></span>}
                      {!loading && <span></span>}
                    </Button>
                  </div>
                </Form>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddShipment;
