import React, { useState, useEffect } from "react";
import { environment } from "../../environment";
import axios from "axios";
import { Form, Button, Dropdown } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, withRouter, useRouteMatch } from "react-router-dom";
import CsvDownload from "react-json-to-csv";

const ViewUserResponse = ({ match }) => {
  const [answers, setAnsers] = useState([]);
  const [question, setQuestions] = useState([]);
  const [questionType, setQuestionType] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [totalWaitage, setTotalWaitage] = useState(0);
  const [masterId, setMasterId] = useState(0);
  const [user_id, setUserId] = useState("");
  const [convertCsv, setConvertCsv] = useState(false);
  const [iaqData, setIaqData] = useState([]);
  const [username, setUserName] = useState("");

  const [showResponse, setShowResponse] = useState(true);
  const [showDownloadBtn, setShowDowbloadBtn] = useState(false);
  const [feedbackType, setFeedbackType] = useState("");

  let userId = match.params.userId;
  let master_id = Number(match.params.masterId);
  let answers_array = [];

  let master_dropdown = [];
  master_dropdown = [
    {
      questionMasterName: "Customer Feedback",
      questionMasterId: 1,
    },
    {
      questionMasterName: "Voluntary Feedback (Before using purifier)",
      questionMasterId: 2,
    },
    {
      questionMasterName: "Voluntary Feedback (After using purifier)",
      questionMasterId: 3,
    },
  ];

  useEffect(() => {
    let fullname = "";
    axios
      .get(environment.dap_url + "users/" + userId)
      .then((res) => {
        console.log(res.data);
        fullname = res.data?.userFirstName + " " + res.data?.userLastName;
        setUserName(fullname);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(fullname);

    console.log(master_id);
    console.log(userId);
    setMasterId(master_id);
    setUserId(userId);
    if (master_id === 1) {
      axios
        .get(
          environment.dap_url +
            "questionnarieApi/getAllQuestionsWithAnswerAndWeightage/" +
            userId
        )
        .then((res) => {
          console.log(res.data.answers);

          console.log(res.data.totalResponseWaitage);
          setTotalWaitage(res.data.totalResponseWaitage);
          if (res.data.totalResponseWaitage > 0) {
            setConvertCsv(true);
          }
          setAnsers(res.data.answers);
          //totalResponseWaitage
          res.data.answers.forEach((element) => {
            setQuestions(element.question);
          });
          setShowButton(true);
          setShowResponse(true);

          res.data.answers.forEach((element, i) => {
            console.log(element.question);
            console.log(element?.answerDetails[0]?.response);
            answers_array.push({
              Sl_No: i + 1,
              Question: element.question,
              Weightage: element.waitage,
              Response: element?.answerDetails[0]?.response,
            });
          });
          setFeedbackType("feedback");
          console.log(answers_array);
          setIaqData(answers_array);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (master_id === 2 || master_id === 3) {
      setConvertCsv(true);
      axios
        .get(
          environment.dap_url +
            "questionnarieApi/getAllQuestionsWithAnswers/" +
            userId +
            "/" +
            master_id
        )
        .then((res) => {
          console.log(res);
          setAnsers(res.data);
          setShowButton(true);
          setShowResponse(true);
          if (res.data) {
            res.data.forEach((element, i) => {
              console.log(element.question);
              if (element?.answerDetails[0]?.response) {
                setShowDowbloadBtn(true);
              }

              answers_array.push({
                Sl_No: i + 1,
                Question: element.question,
                Weightage: element.waitage,
                Response: element?.answerDetails[0]?.response
                  ? element?.answerDetails[0]?.response
                  : JSON.stringify(element?.answerDetails[0]?.responseCheckBox),
              });
            });
            setFeedbackType("voluntary_feedback");
            console.log(answers_array);
            setIaqData(answers_array);
          }
        })
        .catch((err) => {
          console.log(err);
          setConvertCsv(false);
        });
    } else {
      setShowResponse(false);
    }
  }, []);

  const setMasterHandler = (e) => {
    console.log(e.id, e.label);
    setQuestionType(e.label);
    setShowButton(true);
    setMasterId(e.id);
    localStorage.setItem("questionId", e.id);
    if (e.id === 1) {
      axios
        .get(
          environment.dap_url +
            "questionnarieApi/getAllQuestionsWithAnswerAndWeightage/" +
            userId
        )
        .then((res) => {
          console.log(res.data.answers);
          console.log(res.data.totalResponseWaitage);
          setTotalWaitage(res.data.totalResponseWaitage);
          if (res.data.totalResponseWaitage > 0) {
            setConvertCsv(true);
          }
          setAnsers(res.data.answers);
          //totalResponseWaitage
          res.data.answers.forEach((element) => {
            setQuestions(element.question);
          });
          setShowResponse(true);
        })
        .catch((err) => {
          console.log(err);
          setConvertCsv(false);
        });
    } else if (e.id === 2 || e.id === 3) {
      axios
        .get(
          environment.dap_url +
            "questionnarieApi/getAllQuestionsWithAnswers/" +
            userId +
            "/" +
            e.id
        )
        .then((res) => {
          console.log(res);
          setAnsers(res.data);
          // res.data.answers.forEach((element) => {
          //   setQuestions(element.question);
          // });
          setShowResponse(true);
        })
        .catch((err) => {
          console.log(err);
          setConvertCsv(false);
        });
    } else {
      setShowResponse(false);
    }
  };

  const headers = [
    { label: "Sl No", key: "Sl No" },
    { label: "Question", key: "Question" },
    { label: "Weightage", key: "Weightage" },
    { label: "Response", key: "Response" },
  ];

  const csvReport = {
    data: iaqData,
    headers: headers,
    filename: username + "_" + feedbackType + ".csv",
  };

  return (
    <div style={{ marginLeft: "3%", marginTop: "2%" }}>
      {/* <div className='col-lg-6'>
        <Form.Group controlId='name'>
          <Form.Label>
            <h3>Select Question Type</h3>{" "}
          </Form.Label>
          <Select
            options={master_dropdown.map((element) => ({
              id: element.questionMasterId,
              value: element.questionMasterName,
              label: element.questionMasterName,
            }))}
            onChange={(e) => setMasterHandler(e)}
            // defaultValue={dap_deviceList[0].name}
            defaultValue='hi there'
            placeholder={questionType ? questionType : "Set Question Type"}
          />
        </Form.Group>
      </div> */}
      {masterId === 2 && showDownloadBtn ? (
        <CsvDownload className='btn btn-primary  add-group-btn' {...csvReport}>
          {/* Export to CSV{" "} */}

          <span>Download Response</span>
        </CsvDownload>
      ) : null}

      {showResponse ? (
        <>
          {" "}
          {masterId === 1 ? (
            <>
              <p style={{ margin: "2%" }}>
                {" "}
                <strong>Total Response Weightage:</strong> {totalWaitage}{" "}
              </p>
              {convertCsv && (
                <CsvDownload
                  className='btn btn-primary  add-group-btn'
                  {...csvReport}
                >
                  {/* Export to CSV{" "} */}

                  <span>Download Response</span>
                </CsvDownload>
              )}
            </>
          ) : null}
          {answers?.map((item, i) => (
            <div style={{ margin: "2%" }}>
              <div style={{ fontSize: "18px" }}>
                <strong>Question {++i}:</strong>{" "}
                <span key={item._id}>{item?.question}</span>
              </div>
              <div style={{ marginTop: "10px", fontSize: "18px" }}>
                <strong>Ans:</strong>
                {item?.answerDetails.map((res) => (
                  <>
                    {res.responseCheckBox.length > 0 ? (
                      <>
                        {" "}
                        {res?.responseCheckBox.map((option) => (
                          <span> {option} , </span>
                        ))}
                      </>
                    ) : null}

                    <span key={res._id}> {res?.response}</span>
                  </>
                ))}
              </div>
            </div>
          ))}
          {/* <Button style={{ marginLeft: "50%" }}>Fill User Response</Button> */}
        </>
      ) : null}

      {showButton ? (
        <Link
          to={`/fill-user-response/${user_id}/${masterId}`}
          className='btn btn-light my-3'
        >
          Fill User Response
        </Link>
      ) : null}
    </div>
  );
};

export default ViewUserResponse;
