import React from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polyline,
  Marker,
  OverlayView,
  InfoWindow,
} from "react-google-maps";
import MarkerClusterer from "@google/markerclusterer";
import { Dropdown } from "react-bootstrap";
//import googleMapStyles from "./GoogleMapStyles";
import "./UserMap.css";

// import { Map, GoogssssleApiWrapper, Marker } from "google-maps-react";
import { deviceCoordinateService } from "../services/deviceCoordinateService";

//url: "/assets/images/dap_location.png",

const styles = require("./GoogleMapStyles.json");

let coordinates = [];
coordinates = localStorage.getItem("deviceCoordinateArray");
coordinates = JSON.parse(coordinates);

export class Maps extends React.Component {
  super() {
    this.state = {
      mapMarker: null,
      showingInfoWindow: false,
      showDevices: false,
      isLoaded: false,
    };
    this.state = {
      mapArray: [
        {
          lat: 20.2990558,
          lng: 85.8309919,
          location: "OCAC",
          device_name: "Her",
        },
        {
          lat: 20.2990558,
          lng: 85.8309919,
          location: "OCAC",
          device_name: "Her",
        },
      ],
    };
    this.state = {
      mapZeroArray: [],
    };
    this.state = {
      deviceSameCoordinates: [],
    };
  }
  componentDidMount() {
    // deviceCoordinateService();

    let device_coordinates = [];
    let device_zero_coordinates = [];
    device_coordinates = localStorage.getItem("deviceCoordinateArray");

    let device_same_coordinates = [];

    //if (coordinates) {
    coordinates = JSON.parse(device_coordinates);

    console.log(coordinates);
    for (let i = 0; i < coordinates?.length; i++) {
      for (let j = 1; j < coordinates?.length; j++) {
        // console.log(
        this.calcCrow(
          coordinates[i].lat,
          coordinates[i].lon,
          coordinates[i].deviceName,
          coordinates[j].lat,
          coordinates[j].lon,
          coordinates[j].deviceName
        );
        // );
      }
    }

    this.onMarkerClick("abc");
    console.log(device_coordinates);

    if (device_coordinates) {
      // this.setState({
      //   centerLat: device_coordinates[0]?.lat,
      //   centerLon: device_coordinates[0]?.lon,
      // });
      console.log(
        "user map",
        device_coordinates[0]?.lat,
        device_coordinates[0]?.lon
      );
      this.setState({
        centerLat: device_coordinates[0]?.lat || 0,
        centerLon: device_coordinates[0]?.lon || 0,
      });

      function callCoordinates() {
        device_coordinates = JSON.parse(
          localStorage.getItem("deviceCoordinateArray")
        );
        device_zero_coordinates = JSON.parse(
          localStorage.getItem("deviceZeroCoordinates")
        );
      }
      setTimeout(() => {
        callCoordinates();

        this.setState({
          centerLat: Number(device_coordinates[0]?.lat),
          centerLon: Number(device_coordinates[0]?.lon),
        });

        this.setState({ mapZeroArray: device_zero_coordinates });
      }, 4000);
      //console.log("hatighoda",device_coordinates);

      coordinates.forEach((element) => {
        element.lat = Number(element.lat);
        element.lon = Number(element.lon);

        if (element.lat === 20.214748 && element.lon === 85.214745) {
          device_same_coordinates.push({
            device_name: element.device_name,
            lat: element.lat,
            lon: element.lon,
          });
        }
      });
    } else {
      this.setState({
        mapArray: device_coordinates,
      });
      this.setState({
        deviceSameCoordinates: device_same_coordinates,
      });
    }

    console.log(coordinates, "coordinates");
    this.setState({
      mapArray: coordinates,
    });
    this.setState({ mapZeroArray: device_zero_coordinates });
    this.setState({ deviceSameCoordinates: device_same_coordinates });

    this.setState({
      isLoaded: true,
    });
    //}
  }
  state = {
    mapMarker: null,
    showingInfoWindow: false,
    mapArray: coordinates,
    isLoaded: false,
    centerLat: 0,
    centerLon: 0,
    showDevices: false,
  };

  onMarkerClick = (props) => {
    this.setState({
      showingInfoWindow: true,
    });
  };

  onInfoWindowClose = () =>
    this.setState({
      showingInfoWindow: false,
    });

  show_devices = () => {
    if (this.state.showDevices === false) {
      this.setState({
        showDevices: true,
      });
    } else {
      this.setState({
        showDevices: false,
      });
    }
  };

  calcCrow = (lat1, lon1, device1, lat2, lon2, device2) => {
    // var i=0,j=0;
    // var cluster[i][j];
    console.log("first");
    var cluster = [{}];

    var R = 6371; // km
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    var lat1 = this.toRad(lat1);
    var lat2 = this.toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = (R * c).toFixed(1);

    var f = [[]],
      j = 0,
      i = 0;
    if (d > 4000) {
      cluster.push({
        lat: lat1,
        lon: lon1,
        d: d,
        device1: device1,
        device2: device2,
      });
      console.log(cluster);
    }

    return d;
  };

  // Converts numeric degrees to radians
  toRad = (Value) => {
    return (Value * Math.PI) / 180;
  };

  render() {
    const mapStyles = {
      width: "100%",
      height: "100%",
    };
    return (
      <>
        {this.state.mapArray ? (
          <>
            {this.state.isLoaded ? (
              <>
                <GoogleMap
                  defaultZoom={8}
                  // initialCenter={{ lat: 9.761927, lng: 79.95244 }}
                  // defaultCenter={{ lat: 20.2961, lng: 85.8245 }}
                  // center={{ lat: 20.2961, lng: 85.8245 }}
                  center={{
                    lat: this.state?.centerLat,
                    lng: this.state?.centerLon,
                  }}
                  defaultOptions={{
                    disableDefaultUI: false, // disable default map UI
                    draggable: true, // make map draggable
                    keyboardShortcuts: false, // disable keyboard shortcuts
                    scaleControl: true, // allow scale controle
                    scrollwheel: true, // allow scroll wheel
                    styles: styles, // change default map styles
                  }}
                  //onZoomChanged={this._handleZoomChanged.bind(this)}
                >
                  <>
                    <Polyline options={{ strokeColor: "red " }} />

                    {this.state.mapArray.map((location) => (
                      <Marker
                        // position={{
                        //   lat:20.2990558,
                        //   lng: 85.8309919
                        // }}
                        icon={{
                          url: "/assets/images/dap_location.png",
                          // fillColor: "#0000ff",
                          // fillOpacity: 1.0,
                          // strokeWeight: 0,
                          // scale: 1.25
                        }}
                        label={location.name}
                        position={{ lat: location.lat, lng: location.lon }}
                        clickable
                        onClick={() => this.show_devices()}
                        //onClick={this.onMarkerClick}
                      >
                        {this.state.showingInfoWindow === true && (
                          <>
                            {location.lat === 20.214748 &&
                            location.lon === 85.214745 ? (
                              <>
                                {this.state?.deviceSameCoordinates.length > 0 &&
                                this.state.showDevices === false ? (
                                  <>
                                    <InfoWindow
                                      onClick={() => this.show_devices()}
                                    >
                                      <div>
                                        {
                                          this.state?.deviceSameCoordinates[0]
                                            .device_name
                                        }{" "}
                                        +
                                        {this.state?.deviceSameCoordinates
                                          .length - 1}
                                      </div>
                                    </InfoWindow>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {this.state.showDevices === true ? (
                                  <InfoWindow>
                                    <div>
                                      {this.state?.deviceSameCoordinates.map(
                                        (device) => (
                                          <p>{device?.device_name}</p>
                                        )
                                      )}
                                    </div>
                                  </InfoWindow>
                                ) : (
                                  <></>
                                )}

                                {/* <InfoWindow>
                                <div>
                                  {this.state?.deviceSameCoordinates.map(
                                    (device) => (
                                      <p>{device?.device_name}</p>
                                    )
                                  )}
                                </div>
                              </InfoWindow> */}
                              </>
                            ) : (
                              <>
                                <InfoWindow
                                  // position={{
                                  //   lat:20.2990558,
                                  //   lng:85.8309919
                                  // }}
                                  onCloseClick={this.onInfoWindowClose}
                                >
                                  <div>{location.device_name}</div>
                                </InfoWindow>
                              </>
                            )}
                          </>
                        )}
                      </Marker>
                    ))}
                    <Marker
                      position={{ lat: 0, lng: 0 }}
                      icon={{
                        url: "/assets/images/dap_location.png",
                        // fillColor: "#0000ff",
                        // fillOpacity: 1.0,
                        // strokeWeight: 0,
                        // scale: 1.25
                      }}
                      // position={{ lat: location.lat, lng: location.lon }}
                      clickable
                      onClick={this.onMarkerClick}
                    >
                      {this.state.showingInfoWindow === true && (
                        <InfoWindow
                          position={{
                            lat: 0,
                            lng: 0,
                          }}
                          onCloseClick={this.onInfoWindowClose}
                        >
                          <div>
                            {this.state.mapZeroArray.map((element) => (
                              <div>{element.device_name}</div>
                            ))}
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  </>
                </GoogleMap>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <GoogleMap
              defaultZoom={12}
              // initialCenter={{ lat: 9.761927, lng: 79.95244 }}
              // defaultCenter={{ lat: 20.2961, lng: 85.8245 }}
              // center={{ lat: 20.2961, lng: 85.8245 }}
              defaultOptions={{
                disableDefaultUI: false, // disable default map UI
                draggable: true, // make map draggable
                keyboardShortcuts: false, // disable keyboard shortcuts
                scaleControl: true, // allow scale controle
                scrollwheel: true, // allow scroll wheel
                styles: styles, // change default map styles
              }}
            ></GoogleMap>
          </>
        )}
      </>
    );
  }
}

//Maps.defaultProps = googleMapStyles;

const MapComponent = withScriptjs(withGoogleMap(Maps));
const exampleMapStyles = {
  default: [],
  hide: [
    {
      featureType: "poi.business",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
  ],
};
// const styles = {
//   default: [],
//   hide: [
//     {
//       featureType: "poi.business",
//       stylers: [{ visibility: "off" }],
//     },
//     {
//       featureType: "transit",
//       elementType: "labels.icon",
//       stylers: [{ visibility: "off" }],
//     },
//   ],
// };

// export default GoogleApiWrapper({
//   apiKey:
//     "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
// })(Maps);

export default () => (
  <MapComponent
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDpEqauXWZEF99qOZuKNkZKmQwJJ01s2l4"
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `500px`, width: "100%" }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
);

// class Map extends React.Component {
//   state = {
//     progress: [],
//   };

//   velocity = 5;
//   initialDate = new Date();

//   getDistance = () => {

//     const differentInTime = (new Date() - this.initialDate) / 1000; // pass to seconds
//     return differentInTime * this.velocity; // d = v*t -- thanks Newton!
//   };

//   // componentDidMount = () => {
//   //   this.interval = window.setInterval(this.moveObject, 1000);
//   // };

//   // componentWillUnmount = () => {
//   //   window.clearInterval(this.interval);
//   // };

//   // moveObject = () => {
//   //   const distance = this.getDistance();
//   //   if (!distance) {
//   //     return;
//   //   }
//   //   const progress = this.path.filter(
//   //     (coordinates) => coordinates.distance < distance
//   //   );
//   //   this.setState({ progress });
//   // };

//   componentWillMount = () => {
//     this.path = this.path.map((coordinates, i, array) => {
//       if (i === 0) {
//         return { ...coordinates, distance: 0 }; // it begins here!
//       }
//       const { lat: lat1, lng: lng1 } = coordinates;
//       const latLong1 = new window.google.maps.LatLng(lat1, lng1);

//       const { lat: lat2, lng: lng2 } = array[0];
//       const latLong2 = new window.google.maps.LatLng(lat2, lng2);

//       // in meters:
//       const distance =
//         window.google.maps.geometry.spherical.computeDistanceBetween(
//           latLong1,
//           latLong2
//         );

//       return { ...coordinates, distance };
//     });

//     console.log(this.path);
//   };

//   render = () => {
//     return (
//       <GoogleMap
//         defaultZoom={16}
//         defaultCenter={{ lat: 18.559008, lng: -68.388881 }}
//       >
//         {this.state.progress && (
//           <>
//             <Polyline options={{ strokeColor: "#FF0000 " }} />
//             <Marker
//               position={this.state.progress[this.state.progress.length - 1]}
//             />
//           </>
//         )}
//       </GoogleMap>
//     );
//   };
// }

// const MapComponent = withScriptjs(withGoogleMap(Map));

// export default () => (
//   <MapComponent
//     googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
//     loadingElement={<div style={{ height: `100%` }} />}
//     containerElement={<div style={{ height: `450px`, width: "550px" }} />}
//     mapElement={<div style={{ height: `100%` }} />}
//   />
// );
