import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
//import { createUser } from "../services/createUser";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { environment } from "../../environment";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const CreatePassword = ({ match }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showLogin, setShowLogin] = useState(false);

  const search = useLocation();
  const result = new URLSearchParams(search).get("search");
  const token = result.split("=");
  const dap_token = token[1];

  useEffect(() => {
    console.log("jhgja");
    console.log(search);
    console.log(token);
    console.log(dap_token);
  }, []);

  const createPasswordHandler = () => {
    if (password == confirmPassword) {
      axios
        .post(environment.apiUrl + "noauth/activate?sendActivationMail=true", {
          activateToken: dap_token,
          password: password,
        })
        .then((res) => {
          console.log(res);
          MySwal.fire({
            title: <p>user created</p>,
            type: "error",
          });
        })
        .catch((err) => {
          MySwal.fire({
            title: <p>{err?.response?.data?.message || "Failed"}</p>,
            type: "error",
          });
        });
    } else {
      MySwal.fire({
        title: <p>Passwords don't match!</p>,
        type: "error",
      });
    }
  };
  return (
    <div className='container-fluid page-body-wrapper'>
      <div className='main-panel-fp-page'>
        <div className='d-flex align-items-stretch auth auth-img-bg h-100'>
          <div className='row flex-grow' style={{ height: "100vh" }}>
            <div className='col-lg-6 d-flex flex-row left-bg'></div>
            <div className='col-lg-6 d-flex align-items-center justify-content-center right-bg'>
              <div className='auth-form-transparent p-3 '>
                <div className='brand-logo'>
                  <div className='brand-logo text-center'>
                    <img src='../../assets/images/DAP_logo1.png' alt='logo' />
                  </div>
                </div>

                <Form className='mx-auto'>
                  <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='******'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ color: "#ffff" }}
                      required
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='password'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='******'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      style={{ color: "#ffff" }}
                      required
                    ></Form.Control>
                  </Form.Group>
                </Form>
                <div className='my-2 d-flex justify-content-between align-items-center mx-auto'>
                  <div className=' mx-auto'>
                    <Button
                      type='submit'
                      className='btn btn-block btn-light btn-lg font-weight-medium auth-form-btn'
                      onClick={() => {
                        createPasswordHandler();
                      }}
                    >
                      Create Password
                    </Button>
                    {showLogin && (
                      <LinkContainer
                        to={`/login`}
                        style={{
                          float: "right",
                          //paddingTop: "18px",
                          marginBottom: "20px",
                        }}
                      >
                        <Button variant='light' className='btn-lg my-3'>
                          Create Customer
                        </Button>
                      </LinkContainer>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
