import React, { useState, useEffect } from "react";
import { Line, Bar, Doughnut, Pie, Scatter } from "react-chartjs-2";
import "../MasterDashboard.css";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import { environment } from "../../../environment";
import MapComponent from "../AdminMap";
import MasterMap from "../MasterMap";
import Spinner from "../../Components/Spinner";
import withReactContent from "sweetalert2-react-content";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const AgentDashboard = ({ history }) => {
  const [tenantsCount, setTenantsCount] = useState(0);
  const [customerCount, setCustomerCount] = useState(0);
  const [deviceCount, setDeviceCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [inActiveCount, setInActiveCount] = useState(0);
  const [deviceCoordinate, setDeviceCoordinate] = useState([]);
  const [isMapToCalled, setIsMapToCalled] = useState(false);
  const [location, setLocation] = useState([]);
  const [locationType, setLocationType] = useState("");
  const [callMap, setCallMap] = useState(false);
  const [devicePerLocation, setdevicePerLocation] = useState([]);

  let location_array = [];
  location_array = [
    {
      locationName: "All",
      locationId: 10,
    },
    {
      locationName: "Hospital",
      locationId: 1,
    },
    {
      locationName: "School",
      locationId: 2,
    },
    {
      locationName: "Office",
      locationId: 3,
    },
    {
      locationName: "Hospitality",
      locationId: 4,
    },
  ];

  useEffect(() => {
    let activeDevice = [];
    let inActiveDevice = [];
    let dap_device_coordinates = [];
    axios
      .get(environment.dap_url + "dapDevice/getAll")
      .then((res) => {
        console.log(res);

        setDeviceCount(res.data.length);
        console.log("fruit", res.data.length);
        //setDeviceCoordinate(res.data);
        setDeviceLocation(res.data);
        res.data.forEach(function (item) {
          if (item.isActive == true) {
            console.log("active");
            activeDevice.push(item.deviceName);
            // console.log(activeDevice);
            setActiveCount(activeDevice.length);
          }

          if (item.isActive == false) {
            inActiveDevice.push(item.deviceName);
            //console.log(inActiveDevice);
            setInActiveCount(inActiveDevice.length);
          }
        });
      })
      .catch((err) => {});

    axios
      .post(environment.dap_url + "users/getUsersByfilters", {
        filters: {},
      })
      .then((res) => {
        console.log(res.data);

        console.log(res.data.length);
        setCustomerCount(res.data.length);
      })
      .catch((err) => {});

    axios
      .get(
        environment.apiUrl +
          "tenantInfos?pageSize=100&page=0&sortProperty=createdTime&sortOrder=DESC",
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        setTenantsCount(res.data.data.length);
      })
      .catch((err) => {});
  }, []);

  const areaData = {
    labels: ["10", "20", "30", "40", "50"],
    datasets: [
      {
        label: "Customers",
        data: [10, 20, 18, 25, 27],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        fill: true, // 3: no fill
      },
    ],
  };

  const areaOptions = {
    plugins: {
      filler: {
        propagate: true,
      },
    },
  };

  const setDeviceLocation = (device_data) => {
    setCallMap(false);
    let dap_device_coordinates = [];
    console.log(device_data);
    device_data.forEach(function (item) {
      dap_device_coordinates.push({
        deviceName: item.deviceName,
        deviceId: item.deviceId,
        tenantId: item.tenantId,
        lat: item.deviceLat ? Number(item.deviceLat) : 0,
        lon: item.deviceLon ? Number(item.deviceLon) : 0,
      });
    });

    console.log(dap_device_coordinates);
    localStorage.setItem(
      "deviceCoordinates",
      JSON.stringify(dap_device_coordinates)
    );
    setCallMap(true);
    setTimeout(() => {
      setIsMapToCalled(true);
    }, 8000);
  };

  const showTenants = () => {
    history.push(`/tenants`);
  };

  const showUsers = () => {
    history.push(`/customers`);
  };

  const showDevice = () => {
    // console.log(device);
    // // /getSingleDeviceData/:id
    // history.push(
    //   `/getSingleDeviceData/` + device.device_id + "/" + device.device_name
    // );
    history.push(`/devices`);
  };

  const setLocationHandler = (e, deviceId) => {
    console.log(e);
    if (e.id === 10) {
      setLocationType("all");
    } else {
      setLocationType(e.value);
    }

    console.log(e.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(locationType);
    console.log(e.id);
    let dap_device_location = [];
    axios
      .post(environment.dap_url + "deviceApi/getDeviceByLocation", {
        deviceInstalledPlace: locationType,
      })
      .then((res) => {
        console.log(res.data);
        setdevicePerLocation(res.data);
        setDeviceLocation(res.data);
        console.log(res.data.length);
      })
      .catch((err) => {});
    if (locationType === "all") {
      axios
        .get(environment.dap_url + "dapDevice/getAll")
        .then((res) => {
          setDeviceLocation(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // else {
    //   axios
    //     .get(environment.dap_url + "dapDevice/getAll")
    //     .then((res) => {
    //       setDeviceLocation(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  };

  const columns = [
    {
      dataField: "deviceName",
      text: "Device",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div style={{ marginTop: "5%", marginLeft: "2%" }}>
      <div className="row">
        <div className="col-md-6 col-lg-3 grid-margin stretch-card">
          <div className="card-md" onClick={() => showUsers()}>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-md-center">
                <i className="mdi mdi-account-multiple icon-lg text-success"></i>
                <div className="ml-3">
                  <p className="mb-0">Customers Management</p>
                  <h6>Customers</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 grid-margin stretch-card">
          <div className="card-md" onClick={() => showDevice()}>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-md-center">
                <i className="mdi mdi-polaroid icon-lg text-success"></i>
                <div className="ml-3">
                  <p className="mb-0">Device Management</p>
                  <h6>Devices</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-md-6 col-lg-3 grid-margin stretch-card">
        <div className="card-md">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-md-center">
              <i className="mdi mdi-view-dashboard icon-lg text-success"></i>
              <div className="ml-3">
                <p className="mb-0">Dashboard Management</p>
                <h6>Dashbaord</h6>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        <div className="col-md-6 col-lg-3 grid-margin stretch-card">
          <div className="card-md">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-md-center">
                <i className="mdi mdi-settings icon-lg text-success"></i>
                <div className="ml-3">
                  <p className="mb-0">Rules Management</p>
                  <h6>Rules</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 grid-margin stretch-card">
          <div className="card-md" onClick={() => showTenants()}>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-md-center">
                <i className="mdi mdi-account-multiple icon-lg text-success"></i>
                <div className="ml-3">
                  <p className="mb-0">Tenants Management</p>
                  <h6>Tenants</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentDashboard;
