import axios from "axios";
import React, { useState } from "react";
import { Form, Button, FormControl } from "react-bootstrap";

import { environment } from "../../environment.js";

//import SweetAlert from "sweetalert-react";
import "./dashboard.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailErrors, setEmailErrors] = useState({});

  const formValidation = () => {
    const emailErrors = {};
    const passwordErrors = {};
    let isValid = true;

    if (!email) {
      emailErrors.noEmail = "Email id required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailErrors.invalidEmail = "Email address is invalid";
      isValid = false;
    }

    setEmailErrors(emailErrors);

    return isValid;
  };

  const forgotPasswordHandler = (email) => {
    const isValid = formValidation();
    if (isValid) {
      axios
        .post(environment.apiUrl + "noauth/resetPasswordByEmail", {
          email: email,
        })
        .then(() => {
          console.log("password changing initiated");
          axios.post(environment.apiUrl + "noauth/resetPassword");

          MySwal.fire({
            title: (
              <p>Password Reset mail has been sent to the registered mail!</p>
            ),
            type: "success",
          });

          //window.alert("Password Reset link sent!");
        })
        .catch((err) => {
          console.log("object");

          MySwal.fire({
            title: <p>Invalid Email!</p>,
            type: "error",
          });
        });
    }
  };

  return (
    <div className='container-fluid page-body-wrapper'>
      <div className='main-panel-fp-page'>
        <div className='d-flex align-items-stretch auth auth-img-bg h-100'>
          <div className='row flex-grow' style={{ height: "100vh" }}>
            <div className='col-lg-6 d-flex flex-row left-bg'></div>
            <div className='col-lg-6 d-flex align-items-center justify-content-center right-bg'>
              <div className='auth-form-transparent p-3 '>
                <div className='brand-logo'>
                  <div className='brand-logo text-center'>
                    <img src='../../assets/images/DAP_logo1.png' alt='logo' />
                  </div>
                </div>

                <Form className='mx-auto'>
                  <Form.Group controlId='email'>
                    <Form.Control
                      type='email'
                      placeholder='johndoe@gmail.com'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ color: "#ffff" }}
                      required
                    ></Form.Control>
                    <div>
                      {Object.keys(emailErrors).map((key) => {
                        return (
                          <div style={{ color: "#f53325" }}>
                            {emailErrors[key]}
                          </div>
                        );
                      })}
                    </div>
                  </Form.Group>
                </Form>
                <div className='my-2 d-flex justify-content-between align-items-center mx-auto'>
                  <div className=' mx-auto'>
                    <Button
                      type='submit'
                      className='btn btn-block btn-light btn-lg font-weight-medium auth-form-btn'
                      onClick={() => {
                        forgotPasswordHandler(email);
                      }}
                    >
                      Send Password Reset Link!
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <>
    //   <div>
    //     <div className="d-flex align-items-stretch auth auth-img-bg h-100">
    //       <div className="row flex-grow">
    //         <div className="col-lg-6 d-flex flex-row left-bg"></div>
    //         <div className="card col-lg-6 d-flex align-items-center justify-content-center right-bg">
    //           <div className="auth-form-transparent p-3 ">
    //             <div className="brand-logo">
    //               <div className="brand-logo text-center">
    //                 <img src="../../assets/images/DAP_logo1.png" alt="logo" />
    //               </div>
    //             </div>

    //             <form className="pt-3">
    //               <div className="form-group">
    //                 <div className="input-group bg-transparent">
    //                   <div className="input-group-prepend bg-transparent">
    //                     <span className="input-group-text bg-transparent border-right-0">
    //                       <i
    //                         className="fa fa-user fa-2x"
    //                         style={{ color: "#ffff" }}
    //                       ></i>
    //                     </span>
    //                   </div>
    //                   <input
    //                     type="text"
    //                     className="bg-transparent form-control form-control-lg border-left-0"
    //                     id="exampleInputEmail"
    //                     placeholder="johndoe@gmail.com"
    //                     value={email}
    //                     onChange={(e) => setEmail(e.target.value)}
    //                   />
    //                 </div>
    //               </div>

    //               <div className="my-2 d-flex justify-content-between align-items-center mx-auto">
    //                 <div className=" mx-auto">
    //                   <Button
    //                     type="submit"
    //                     className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
    //                     onClick={forgotPasswordHandler(email)}
    //                   >
    //                     Send Password Reset Link!
    //                   </Button>
    //                 </div>
    //               </div>
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
};

export default ForgotPassword;
