import React from "react";
import "./WeatherComponent.css";

const Weather = (props) => {
  return (
    <>
      <div
        className="card"
        style={{
          boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
          borderRadius: "20px",
          height: "100%",
        }}
        id="weather-card"
      >
        <div className="card-body" id="wether-card">
          <div className="mx-auto" style={{ width: "50%" }}>
            <div clasName="mx-auto text-center">
              <h1>{props.city}</h1>
              <h5>
                {props.region},&#160;{props.country}
              </h5>

              {props.localtime}
            </div>
            <div>
              <div>
                {" "}
                <img
                  src={props.icon}
                  alt="weathericon"
                  style={{ marginLeft: "30%", width: "10%" }}
                  id="weathericon"
                />
              </div>

              <div className="row">
                <div className="col-4" style={{ marginLeft: "10%" }}>
                  <h1>
                    <strong>{props.temp_max}&deg;</strong>
                  </h1>
                  <h5>{props.description}</h5>
                </div>
              </div>

              <div className="row">
                <div className="col-2">
                  <h5 className="py-3 mx-auto text-center">
                    <p>CO</p> {props.co}
                    &#160;ppb
                  </h5>
                </div>
                <div className="col-2">
                  <h5 className="py-3 mx-auto text-center">
                    <p>NO2</p> {props.no2}
                    &#160;ppb
                  </h5>
                </div>
                <div className="col-2">
                  <h5 className="py-3  mx-auto text-center">
                    <p> O3 </p>
                    {props.o3}
                    &#160;ppb
                  </h5>
                </div>
                <div className="col-2">
                  <h5 className="py-3 mx-auto text-center">
                    <p> PM 2.5</p> {props.pm2_5}&#160; &#181;g/m<sup>3</sup>
                  </h5>
                </div>
                <div className="col-2">
                  <h5 className="py-3 mx-auto text-center">
                    <p> PM 10</p> {props.pm10}&#160; &#181;g/m<sup>3</sup>
                  </h5>
                </div>
                <div className="col-2">
                  <h5 className="py-3 mx-auto text-center">
                    <p> SO2</p> {props.so2}
                    &#160;ppb
                  </h5>
                </div>
              </div>

              <div className="row">
                <div className="col-2">
                  <h5 className="py-3 mx-auto text-center">
                    <p>Humidity </p>
                    {props.humidity}&#160;g/m<sup>3</sup>
                  </h5>
                </div>
                {/* <h5 className="py-3  mx-auto text-center">
                  <p>Last Updated</p> {props.last_updated}
                </h5> */}
                <div className="col-2">
                  <h5 className="py-3">
                    <p>Pressure</p> {props.pressure_in}&#160;Pa
                  </h5>
                </div>
                <div className="col-2">
                  <h5 className="py-3  mx-auto text-center">
                    <p>UV </p>
                    {props.uv}&#160;mW/cm<sup>2</sup>
                  </h5>
                </div>
                <div className="col-2">
                  <h5 className="py-3  mx-auto text-center">
                    <p>Wind Dir</p> {props.wind_dir}
                  </h5>
                </div>
                <div className="col-2">
                  <h5 className="py-3  mx-auto text-center">
                    <p>Wind kph</p> {props.wind_kph}&#160;kph
                  </h5>
                </div>
                <div className="col-2">
                  <h5 className="py-3  mx-auto text-center">
                    <p>Wind mph</p> {props.wind_mph}&#160;mph
                  </h5>
                </div>
              </div>

              {/* <h5 className="py-3">Wind mph: {props.wind_mph}&#160;mph</h5> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function minmaxTemp(min, max) {
  if (min && max) {
    return (
      <h5>
        Min Temp:<span className="px-4">{min}&deg;C</span>
        Max Temp:<span className="px-4">{max}&deg;C</span>
      </h5>
    );
  }
}
export default Weather;
