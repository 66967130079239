import React, { useState, useEffect } from "react";
import { environment } from "../../environment";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const MySwal = withReactContent(Swal);

const CustomersForAdmin = ({ history }) => {
  const [customers, setCustomers] = useState([]);
  const [email, setEmail] = useState("");
  const [customerCount, setCustomerCount] = useState("");
  var customers_array = [];
  useEffect(() => {
    axios
      .post(environment.dap_url + "users/getUsersByfilters", {
        filters: { userType: "CUSTOMER_USER" },
      })
      .then((res) => {
        console.log(res.data);
        setCustomers(res.data);
        console.log(res.data.length);
        setCustomerCount(res.data.length);
        customers_array = res.data;
        console.log(customers_array);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const viewAnserHandler = (id) => {
    console.log(id);
    history.push(`/viewResponse/${id}`);
  };

  const columns = [
    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
    },
    {
      dataField: "userFirstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "userLastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   sort: false,
    //   formatter: () => {
    //     return (
    //       <div>
    //         <button className='btn btn-light'>
    //           <i className='mdi mdi-eye-outline text-primary'></i>View
    //         </button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <>
      <div>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <h4 className='card-title'>CUSTOMERS</h4>
                <div className='row'>
                  <div className='col-12'>
                    <ToolkitProvider
                      keyField='id'
                      bootstrap4
                      data={customers}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className='d-flex align-items-center'>
                            <p className='mb-2 mr-2'>Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses='table-responsive'
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h5 style={{ marginLeft: "5%" }}>
        Total number of Customers: {customerCount}
      </h5>
    </>
  );
};

export default CustomersForAdmin;
