import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
//import { createCustomer } from "../services/createCustomer";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../environment";
//import Loader from "../Components/Loader";
import Swal from "sweetalert2";
import Select from "react-select";
import { ChipSet, Chip } from "@material/react-chips";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const CreateQuestions = ({ history }) => {
  const [question, setQuestion] = useState("");
  const [dap_option_name, setoption_name] = useState("");
  const [question_type_id, setQuestionTypeId] = useState("");
  const [dap_state, setState] = useState("");
  const [question_type, setQuestionType] = useState("");
  const [masterId, setMasterId] = useState("");
  const [masterType, setMasterType] = useState("");
  const [options, setOption] = useState([]);
  const [weightage, setWeightage] = useState("");
  const [inputList, setInputList] = useState([
    { option_name: "", waitage_val: "" },
  ]);
  const [checkBoxOptions, setCheckBoxOptions] = useState([]);
  const [checkBoxCustomOptions, setCheckBoxCustomOptions] = useState([]);

  const [emailErrors, setMasterIdErrors] = useState({});
  const [nameErrors, setNameErrors] = useState({});
  const [phoneErrors, setPhoneErrors] = useState({});

  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   let userDetails = "";
  //   userDetails = localStorage.getItem("userDetails");
  //   userDetails = JSON.parse(userDetails);
  //   console.log("hyyy therer", userDetails.data.tenantId.id);
  //   setTenantId(userDetails.data.tenantId.id);
  // }, []);
  let master_dropdown = [];
  master_dropdown = [
    {
      questionMasterName: "Customer Feedback",
      questionMasterId: 1,
    },
    {
      questionMasterName: "Volunatry Feedback (Before using purifier)",
      questionMasterId: 2,
    },
    {
      questionMasterName: "After Usage Feedback (After using purifier)",
      questionMasterId: 3,
    },
  ];

  let question_array_before = [];
  question_array_before = [
    {
      type: "Yes/No Type",
      questionTypeId: 1,
    },
    {
      type: "Option Based",
      questionTypeId: 2,
    },

    {
      type: "Descriptive",
      questionTypeId: 3,
    },
    {
      type: "Checkbox",
      questionTypeId: 4,
    },
    {
      type: "Checkbox with custom input",
      questionTypeId: 5,
    },
    {
      type: "Ranking",
      questionTypeId: 6,
    },
    {
      type: "Option with custom input",
      questionTypeId: 7,
    },
  ];

  let question_array_feedback = [];
  question_array_feedback = [
    {
      type: "Yes/No Type",
      questionTypeId: 1,
    },
    {
      type: "Option Based",
      questionTypeId: 2,
    },
    {
      type: "Descriptive",
      questionTypeId: 3,
    },
    // {
    //   type: "Checkbox",
    //   questionTypeId: 4,
    // },
    // {
    //   type: "Checkbox with custom input",
    //   questionTypeId: 5,
    // },
    // {
    //   type: "Ranking",
    //   questionTypeId: 6,
    // },
    // {
    //   type: "Option with custom input",
    //   questionTypeId: 7,
    // },
  ];

  const formValidation = () => {
    const emailErrors = {};
    const nameErrors = {};
    const phoneErrors = {};
    let isValid = true;

    if (!masterId) {
      emailErrors.noEmail = "* Email address required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(masterId)) {
      emailErrors.invalidEmail = "* Email address is invalid";
      isValid = false;
    }
    if (!dap_option_name) {
      nameErrors.noName = "* First name is required";
      isValid = false;
    } else if (dap_option_name.trim().length < 2) {
      nameErrors.shortName = "* First name is too short";
      isValid = false;
    } else if (dap_option_name.trim().length > 30) {
      nameErrors.longName = "* First name is too long";
      isValid = false;
    }

    setMasterIdErrors(emailErrors);

    setNameErrors(nameErrors);

    return isValid;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("question id", question_type_id);
    console.log(
      "question:",
      question,
      "question_type_id",
      question_type_id,
      "question_type",
      question_type,
      "masterid",
      masterId,
      "masterTYpe",
      masterType,
      "options",
      options
    );

    const isValid = formValidation();

    if ((question, question_type_id, question_type)) {
      setLoading(true);
      await axios
        .post(
          environment.dap_url +
            // "user?sendActivationMail=true&entityGroupId=49942ab0-0f9a-11ec-aeab-6338f3fa1c68",
            "questionnarieApi/createQuestion",
          {
            question: question,
            questionTypeId: question_type_id,
            questionType: question_type,
            questionMasterId: masterId,
            answerOptions: options,
            waitage: weightage,
          }
        )
        .then((res) => {
          console.log(res);
          MySwal.fire({
            title: <p>Question has been successfully created!</p>,
            type: "success",
          });
          setMasterType("");
          setQuestionType("");
          setInputList([{ option_name: "", waitage_val: "" }]);
          setQuestion("");
          setWeightage("");
          setLoader(false);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          MySwal.fire({
            title: (
              <p>
                {err?.response?.data?.message || "Question creation failed"}
              </p>
            ),
            type: "error",
          });
        });
    }
  };

  const setQuestionHandler = (e) => {
    console.log(e);
    console.log(e.id);
    setQuestionTypeId(e.id);
    setQuestionType(e.label);
  };

  const setMasterHandler = (e) => {
    console.log(e);
    setMasterId(e.id);
    setMasterType(e.label);
  };

  const appendInput = (e) => {
    e.preventDefault();
    console.log("add");
    console.log(options);
    let options_to_append = [];
    options_to_append.push(options);

    console.log(options_to_append);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    e.preventDefault();
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    let input_arr = [];

    inputList.forEach((i) => {
      if (i.option_name) {
        input_arr.push({
          option: i.option_name,
          waitage: Number(i.waitage_val),
        });
      }
    });
    console.log(input_arr);
    setOption(input_arr);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { option_name: "", waitage_val: "" }]);
  };

  // // handle click event of the Add button
  // const handleAddClick = (e) => {
  //   e.preventDefault();
  //   setInputList([...inputList, { input: "", waitage: "" }]);
  //   let input_arr = [];

  //   inputList.forEach((i) => {
  //     if (i.input) {
  //       input_arr.push(i.input);
  //     }
  //   });
  //   console.log(input_arr);
  //   setOption(input_arr);
  // };

  return (
    <>
      <div>
        <div className='row'>
          <div className='col-lg-3'></div>
          <div className='col-lg-6'>
            <Link to='/dashboard' className='btn btn-light my-3'>
              Go Back
            </Link>
          </div>
          <div className='col-lg-3'></div>
        </div>

        <div className='row'>
          <div className='col-lg-3'></div>
          <div className='col-md-6 grid-margin stretch-card'>
            <div className='card'>
              <div className='card-body'>
                <h1 className='text-center'>ADD QUESTION</h1>
                <Form>
                  <Form.Group controlId='name'>
                    <Form.Label>Feedback Question Type</Form.Label>
                    <Select
                      options={master_dropdown.map((element) => ({
                        value: element.questionMasterName,
                        label: element.questionMasterName,
                        id: element.questionMasterId,
                      }))}
                      onChange={(e) =>
                        //setMasterId(element.questionMasterId)
                        setMasterHandler(e)
                      }
                      // defaultValue={dap_deviceList[0].name}
                      defaultValue='hi there'
                      placeholder={masterType}
                    />
                  </Form.Group>

                  <Form.Group controlId='title'>
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      type='question'
                      placeholder='Enter question'
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <>
                    <div>
                      {masterId === 1 ? (
                        <Form.Group controlId='name'>
                          <Form.Label>Answer Type</Form.Label>
                          <Select
                            options={question_array_feedback.map((element) => ({
                              value: element.type,
                              label: element.type,
                              id: element.questionTypeId,
                            }))}
                            onChange={(e) => setQuestionHandler(e)}
                            // defaultValue={dap_deviceList[0].name}
                            defaultValue='hi there'
                            placeholder={question_type}
                          />
                        </Form.Group>
                      ) : (
                        <Form.Group controlId='name'>
                          <Form.Label>Answer Type</Form.Label>
                          <Select
                            options={question_array_before.map((element) => ({
                              value: element.type,
                              label: element.type,
                              id: element.questionTypeId,
                            }))}
                            onChange={(e) => setQuestionHandler(e)}
                            // defaultValue={dap_deviceList[0].name}
                            defaultValue='hi there'
                            placeholder={question_type}
                          />
                        </Form.Group>
                      )}
                    </div>
                  </>

                  {masterId === 1 && (
                    <>
                      {question_type_id === 1 || question_type_id === 3 ? (
                        <div className='col-lg-6'>
                          <Form.Group controlId='title'>
                            <Form.Label>Enter Question Weightage</Form.Label>
                            <Form.Control
                              type='number'
                              placeholder='Enter Question Weightage'
                              value={weightage}
                              onChange={(e) =>
                                setWeightage(Number(e.target.value))
                              }
                            ></Form.Control>
                          </Form.Group>
                        </div>
                      ) : null}
                    </>
                  )}

                  {question_type_id === 2 ||
                  question_type_id === 4 ||
                  question_type_id === 5 ||
                  question_type_id === 6 ||
                  question_type_id === 7 ? (
                    <>
                      {inputList.map((x, i) => {
                        return (
                          <div className='row'>
                            <div className='col-lg-4'>
                              {" "}
                              <Form.Control
                                key={i}
                                name='option_name'
                                placeholder='Enter Option'
                                value={x.option_name}
                                onChange={(e) => handleInputChange(e, i)}
                                style={{ marginTop: "5px" }}
                              />
                            </div>

                            <div className='col-lg-4'>
                              {" "}
                              <Form.Control
                                key={i}
                                type='number'
                                className='ml10'
                                name='waitage_val'
                                placeholder='Enter weightage'
                                value={x.waitage_val}
                                onChange={(e) => handleInputChange(e, i)}
                                style={{ marginTop: "5px" }}
                              />
                            </div>
                            <div className='col-lg-2'>
                              {" "}
                              {inputList.length !== 1 && (
                                <div
                                  // className='mr10'
                                  onClick={(e) => handleRemoveClick(e, i)}
                                  style={{ marginTop: "5px" }}
                                >
                                  <i className='mdi mdi-window-close'></i>
                                </div>
                              )}
                            </div>
                            <div>
                              {inputList.length - 1 === i && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginLeft: "10px",
                                  }}
                                  className='btn btn-primary btn-sm'
                                  onClick={(e) => handleAddClick(e)}
                                >
                                  Add More
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}

                  {/* {question_type_id === 6 ? (
                    <Form.Group controlId='option'>
                      <Form.Label>Check Box with custom options</Form.Label>
                      <Form.Control
                        type='question'
                        placeholder='Enter check box options separated by comma'
                        value={options}
                        onChange={(e) =>
                          setCheckBoxCustomOptions(e.target.value)
                        }
                      ></Form.Control>
                    </Form.Group>
                  ) : null} */}

                  <div className='mt-3'>
                    <Button
                      type='submit'
                      className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
                      disabled={loading}
                      onClick={submitHandler}
                    >
                      Create Question
                      {loading && (
                        <i
                          className='fa fa-refresh fa-spin'
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span></span>}
                      {!loading && <span></span>}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className='col-lg-3'></div>
        </div>
      </div>
    </>
  );
};

export default CreateQuestions;
