import React from "react";

const DeviceHealthStatus = () => {
  return (
    <>
      <div className='row' style={{ padding: "3%" }}>
        <div className='col-md-6 stretch-card'>
          <div className='row flex-grow'>
            <div className='col-12 grid-margin stretch-card'>
              <div className='card'>
                <div className='card-body'>
                  <h6 className='card-title mb-0'>Hera</h6>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-inline-block pt-3'>
                      <div className='d-lg-flex'>
                        <h5 className='mb-0'>Battery Percentage: 75%</h5>
                        <div className='d-flex align-items-center ml-lg-2'>
                          <i className='mdi mdi-clock text-muted'></i>
                          <small className='ml-1 mb-0'>
                            Installed on : 1/1/2021
                          </small>
                        </div>
                      </div>
                      <small className='text-gray'>Device is active.</small>
                    </div>
                    <div className='d-inline-block'>
                      <div className='bg-success px-3 px-md-4 py-2 rounded'>
                        <i className='mdi mdi-buffer text-white icon-lg'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 grid-margin stretch-card'>
              <div className='card'>
                <div className='card-body'>
                  <h6 className='card-title mb-0'>Zeus</h6>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-inline-block pt-3'>
                      <div className='d-lg-flex'>
                        <h5 className='mb-0'>Battery Percentage: 90%</h5>
                        <div className='d-flex align-items-center ml-lg-2'>
                          <i className='mdi mdi-clock text-muted'></i>
                          <small className='ml-1 mb-0'>
                            Installed on : 1/1/2021
                          </small>
                        </div>
                      </div>
                      <small className='text-gray'>Device is offline.</small>
                    </div>
                    <div className='d-inline-block'>
                      <div className='bg-warning px-3 px-md-4 py-2 rounded'>
                        <i className='mdi mdi-wallet text-white icon-lg'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 stretch-card'>
          <div className='row flex-grow'>
            <div className='col-12 grid-margin stretch-card'>
              <div className='card'>
                <div className='card-body'>
                  <h6 className='card-title mb-0'>Poseidon</h6>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-inline-block pt-3'>
                      <div className='d-lg-flex'>
                        <h5 className='mb-0'>Battery Percentage: 75%</h5>
                        <div className='d-flex align-items-center ml-lg-2'>
                          <i className='mdi mdi-clock text-muted'></i>
                          <small className='ml-1 mb-0'>
                            Installed on : 1/1/2021
                          </small>
                        </div>
                      </div>
                      <small className='text-gray'>Device is active.</small>
                    </div>
                    <div className='d-inline-block'>
                      <div className='bg-success px-3 px-md-4 py-2 rounded'>
                        <i className='mdi mdi-buffer text-white icon-lg'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 grid-margin stretch-card'>
              <div className='card'>
                <div className='card-body'>
                  <h6 className='card-title mb-0'>Hermes</h6>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-inline-block pt-3'>
                      <div className='d-lg-flex'>
                        <h5 className='mb-0'>Battery Percentage: 90%</h5>
                        <div className='d-flex align-items-center ml-lg-2'>
                          <i className='mdi mdi-clock text-muted'></i>
                          <small className='ml-1 mb-0'>
                            Installed on : 1/1/2021
                          </small>
                        </div>
                      </div>
                      <small className='text-gray'>Device is offline.</small>
                    </div>
                    <div className='d-inline-block'>
                      <div className='bg-warning px-3 px-md-4 py-2 rounded'>
                        <i className='mdi mdi-wallet text-white icon-lg'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 stretch-card'>
          <div className='row flex-grow'>
            <div className='col-12 grid-margin stretch-card'>
              <div className='card'>
                <div className='card-body'>
                  <h6 className='card-title mb-0'>Apollo</h6>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-inline-block pt-3'>
                      <div className='d-lg-flex'>
                        <h5 className='mb-0'>Battery Percentage: 75%</h5>
                        <div className='d-flex align-items-center ml-lg-2'>
                          <i className='mdi mdi-clock text-muted'></i>
                          <small className='ml-1 mb-0'>
                            Installed on : 1/1/2021
                          </small>
                        </div>
                      </div>
                      <small className='text-gray'>Device is active.</small>
                    </div>
                    <div className='d-inline-block'>
                      <div className='bg-success px-3 px-md-4 py-2 rounded'>
                        <i className='mdi mdi-buffer text-white icon-lg'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 stretch-card'>
          <div className='row flex-grow'>
            <div className='col-12 grid-margin stretch-card'>
              <div className='card'>
                <div className='card-body'>
                  <h6 className='card-title mb-0'>Demeter</h6>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-inline-block pt-3'>
                      <div className='d-lg-flex'>
                        <h5 className='mb-0'>Battery Percentage: 75%</h5>
                        <div className='d-flex align-items-center ml-lg-2'>
                          <i className='mdi mdi-clock text-muted'></i>
                          <small className='ml-1 mb-0'>
                            Installed on : 1/1/2021
                          </small>
                        </div>
                      </div>
                      <small className='text-gray'>Device is active.</small>
                    </div>
                    <div className='d-inline-block'>
                      <div className='bg-success px-3 px-md-4 py-2 rounded'>
                        <i className='mdi mdi-buffer text-white icon-lg'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceHealthStatus;
