import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
//import { createDevice } from "../services/createDevice";
import Editable from "react-bootstrap-editable";
import axios from "axios";
import Select from "react-select";
import { environment } from "../../environment";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const UpdateDeviceSysAdmin = ({ match, history }) => {
  const [dap_label, setLabel] = useState("");
  const [dap_description, setDescription] = useState("");
  const [dap_name, setName] = useState("");
  const [dap_transport_type, setTrasportType] = useState("");
  const [dap_profileName, setProfileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState([]);
  const [locationType, setLocationType] = useState("");

  const deviceId = match.params.deviceId;

  let location_array = [];
  location_array = [
    {
      locationName: "Hospital",
      locationId: 1,
    },
    {
      locationName: "School",
      locationId: 2,
    },
    {
      locationName: "Industrial Company",
      locationId: 3,
    },
    {
      locationName: "Hospitality",
      locationId: 4,
    },

    {
      locationName: "None",
      locationId: 10,
    },
  ];

  const submitHandler = (e) => {
    e.preventDefault();
    if (locationType || locationType === null) {
      setLoading(true);
      axios
        .put(environment.dap_url + "deviceApi/updateDevicePlace", {
          deviceId: deviceId,
          deviceInstalledPlace: locationType,
        })
        .then((res) => {
          setLoading(false);
          console.log(res);
          console.log(res);
          MySwal.fire({
            title: <p>Device location updated!</p>,
            type: "success",
          });
          history.push("/devices");
          setLocationType("");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          console.log(err);
          MySwal.fire({
            title: (
              <p>
                {err?.response?.data?.message || "Location Updation failed"}
              </p>
            ),
            type: "error",
          });
        });
    } else {
      MySwal.fire({
        title: <p>Please enter location</p>,
        type: "error",
      });
    }
  };

  const setLocationHandler = (e, deviceId) => {
    console.log(e);
    if (e.id === 10) {
      setLocationType(null);
    } else {
      setLocationType(e.value);
    }

    console.log(e.value);
  };

  return (
    <>
      <div className='row'>
        <div className='col-lg-3'></div>
        <Link to='/devices' className='btn btn-light my-3'>
          Go Back
        </Link>
      </div>
      <div>
        <div className='row'>
          <div className='col-lg-3'></div>
          <div className='col-md-6 grid-margin stretch-card'>
            <div className='card'>
              <div
                className='card-title text-center'
                style={{ marginTop: "5%" }}
              >
                UPDATE DEVICE LOCATION
              </div>
              <div className='card-body'>
                <Form onSubmit={submitHandler}>
                  <div className='col-lg-12'>
                    <Form.Group controlId='name'>
                      <Form.Label>Select Location Type </Form.Label>
                      <Select
                        options={location_array.map((element) => ({
                          id: element.locationId,
                          value: element.locationName,
                          label: element.locationName,
                        }))}
                        onChange={(e) => setLocationHandler(e)}
                        // defaultValue={dap_deviceList[0].name}
                        defaultValue='hi there'
                        placeholder={
                          locationType ? locationType : "set location"
                        }
                      />
                    </Form.Group>
                  </div>

                  <div className='mt-3'>
                    <Button
                      type='submit'
                      className='btn btn-primary my-3 font-weight-small '
                      disabled={loading}
                      style={{ marginTop: "10%", marginLeft: "32%" }}
                    >
                      UPDATE LOCATION
                      {loading && (
                        <i
                          className='fa fa-refresh fa-spin'
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span></span>}
                      {!loading && <span></span>}
                    </Button>
                  </div>
                </Form>
              </div>
              <div className='col-lg-3'></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateDeviceSysAdmin;
