import React from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polyline,
  Marker,
  OverlayView,
  InfoWindow,
} from "react-google-maps";
import MarkerClusterer from "@google/markerclusterer";
//import googleMapStyles from "./GoogleMapStyles";
import "./UserMap.css";

// import { Map, GoogssssleApiWrapper, Marker } from "google-maps-react";
import { deviceCoordinateService } from "../services/deviceCoordinateService";

//url: "/assets/images/dap_location.png",

const styles = require("./GoogleMapStyles.json");

let coordinates = [];
coordinates = localStorage.getItem("deviceCoordinates");
coordinates = JSON.parse(coordinates);

export class Maps extends React.Component {
  super() {
    this.state = {
      mapMarker: null,
      showingInfoWindow: false,
      isLoaded: false,
    };
    this.state = {
      mapArray: [
        {
          lat: 20.2990558,
          lng: 85.8309919,
          location: "OCAC",
          device_name: "Her",
        },
        {
          lat: 20.2990558,
          lng: 85.8309919,
          location: "OCAC",
          device_name: "Her",
        },
      ],
    };
  }

  componentDidMount() {
    let device_coordinates = [];
    let device_zero_coordinates = [];
    device_coordinates = localStorage.getItem("deviceCoordinates");

    //if (coordinates) {
    coordinates = JSON.parse(device_coordinates);

    console.log(coordinates);
    if (coordinates.deviceName) {
      this.setState({
        centerLat: coordinates[0]?.deviceLat || 0,
        centerLon: coordinates[0]?.deviceLon || 0,
      });

      function callCoordinates() {
        device_coordinates = JSON.parse(
          localStorage.getItem("deviceCoordinates")
        );
      }
      setTimeout(() => {
        callCoordinates();

        this.setState({
          centerLat: Number(device_coordinates[0]?.deviceLat),
          centerLon: Number(device_coordinates[0]?.deviceLon),
        });
      }, 4000);
      //console.log("hatighoda",device_coordinates);

      coordinates.forEach((element) => {
        element.lat = Number(element.deviceLat);
        element.lon = Number(element.deviceLon);
      });
    } else {
      this.setState({
        mapArray: coordinates,
      });
      this.setState({
        isLoaded: true,
      });
    }

    console.log(coordinates, "coordinates");
    this.setState({
      mapArray: coordinates,
    });
    this.setState({
      isLoaded: true,
    });
  }
  state = {
    mapMarker: null,
    showingInfoWindow: false,
    mapArray: coordinates,
    isLoaded: false,
    centerLat: 0,
    centerLon: 0,
  };

  goToDevicePage(location, e) {
    //e.preventDefault();
    console.log("go to device");
    console.log(location);
    let id = location.deviceId;
    let tenantId = location.tenantId;
    let deviceName = location.deviceName;

    // location.href(`/getSingleDeviceData/${id}/${deviceName}`);
    window.location.href = `/getSingleDeviceData/${id}/${deviceName}`;
  }

  render() {
    const mapStyles = {
      width: "100%",
      height: "100%",
    };
    return (
      <>
        {this.state.isLoaded ? (
          <>
            <GoogleMap
              defaultZoom={8}
              center={{ lat: 20.2961, lng: 85.8245 }}
              // center={{
              //   lat: this.state?.centerLat,
              //   lng: this.state?.centerLon,
              // }}
              defaultOptions={{
                disableDefaultUI: false, // disable default map UI
                draggable: true, // make map draggable
                keyboardShortcuts: false, // disable keyboard shortcuts
                scaleControl: true, // allow scale controle
                scrollwheel: true, // allow scroll wheel
                styles: styles, // change default map styles
              }}
            >
              <>
                <Polyline options={{ strokeColor: "red " }} />
                {this.state.mapArray.reverse().map((location) => (
                  <Marker
                    // position={{
                    //   lat:20.2990558,
                    //   lng: 85.8309919
                    // }}
                    icon={{
                      url: "/assets/images/dap_location.png",
                      // fillColor: "#0000ff",
                      // fillOpacity: 1.0,
                      // strokeWeight: 0,
                      // scale: 1.25
                    }}
                    //label='location'
                    // position={{
                    //   lat: location?.lat,
                    //   lng: location?.lon,
                    // }}
                    //label={location.deviceName}
                    position={{ lat: location.lat, lng: location.lon }}
                    // position={{
                    //   lat: 20.2990558,
                    //   lng: 85.8309919,
                    // }}
                    clickable
                    onClick={(e) => this.goToDevicePage(location, e)}
                  >
                    <InfoWindow
                    // position={{
                    //   lat:20.2990558,
                    //   lng:85.8309919
                    // }}
                    // onClick={this.goToDevicePage}
                    >
                      <div>{location.deviceName}</div>
                      {/* <div>{location.lat}</div> */}
                    </InfoWindow>
                  </Marker>
                ))}
              </>
            </GoogleMap>
          </>
        ) : (
          <>
            <GoogleMap
              defaultZoom={12}
              // initialCenter={{ lat: 9.761927, lng: 79.95244 }}
              defaultCenter={{ lat: 20.2961, lng: 85.8245 }}
              center={{ lat: 20.2961, lng: 85.8245 }}
              defaultOptions={{
                disableDefaultUI: false, // disable default map UI
                draggable: true, // make map draggable
                keyboardShortcuts: false, // disable keyboard shortcuts
                scaleControl: true, // allow scale controle
                scrollwheel: true, // allow scroll wheel
                styles: styles, // change default map styles
              }}
            ></GoogleMap>
          </>
        )}

        {/* <>
          <GoogleMap
            defaultZoom={12}
            // initialCenter={{ lat: 9.761927, lng: 79.95244 }}
            defaultCenter={{ lat: 20.2961, lng: 85.8245 }}
            center={{ lat: 20.2961, lng: 85.8245 }}
            defaultOptions={{
              disableDefaultUI: false, // disable default map UI
              draggable: true, // make map draggable
              keyboardShortcuts: false, // disable keyboard shortcuts
              scaleControl: true, // allow scale controle
              scrollwheel: true, // allow scroll wheel
              styles: styles, // change default map styles
            }}
          ></GoogleMap>
        </> */}
      </>
    );
  }
}

//Maps.defaultProps = googleMapStyles;

const MapComponent = withScriptjs(withGoogleMap(Maps));
const exampleMapStyles = {
  default: [],
  hide: [
    {
      featureType: "poi.business",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
  ],
};

export default () => (
  <MapComponent
    googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyDpEqauXWZEF99qOZuKNkZKmQwJJ01s2l4'
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `500px`, width: "100%" }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
);
