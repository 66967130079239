import React from "react";
// import { ProgressBar } from "react-bootstrap";
// import Rating from "react-rating";
import axios from "axios";
import { useState, useEffect } from "react";
import { environment } from "../../environment";
import { useHistory } from "react-router-dom";
import Loader from "../Components/Loader";

const UserProfile = () => {
  const { push } = useHistory();
  // var dap_storedDetails;
  // let dap_responseData;
  let image_url = "../../assets/images/DAP_logo1.png";
  let userDetails;
  const [loader, setLoader] = useState(true);
  const [dap_profilePicUrl, setProfilePicUrl] = useState("");
  const [dap_userName, setUserDetails] = useState({
    name: "",
    email: "",
    authority: "",
    address: "",
  });
  const [dap_mobile, setMobile] = useState("");
  const [dap_address, setAddress] = useState("");

  var fullName = "";
  // var dap_email = "";
  useEffect(() => {
    userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    fullName = userDetails.data.firstName + " " + userDetails.data.lastName;
    var dap_email = userDetails.data.email;
    console.log(dap_email);
    setUserDetails({
      name: fullName,
      email: userDetails.data.email,
      authority: userDetails.data.authority,
    });
    getUserDetails();
    setLoader(false);
  }, []);
  const getUserDetails = async () => {
    // e.preventDefault()

    console.log(userDetails);
    await axios
      .get(environment.dap_url + "users/" + userDetails.data.id.id)
      .then((res) => {
        console.log(res);
        let profile_url = res.data.profileImageUrl;
        if (res.data.profileImageUrl) {
          setProfilePicUrl(environment.dap_imageUrl + profile_url);
        } else {
          setProfilePicUrl("../../assets/images/useravatar.png");
        }
        setMobile(res.data.phone);
        setAddress(res.data.address);
      })
      .catch((err) => {
        window.alert(err.message);
      });
  };

  // render() {
  return loader ? (
    <Loader />
  ) : (
    <div>
      <div className="row">
        <div className="col-3"></div>
        <div className="col-6">
          <div className="card">
            <div className="card-body" style={{ paddingTop: "7%" }}>
              <div className="border-bottom text-center pb-4">
                <img
                  src={dap_profilePicUrl}
                  alt="profile"
                  className="img-lg rounded-circle mb-3"
                />
                <div className="mb-3">
                  <h3>{dap_userName.name}</h3>
                  <div className="d-flex align-items-center justify-content-center">
                    <h5 className="mb-0 mr-2 text-muted">
                      {dap_userName.email}
                    </h5>
                  </div>
                </div>

                <div>
                  <p className="clearfix">
                    <span className="float-left">Role</span>
                    <span className="float-right text-muted">
                      {" "}
                      {/* {userDetails.data.authority}{" "} */}
                      {dap_userName.authority}
                    </span>
                  </p>
                  <p className="clearfix">
                    <span className="float-left">E mail</span>
                    <span className="float-right text-muted">
                      {/* {userDetails.data.email} */}
                      {dap_userName.email}
                    </span>
                  </p>
                  <p className="clearfix">
                    <span className="float-left">Phone</span>
                    <span className="float-right text-muted">
                      {" "}
                      {dap_mobile}{" "}
                    </span>
                  </p>

                  <p className="clearfix">
                    <span className="float-left">Address</span>
                    <span className="float-right text-muted">
                      {" "}
                      {/* {userDetails.data.authority}{" "} */}
                      {dap_address}
                    </span>
                  </p>
                </div>

                <button
                  className="btn btn-success btn-block mb-2 "
                  onClick={() => push("/user/profile")}
                  style={{ wordWrap: "break-word" }}
                >
                  Edit Profile
                </button>

                <button
                  className="btn btn-primary btn-block mb-2"
                  onClick={() => push("/user/changePassword")}
                  style={{ wordWrap: "break-word" }}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3"></div>
      </div>
    </div>
  );
};

export default UserProfile;
