//Deployment Check
import React, { useState, useEffect } from "react";
import { Link, withRouter, useRouteMatch } from "react-router-dom";
//import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import axios from "axios";
import { environment } from "../../environment";
import { useLocation } from "react-router-dom";
import "./Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faAirFreshener,
  faUser,
  faEnvelope,
  faMicrochip,
} from "@fortawesome/free-solid-svg-icons";

const SideBar = ({ history, props }) => {
  const [menuState, setMenuState] = useState(false);
  const [authority, setAuthority] = useState("");
  const [userId, setUserId] = useState("");
  // const [dap_users, setUsers] = useState("");
  const [userPermission, setUserPermission] = useState([]);
  const [dap_entityId, setEntityId] = useState("");
  const [permission, setPermission] = useState(false);
  const [show, setShow] = useState(false);

  //let { match } = this.props.location;
  //let { path, url } = useRouteMatch();
  let dashboard = useRouteMatch("/dashboard");
  let customer = useRouteMatch("/customers");
  let device = useRouteMatch("/devices");
  let ruleEngine = useRouteMatch("/rule-engine");
  let profile = useRouteMatch("/profile");
  let customerProfile = useRouteMatch("/profile");
  let userProfile = useRouteMatch("/profile");
  let alerts = useRouteMatch("/alerts");
  let messages = useRouteMatch("/messages");
  let connectDevice = useRouteMatch("/connectDevice");
  let createQuestion = useRouteMatch("/createQuestion");
  let Partners = useRouteMatch("/Partners");
  let myshipment = useRouteMatch("/myshipments");
  // let match = useRouteMatch("");
  const [isJapan, setIsDapJapan] = useState(false);
  const [isDaikin, setIsDaikin] = useState(false);

  let customerUsers = useRouteMatch(`/customerUsers/${dap_entityId}`);
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("isDapJapan")) {
      setIsDapJapan(true);
    }
    if (localStorage.getItem("isDaikin")) {
      setIsDaikin(true);
    }
    console.log(isJapan);

    // console.log(isDapJapan);
    // if(isDapJapan == true){
    //   setIsDapJapan(Boolean(isDapJapan))

    // }else{
    //   setIsDapJapan(false)
    // }
    console.log("pathanme", location.pathname);
    if (location.pathname == ("" || null || undefined)) {
      location.pathname("/dashboard");
    }
    onRouteChanged();

    if (localStorage.getItem("token")) {
      history.push(location.pathname);

      if (location.pathname == "/") {
        history.push("/dashboard");
      }
    } else {
      history.push("/");
    }

    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
    let details = localStorage.getItem("userDetails");
    console.log(details);
    details = JSON.parse(details);
    setUserId(details.data.customerId.id);
    setAuthority(details.data.authority);
    console.log(details.data.authority);

    // axios
    //   .get(environment.apiUrl + "permissions/allowedPermissions", {
    //     headers: {
    //       "X-Authorization": "Bearer " + localStorage.getItem("token"),
    //     },
    //   })
    //   .then((res) => {
    //     console.log(
    //       "generic permission: ",
    //       res.data.userPermissions.genericPermissions
    //     );

    //     setUserPermission(res.data.userPermissions.genericPermissions);
    //     // localStorage.setItem(
    //     //   "userPermission",
    //     //   res.data.userPermissions.genericPermissions.ALL
    //     // );
    //   });
  }, []);

  const condition = () => {
    // for (let i = 0; i < userPermission.length; i++) {
    //   if (userPermission[i] === "ALL") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
    // if (userPermission.ALL) {
    //   axios
    //     .get(environment.apiUrl + "entityGroups/USER", {
    //       headers: {
    //         "X-Authorization": "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((res, next) => {
    //       console.log(res.data[1].ownerId.id);
    //       setEntityId(res.data[1].ownerId.id);
    //     });
    //   return true;
    // } else {
    //   return false;
    // }
  };

  const toggleMenuState = (menuState) => {
    if ([menuState]) {
      setMenuState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      setMenuState({ [menuState]: true });
    } else {
      Object.keys(menuState).forEach((i) => {
        setMenuState({ [i]: false });
      });
      setMenuState({ [menuState]: true });
    }
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(menuState).forEach((i) => {
      setMenuState({ [i]: false });
    });
  };
  return (
    <div>
      <nav
        className="sidebar sidebar-offcanvas"
        id="sidebar"
        style={{ background: "linear-gradient(#4159CA, #212D65)" }}
      >
        <ul className="nav">
          <li className="nav-item nav-profile">
            <div className="nav-link">
              <div>
                <Link to="/dashboard">
                  <img
                    src="/assets/images/DAP_logo1.png"
                    alt="profile"
                    style={{
                      width: "120px",
                      marginLeft: "21%",
                      marginTop: "2%",
                    }}
                  />
                  <span className="online-status online"></span>
                </Link>
              </div>
            </div>
          </li>

          {localStorage.getItem("isPartnerLogin") ? (
            <>
              <li
                className={
                  dashboard && dashboard.path === `/dashboard`
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/dashboard">
                  <i className="icon-menu menu-icon"></i>
                  <span className="menu-title" style={{ marginLeft: "15px" }}>
                    <Trans>Dashboard</Trans>
                  </span>
                </Link>
              </li>
              <li
                className={
                  dashboard && dashboard.path === `/myshipment`
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/myshipments">
                  <FontAwesomeIcon icon={faTruck} />
                  <span className="menu-title" style={{ marginLeft: "15px" }}>
                    {" "}
                    <Trans>My Shipments</Trans>
                  </span>
                </Link>
              </li>
              <li
                className={
                  dashboard && dashboard.path === `/manageCustomer`
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/manageCustomer">
                  <FontAwesomeIcon icon={faUser} />
                  <span className="menu-title" style={{ marginLeft: "15px" }}>
                    {" "}
                    <Trans>Manage Customers</Trans>
                  </span>
                </Link>
              </li>

              <li
                className={
                  dashboard && dashboard.path === `/partner-devices`
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/partner-devices">
                  <FontAwesomeIcon icon={faMicrochip} />
                  <span className="menu-title" style={{ marginLeft: "15px" }}>
                    {" "}
                    <Trans>Devices</Trans>
                  </span>
                </Link>
              </li>
            </>
          ) : (
            <>
              <>
                {(() => {
                  if (isJapan == true) {
                    return (
                      <>
                        <li
                          className={
                            dashboard && dashboard.path === `/dashboard`
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/dashboard">
                            <i className="icon-menu menu-icon"></i>
                            <span className="menu-title">
                              <Trans>Dashboard</Trans>
                            </span>
                          </Link>
                        </li>
                        <li
                          className={
                            dashboard &&
                            dashboard.path === `/historicaldatadownload`
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link
                            className="nav-link"
                            to="/historicaldatadownload"
                          >
                            <i className="icon-chart chart-icon"></i>
                            <span
                              className="menu-title"
                              style={{ marginLeft: "20px" }}
                            >
                              <Trans>Historical Data</Trans>
                            </span>
                          </Link>
                        </li>
                      </>
                    );
                  } else if (isDaikin) {
                    return (
                      <>
                        <li
                          className={
                            dashboard && dashboard.path === `/dashboard`
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/dashboard">
                            <i className="icon-menu menu-icon"></i>
                            <span className="menu-title">
                              <Trans>Dashboard</Trans>
                            </span>
                          </Link>
                        </li>
                        <li
                          className={
                            dashboard &&
                            dashboard.path === `/historicaldatadownload`
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link
                            className="nav-link"
                            to="/historicaldatadownload"
                          >
                            <i className="icon-chart chart-icon"></i>
                            <span
                              className="menu-title"
                              style={{ marginLeft: "20px" }}
                            >
                              <Trans>Historical Data</Trans>
                            </span>
                          </Link>
                        </li>
                      </>
                    );
                  } else {
                    if (authority === "TENANT_ADMIN") {
                      return (
                        <>
                          <li
                            className={
                              dashboard && dashboard.path === `/dashboard`
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/dashboard">
                              <i className="icon-menu menu-icon"></i>
                              <span className="menu-title">
                                <Trans>Dashboard</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              customer && customer.path === `/customers`
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/customers">
                              <i className="fa fa-users"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Customers</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              device && device.path === "/devices"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/devices">
                              <i className="fa fa-hdd-o"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Devices</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              customerProfile &&
                              customerProfile.path === "/alerts"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/alerts">
                              <i className="fa fa-bell"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Alerts</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              customerProfile &&
                              customerProfile.path === "/messages"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/messages">
                              <i className="fa fa-envelope"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Messages</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              dashboard && dashboard.path === `/historicalData`
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/historicalData">
                              <i className="icon-chart chart-icon"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Historical Data</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              ruleEngine && ruleEngine.path === "/Partners"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/Partners">
                              <i className="fa fa-gear"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Partners</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              profile && profile.path === "/profile"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/profile">
                              <i className="fa fa-user"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Profile</Trans>
                              </span>
                            </Link>
                          </li>
                        </>
                      );
                    } else if (authority === "CUSTOMER_USER") {
                      return (
                        <>
                          <li
                            className={
                              dashboard && dashboard.path === "/dashboard"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/dashboard">
                              <i className="icon-menu menu-icon"></i>
                              <span className="menu-title">
                                <Trans>Dashboard</Trans>
                              </span>
                            </Link>
                          </li>

                          {condition() ? (
                            <li
                              className={
                                customerUsers &&
                                customerUsers.path ===
                                  `/customerUsers/${dap_entityId}`
                                  ? "nav-item active"
                                  : "nav-item"
                              }
                            >
                              <Link
                                className="nav-link"
                                to={`/customerUsers/${dap_entityId}`}
                                // onClick={getCustomerUser}
                              >
                                <i className="fa fa-users"></i>
                                <span
                                  className="menu-title"
                                  style={{ marginLeft: "20px" }}
                                >
                                  <Trans>Users</Trans>
                                </span>
                              </Link>
                            </li>
                          ) : (
                            <li></li>
                          )}

                          {/* <li
                    className={
                      condition === true ? setShow(true) : setShow(false)
                    }
                  ><Link
                  className="nav-link"
                  to={`/customerUsers/${dap_entityId}`}
                >
                  <i className="fa fa-user"></i>
                  <span
                    className="menu-title"
                    style={{ marginLeft: "20px" }}
                  >
                    Users
                  </span>
                </Link></li> */}

                          <li
                            className={
                              customerProfile &&
                              customerProfile.path === "/alerts"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/alerts">
                              <i className="fa fa-bell"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Alerts</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              customerProfile &&
                              customerProfile.path === "/messages"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/messages">
                              <i className="fa fa-envelope"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Messages</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              customerProfile &&
                              customerProfile.path === "/connectDevice"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/connectDevice">
                              <i className="mdi mdi-wifi"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Connect Device </Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              customerProfile &&
                              customerProfile.path === "/fill-response"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/fill-response">
                              <i className="fa fa-question"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Feedback</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              customerProfile &&
                              customerProfile.path === "/profile"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/profile">
                              <i className="fa fa-user"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Profile</Trans>
                              </span>
                            </Link>
                          </li>
                        </>
                      );
                    } else if (authority === "SYS_ADMIN") {
                      return (
                        <>
                          <li
                            className={
                              dashboard && dashboard.path === "/dashboard"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/dashboard">
                              <i className="icon-menu menu-icon"></i>
                              <span className="menu-title">
                                <Trans>Dashboard</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              dashboard && dashboard.path === "/tenants"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/tenants">
                              <i className="fa fa-users"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Tenants</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              dashboard && dashboard.path === "/devices"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/devices">
                              <i className="fa fa-hdd-o"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Devices</Trans>
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              dashboard && dashboard.path === "/purifiers"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/purifiers">
                              <FontAwesomeIcon icon={faAirFreshener} />
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Purifier</Trans>
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              dashboard && dashboard.path === "/purifiers"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/shipments">
                              <FontAwesomeIcon icon={faTruck} />
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Shipments</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              dashboard && dashboard.path === "/customers"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/customers">
                              <i className="fa fa-users"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Customers</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              ruleEngine && ruleEngine.path === "/Partners"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/Partners">
                              <i className="fa fa-gear"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Partners</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              ruleEngine &&
                              ruleEngine.path === "/createQuestion"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/createQuestion">
                              <i className="fa fa-question"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Create Questions</Trans>
                              </span>
                            </Link>
                          </li>

                          <li
                            className={
                              ruleEngine && ruleEngine.path === "/user-feedback"
                                ? "nav-item active"
                                : "nav-item"
                            }
                          >
                            <Link className="nav-link" to="/user-feedback">
                              <i className="mdi mdi-thumb-up"></i>
                              <span
                                className="menu-title"
                                style={{ marginLeft: "20px" }}
                              >
                                <Trans>Feedback</Trans>
                              </span>
                            </Link>
                          </li>
                        </>
                      );
                    }
                  }
                })()}
              </>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default withRouter(SideBar);

// class SideBar extends Component {
//   state = {
//     show: false,
//   };

//   condition() {
//     // const Details = localStorage.getItem("userDetails");
//     //console.log(localStorage.getItem("userDetails"));
//     let details = localStorage.getItem("userDetails");
//     console.log(details);
//     details = JSON.parse(details);
//     console.log(details.data.authority);
//     // var user = JSON.parse(localStorage.getItem("userDetails"));
//     // console.log(user.firstname);
//   }

//   toggleMenuState(menuState) {
//     if (this.state[menuState]) {
//       this.setState({ [menuState]: false });
//     } else if (Object.keys(this.state).length === 0) {
//       this.setState({ [menuState]: true });
//     } else {
//       Object.keys(this.state).forEach((i) => {
//         this.setState({ [i]: false });
//       });
//       this.setState({ [menuState]: true });
//     }
//   }

//   componentDidUpdate(prevProps) {
//     if (this.props.location !== prevProps.location) {
//       this.onRouteChanged();
//     }
//   }

//   onRouteChanged() {
//     document.querySelector("#sidebar").classList.remove("active");
//     Object.keys(this.state).forEach((i) => {
//       this.setState({ [i]: false });
//     });
//   }

//   render() {
//     return (
//       <nav className="sidebar sidebar-offcanvas" id="sidebar">
//         <ul className="nav">
//           <li className="nav-item nav-profile">
//             <div className="nav-link">
//               <div>
//                 <img
//                   src="assets/images/DAP_logo.png"
//                   alt="pr0file"
//                   style={{ width: "120px", marginLeft: "21%" }}
//                 />
//                 <span className="online-status online"></span>
//               </div>
//               <div className="profile-name">
//                 {/* <p className="name">DAP</p> */}
//               </div>
//             </div>
//           </li>
//           <li
//             className={
//               this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
//             }
//             onClick={this.condition}
//           >
//             <Link className="nav-link" to="/dashboard">
//               <i className="icon-menu menu-icon"></i>
//               <span className="menu-title">
//                 <Trans>Dashboard</Trans>
//               </span>
//             </Link>
//           </li>

//           <li
//             className={
//               this.isPathActive("/customers") ? "nav-item active" : "nav-item"
//             }
//           >
//             <Link className="nav-link" to="/customers">
//               <i className="icon-user user-icon"></i>
//               <span className="menu-title" style={{ marginLeft: "20px" }}>
//                 Customers
//               </span>
//             </Link>
//           </li>

//           <li
//             className={
//               this.isPathActive("/devices") ? "nav-item active" : "nav-item"
//             }
//           >
//             <Link className="nav-link" to="/devices">
//               <i className="mdi mdi-lumx"></i>
//               <span className="menu-title" style={{ marginLeft: "20px" }}>
//                 <Trans>Devices</Trans>
//               </span>
//             </Link>
//           </li>

//           <li
//             className={
//               this.isPathActive("/rule-engine") ? "nav-item active" : "nav-item"
//             }
//           >
//             <Link className="nav-link" to="/rule-engine">
//               <i className="mdi mdi-engine"></i>
//               <span className="menu-title" style={{ marginLeft: "20px" }}>
//                 <Trans>Rule Engine</Trans>
//               </span>
//             </Link>
//           </li>

//           <li
//             className={
//               this.isPathActive("/profile") ? "nav-item active" : "nav-item"
//             }
//           >
//             <Link className="nav-link" to="/profile">
//               <i className="fa fa-user-md"></i>
//               <span className="menu-title" style={{ marginLeft: "20px" }}>
//                 <Trans>Profile</Trans>
//               </span>
//             </Link>
//           </li>
//         </ul>
//       </nav>
//     );
//   }

//   isPathActive(path) {
//     return this.props.location.pathname.startsWith(path);
//   }

//   componentDidMount() {
//     this.onRouteChanged();
//     // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
//     const body = document.querySelector("body");
//     document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
//       el.addEventListener("mouseover", function () {
//         if (body.classList.contains("sidebar-icon-only")) {
//           el.classList.add("hover-open");
//         }
//       });
//       el.addEventListener("mouseout", function () {
//         if (body.classList.contains("sidebar-icon-only")) {
//           el.classList.remove("hover-open");
//         }
//       });
//     });
//   }
// }

// export default withRouter(SideBar);
