import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { Trans } from "react-i18next";
import { Button } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../environment";

const Alerts = () => {
  const [notification, setNotification] = useState([]);

  //let alertArray = [];

  //   alertArray = JSON.parse(localStorage.getItem("alertArray"));
  //   console.log(alertArray);
  //setNotification({ notification: alertArray });

  useEffect(() => {
    setInterval(sayHi, 5000);
    getAlerts();
  }, []);

  const getAlerts = () => {
    axios
      .get(
        environment.apiUrl +
          "alarms?endTime=" +
          Date.now() +
          "&page=1&pageSize=10&startTime=" +
          //(Date.now() - 10 * 60 * 60 * 5000),
          1641016740000,
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        //setNotification(alertArray);
        console.log(res.data.data);
        setNotification(res?.data?.data);
      })
      .catch((err) => {});
  };

  const closeAll = () => {
    console.log("close all ");

    console.log(notification);
    if (notification.length > 0) {
      notification.forEach((i) => {
        axios
          .post(
            environment.apiUrl + "alarm/" + i?.id?.id + "/clear",
            {},
            {
              headers: {
                "X-Authorization": "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            //setNotification(alertArray);
            console.log("object");
          })
          .catch((err) => {});
      });
    }
  };
  const hideAlert = (id) => {
    console.log(id);

    axios
      .post(
        environment.apiUrl + "alarm/" + id,
        {},
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        //setNotification(alertArray);
        console.log(res.data);
        getAlerts();
      })
      .catch((err) => {});
  };

  const sayHi = () => {
    // console.log("getting alert array");
    // let alertArray = [];
    // if (localStorage.getItem("alertArray")) {
    //   alertArray = JSON.parse(localStorage.getItem("alertArray"));
    //   console.log(alertArray);
    //   setNotification(alertArray);
    // }
  };

  return (
    <>
      <div className='row'>
        <div className='col-3'></div>
        <div className='col-md-6 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body' style={{ paddingTop: "7%" }}>
              <h4 className='card-title'>
                <Trans>Alerts Hub</Trans>
              </h4>
              <p className='card-description'>
                <Trans>
                  Alerts are triggered when a specific <code>contaminant</code>{" "}
                  surpases a certain level.
                </Trans>
                <div className='btn' onClick={() => closeAll()}>
                  <span className='badge badge-pill badge-warning float-right'>
                    Close All
                  </span>
                </div>
              </p>

              {/* <Alert variant='warning'>CO2 level is moderately high!</Alert>
              <Alert variant='warning'>Device is currently offline!</Alert> */}
              {notification.map((i) => (
                <Alert key={i} variant='warning'>
                  <p className='preview-subject '>
                    {i.name}

                    {/* value increased to {i.value} at time{" "}
                    {new Date(i.ts).getHours() + ":"}
                    {new Date(i.ts).getMinutes() < 10
                      ? "0"
                      : "" + new Date(i.ts).getMinutes()}{" "}
                    {new Date(i.ts).getHours() >= 12 ? "PM" : "AM"} */}
                    <button
                      type='button'
                      className='close'
                      data-dismiss='alert'
                      aria-label='Close'
                      onClick={() => hideAlert(i.id.id)}
                    >
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </p>
                </Alert>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Alerts;
