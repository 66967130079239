import React from "react";
import { Line } from "react-chartjs-2";
import { interval } from "rxjs";
import { Dropdown } from "react-bootstrap";
import { Bar, Doughnut, Pie, Scatter } from "react-chartjs-2";

import axios from "axios";
import { useState } from "react";

// import LowerDashboard from "../Components/LowerDashboard";
import C3Chart from "react-c3js";
import "c3/c3.css";
import Loader from "../Components/Loader";
import Spinner from "../Components/Spinner";

import { environment } from "../../environment";
import "./dashboard.css";
import { logout } from "../services/logout";
import { getMessaging, getToken } from "firebase/messaging";
//import * as firebase from "firebase";
import firebase from "../firebase";
import { dashboardService } from "../services/userDashboardService";
import { deviceCoordinateService } from "../services/deviceCoordinateService";
import Select from "react-select";
import MapComponent from "./UserMap";

const TenantSingleDevice = ({ history, match, props }) => {
  const dap_deviceId = match.params.id;
  const dev_name = match.params.deviceName;
  dev_name.replace(/%20/g, " ");

  let salesOptions;
  let dap_alertAlarmN = [];
  const [loader, setLoader] = useState(true);
  const source = interval(10000);
  const [isMapToCalled, setIsMapToCalled] = useState(false);
  const [dap_city, setCity] = useState("");
  const [dap_currentDay, setCurrentDay] = useState("");
  const [dap_country, setCountry] = useState("");
  const [dap_region, setRegion] = useState("");
  const [dap_pressure, setPressure] = useState("");
  const [dap_humidity, setHumidity] = useState("");
  const [dap_temperature, setTemperature] = useState("");
  const [dap_windspeed, setWindSpeed] = useState("");
  const [dap_WeatherData, setWeatherData] = useState({
    dap_co: 0,
    dap_pm2_5: 0,
    dap_no2: 0,
    dap_pm10: 0,
    humidity: 0,
    condition: "",
    imageUrl: "",
  });

  const [dap_iaqColor, setIaqColorClassName] = useState("");
  const [dap_iaqColor_1, setIaq_ColorClassName_1] = useState("");
  const [dap_iaqname, setIaq_ColorIaqname] = useState("");
  const [dap_iaqxval, setIaq_ColorIaqXval] = useState("");

  const [latest_aqi, setLatestAqi] = useState(0);
  const [latest_pm2, setLAtestPm2] = useState(0);
  const [latest_co2, setLAtestCo2] = useState(0);
  const [latest_tvoc, setLAtestTvoc] = useState(0);
  const [latest_temp, setLatestTemp] = useState(0);
  const [lastUpdated, setLastUpdated] = useState("");
  const [bat, setBat] = useState("");
  const [mode, setMode] = useState("");

  const [latest_pm10, setLAtestPm10] = useState(0);
  const [latest_humidity, setLAtesthumidity] = useState(0);

  const [dap_userId, setEmail] = useState("");
  const [dap_password, setUserDetails] = useState("");
  const [data, setData] = useState(false);
  const [dap_pm2, setpm2] = useState([]);
  const [dap_co2, setCo2] = useState([]);
  const [dap_pm10, setPm10] = useState([]);
  const [dap_tvoc, setTvoc] = useState([]);
  const [dap_selectedDeviceName, setSelectedDeviceName] = useState("");
  const [dap_time_array, setTimeArray] = useState([]);

  const [dap_dashboadrdData, setDashboardData] = useState({
    co2: 0,
    pm10: 0,
    pm25: 0,
    temp: 0,
    tvoc: 0,
    rh: 0,
  });
  const [dap_aqi_1, setAqi] = useState(46);
  const [dap_aqi, setDapAqi] = useState("");
  const [dap_deviceList, setListDevice] = useState([]);
  const [deviceLoader, setDeviceLoader] = useState(false);

  let dap_airqIndex = {
    good: "Good < 50",
    satisfactory: "Satisfactory (51-100)",
    moderate: "Moderate (101-200)",
    poor: "Poor (201-300)",
    very_poor: "Very Poor (301-400)",
    severe: "Severe >400",
  };

  let userDetails = {};
  var firstName;

  let totalRevenueOptions = {
    responsive: true,
    maintainAspectRatio: true,
    ymin: 0,
    scales: {
      ymin: "auto [0]",
      xAxes: [
        {
          display: true,
          gridLines: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            display: true,
            maxTicksLimit: 4,
          },
        },
      ],
      yAxes: [
        {
          ymin: "auto [0]",
          display: true,
          gridLines: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            display: true,
            maxTicksLimit: 6,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    layout: {
      padding: {
        top: 5,
        bottom: 5,
      },
    },
  };

  React.useEffect(() => {
    console.log(dev_name);
    userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    console.log(userDetails);
    firstName = userDetails.data.firstName;
    setSelectedDeviceName(dev_name);
    console.log(firstName);
    setUserDetails(firstName);
    setLoader(false);
    //dap_weather();
    getCurrentCity();
    getDevicePerUser();
    if (userDetails.data.authority === "SYS_ADMIN") {
      let deviceCoordinates = localStorage.getItem("deviceCoordinates");
      deviceCoordinates = JSON.parse(deviceCoordinates);
      let device_id = match.params.id;
      console.log(device_id);
      deviceCoordinates.forEach((element) => {
        if (element.deviceId === device_id) {
          getAccessTokenForTenant(
            element.deviceId,
            element.tenantId,
            element.deviceOwnerId
          );
          // console.log(
          //   element.deviceId,
          //   element.tenantId,
          //   element.deviceOwnerId
          // );
        }
      });
    }
    if (userDetails.data.authority === "TENANT_ADMIN") {
      getDashboardData();
    }

    var hours = 1;
    const interval = setInterval(() => {
      logout();
    }, hours * 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const getCurrentCity = async () => {
    let lat;
    let lon;
    // This has been copied to your clipboard.This has been copied to your clipboard.https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=37.42159&longitude=-122.0837&localityLanguage=en
    await navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("position is :", position);
      lon = parseFloat(position.coords.longitude);
      lat = parseFloat(position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      axios
        .get(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=` +
            lat +
            `&longitude=` +
            lon +
            `&localityLanguage=en`
        )
        .then((res) => {
          console.log(res.data);
          dap_weather(res.data.localityInfo.administrative[4].name);
          setInterval(
            () => dap_weather(res.data.localityInfo.administrative[4].name),
            6000 * 50
          );
        })
        .catch(function (error) {
          //window.alert("get current city", error.message);
          console.log("error");
        });
    });
  };

  const dap_weather = async (location) => {
    await axios
      .get(
        environment.dap_weatherUrl +
          "current.json?key=fee0b93daf7744fb85871040211108&q=" +
          location +
          "&aqi=yes"
      )
      .then((res) => {
        setCity(res.data.location.name);
        setRegion(res.data.location.region);
        setCountry(res.data.location.country);
        setHumidity(res.data.current.humidity);
        setPressure(res.data.current.pressure_in);
        setTemperature(res.data.current.temp_c);
        setWindSpeed(res.data.current.wind_mph);
        //setCurrentDay(res.data.location.localtime);
        //setTimeout(console.log(dap_city), 3000);
        console.log(res.data);

        console.log(dap_city);
        console.log(res.data.location.name);
        setWeatherData({
          dap_co: res.data.current.air_quality.co,
          dap_no2: res.data.current.air_quality.no2,
          dap_pm10: res.data.current.air_quality.pm10,
          dap_pm2_5: res.data.current.air_quality.pm2_5,
          humidity: res.data.current.humidity,
          condition: res.data.current.condition.text,
          imageUrl: res.data.current.condition.icon,
        });
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        var days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        var d = new Date();
        var dayName = days[d.getDay()];
        var month = monthNames[d.getMonth()];
        console.log(dayName, month);
        setCurrentDay(
          dayName + ", " + d.getDate() + " " + month + " " + d.getFullYear()
        );
      })
      .catch((err) => console.log(err));
  };

  const getDevicePerUser = () => {
    let token = localStorage.getItem("token");
    setLoader(true);
    axios
      .get(
        environment.apiUrl +
          "customer/" +
          userDetails.data.customerId.id +
          "/devices?pageSize=100&page=0",
        { headers: { "X-Authorization": "Bearer " + token } }
      )
      .then((res) => {
        console.log(res.data.data[0].id.id);
        // setDeviceId(res.data.data[0].id.id);

        setSelectedDeviceName(res.data.data[0].name);
        dashboardService.sendMessage(res.data.data[0].id.id);
        getDashboardDataFirstTime();
        setListDevice(res.data.data);
        deviceCoordinateService();
        setLoader(false);
        // isMapToCalled = true;
        setTimeout(() => {
          setIsMapToCalled(true);
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err.Error);
      });
  };

  const getDashboardData = () => {
    setLoader(true);
    const subscribe = source.subscribe((val) => {
      let token;
      if (userDetails.data.authority === "TENANT_ADMIN") {
        token = localStorage.getItem("token");
      } else if (userDetails.data.authority === "SYS_ADMIN") {
        token = localStorage.getItem("tenant_token");
      }

      // https://dap.centralindia.cloudapp.azure.com/api/plugins/telemetry/DEVICE/0b199a90-f686-11eb-aeab-6338f3fa1c68/values/timeseries?keys=pm25,pm10,co2&startTs=1628200000000&endTs=1628426783314&interval=60000&limit=100
      axios
        .get(
          environment.apiUrl +
            "plugins/telemetry/DEVICE/" +
            dap_deviceId +
            "/values/timeseries?keys=iaq,pm25,pm10,co2,tvoc,temp,rh,bat,mode&startTs=" +
            (Date.now() - 10 * 60 * 60 * 5000) +
            "&endTs=" +
            Date.now() +
            "&interval=60000&limit=20",
          { headers: { "X-Authorization": "Bearer " + token } }
        )
        .then((res) => {
          let i = 0;
          console.log(res);
          console.log("**********************************");
          for (let i = 0; i < dap_alertAlarmN.length; i++) {
            console.log(dap_alertAlarmN[i].name);
          }
          // dap_alertAlarmN = []
          console.log(res.data.co2[0].value);
          console.log(dap_alertAlarmN);
          if (res.data.co2[0].value > 110) {
            console.log("push the data to localstorage");
            // dap_alertAlarmN.push({
            //   name: 'co2',
            //   value: res.data.co2[0].value,
            //   ts: res.data.co2[0].ts,
            // })
            for (let i = 0; i < dap_alertAlarmN.length; i++) {
              if (dap_alertAlarmN[i].name === "co2") {
                const removeIndex = dap_alertAlarmN.findIndex(
                  (item) => item.name === "co2"
                );
                dap_alertAlarmN.splice(removeIndex, 1);
                //dap_alertAlarmN = [];
                //console.log('push the data to localstorage')
                dap_alertAlarmN.push({
                  id: Math.random(),
                  name: "co2",
                  value: res.data.co2[0].value,
                  ts: res.data.co2[0].ts,
                });
              }
            }

            console.log(dap_alertAlarmN);
          }
          if (res.data.pm10[0].value > 70) {
            console.log("pm10 is highhhh");
            console.log("pm10", res.data.pm10[0].value);
            for (let i = 0; i < dap_alertAlarmN.length; i++) {
              if (dap_alertAlarmN[i].name === "pm10") {
                const removeIndex = dap_alertAlarmN.findIndex(
                  (item) => item.name === "pm10"
                );
                dap_alertAlarmN.splice(removeIndex, 1);
                dap_alertAlarmN.push({
                  id: Math.random(),
                  name: "pm10",
                  value: res.data.pm10[0].value,
                  ts: res.data.pm10[0].ts,
                });
              }
            }
          }

          if (res.data.pm25[0].value > 20) {
            console.log("pm25 is high");

            for (let i = 0; i < dap_alertAlarmN.length; i++) {
              if (dap_alertAlarmN[i].name === "pm25") {
                const removeIndex = dap_alertAlarmN.findIndex(
                  (item) => item.name === "pm25"
                );
                dap_alertAlarmN.splice(removeIndex, 1);
                dap_alertAlarmN.push({
                  id: Math.random(),
                  name: "pm25",
                  value: res.data.pm25[0].value,
                  ts: res.data.pm25[0].ts,
                });
              }
            }
          }
          if (parseInt(res.data.rh[0].value) > 675638) {
            console.log("co2 is high");
            dap_alertAlarmN.push({
              id: Math.random(),
              name: "humidity",
              value: res.data.rh[0].value,
              ts: res.data.rh[0].ts,
            });
          }
          if (parseInt(res.data.temp[0].value) > 675638) {
            console.log("co2 is high");
            dap_alertAlarmN.push({
              name: "temperature",
              value: res.data.temp[0].value,
              ts: res.data.temp[0].ts,
            });
          }
          if (parseInt(res.data.tvoc[0].value) > 675638) {
            console.log("co2 is high");
            dap_alertAlarmN.push({
              name: "tvoc",
              value: res.data.tvoc[0].value,
              ts: res.data.tvoc[0].ts,
            });
          }
          if (parseInt(res.data.iaq[0].value) > 675638) {
            console.log("co2 is high");
            dap_alertAlarmN.push({
              id: i + 1,
              name: "iaq",
              value: res.data.iaq[0].value,
              ts: res.data.iaq[0].ts,
            });
          }

          if (dap_alertAlarmN.length > 0) {
            localStorage.setItem("alertArray", JSON.stringify(dap_alertAlarmN));
          }
          // setpm2(res.data.pm25)
          let test = [];
          let pm2 = [];
          let co2 = [];
          let pm10 = [];
          let tvoc = [];
          let time = [];

          // res.data.pm25.forEach(element => {
          //  test.push(element.value)
          // });
          for (let i = 0; i < 20; i++) {
            pm2.push(res.data.pm25[i].value);
            co2.push(res.data.co2[i].value);
            pm10.push(res.data.pm10[i].value);
            tvoc.push(res.data.tvoc[i].value);
            //  pm2.push(res.data.pm25[i].value)
          }
          setLAtestPm2(parseFloat(res.data.pm25[0].value).toFixed(2));
          setLAtestPm10(parseFloat(res.data.pm10[0].value).toFixed(2));
          setLAtesthumidity(Number(res.data.rh[0].value).toFixed(2));
          setLatestAqi(res.data.iaq[0].value);
          setLAtestCo2(res.data.co2[0].value);
          setLAtestTvoc(parseFloat(res.data.tvoc[0].value).toFixed(1));
          setLatestTemp(Number(res.data.temp[0].value).toFixed(0));
          setLastUpdated(
            "Last Updated at " +
              new Date(res.data.iaq[0].ts).toLocaleTimeString()
          );
          setBat(res.data.bat[0].value);
          setMode(res.data.mode[0].value);
          if (res.data.iaq[0].value >= 401) {
            setIaqColorClassName("aqi-color-severe");
            setIaq_ColorClassName_1("aqi-color-severe-1");
            setIaq_ColorIaqname("Severe");
            setIaq_ColorIaqXval(13.2);
          } else if (
            res.data.iaq[0].value >= 301 &&
            res.data.iaq[0].value <= 400
          ) {
            setIaqColorClassName("aqi-color-very-poor");
            setIaq_ColorClassName_1("aqi-color-very-poor-1");
            setIaq_ColorIaqname("Very poor");
            setIaq_ColorIaqXval(11.2);
          } else if (
            res.data.iaq[0].value >= 201 &&
            res.data.iaq[0].value <= 300
          ) {
            setIaqColorClassName("aqi-color-poor");
            setIaq_ColorClassName_1("aqi-color-poor-1");
            setIaq_ColorIaqname("Poor");
            setIaq_ColorIaqXval(14.2);
          } else if (
            res.data.iaq[0].value >= 101 &&
            res.data.iaq[0].value <= 200
          ) {
            setIaqColorClassName("aqi-color-Moderate");
            setIaq_ColorClassName_1("aqi-color-Moderate-1");
            setIaq_ColorIaqname("Moderate");
            setIaq_ColorIaqXval(11.5);
          } else if (
            res.data.iaq[0].value >= 51 &&
            res.data.iaq[0].value <= 100
          ) {
            setIaqColorClassName("aqi-color-satisfactory");
            setIaq_ColorClassName_1("aqi-color-satisfactory-1");
            setIaq_ColorIaqname("Satisfactory");
            setIaq_ColorIaqXval(10);
          } else if (res.data.iaq[0].value > 0 && res.data.iaq[0].value <= 50) {
            setIaqColorClassName("aqi-color-good");
            setIaq_ColorClassName_1("aqi-color-good-1");
            setIaq_ColorIaqname("Good");
            setIaq_ColorIaqXval(14.2);
          }

          setpm2(pm2);
          setCo2(co2);
          setPm10(pm10);
          setTvoc(tvoc);

          for (let i = 0; i < 8; i++) {
            console.log(new Date(res.data.pm25[i].ts).toLocaleTimeString());

            time.push(
              new Date(res.data?.pm25[i].ts).getHours() +
                ":" +
                new Date(res.data?.pm25[i].ts).getMinutes()
            );
          }
          setTimeArray(time);
          //      setDashboardData({ co2:res.data.co2[0].value,
          //       pm10:res.data.pm10[0].value,
          //       pm25:res.data.pm25[0].value,
          //       temp:res.data.temp[0].value,
          //       tvoc:res.data.tvoc[0].value,
          //       rh:res.data.rh[0].value})
          //       console.log(res.data.co2[0].value)

          //       dap_aqi=(res.data.co2[0].value+res.data.pm25[0].value+res.data.temp[0].value+res.data.tvoc[0].value+res.data.temp[0].rh)/5
          // setAqi(dap_aqi)
          //       console.log(dap_aqi)
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err.Error);
        });
    });
  };

  const getDashboardDataFirstTime = () => {
    // console.log(dashboardService.getMessage());
    dashboardService.getMessage().subscribe((deviceId) => {
      console.log(deviceId);
      dap_deviceId = deviceId;
    });
    let token = localStorage.getItem("token");
    setLoader(true);
    // https://dap.centralindia.cloudapp.azure.com/api/plugins/telemetry/DEVICE/0b199a90-f686-11eb-aeab-6338f3fa1c68/values/timeseries?keys=pm25,pm10,co2&startTs=1628200000000&endTs=1628426783314&interval=60000&limit=100
    axios
      .get(
        environment.apiUrl +
          "plugins/telemetry/DEVICE/" +
          dap_deviceId +
          "/values/timeseries?keys=iaq,pm25,pm10,co2,tvoc,temp,rh,bat&startTs=" +
          (Date.now() - 10 * 60 * 60 * 5000) +
          "&endTs=" +
          Date.now() +
          "&interval=60000&limit=20",
        { headers: { "X-Authorization": "Bearer " + token } }
      )
      .then((res) => {
        let i = 0;
        console.log(res);
        // dap_alertAlarmN = []
        console.log(res.data.co2[0].value);
        console.log(dap_alertAlarmN);

        if (res.data.co2[0].value > 10) {
          console.log("push the data to localstorage co2");
          dap_alertAlarmN.push({
            id: Math.random(),
            name: "co2",
            value: res.data.co2[0].value,
            ts: res.data.co2[0].ts,
          });
        }
        if (res.data.pm10[0].value > 0) {
          console.log("push the pm10 to localstorage for the first time");
          dap_alertAlarmN.push({
            id: Math.random(),
            name: "pm10",
            value: res.data.pm10[0].value,
            ts: res.data.pm10[0].ts,
          });
        }

        if (res.data.pm25[0].value > 0) {
          console.log("pm25>17");

          dap_alertAlarmN.push({
            id: Math.random(),
            name: "pm25",
            value: res.data.pm25[0].value,
            ts: res.data.pm25[0].ts,
          });
        }
        if (parseInt(res.data.rh[0].value) > 675638) {
          console.log("co2 is highhhh");
          dap_alertAlarmN.push({
            name: "humidity",
            value: res.data.rh[0].value,
            ts: res.data.rh[0].ts,
          });
        }
        if (parseInt(res.data.temp[0].value) > 675638) {
          console.log("co2 is highhhh");
          dap_alertAlarmN.push({
            name: "temperature",
            value: res.data.temp[0].value,
            ts: res.data.temp[0].ts,
          });
        }
        if (parseInt(res.data.tvoc[0].value) > 675638) {
          console.log("co2 is highhhh");
          dap_alertAlarmN.push({
            name: "tvoc",
            value: res.data.tvoc[0].value,
            ts: res.data.tvoc[0].ts,
          });
        }
        if (parseInt(res.data.iaq[0].value) > 675638) {
          console.log("co2 is highhhh");
          dap_alertAlarmN.push({
            name: "iaq",
            value: res.data.iaq[0].value,
            ts: res.data.iaq[0].ts,
          });
        }

        if (dap_alertAlarmN.length > 0) {
          localStorage.setItem("alertArray", JSON.stringify(dap_alertAlarmN));
        }
        // setpm2(res.data.pm25)
        let test = [];
        let pm2 = [];
        let co2 = [];
        let pm10 = [];
        let tvoc = [];
        let time = [];

        // res.data.pm25.forEach(element => {
        //  test.push(element.value)
        // });
        for (let i = 0; i < 20; i++) {
          pm2.push(res.data.pm25[i].value);
          co2.push(res.data.co2[i].value);
          pm10.push(res.data.pm10[i].value);
          tvoc.push(res.data.tvoc[i].value);
          //  pm2.push(res.data.pm25[i].value)
        }
        setLAtestPm2(parseFloat(res.data.pm25[0].value).toFixed(2));
        console.log(res.data.co2[0].value);
        setLAtestCo2(res.data.co2[0].value);
        setLAtestTvoc(parseFloat(res.data.tvoc[0].value).toFixed(1));
        setLAtestPm10(parseFloat(res.data.pm10[0].value).toFixed(2));
        setLAtesthumidity(Number(res.data.rh[0].value).toFixed(2));
        setLatestAqi(res.data.iaq[0].value);
        setLatestTemp(Number(res.data.temp[0].value).toFixed(0));
        setLastUpdated(
          "Last Updated at " + new Date(res.data.iaq[0].ts).toLocaleTimeString()
        );

        setBat(res.data.bat[0].value);
        setMode(res.data.mode[0].value);

        for (let i = 0; i < 8; i++) {
          console.log(
            new Date(res.data?.pm25[i].ts).getHours() +
              ":" +
              new Date(res.data?.pm25[i].ts).getMinutes()
          );
          time.push(
            new Date(res.data?.pm25[i].ts).getHours() +
              ":" +
              new Date(res.data?.pm25[i].ts).getMinutes()
          );
        }

        setTimeArray(time);

        if (res.data.iaq[0].value > 401) {
          setIaqColorClassName("aqi-color-severe");
          setIaq_ColorClassName_1("aqi-color-severe-1");
          setIaq_ColorIaqname("Severe");
          setIaq_ColorIaqXval(13.2);
        } else if (
          res.data.iaq[0].value > 301 &&
          res.data.iaq[0].value <= 400
        ) {
          setIaqColorClassName("aqi-color-very-poor");
          setIaq_ColorClassName_1("aqi-color-very-poor-1");
          setIaq_ColorIaqname("Very poor");
          setIaq_ColorIaqXval(11.2);
        } else if (
          res.data.iaq[0].value > 201 &&
          res.data.iaq[0].value <= 300
        ) {
          setIaqColorClassName("aqi-color-poor");
          setIaq_ColorClassName_1("aqi-color-poor-1");
          setIaq_ColorIaqname("Poor");
          setIaq_ColorIaqXval(14.2);
        } else if (
          res.data.iaq[0].value > 101 &&
          res.data.iaq[0].value <= 200
        ) {
          setIaqColorClassName("aqi-color-Moderate");
          setIaq_ColorClassName_1("aqi-color-Moderate-1");
          setIaq_ColorIaqname("Moderate");
          setIaq_ColorIaqXval(11.5);
        } else if (res.data.iaq[0].value > 51 && res.data.iaq[0].value <= 100) {
          setIaqColorClassName("aqi-color-satisfactory");
          setIaq_ColorClassName_1("aqi-color-satisfactory-1");
          setIaq_ColorIaqname("Satisfactory");
          setIaq_ColorIaqXval(10);
        } else if (res.data.iaq[0].value > 0 && res.data.iaq[0].value <= 51) {
          setIaqColorClassName("aqi-color-good");
          setIaq_ColorClassName_1("aqi-color-good-1");
          setIaq_ColorIaqname("Good");
          setIaq_ColorIaqXval(14.2);
        }

        setpm2(pm2);
        setCo2(co2);
        setPm10(pm10);
        setTvoc(tvoc);
        getDashboardData();
        //      setDashboardData({ co2:res.data.co2[0].value,
        //       pm10:res.data.pm10[0].value,
        //       pm25:res.data.pm25[0].value,
        //       temp:res.data.temp[0].value,
        //       tvoc:res.data.tvoc[0].value,
        //       rh:res.data.rh[0].value})
        //       console.log(res.data.co2[0].value)

        //       dap_aqi=(res.data.co2[0].value+res.data.pm25[0].value+res.data.temp[0].value+res.data.tvoc[0].value+res.data.temp[0].rh)/5
        // setAqi(dap_aqi)
        //       console.log(dap_aqi)
        setLoader(false);
      })
      .catch((err) => {
        console.log(err.Error);
        setLoader(false);
      });
  };

  const pm25AreaData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 150);
    gradient.addColorStop(0, "rgba(	255, 185, 51, 1)");
    gradient.addColorStop(1, "rgba(235, 205, 143, 0.5)");

    return {
      labels: dap_time_array,
      datasets: [
        {
          fill: "start",
          label: "PM 2.5",
          backgroundColor: gradient, // Put the gradient here as a fill color
          borderColor: "rgba(118, 86, 23, 1)",
          borderWidth: 1,
          pointBackgroundColor: "#C38E29",
          pointBorderWidth: 1,
          pointColor: "#C38E29",
          pointBorderWidth: 1,
          pointStrokeColor: "#ff6c23",
          pointHighlightFill: "#C38E29",
          pointHighlightStroke: "#ff6c23",
          data: dap_pm2,
        },
      ],
    };
  };

  let pm25_options = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: "In Units (mg/m3)  ",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Time Period (in sec)",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 1.5,
      },
    },
  };

  let dap_graph_pm2 = {
    labels: dap_time_array,
    datasets: [
      {
        data: dap_pm2,
        pointBackgroundColor: "#5271ff",
        pointBorderWidth: 1,
        backgroundColor: ["#5271ff"],
        borderColor: ["#5271ff"],
        borderWidth: 2,
        fill: false,
      },
    ],
  };
  let dap_graph_co2 = {
    labels: dap_time_array,
    datasets: [
      {
        data: dap_co2,
        pointBackgroundColor: "#5271ff",
        pointBorderWidth: 1,
        backgroundColor: ["#5271ff"],
        borderColor: ["#5271ff"],
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const tvocBarData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 150);
    gradient.addColorStop(0, "rgba(112, 48, 160, 1)");
    gradient.addColorStop(1, "rgba(178, 103, 235, 0.5)");

    return {
      labels: dap_time_array,
      datasets: [
        {
          fill: "start",
          label: "TVOC",
          backgroundColor: gradient, // Put the gradient here as a fill color
          //borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
          // pointBackgroundColor: "#5271ff",
          // pointBorderWidth: 1,
          // pointColor: "#fff",
          // pointBorderWidth: 1,
          // pointStrokeColor: "#ff6c23",
          // pointHighlightFill: "#fff",
          // pointHighlightStroke: "#ff6c23",
          data: dap_tvoc,
        },
      ],
    };
  };

  let tvoc_bar_options = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },

          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: "In Units (ppb)",
          },
        },
      ],
      xAxes: [
        {
          // categoryPercentage: 1.0,
          // barPercentage: 1.0,
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Time Period (in sec)",
          },
        },
      ],
    },

    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const co2BarData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 150);
    gradient.addColorStop(0, "rgba(18, 128, 224, 1)");
    gradient.addColorStop(1, "rgba(116, 187, 247,0.5)");

    return {
      labels: dap_time_array,
      datasets: [
        {
          //fill: "start",
          label: "",
          backgroundColor: gradient, // Put the gradient here as a fill color
          //borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
          pointBackgroundColor: "#5271ff",
          pointBorderWidth: 1,
          pointColor: "#fff",
          pointBorderWidth: 1,
          pointStrokeColor: "#ff6c23",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#ff6c23",
          data: dap_co2,
        },
      ],
    };
  };

  let co2_bar_options = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },

          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: "In Units (ppm)",
          },
        },
      ],
      xAxes: [
        {
          // categoryPercentage: 1.0,
          // barPercentage: 1.0,
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Time Period (in sec)",
          },
        },
      ],
    },

    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const pm10AreaData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 150);

    gradient.addColorStop(0, "rgba(73, 210, 212, 1)");
    gradient.addColorStop(1, "rgba(164, 236, 237, 0.5)");

    return {
      labels: dap_time_array,
      datasets: [
        {
          fill: "start",
          label: "PM 10",
          backgroundColor: gradient, // Put the gradient here as a fill color
          borderColor: "rgba(51, 70, 158, 1)",
          borderWidth: 1,
          pointBackgroundColor: "#06b7bf",
          pointBorderWidth: 1,
          pointColor: "#fff",
          pointBorderWidth: 1,
          pointStrokeColor: "#ff6c23",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#ff6c23",
          data: dap_pm10,
        },
      ],
    };
  };

  let pm10_options = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: "In Units (mg/m3)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Time Period (in sec)",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 1.5,
      },
    },
  };

  let dap_graph_pm10 = {
    labels: dap_time_array,
    datasets: [
      {
        data: dap_pm10,
        pointBackgroundColor: "#5271ff",
        pointBorderWidth: 1,
        backgroundColor: ["#5271ff"],
        borderColor: ["#5271ff"],
        borderWidth: 2,
        fill: false,
      },
    ],
  };
  let monthlyAnalyticOptions = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
      position: "top",
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
            beginAtZero: true,
            fontColor: "rgba(0, 0, 0, 1)",
          },
          gridLines: {
            display: false,
            drawBorder: false,
            color: "transparent",
            zeroLineColor: "#eeeeee",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            display: true,
            color: "#eeeeee",
          },
          categoryPercentage: 0.5,
          ticks: {
            display: true,
            beginAtZero: true,
            // stepSize: 20,
            // max: 80,
            fontColor: "rgba(0, 0, 0, 1)",
          },
        },
      ],
    },
  };

  const dap_pm2Data = {
    columns: [
      ["Outdoor", dap_WeatherData.dap_pm2_5],
      ["Indoor", dap_pm2],
    ],
    type: "pie",
    onclick: function (d, i) {
      console.log("onclick", d, i);
    },
    onmouseover: function (d, i) {
      console.log("onmouseover", d, i);
    },
    onmouseout: function (d, i) {
      console.log("onmouseout", d, i);
    },
  };
  salesOptions = {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          barPercentage: 0.4,
          ticks: {
            display: false,
            beginAtZero: true,
            fontColor: "rgba(0, 0, 0, 1)",
          },
          gridLines: {
            display: false,
            drawBorder: false,
            color: "transparent",
            zeroLineColor: "#eeeeee",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            display: false,
            color: "#eeeeee",
          },
          categoryPercentage: 0.5,
          ticks: {
            display: false,
            beginAtZero: true,
            stepSize: 10,
            max: 80,
            fontColor: "rgba(0, 0, 0, 1)",
          },
        },
      ],
    },
  };

  const changeDevice = (device) => {
    console.log(device);
    setSelectedDeviceName(device.label);

    dashboardService.sendMessage(device.value);
    getDashboardData();
  };

  const getAccessTokenForTenant = async (deviceId, tenantId, ownerId) => {
    // http://core-ce.diaq.in/api/user/efac1770-4b5c-11ec-803e-45aeaa5f3440/token
    console.log(deviceId, tenantId);
    await axios
      .get(environment.apiUrl + "user/" + tenantId + "/token", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res?.data);
        localStorage.setItem("tenant_token", res?.data);
        getDashboardData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return loader ? (
    <Loader />
  ) : (
    <div>
      <div className='row'>
        <div
          className='col-md-6 col-lg-4 d-flex flex-column'
          style={{ marginBottom: "20px" }}
        >
          <div
            className='card'
            style={{
              boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
              borderRadius: "20px",
              height: "100%",
            }}
          >
            <div className='card-body'>
              {/* <h6 className="card-title">Air Quality Index</h6> */}
              <div className='mx-auto' style={{ width: "62%" }}>
                <div className='d-flex justify-content-between text-center'>
                  <h3
                    style={{
                      color: "#5864e8",
                      margin: "auto",
                      paddingBottom: "20px",
                      color: "#5271FF",
                    }}
                  >
                    {dap_selectedDeviceName}

                    {/* {deviceLoader ? <Loader /> : { dap_selectedDeviceName }} */}
                  </h3>
                </div>
                <div>
                  {/* <Doughnut
                      data={this.doughnutPieData}
                      options={this.doughnutPieOptions}
                      height={300}
                    /> */}

                  <div className='single-chart'>
                    <svg
                      viewBox='0 0 36 36'
                      //  className="circular-chart blue"

                      style={{
                        borderRadius: "50%",
                        boxShadow: " 0 0 10px 3px #ccc",
                      }}
                    >
                      <path
                        className={dap_iaqColor}
                        d='M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831'
                      />
                      <path
                        className='circle'
                        strokeDasharray='100, 100'
                        d='M18 2.0845
                       a 15.9155 15.9155 0 0 1 0 31.831
                           a 15.9155 15.9155 0 0 1 0 -31.831'
                      />
                      <text
                        x='18'
                        y='14'
                        className={`percentage ${dap_iaqColor_1}`}
                      >
                        {latest_aqi}
                      </text>
                      <text
                        x='5.8'
                        y='20.35'
                        className='percentage-1'
                        style={{ fontSize: "0.20em" }}
                      >
                        Indoor Air Quality
                      </text>
                      <text
                        x={dap_iaqxval}
                        y='24.35'
                        className={`percentage-1 ${dap_iaqColor_1}`}
                        // className="percentage-1"
                        style={{ fontSize: "0.20em" }}
                        // className={dap_iaqColor_1}
                      >
                        {dap_iaqname}
                      </text>
                    </svg>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <div
                    className='donut-legend mt-4 card'
                    style={{
                      backgroundColor: "#F8F8F8",
                      display: "block",
                      //flexDirection: 'column',
                      height: "100%",
                      width: "100%",
                      borderRadius: "6%",
                      padding: "2% 15% 5% 30%",
                      //marginBottom: '-32%',
                      justifyContent: "center",
                      textAlign: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <span>
                      <span
                        style={{
                          backgroundColor: "#00B050",
                          borderRadius: "0px",
                        }}
                      >
                        &nbsp;
                      </span>
                      {dap_airqIndex.good}
                    </span>

                    <span>
                      <span
                        style={{
                          backgroundColor: "#DFDF00",
                          borderRadius: "0px",
                        }}
                      >
                        &nbsp;
                      </span>
                      {dap_airqIndex.satisfactory}
                    </span>

                    <span>
                      <span
                        style={{
                          backgroundColor: "#FF9900",
                          borderRadius: "0px",
                        }}
                      >
                        &nbsp;
                      </span>
                      {dap_airqIndex.moderate}
                    </span>

                    <span>
                      <span
                        style={{
                          backgroundColor: "#FF0000",
                          borderRadius: "0px",
                        }}
                      >
                        &nbsp;
                      </span>
                      {dap_airqIndex.poor}
                    </span>

                    <span>
                      <span
                        style={{
                          backgroundColor: "#7030A0",
                          borderRadius: "0px",
                        }}
                      >
                        &nbsp;
                      </span>
                      {dap_airqIndex.very_poor}
                    </span>

                    <span>
                      <span
                        style={{
                          backgroundColor: "#990033",
                          borderRadius: "0px",
                        }}
                      >
                        &nbsp;
                      </span>
                      {dap_airqIndex.severe}
                    </span>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-12 md-4'>
                  <p
                    className='wrapper'
                    style={{
                      textAlign: "center",
                      marginTop: "14%",
                      fontSize: "small",
                    }}
                  >
                    {lastUpdated}
                  </p>
                  {bat ? (
                    <p
                      className='wrapper'
                      style={{
                        textAlign: "center",
                        fontSize: "small",
                      }}
                    >
                      <i className='mdi mdi-battery'></i>
                      {bat}%
                    </p>
                  ) : null}

                  {mode ? (
                    <p
                      className='wrapper'
                      style={{
                        textAlign: "center",
                        fontSize: "small",
                      }}
                    >
                      <i className='mdi mdi-wifi'></i>
                      {mode}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 d-flex flex-column'>
          <div className='row flex-grow'>
            <div className='col-12 col-md-4 col-lg-12 grid-margin stretch-card'>
              <div
                className='card'
                style={{
                  boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                  borderRadius: "20px",
                }}
              >
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      {" "}
                      <h6 className='card-title' style={{ color: "#5271FF" }}>
                        PM 2.5
                      </h6>
                    </div>
                    <div className='col-lg-8'>
                      {" "}
                      <p style={{ textAlign: "center", color: "#AFAFAF" }}>
                        Current Value: {latest_pm2} mg/m3
                      </p>
                    </div>
                  </div>

                  <div className='demo-chart' style={{ height: "100%" }}>
                    <Line
                      data={pm25AreaData}
                      options={pm25_options}
                      //height={150}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-4 col-lg-12 grid-margin stretch-card'>
              <div
                className='card'
                style={{
                  boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                  borderRadius: "20px",
                }}
              >
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      {" "}
                      <h6 className='card-title' style={{ color: "#5271FF" }}>
                        PM 10
                      </h6>
                    </div>
                    <div className='col-lg-8'>
                      {" "}
                      <p style={{ textAlign: "center", color: "#AFAFAF" }}>
                        Current Value: {latest_pm10} ppm
                      </p>
                    </div>
                  </div>
                  <div className='demo-chart' style={{ height: "100%" }}>
                    <Line
                      data={pm10AreaData}
                      options={pm10_options}
                      //height={150}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-lg-4 d-flex flex-column'>
          <div className='row flex-grow'>
            <div className='col-12 col-md-4 col-lg-12 grid-margin stretch-card'>
              <div
                className='card'
                style={{
                  boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                  borderRadius: "20px",
                }}
              >
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      {" "}
                      <h6 className='card-title' style={{ color: "#5271FF" }}>
                        CO2
                      </h6>
                    </div>
                    <div className='col-lg-8'>
                      {" "}
                      <p style={{ textAlign: "center", color: "#AFAFAF" }}>
                        Current Value: {latest_co2} mg/m3
                      </p>
                    </div>
                  </div>

                  <div className='demo-chart' style={{ height: "100%" }}>
                    <Bar
                      data={co2BarData}
                      options={co2_bar_options}
                      //height={150}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-4 col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title">PM 10</h6>
                  <div className="demo-chart" style={{ height: "145px" }}>
                    <Line
                      data={dap_graph_pm10}
                      options={monthlyAnalyticOptions}
                      height={100}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className='col-12 col-md-4 col-lg-12 grid-margin stretch-card'>
              <div
                className='card'
                style={{
                  boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                  borderRadius: "20px",
                }}
              >
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      {" "}
                      <h6 className='card-title' style={{ color: "#5271FF" }}>
                        TVOC
                      </h6>
                    </div>
                    <div className='col-lg-8'>
                      {" "}
                      <p style={{ textAlign: "center", color: "#AFAFAF" }}>
                        Current Value: {latest_tvoc} ppb
                      </p>
                    </div>
                  </div>

                  <div className='demo-chart' style={{ height: "100%" }}>
                    <Bar data={tvocBarData} options={tvoc_bar_options} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-md-6 grid-margin stretch-card">
        <div className="card card1 card-statistics">
          <div className="card-body pb-0">
            <h2 className="text-dark">{dap_city}</h2>
           
            <div
              className="d-flex align-items-center"
              style={{ marginTop: "10px" }}
            >
              <h6 className="text-success font-weight-semibold ml-2">
                {dap_region}
              </h6>
              <h6 className="text-success font-weight-semibold ml-2">
                {dap_country}
              </h6>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <small className="text-muted"> Temperature </small>
                <h1 className="py2">{dap_temperature}&deg; C</h1>
              </div>
              <i className="fa fa-cloud fa-lg"></i>
              <div className="col-lg-6">
                <p>Humidity </p>{" "}
                <span>
                  {" "}
                  <p> {dap_humidity} </p>
                </span>{" "}
                <p>Pressure </p>{" "}
                <span>
                  <p> {dap_pressure} </p>
                </span>{" "}
              </div>
            </div>

            <div className="row te" style={{ marginTop: "20px" }}>
              <div className="col-lg-2">
                <p>
                  CO <p>{dap_WeatherData.dap_co.toFixed(2)}</p>{" "}
                </p>
              </div>
              <div className="col-lg-2">
                <p>
                  {" "}
                  NO2 <p>{dap_WeatherData.dap_no2.toFixed(2)}</p>{" "}
                </p>
              </div>
              <div className="col-lg-2">
                <p>
                  {" "}
                  PM10 <p> {dap_WeatherData.dap_pm10.toFixed(2)}</p>{" "}
                </p>
              </div>
              <div className="col-lg-2">
                <p>
                  {" "}
                  PM10 <p>{dap_WeatherData.dap_pm10.toFixed(2)}</p>{" "}
                </p>
              </div>
              <div className="col-lg-2">
                <p>
                  {" "}
                  PM10 <p>{dap_WeatherData.dap_pm10.toFixed(2)}</p>{" "}
                </p>
              </div>
              <div className="col-lg-2">
                <p>
                  {" "}
                  PM10 <p>{dap_WeatherData.dap_pm10.toFixed(2)}</p>{" "}
                </p>
              </div>
            </div>
          </div>
          
          <div className="graph-wrapper"></div>
        </div>
        </div>
      
      
      
       */}

      <div className='row'>
        <div className='col-md-6 grid-margin stretch-card'>
          {/* comparision chart */}

          <div className='card' style={{ height: "104%" }}>
            <div class='row'>
              <div className='col-lg-4'>
                <div className='card-body'>
                  <h4
                    className='card-title'
                    style={{ color: "#5271FF", marginTop: "15%" }}
                  >
                    PM 10
                  </h4>
                  <C3Chart
                    data={{
                      columns: [
                        ["Outdoor", dap_WeatherData.dap_pm10],
                        ["Indoor", latest_pm10],
                      ],
                      type: "bar",
                      colors: {
                        Outdoor: "#00ADB5",
                        Indoor: "#AAD8D3",
                      },
                      // e69a8d
                    }}
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='card-body'>
                  <h4
                    className='card-title'
                    style={{ color: "#5271FF", marginTop: "15%" }}
                  >
                    PM 2.5
                  </h4>
                  <C3Chart
                    data={{
                      columns: [
                        ["Outdoor", dap_WeatherData.dap_pm2_5],
                        ["Indoor", latest_pm2],
                      ],
                      colors: {
                        Outdoor: "#00ADB5",
                        Indoor: "#AAD8D3",
                      },
                      type: "bar",
                    }}
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                {" "}
                <div className='card-body'>
                  <h4
                    className='card-title'
                    style={{ color: "#5271FF", marginTop: "15%" }}
                  >
                    Humidity
                  </h4>
                  <C3Chart
                    data={{
                      columns: [
                        ["Outdoor", dap_WeatherData.humidity],
                        ["Indoor", latest_humidity],
                      ],
                      colors: {
                        Outdoor: "#00ADB5",
                        Indoor: "#AAD8D3",
                      },
                      type: "bar",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='row'>
            <div className='col-md-4'>
              {/* temperature */}
              <div
                className='card'
                style={{
                  paddingTop: "20px",
                  boxShadow: "8px 5px 5px rgb(0 0 0 / 4%)",
                  border: "none",
                  background: "linear-gradient(to bottom, #5271FF, #293980)",
                  height: "100%",
                }}
              >
                {/* <div className="search">
                  <h1 className="city">{dap_city}</h1>
                </div>

                <p className="description">{dap_WeatherData.condition}</p>
                <p className="date">{dap_currentDay}</p>

                <div className="numbers">
                  <div className="temp">
                    <span> {dap_temperature} </span>
                    <sup className="unit">°C</sup>
                  </div>

                  <div className="icon">
                    <img src={dap_WeatherData.imageUrl} alt="" />
                  </div>

                  <div>
                    <table className="measurements">
                      <tr>
                        <td className="label">Pressure</td>
                        <td className="value"> {dap_pressure} </td>
                      </tr>
                      <tr>
                        <td className="label">Humidity</td>
                        <td className="value"> {dap_humidity} </td>
                      </tr>
                      <tr>
                        <td className="label">Wind speed</td>
                        <td className="value"> {dap_windspeed} meters / sec</td>
                      </tr>
                    </table>
                  </div>
                </div> */}

                {/* triaal */}
                <h1
                  className='card-title'
                  style={{
                    color: "white",
                    textAlign: "center",
                    marginTop: "15%",
                  }}
                >
                  TEMPERATURE
                </h1>
                <div className='card-body'>
                  <h4 className='city' style={{ textAlign: "center" }}>
                    {dap_city}
                  </h4>
                  <p style={{ color: "white", textAlign: "center" }}>
                    {dap_currentDay}
                  </p>
                  <hr></hr>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <h6
                        className='outdoor'
                        style={{ textAlign: "center", color: "white" }}
                      >
                        OUTDOOR
                      </h6>
                      <h4 className='city' style={{ textAlign: "center" }}>
                        {dap_temperature} <sup className='unit'>°C</sup>
                      </h4>
                      <p className='td-value'>HUMIDITY: {dap_humidity}</p>
                    </div>
                    <div className='col-lg-6'>
                      <h6
                        className='outdoor'
                        style={{ textAlign: "center", color: "white" }}
                      >
                        INDOOR
                      </h6>
                      <h4 className='city' style={{ textAlign: "center" }}>
                        {latest_temp} <sup className='unit'>°C</sup>
                      </h4>
                      <p className='td-value'>HUMIDITY: {latest_humidity}</p>
                    </div>
                  </div>
                  <hr></hr>
                  <div className='row'>
                    <div className='col-lg-12 mx-auto'>
                      <div className='icon'>
                        <img
                          src={dap_WeatherData.imageUrl}
                          alt=''
                          style={{ marginLeft: "30%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6 mx-auto '>
                      <p className='td-value'> PRESSURE: {dap_pressure}</p>
                    </div>
                    <div className='col-lg-6 mx-auto td-value'>
                      <p className='td-value'>WINDSPEED: {dap_windspeed}</p>
                    </div>
                  </div>
                </div>
                {/* trial ends */}
              </div>
            </div>
            <div className='col-md-8'>
              {/*map */}
              <div className='row'>
                <div class=' card col-md-12' style={{ padding: "10px" }}>
                  {/* {isMapToCalled ? (
                    <MapComponent> </MapComponent>
                  ) : (
                    <>
                      {" "}
                      <Spinner />{" "}
                    </>
                  )} */}
                  <MapComponent> </MapComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantSingleDevice;
