import React, { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../../environment.js";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const MySwal = withReactContent(Swal);

const CustomerUsers = ({ history, match, props }) => {
  const [dap_users, setUsers] = useState([]);

  const [loader, setLoader] = useState(true);

  const dap_id = match.params.id;
  useEffect(() => {
    axios
      .get(
        environment.apiUrl +
          `customer/` +
          dap_id +
          `/users?pageSize=100&page=0`,
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )

      .then((res) => {
        //const dap_users = res.data.data;
        setUsers(res.data.data.reverse());
        console.log(res.data.data);
        setLoader(false);
      })
      .catch(function (error) {
        MySwal.fire({
          title: <p>{error.message}</p>,
          type: "error",
        });
      });
  }, []);

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(function (result) {
      if (result.isConfirmed) {
        axios
          .delete(environment.apiUrl + "user/" + id, {
            headers: {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log(res.data.data);

            axios
              .delete(environment.dap_url + `users/deleteUserById/` + id)
              .then((res) => {
                MySwal.fire({
                  title: <p>User has been successfully deleted!</p>,
                  type: "success",
                });
                //history.push(`/users/${dap_id}`);
                axios
                  .get(
                    environment.apiUrl +
                      `customer/` +
                      dap_id +
                      `/users?pageSize=20&page=0`,
                    {
                      headers: {
                        "X-Authorization":
                          "Bearer " + localStorage.getItem("token"),
                      },
                    }
                  )

                  .then((res) => {
                    //const dap_users = res.data.data;
                    setUsers(res.data.data.reverse());
                    setLoader(false);
                  })
                  .catch(function (error) {
                    MySwal.fire({
                      title: <p>{error.response.data.message}</p>,
                      type: "error",
                    });
                  });
              })
              .catch((err) => {
                MySwal.fire({
                  title: (
                    <p>
                      {err?.response?.data?.message ||
                        "User couldn't be deleted"}
                    </p>
                  ),
                  type: "error",
                });
              });
          })
          .catch((err) => {
            // setShow(true);
            // setMessage("user couldn't be deleted ");
            MySwal.fire({
              title: (
                <p>
                  {err?.response?.data?.message || "User couldn't be deleted"}
                </p>
              ),
              type: "error",
            });
          });
      }
    });
  };

  const columns = [
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "additionalInfo.description",
      text: "Description",
      sort: true,
    },

    {
      dataField: "id.id",
      text: "Delete",
      sort: false,
      formatter: (dataField) => {
        return (
          <div>
            <Button
              variant='danger'
              className='btn-sm'
              onClick={() => deleteHandler(dataField)}
            >
              Delete <i className='fa fa-trash-o'></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return loader ? (
    <Loader />
  ) : (
    <div>
      <>
        <div className='row' style={{ marginTop: "2%", padding: "10px" }}>
          <div className='col-lg-6'>
            <Link to='/customers' className='btn btn-light my-3'>
              Go Back
            </Link>
          </div>
          <div className='col-lg-6'>
            <LinkContainer
              to={`/user/create/${dap_id}`}
              style={{
                // marginLeft: "80%",
                // verticalAlign: "bottom",
                paddingTop: "18px",
                float: "right",
              }}
            >
              <Button variant='light' className='btn btn-light my-3'>
                Add User
              </Button>
            </LinkContainer>
          </div>
        </div>

        <div>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <h4 className='card-title'>USERS</h4>
                  <div className='row'>
                    <div className='col-12'>
                      <ToolkitProvider
                        keyField='id'
                        bootstrap4
                        data={dap_users}
                        columns={columns}
                        search
                      >
                        {(props) => (
                          <div>
                            <div className='d-flex align-items-center'>
                              <p className='mb-2 mr-2'>Search in table:</p>
                              <SearchBar {...props.searchProps} />
                            </div>
                            <BootstrapTable
                              defaultSorted={defaultSorted}
                              pagination={paginationFactory()}
                              {...props.baseProps}
                              wrapperClasses='table-responsive'
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default CustomerUsers;
