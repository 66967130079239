import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { Trans } from "react-i18next";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Form } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../../environment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Toggle from "react-toggle";
import "./Shipment.css";

const MySwal = withReactContent(Swal);

const { SearchBar } = Search;

const ShipmentDashboard = ({ history, match, props }) => {
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [shipmentLogs, setShipmentLogs] = useState([]);
  const [devicedeials, setDeviceDetails] = useState([]);
  const [consignordetails, setConsignorDetails] = useState({});
  const [status, setStatus] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .put(
        environment.dap_url +
          "shipmentApi/updateShipmentStatus?_id=" +
          match.params.id,
        {
          status: status,
          statusBefore: shipmentDetails.status,
          notes: "abc",
        }
      )
      .then((res) => {
        MySwal.fire({
          title: <p>Status Changed Successfully</p>,
          type: "success",
        });
      })
      .catch((err) => {
        MySwal.fire({
          title: <p>Unable to change the status</p>,
          type: "error",
        });
      });
  };
  const ChangeStatus = (e) => {
    setStatus(e.value);
    // setStatus(status);
  };
  
  useEffect(() => {
    let id = match.params.id;
    getShipmentData(id);
  }, []);
  let Transition = [
    {
      status: "Initialized",
      statusID: 0,
    },
    {
      status: "In Transit",
      statusID: 1,
    },
    {
      status: "Received",
      statusID: 2,
    },
    {
      status: "Completed",
      statusID: 3,
    },
  ];
  const toggle = (e) => {
    const userdetails = JSON.parse(localStorage.getItem("userDetails"));
    console.log(userdetails.data.tenantId.id);
    axios
      .put(environment.dap_url + "shipmentApi/updateConsignmentDeviceStatus", {
        shipmentIdMongo: match.params.id,
        deviceIdMongo: e.target.value,
        tenantId: userdetails.data.tenantId.id,
      })
      .then((res) => {
        MySwal.fire({
          title: <p>Successfully Received the Device</p>,
          type: "success",
        });
      })
      .catch((err) => {
        MySwal.fire({
          title: <p>Failed to recieve the device</p>,
          type: "error",
        });
      });
  };
  const getShipmentData = (id) => {
    // let button = "";
    axios
      .get(environment.dap_url + "shipmentApi/getByIdWithLogs?_id=" + id)
      .then((res) => {
        console.log(res.data);
        setShipmentDetails(res.data.shipmentDetails[0]);
        setDeviceDetails(res.data.shipmentDetails[0].shipmentDevices);
        setShipmentLogs(res.data.shipmentLogs);
        setConsignorDetails(res.data.shipmentDetails[0].consignordetails[0]);
        // if (res.data.shipmentDetails[0].status == 1) {
        //   button = <span class="label label-info">In Transition</span>;
        // }
      })
      .catch((err) => {});
  };

  return (
    <>
      <div style={{ marginTop: "2%" }} className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h4>Shipment Details</h4>
                <ul>
                  <li>Country - {shipmentDetails.consignmentCountry}</li>
                  <li>State - {shipmentDetails.consignmentState}</li>
                  <li>City - {shipmentDetails.consignmentCity}</li>
                  <li>Phone - {shipmentDetails.phone}</li>
                  <li>
                    {(() => {
                      switch (shipmentDetails.status) {
                        case 0:
                          return "Current Status - Intialized";
                        case 1:
                          return "Current Status - In transit";
                        case 2:
                          return "Current Status - Received";
                        case 3:
                          return "Current Status - Completed";
                        default:
                          return "#FFFFFF";
                      }
                    })()}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h5>Consignor Details</h5>
                <ul>
                  <li>Name -{consignordetails.userFirstName}</li>
                  <li>Email - {consignordetails.email}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2%" }} className="col-12">
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h4> Devices</h4>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Label</th>
                        <th scope="col">Is Installed</th>
                        <th scope="col">Is Received</th>
                      </tr>
                    </thead>
                    {devicedeials.map((val, key) => {
                      return (
                        <tbody>
                          <tr key={key}>
                            <td>{val.deviceName}</td>
                            <td>{val.deviceLabel}</td>
                            <td>{val.isInstalled ? "Yes" : "NO"}</td>
                            <td>
                              <Toggle
                                id="cheese-status"
                                value={val._id}
                                // defaultChecked={this.state.cheeseIsReady}
                                onChange={(e) => toggle(e)}
                              />
                              <label htmlFor="cheese-status"></label>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-6">
                    <div class="status-col">
                      <Form class="hi" onSubmit={submitHandler}>
                        <Form.Group controlId="name">
                          <Form.Label>Statuss</Form.Label>
                          <Select
                            options={Transition.map((element) => ({
                              id: element.statusID,
                              value: element.statusID,
                              label: element.status,
                            }))}
                            onChange={(e) => ChangeStatus(e)}
                          />
                        </Form.Group>
                        <Button type="submit">Change</Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipmentDashboard;
