import React, { useState, useEffect } from "react";
import { environment } from "../../environment";
import axios from "axios";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const MySwal = withReactContent(Swal);

const Tenants = () => {
  const [tenants, setTenants] = useState([]);
  useEffect(() => {
    // axios
    //   .get(
    //     environment.apiUrl +
    //       "tenantInfos?pageSize=200&page=0&sortProperty=createdTime&sortOrder=DESC",
    //     {
    //       headers: {
    //         "X-Authorization": "Bearer " + localStorage.getItem("token"),
    //       },
    //     }
    //   )
    axios
      .get(environment.dap_url + "users/getAllTenant")
      .then((res) => {
        console.log("abc");
        console.log(res.data);
        setTenants(res.data);
        // setTenants(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const deleteHandler = (id) => {
    // alert(id);
    MySwal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(function (result) {
      if (result.isConfirmed) {
        axios
          .delete(environment.apiUrl + "tenant/" + id, {
            headers: {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log(res.data.data);
            MySwal.fire({
              title: <p>Tenant has been successfully deleted!</p>,
              type: "success",
            });
            axios
              .delete(environment.dap_url + "partnerApi/deleteById?_id=" + id)
              .then((res) => {
                // console.log(res.data.data);
                MySwal.fire({
                  title: <p>Partner has been successfully deleted!</p>,
                  type: "success",
                });
                //history.push("/customers");
              })
              .catch((err) => {
                MySwal.fire({
                  title: (
                    <p>
                      {err?.response?.data?.message ||
                        "Partner deletion failed"}
                    </p>
                  ),
                  type: "error",
                });
              });
            //history.push("/customers");
            axios
              .get(environment.dap_url + "users/getAllTenant")
              .then((res) => {
                console.log(res.data.data);
                setTenants(res.data.data);
              })
              .catch((err) => {});
          })
          .catch((err) => {
            MySwal.fire({
              title: (
                <p>
                  {err?.response?.data?.message || "Tenant deletion failed"}
                </p>
              ),
              type: "error",
            });
          });
      }
    });
  };

  const columns = [
    {
      dataField: "userFirstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "userLastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "userId",
      text: "Action",
      sort: false,
      formatter: (dataField) => {
        return (
          <div>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => deleteHandler(dataField)}
            >
              Delete <i className="fa fa-trash-o"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div className="row" style={{ marginTop: "72px" }}>
        <div className="col-lg-12">
          <LinkContainer
            to={`/tenant/create`}
            style={{
              float: "right",
              //paddingTop: "18px",
              marginBottom: "20px",
            }}
          >
            <Button variant="light" className="btn-lg">
              Add Tenant
            </Button>
          </LinkContainer>
        </div>
      </div>
      {/* <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Tenants</h4>

              <div className='table-responsive'>
                <table className='table table-striped table-bordered'>
                  <thead>
                    <tr>
                      <th>Created Time</th>
                      <th>Title</th>
                      <th>Tenant Profile</th>
                      <th>Email</th>
                      <th>Country</th>
                      <th>City</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenants.map((tenant) => (
                      <tr key={tenant.id.id}>
                        <td>
                          {new Date(tenant.createdTime).getDate() +
                            "/" +
                            (new Date(tenant.createdTime).getMonth() + 1) +
                            "/" +
                            new Date(tenant.createdTime).getFullYear() +
                            " " +
                            new Date(tenant.createdTime).getHours() +
                            ":" +
                            new Date(tenant.createdTime).getMinutes()}{" "}
                          {new Date(tenant.createdTime).getHours() >= 12
                            ? "PM"
                            : "AM"}
                        </td>
                        <td>{tenant.title}</td>
                        <td>{tenant.tenantProfileName}</td>
                        <td>{tenant.email}</td>
                        <td>{tenant.country}</td>
                        <td>{tenant.city}</td>
                        <td>
                          <Button
                            variant='danger'
                            className='btn-sm'
                            onClick={() => deleteHandler(tenant.id.id)}
                          >
                            delete <i className='fa fa-trash-o'></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">TENANTS</h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={tenants}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tenants;
