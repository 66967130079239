import axios from "axios";
import React, { useEffect } from "react";

const DeviceHistoricalData = () => {
  useEffect(() => {
    var d = new Date(1635111198699);
    console.log(d);
  }, []);

  const getData = () => {
    axios
      .get(
        "https://dap.centralindia.cloudapp.azure.com/api/plugins/telemetry/DEVICE/6eef6c20-1ada-11ec-aeab-6338f3fa1c68/values/timeseries?keys=pm25,iaq,pm10,co2,tvoc,temp,lat,lon,rh&startTs=1633854267&endTs= &interval=600000&limit=100000000",
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.json);
      });
  };

  return (
    <div>
      <form>
        <>
          {/* <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              name="country"
              autoComplete="off"`
              placeholder="Country"
            />
          </div> */}

          <div>
            <span>
              <button className="btn btn-primary" onClick={getData()}>
                Get device historical Data
              </button>
            </span>
          </div>
        </>
      </form>
    </div>
  );
};

export default DeviceHistoricalData;

//  https://dap.centralindia.cloudapp.azure.com/api/plugins/telemetry/DEVICE/6eef6c20-1ada-11ec-aeab-6338f3fa1c68/values/timeseries?keys=pm25,iaq,pm10,co2,tvoc,temp,lat,lon,rh&startTs=1635111198699&endTs=1635147198699&interval=60000&limit=1250

//b9dbc3e0-16b2-11ec-aeab-6338f3fa1c68 -zeus
//c676ffc0-16b2-11ec-aeab-6338f3fa1c68 - hera
//d1813b60-16b2-11ec-aeab-6338f3fa1c68 - poseidon
//78637b00-1f46-11ec-aeab-6338f3fa1c68 -athena
