import React from "react";
import { environment } from "../../environment.js";

export const CustomDropdown = (props) => (
  <div className="form-group">
    <strong>{props.userFirstName}</strong>
    <select
      className="form-control"
      name="{props.username}"
      onChange={props.onChange}
    >
      <option defaultValue>Select {props.name}</option>
      {props.options.map((item, index) => (
        <option key={index} value={item._id}>
          {item.userFirstName}
        </option>
      ))}
    </select>
  </div>
);
export default class CustomListDropDown extends React.Component {
  constructor() {
    super();
    this.state = {
      collection: [],
      value: "",
    };
  }
  componentDidMount() {
    fetch(environment.dap_url + "partnerApi/getAll")
      .then((response) => response.json())
      .then((res) => this.setState({ collection: res }));
  }
  onChange = (event) => {
    this.setState({ value: event.target.value });
  };
  render() {
    return (
      <div className="container mt-4">
        <CustomDropdown
          name={this.state.userFirstName}
          options={this.state.collection}
          onChange={this.onChange}
        />
      </div>
    );
  }
}
