import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import Backend from "i18next-xhr-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    // resources: {
    //   en: {
    //     translations: {
    //       app_title: "Title",
    //       language: "Language",
    //       welcome_message: "Welcome",
    //       days_since_release:
    //         "it's been {{number_of_days}} days since this video was released",
    //     },
    //   },
    //   ar: {
    //     translations: { "Weekly Sales": "المبيعات الأسبوعية" },
    //   },
    // },
    supportedLngs: ["en", "ar", "jp"],
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys
    detection: {
      order: ["localStorage", "cookie", "htmlTag", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
