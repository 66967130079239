import React from "react";

const PageNotFound = () => {
  return (
    <div id="wrapper">
      <div style={{ width: "100%", height: "100%" }}>
        <div className="row">
          <div className="col-4" id="info"></div>
          <div className="col-8">
            {" "}
            <img
              src="../../assets/images/pagenotfound.png"
              alt="notfound"
              style={{ height: "600px" }}
            />
            <h1
              style={{
                fontSize: "50PX",
                fontFamily: "cursive",
                color: "rgb(177 165 165)",
              }}
            >
              404 PAGE NOT FOUND!
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
