import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { InputGroup } from "reactstrap";
// import { login } from "../services/login.js";
import "./dashboard.css";
// import Device from "../../assets/images/Device.png";

import axios from "axios";
import { environment } from "../../environment.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValues] = useState([]);

  const [emailErrors, setEmailErrors] = useState({});
  const [passwordErrors, setPasswordErrors] = useState({});

  useEffect(() => {
    if (localStorage.getItem("token")) {
      localStorage.setItem("alertArray", [
        { name: "co2", value: "104", ts: 1631048167382 },
      ]);
      history.push("/dashboard");
    } else {
      history.push("/");
    }
  }, []);

  const formValidation = () => {
    const emailErrors = {};
    const passwordErrors = {};
    let isValid = true;

    if (!email) {
      emailErrors.noEmail = "Email address required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailErrors.invalidEmail = "Email address is invalid";
      isValid = false;
    }
    if (!password) {
      passwordErrors.noPassword = "Password is required";
      isValid = false;
    }
    setEmailErrors(emailErrors);

    setPasswordErrors(passwordErrors);
    //setPasswordErrors(passwordErrors);
    return isValid;
  };

  const handleEyeButton = async () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const submitHandler = (e) => {
    const isValid = formValidation();

    e.preventDefault();
    if (isValid) {
      setLoading(true);

      if (!localStorage.getItem("token")) {
        history.push("/");
      }
      try {
        // login(email, password, history);
        console.log(email, password);
        let val = {};
        if (email == "japdata@diaq.in" && password == "dap_jap#@$12rt45") {
          val["username"] = "tenant_bbsr@diaq.in";
          val["password"] = "Bbsr@#$qwe123!#";
          localStorage.setItem("isDapJapan", true);
        } else if (email == "bbsrdata@diaq.in" && password == "bbsrD#@34apyu") {
          val["username"] = "tenant_bbsr@diaq.in";
          val["password"] = "Bbsr@#$qwe123!#";
          localStorage.setItem("isDapJapan", true);
        } else if (
          email == "data_team@diaq.in" &&
          password == "dvyteSw#45bhn8$"
        ) {
          val["username"] = "tenant_bbsr@diaq.in";
          val["password"] = "Bbsr@#$qwe123!#";
          localStorage.setItem("isDapJapan", true);
        } else if (
          email == "daikinindia@gmail.com" &&
          password == "daikin!@#"
        ) {
          val["username"] = "daikinindia@gmail.com";
          val["password"] = "daikin!@#";
          localStorage.setItem("isDaikin", true);
        } 
        else if (email == "daikinindia123@gmail.com" && password == "daikinbbsr!@#") {
          val["username"] = "daikinindia@gmail.com";
          val["password"] = "daikin!@#";
          localStorage.setItem("isDaikin", true);
        }
        else if (email == "ncrdata@gmail.com" && password == "daikinncr!@#") {
          val["username"] = "ncr_tenant@diaq.in";
          val["password"] = "ncr_adap#$%231IJkl";
          localStorage.setItem("isDaikin", true);
        }
        
        else {
          val["username"] = email;
          val["password"] = password;
        }
        console.log(email, password);

        axios
          .post(environment.apiUrl + "auth/login", val)
          .then((res) => {
            try {
              if (!res.data.token) {
                console.log("auth failed");
              } else {
                let token = res.data.token;
                let refreshToken = res.data.refreshToken;

                axios
                  .get(environment.apiUrl + "auth/user", {
                    headers: { "X-Authorization": "Bearer " + res.data.token },
                  })
                  .then((res) => {
                    console.log(res);
                    //localStorage.setItem("userDetails", JSON.stringify(res));
                    let userDetails = JSON.stringify(res);
                    localStorage.setItem("alertArray", [
                      { name: "co2", value: "104", ts: 1631048167382 },
                    ]);
                    // history.push("/page/dashboardPage");

                    axios
                      .post(environment.dap_url + "webLogin/login", {
                        email: res.data.email,
                        userId: res.data.id.id,
                        userFirstName: res.data.firstName,
                        userEmail: res.data.email,
                        userLastName: res.data.lastName,
                        profileImageUrl: "",
                        webPushNotificationId: "",
                        userType: res.data.authority,
                        customerId: res.data.customerId.id,
                        tenantId: res.data.tenantId.id,
                      })
                      .then((res) => {
                        //setShowLoading(false)
                        // console.log(res.data);
                        if (res.data[0]?.userType == "PARTNER") {
                          localStorage.setItem("isPartnerLogin", true);
                          localStorage.setItem(
                            "partnerDetails",
                            JSON.stringify(res.data[0])
                          );
                        }
                        localStorage.setItem("token", token);
                        localStorage.setItem("refreshToken", refreshToken);
                        console.log(res);
                        localStorage.setItem("userDetails", userDetails);
                        history.push("/dashboard");
                      })
                      .catch((err) => {
                        console.log(err);
                        setLoading(false);

                        console.log(err);
                        MySwal.fire({
                          title: <p>Invalid username or password</p>,
                          type: "error",
                        });
                      });
                  });

                //console.log(res.data.token)
              }
            } catch (err) {
              setLoading(false);
              console.log(err.response);
              MySwal.fire({
                title: <p>Invalid username or password</p>,
                type: "error",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            MySwal.fire({
              title: <p>Authentication failed</p>,
              type: "error",
            });
          });
      } catch (err) {
        console.log(err);

        window.alert("error logging in");
        setLoading(false);
      }
    }
  };

  return (
    <>
      {/* <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo text-center">
                  <img src="../../assets/images/DAP_logo3.png" alt="logo" />
                </div>

                <Form onSubmit={submitHandler}>
                  <Form.Group controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <div className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    >
                      SIGN IN
                    </Button>
                  </div>
                </Form>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="mx-auto">
                    <Link className="nav-link" to="/forgotPassword">
                      <button className="btn">
                        <p style={{ color: "blue" }}>Forgot password?</p>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div>
        <div className="d-flex align-items-stretch auth auth-img-bg h-100">
          <div className="row flex-grow">
            <div className="col-lg-6 d-flex flex-row left-bg"></div>
            <div className="card col-lg-6 d-flex align-items-center justify-content-center right-bg">
              <div className="auth-form-transparent p-3 ">
                <div className="brand-logo">
                  <div className="brand-logo text-center">
                    <img src="../../assets/images/DAP_logo1.png" alt="logo" />
                  </div>
                </div>

                {/* <Form onSubmit={submitHandler}>
                  <Form.Group controlId="email" className="login-form-label">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="johndoe@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="password" className="login-form-label">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="********"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <div className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-block btn-lg font-weight-medium auth-form-btn login-btn"
                    >
                      <h5 style={{ color: "#5271FF", fontWeight: "bold" }}>
                        LOGIN
                      </h5>
                    </Button>
                  </div>
                </Form> */}

                <form className="pt-3" onSubmit={submitHandler}>
                  <div className="form-group">
                    <div className="input-group bg-transparent">
                      <div className="input-group-prepend bg-transparent">
                        <span className="input-group-text bg-transparent border-right-0">
                          <i
                            className="fa fa-envelope fa-lg"
                            style={{ color: "#ffff" }}
                          ></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent form-control form-control-lg border-left-0"
                        id="exampleInputEmail"
                        placeholder="johndoe@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ color: "#ffff" }}
                      />
                    </div>
                    <div>
                      {Object.keys(emailErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>{emailErrors[key]}</div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend bg-transparent">
                        <span className="input-group-text bg-transparent border-right-0">
                          <i
                            className="fa fa-lock fa-2x"
                            style={{ color: "#ffff" }}
                          ></i>
                        </span>
                      </div>
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control form-control-lg border-left-0"
                        id="exampleInputPassword"
                        placeholder="*******"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ color: "#ffff" }}
                      />
                      <Button
                        onClick={handleEyeButton}
                        style={{ backgroundColor: "rgb(104 68 236 / 35%)" }}
                      >
                        {passwordShown ? (
                          <i className="mdi mdi-eye-off"></i>
                        ) : (
                          <i className="mdi mdi-eye"></i>
                        )}
                      </Button>
                    </div>
                    <div>
                      {Object.keys(passwordErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>
                            {passwordErrors[key]}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-block btn-light btn-lg font-weight-medium auth-form-btn"
                      //onClick={fetchData}
                      // onClick={setLoading(true)}
                      disabled={loading}
                    >
                      LOGIN{" "}
                      {loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span></span>}
                      {!loading && <span></span>}
                    </Button>
                  </div>
                </form>

                {/* end */}

                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="mx-auto">
                    <Link className="nav-link" to="/forgotPassword">
                      <button className="btn">
                        <p style={{ color: "#ffff" }}>Forgot password?</p>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
