import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
//import { createDevice } from "../services/createDevice";
import Editable from "react-bootstrap-editable";
import axios from "axios";
import { environment } from "../../environment";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const AddPurifier = () => {
  const [dap_label, setLabel] = useState("");
  const [dap_description, setDescription] = useState("");
  const [dap_name, setName] = useState("");
  // const [dap_transport_type, setTrasportType] = useState("");
  // const [dap_profileName, setProfileName] = useState("");
  const [loading, setLoading] = useState(false);

  //const [loader, setLoader] = useState(true);

  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();

    // createDevice(
    //   dap_name,
    //   dap_label,
    //   dap_transport_type,
    //   dap_description,
    //   dap_profileName
    // );
    // let dap_objToPost = {
    //   name: dap_profileName,
    //   type: "DEFAULT",
    //   transportType: "DEFAULT",
    //   provisionType: "DISABLED",
    //   profileData: {
    //     configuration: {
    //       type: "DEFAULT",
    //     },
    //     transportConfiguration: {
    //       type: "DEFAULT",
    //     },
    //     alarms: null,
    //     provisionConfiguration: {
    //       type: "DISABLED",
    //     },
    //   },
    // };

    axios
      .post(environment.dap_url + "purifier/createPurifier", {
        // consignorId: userdetails.data.id.id,
        // consigneeId: e.target[0].value,
        // consignmentCity: dap_city,
        // consignmentState: dap_states,
        // consignmentCountry: dap_countries,
        // address:dap_address,
        // deviceList:"[1234,2345]",
        purifierModelNo: dap_label,
        purifierDescription: dap_description,
        purifierId: dap_name,
      })
      .then((res) => {
        MySwal.fire({
          title: <p>Purifier has been successfully created!</p>,
          type: "success",
        });
        setLabel("");
        setDescription("");
        setName("");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        MySwal.fire({
          title: <p>{err?.response?.data?.message}</p>,
          type: "error",
        });
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-3"></div>
        <Link to="/Purifiers" className="btn btn-light my-3">
          Go Back
        </Link>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <h1 className="text-center">ADD PURIFIER</h1>
              <div className="card-body">
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId="name">
                    <Form.Label>* Purifier Id</Form.Label>
                    <Form.Control
                      input
                      type="number"
                      placeholder="Enter Purifier Id"
                      value={dap_name}
                      onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="lastName">
                    <Form.Label>* Purifier Model Number</Form.Label>
                    <Form.Control
                      type="label"
                      placeholder="Enter Model Number"
                      value={dap_label}
                      onChange={(e) => setLabel(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  {/* <div className="form-group row">
                    <label className="col-6 col-lg-4 col-form-label">
                      Transport Type
                    </label>
                    <div className="col-6 col-lg-8 d-flex align-items-center">
                      <Editable
                        initialValue="default"
                        isValueClickable
                        mode="inline"
                        type="select"
                        options={["default", "MQTT"]}
                        value={dap_transport_type}
                        onChange={(e) => setTrasportType(e.target.value)}
                      />
                    </div>
                  </div> */}

                  {/* <div className="col-md-6">
                    <Form.Group controlId="profile name">
                      <Form.Label>* Device Profile</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="Device Profile Name"
                        value={dap_profileName}
                        onChange={(e) => setProfileName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </div> */}

                  <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="description"
                      placeholder="Add Description"
                      value={dap_description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <div className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      disabled={loading}
                    >
                      ADD PURIFIER
                      {loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span></span>}
                      {!loading && <span></span>}
                    </Button>
                  </div>
                </Form>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPurifier;
