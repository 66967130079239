import cogoToast from "cogo-toast";
import Alert from "react-bootstrap/Alert";
import axios from "axios";

import { environment } from "../../environment.js";

import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Line, Bar, Doughnut, Pie, Scatter } from "react-chartjs-2";
import C3Chart from "react-c3js";
import { MultiSelect } from "react-multi-select-component";
import Loader from "../Components/Loader.js";
import "./HistoricalData.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
//import { ObjectsToCsv } from "objects-to-csv";
import CsvDownload from "react-json-to-csv";

import "react-datepicker/dist/react-datepicker.css";
import { City } from "country-state-city";

const MySwal = withReactContent(Swal);
const HistoricalDataJapan = () => {
  const [dap_deviceList, setDapDevices] = useState([]);
  const [loader, setLoader] = useState(false);
  const [dap_deviceNames, setDeviceNames] = useState([]);
  const [dap_selectedDevice, setSelectedDeviceName] = useState("");
  const [startTs1, setStartTs1] = useState(new Date(Date.now() - 86400000));
  const [endTs1, setEndTs1] = useState(new Date(Date.now() - 8640000));
  const [setloading, setSearchLoading] = useState(false);
  const [locationType, setLocationType] = useState("");
  const [segment, setSegment] = useState("");
  const [customer, setCustomer] = useState([]);
  const [params, setQueryParams] = useState("");
  const [industry, setIndustry] = useState("");
  const [tenant, setTenant] = useState("");
  const [place, setPlace] = useState("");

  // const changeCustomer = (element) => {

  //   axios
  //   .get(environment.dap_url + `dapDevice/getByInstalledCity?deviceInstalledCity=`+segment+`&tenantId=`+element.value)
  //   .then((res) => {
  //    console.log(res);
  //    setDapDevices(res.data);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     MySwal.fire({
  //       title: <p>{err?.response?.data?.message}</p>,
  //       type: "error",
  //     });
  //   });

  // };
  let location_array = [];
  location_array = [
    {
      locationName: "Healthcare",
      locationId: 1,
    },
    {
      locationName: "Education",
      locationId: 2,
    },
    {
      locationName: "Manufacturing",
      locationId: 3,
    },
    {
      locationName: "Hospitality",
      locationId: 4,
    },
    {
      locationName: "Corporate",
      locationId: 5,
    },

    {
      locationName: "None",
      locationId: 10,
    },
  ];
  let places = [];
  places = [
    {
      placeName: "Bhubaneswar",
      placeId: 1,
    },
    {
      placeName: "cuttack",
      placeId: 2,
    },
    {
      placeName: "Delhi",
      placeId: 3,
    },
    {
      placeName: "Japan",
      placeId: 4,
    },
  ];
  useEffect(() => {
    //setLoader(true);
    // console.log('token1');
    // console.log(localStorage.getItem("token"));
    axios
      .get(environment.dap_url + `externalApi/getAllDeviceWithTenantId`, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })

      .then((res) => {
        // const devices = res.data.data;
        setDapDevices(res.data);
        // console.log(dap_deviceList);
        // devices=
        // setDeviceNames(res.data.data.name);
        //setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .post(environment.dap_url + "users/getUsersByfilters", {
        filters: {
          userType: "TENANT_ADMIN",
        },
      })
      .then((res) => {
        console.log(res);
        setCustomer(res.data);
        console.log("abcd");
        console.log(customer);
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          title: <p>{err?.response?.data?.message}</p>,
          type: "error",
        });
      });
  }, []);
  // let place = "";
  // let industry = "";
  // let tenant = "";
  let devices = [];
  const setParameter = (e) => {
    // alert(e);
    if (e.from == "place") {
      setPlace(e.value);
    } else if (e.from == "industry") {
      setIndustry(e.value);
    } else if (e.from == "customer") {
      setTenant(e.value);
    }

    // let query_params = "";

    // if (place != "") query_params += "&deviceInstalledCity=" + place;
    // if (industry != "") query_params += "&deviceInstalledPlace=" + industry;
    // if (tenant != "") query_params += "&tenantId=" + tenant;

    // query_params = query_params.substring(1);
    // console.log(query_params);
    // axios
    //   .get(environment.dap_url + "dapDevice/getByInstalledCity?" + query_params)
    //   .then((res) => {
    //     // console.log('dap_device_list',dap_deviceList)
    //     console.log(res.data);
    //     // setDapDevices([]);
    //     setDapDevices(res.data);
    //     console.log(dap_deviceList);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     MySwal.fire({
    //       title: <p>{err?.response?.data?.message}</p>,
    //       type: "error",
    //     });
    //   });
    // setQueryParams(query_params);
  };
  //   useEffect(() => {
  //   alert(params);
  //   axios
  //   .get(environment.dap_url + "dapDevice/getByInstalledCity?" + params)
  //   .then((res) => {
  //     // console.log('dap_device_list',dap_deviceList)
  //     //console.log(res.data);
  //     // setDapDevices([]);
  //     setDapDevices(res.data);
  //     //console.log(dap_deviceList);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     MySwal.fire({
  //       title: <p>{err?.response?.data?.message}</p>,
  //       type: "error",
  //     });
  //   });
  // }, [params]);

  useEffect(() => {
    console.log(place);
    console.log(industry);
    console.log(tenant);
    let query_params = "";
    if (place != "") query_params += "&deviceInstalledCity=" + place;
    if (industry != "") query_params += "&deviceInstalledPlace=" + industry;
    if (tenant != "") query_params += "&tenantId=" + tenant;
    query_params = query_params.substring(1);
    console.log(query_params);
    axios
      .get(environment.dap_url + "dapDevice/getByInstalledCity?" + query_params)
      .then((res) => {
        // console.log('dap_device_list',dap_deviceList)
        // console.log(res.data);
        // setDapDevices([]);
        setDapDevices(res.data);
        console.log(dap_deviceList);
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          title: <p>{err?.response?.data?.message}</p>,
          type: "error",
        });
      });
  }, [place, industry, tenant]);
  const changeDevice = (device) => {
    // console.log(device);
    setSelectedDeviceName(device);
  };

  const searchData = () => {
    if (dap_selectedDevice) {
      // console.log(dap_selectedDevice);
      // const id = dap_selectedDevice.value;
      // let tenant_id = 0;
      // let device_id = 0;
      // dap_deviceList.forEach((element) => {
      //   if (element._id == id) {
      //     device_id = element.deviceId;
      //     tenant_id = element.TenantDetails[0].userId;
      //   }
      // });
      let end_date = endTs1.getTime() + 60 * 60 * 24 * 1000;
      window.location.assign(
        environment.dap_url +
          "reportsModule/getHistoricalDataExcel?deviceId=" +
          dap_selectedDevice.device_Id +
          "&tenantId=" +
          dap_selectedDevice.tenant_Id +
          "&startDate=" +
          startTs1.getTime() +
          "&endDate=" +
          end_date +
          "&deviceName=" +
          dap_selectedDevice.label
      );
      //   axios
      //     .get(environment.apiUrl + "reportsModule/getHistoricalDataExcel?deviceId="+device_id+"&tenantId="+tenant_id +"&startDate="+startTs1.getTime()+"&endDate="+endTs1.getTime()+"&deviceName="+dap_selectedDevice.label)
      //     .then((res) => {
      //       // const devices = res.data.data;
      //       setLoader(false);
      //       console.log(res);
      //       console.log('Download Complete');
      //       //setLoader(false);
      //     })
      //     .catch(function (error) {
      //       console.log(error);
      //     });
    }

    //setLoader(false);
    // console.log(device);
    // setSelectedDeviceName(device.label);
    // dashboardService.sendMessage(device.value);
    // getDashboardData();
  };

  // const setLocationHandler = (e) => {
  //   axios
  //   .post(environment.dap_url + "deviceApi/getDeviceByPlaces", {
  //     deviceInstalledPlace:e.label
  //   })
  //   .then((res) => {
  //    console.log(res);
  //    setDapDevices(res.data);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     MySwal.fire({
  //       title: <p>{err?.response?.data?.message}</p>,
  //       type: "error",
  //     });
  //   });
  //   axios
  //   .get(environment.dap_url + `dapDevice/getByInstalledCity?deviceInstalledCity=`+segment+`&deviceInstalledPlace=`+e.label)
  //   .then((res) => {
  //    console.log(res);
  //    setDapDevices(res.data);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     MySwal.fire({
  //       title: <p>{err?.response?.data?.message}</p>,
  //       type: "error",
  //     });
  //   });
  // };

  // const setPlaceHandler = (e) => {
  //   setSegment(e.label);
  //   axios
  //   .get(environment.dap_url + `dapDevice/getByInstalledCity?deviceInstalledCity=`+e.label)
  //   .then((res) => {
  //    console.log(res);
  //    setDapDevices(res.data);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     MySwal.fire({
  //       title: <p>{err?.response?.data?.message}</p>,
  //       type: "error",
  //     });
  //   });

  // };

  return (
    <>
      <div className="row">
        <div className="col-md-8 grid-margin stretch-card">
          <div className="card" style={{ paddingTop: "5%" }}>
            <div className="card-body">
              <h4 className="card-title">View Historical Data</h4>
              <p className="card-description"></p>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId="name">
                    <Form.Label>Location</Form.Label>
                    <Select
                      options={places.map((element) => ({
                        id: element.placeId,
                        value: element.placeName,
                        label: element.placeName,
                        from: "place",
                      }))}
                      onChange={(e) => setParameter(e)}
                      // defaultValue={dap_deviceList[0].name}
                      defaultValue="hi there"
                      placeholder={
                        locationType ? locationType : "Choose Location"
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="name">
                    <Form.Label>Industry</Form.Label>
                    <Select
                      options={location_array.map((element) => ({
                        id: element.locationId,
                        value: element.locationName,
                        label: element.locationName,
                        from: "industry",
                      }))}
                      onChange={(e) => setParameter(e)}
                      // defaultValue={dap_deviceList[0].name}
                      defaultValue="hi there"
                      placeholder={
                        locationType ? locationType : "Choose Industry "
                      }
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Label>Tenants</Form.Label>
                  <Select
                    options={customer.map((element) => ({
                      value: element.tenantId,
                      label: element.userFirstName,
                      from: "customer",
                    }))}
                    onChange={(element) => setParameter(element)}
                    // defaultValue={dap_deviceList[0].name}
                    defaultValue="hi there"
                    placeholder=" Choose Tenant"
                    //onchange={(e) => handleChange(e)}
                  />
                </div>
                <div className="col-md-6">
                  <Form.Label>Devices </Form.Label>
                  <Select
                    options={dap_deviceList.map((listDevices) => ({
                      value: listDevices._id,
                      label: listDevices.deviceName,
                      tenant_Id: listDevices.TenantDetails[0].userId,
                      device_Id: listDevices.deviceId,
                    }))}
                    onChange={(e) => changeDevice(e)}
                    // defaultValue={dap_deviceList[0].name}
                    defaultValue="hi there"
                    placeholder=" Choose Device"
                    //onchange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="row" style={{ paddingTop: "15px" }}>
                <div className="col-md-3">
                  <h5> From Date</h5>
                  {/* <input
                  type="date"
                  onChange={(e) => changeFromDate(setStartTs(e.target.value))}
                /> */}
                  <DatePicker
                    selected={startTs1}
                    onChange={(startTs1) => setStartTs1(startTs1)}
                    //minDate={minStartDate}
                    //maxDate={startTs1}
                    className=" css-yk16xz-control react-datepicker__input-container"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <div className="col-md-3">
                  <h5> To Date</h5>
                  {/* <input
                  type="date"
                  onChange={(e) => changeToDate(setEndTs(e.target.value))}
                /> */}
                  <DatePicker
                    selected={endTs1}
                    onChange={(endTs1) => setEndTs1(endTs1)}
                    //maxDate={endTs1}
                    className="css-yk16xz-control  react-datepicker__input-container"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <Button
                    onClick={searchData}
                    style={{ marginTop: "5%" }}
                    disabled={setloading}
                  >
                    {setloading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {setloading && <span>Downloading</span>}
                    {!setloading && <span>Download</span>}
                  </Button>
                </div>
              </div>
            </div>

            {/* <div>
                {showDownload ? (
                  <a
                    href={`data:text/csv;charset=utf-8,${escape(csvData)}`}
                    download='iaqdata.csv'
                  >
                    download
                  </a>
                ) : (
                  <></>
                )}
              </div> */}
          </div>
        </div>
        <div className="col-md-4 grid-margin stretch-card">
          <div className="card intro-card">
            <h4 className="instruction">Instructions</h4>
            <div class="instruction-data">
              <ul>
                <li>
                  Please select a location to view data for a particular
                  location
                </li>
                <li>
                  Please select an Industry to view data for a particular
                  industry
                </li>
                <li>
                  Please select a Tenant to view data for a particular Tenant
                </li>
                <li>
                  {" "}
                  Please select a Device to Download data for a particular
                  device
                </li>
                <li>
                  {" "}
                  You can also use a combination of the first three filters,
                  though selecting a device is mandatory
                </li>
                <li>You can filter the data basis a date range</li>
                <li>
                  The combination/individual Of the 3
                  filters(Location,Tenant,Industry) will Reflect On the Devices
                  Dropdown
                </li>
                <li>
                  For best results please limit your date range to 30 days
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoricalDataJapan;
