import React, { Component, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Spinner from "../app/shared/Spinner";
import AddCustomerUser from "./Screens/AddCustomerUser";
import AddDevice from "./Screens/AddDevice";
import AddUser from "./Screens/AddUser";
import ChangePassword from "./Screens/ChangePassword";
import CreateCustomer from "./Screens/CreateCustomer";
import CustomerEditScreen from "./Screens/CustomerEditScreen";
import Customers from "./Screens/Customers";
import CustomerUsers from "./Screens/CustomerUsers";
import Device from "./Screens/Device";
import EditUserProfile from "./Screens/EditUserProfile";
import ForgotPassword from "./Screens/ForgotPassword";
import TenantDashboard from "./Screens/TenantDashboard";
import UserProfile from "./Screens/UserProfile";
import Users from "./Screens/Users";
import TenantSingleDevice from "./Screens/TenantSingleDevice";
import Alerts from "./Screens/Alerts";
import Messages from "./Screens/Messages";
import ListPurifier from "./Screens/ListPurifier";
import AddPurifier from "./Screens/AddPurifier";
import ListShipments from "./Screens/ListShipment";
import AddShipment from "./Screens/AddShipment";
// const Dashboard = lazy(() => import("./Screens/Dashboard"));
// const Login = lazy(() => import("./Screens/Login"));
import Dashboard from "./Screens/Dashboard";
import Login from "./Screens/Login";

import DeviceHistoricalData from "./Components/DeviceHistoricalData";
import MasterDashboard from "./Screens/MasterDashboard";
import HistoricalData from "./Screens/HistoricalData";
import Tenants from "./Screens/Tenants";
import PageNotFound from "./Screens/PageNotFound";
import AddTenant from "./Screens/AddTenant";
import DeviceForAdmin from "./Screens/DeviceForAdmin";
import CustomersForAdmin from "./Screens/CustomersForAdmin";
import DeviceHealthStatus from "./Screens/DeviceHealthStatus";
import AddDeviceSysAdmin from "./Screens/AddDeviceSysAdmin";
import ListTenantDevices from "./Screens/ListTenantDevices";
import CreatePassword from "./Screens/CreatePassword";
import ResetPassword from "./Screens/ResetPassword";
import ConnectDevice from "./Screens/ConnectDevice";
import CreateQuestions from "./Screens/CreateQuestions";
import ViewUserResponse from "./Screens/ViewUserResponse";
import FillUserResponse from "./Screens/FillUserResponse";
import UserFeedback from "./Screens/UserFeedback";
import UpdateDeviceSysAdmin from "./Screens/UpdateDeviceSysAdmin";
import UserQuestionnaire from "./Screens/UserQuestionnaire";
import HistoricalDataJapan from "./Screens/HistoricalDataJapan";
import Partners from "./Screens/Partners";
import AddPartners from "./Screens/AddPartners";
import PartnerDashboard from "./Screens/PartnerScreens/PartnerDashboard";
import ShipmentDashboard from "./Screens/PartnerScreens/ShipmentDetailsPage";
import ManageCustomers from "./Screens/PartnerScreens/manageCustomer";
import ManageCustomerUsers from "./Screens/PartnerScreens/customUsers";
import PartnerCreateCustomer from "./Screens/PartnerScreens/addCustomerUser";
import AgentDashboard from "./Screens/PartnerScreens/AgentDashboard";
import ListPartnerDevices from "./Screens/PartnerScreens/PartnerListDevices";

const AppRoutes = () => {
  let details = localStorage.getItem("userDetails");
  details = JSON.parse(details);
  let userPermission = localStorage.getItem("userPermission");
  let isPartnerLogin = localStorage.getItem("isPartnerLogin");
  //  userPermission = JSON.parse(userPermission);
  //  const n = userPermission.length;

  return (
    <Suspense fallback={<Spinner />}>
      {!localStorage.getItem("token") ? (
        <Switch>
          <Route path="/" component={Login} exact />
          <Route path="/forgotPassword" component={ForgotPassword} exact />
          <Route path="/createPassword" component={CreatePassword} exact />
          <Route path="/resetPassword" component={ResetPassword} exact />
          <Route component={PageNotFound} />
        </Switch>
      ) : (
        <>
          {localStorage.getItem("isPartnerLogin") ? (
            <>
              <Route path="/dashboard" component={PartnerDashboard} exact />
              <Route
                path="/shipmentDetails/:id"
                component={ShipmentDashboard}
                exact
              />
              <Route path="/manageCustomer" component={ManageCustomers} exact />
              <Route path="/users/:id" component={ManageCustomerUsers} />
              <Route path="/user/create/:id" component={AddCustomerUser} />
              <Route
                path="/customer/create"
                component={PartnerCreateCustomer}
              />
              <Route path="/new-dashboard" component={AgentDashboard} exact />
              <Route path="/partner-devices" component={ListPartnerDevices} />
            </>
          ) : (
            <>
              <>
                {details.data.authority === "TENANT_ADMIN" ? (
                  <Switch>
                    <Route
                      exact
                      path="/dashboard"
                      component={TenantDashboard}
                    />
                    <Route
                      path="/customers"
                      component={Customers}
                      exact
                      protect
                    />
                    <Route
                      path="/customer/:id/edit"
                      component={CustomerEditScreen}
                    />
                    <Route path="/customer/create" component={CreateCustomer} />
                    <Route path="/users/:id" component={Users} />
                    <Route path="/user/create/:id" component={AddUser} />
                    <Route path="/device/create" component={AddDevice} />
                    {/* <Route path="/devices" component={Device} /> */}
                    <Route path="/devices" component={ListTenantDevices} />
                    <Route path="/profile" component={UserProfile} />
                    <Route path="/user/profile" component={EditUserProfile} />
                    <Route
                      path="/historicaldatadownload"
                      component={HistoricalDataJapan}
                    />
                    <Route
                      path="/getSingleDeviceData/:id/:deviceName"
                      component={TenantSingleDevice}
                    />
                    <Route
                      path="/user/changePassword"
                      component={ChangePassword}
                    />
                    <Route path="/alerts" component={Alerts} />
                    <Route path="/messages" component={Messages} />
                    <Route
                      path="/deviceData"
                      component={DeviceHistoricalData}
                    />

                    <Route
                      path="/deviceData"
                      component={DeviceHistoricalData}
                    />
                    <Route path="/HistoricalData" component={HistoricalData} />
                    <Route
                      path="/DeviceHealthStatus"
                      component={DeviceHealthStatus}
                    />
                    <Route
                      path="/update-device-location/:deviceId"
                      component={UpdateDeviceSysAdmin}
                    />
                    <Route component={PageNotFound} />
                  </Switch>
                ) : (
                  <>
                    {details.data.authority === "SYS_ADMIN" ? (
                      <Switch>
                        {" "}
                        <Route
                          path="/dashboard"
                          component={MasterDashboard}
                          exact
                        />
                        <Route path="/devices" component={DeviceForAdmin} />
                        <Route
                          path="/device/create"
                          component={AddDeviceSysAdmin}
                        />
                        <Route path="/tenants" component={Tenants} />
                        <Route path="/tenant/create" component={AddTenant} />
                        <Route
                          path="/customers"
                          component={CustomersForAdmin}
                        />
                        <Route path="/purifiers" component={ListPurifier} />
                        <Route path="/purifiers" component={ListPurifier} />
                        <Route path="/shipments" component={ListShipments} />
                        <Route
                          path="/shipment/create"
                          component={AddShipment}
                        />
                        <Route path="/partners" component={Partners} />
                        <Route path="/partner/create" component={AddPartners} />
                        <Route
                          exact
                          path="/purifier/create"
                          component={AddPurifier}
                        />
                        <Route
                          path="/DeviceHealthStatus"
                          component={DeviceHealthStatus}
                        />
                        <Route
                          path="/createQuestion"
                          component={CreateQuestions}
                        />
                        <Route
                          path="/getSingleDeviceData/:id/:deviceName"
                          component={TenantSingleDevice}
                        />
                        <Route
                          path="/viewResponse/:userId/:masterId"
                          component={ViewUserResponse}
                        />
                        <Route
                          path="/fill-user-response/:userId/:masterId"
                          component={FillUserResponse}
                        />
                        <Route path="/user-feedback" component={UserFeedback} />
                        <Route
                          path="/update-device-location/:deviceId"
                          component={UpdateDeviceSysAdmin}
                        />
                        <Route component={PageNotFound} />
                      </Switch>
                    ) : (
                      <Switch>
                        <Route path="/dashboard" component={Dashboard} exact />
                        <Route path="/profile" component={UserProfile} />
                        <Route
                          path="/user/profile"
                          component={EditUserProfile}
                        />
                        <Route
                          path="/customerUsers/:id"
                          component={CustomerUsers}
                        />
                        <Route path="/user/create" component={AddUser} />
                        <Route
                          path="/customerUser/create/:id"
                          component={AddCustomerUser}
                        />
                        <Route
                          path="/user/changePassword"
                          component={ChangePassword}
                        />
                        <Route path="/alerts" component={Alerts} />
                        <Route path="/messages" component={Messages} />
                        <Route
                          path="/connectDevice"
                          component={ConnectDevice}
                        />
                        <Route
                          path="/fill-response"
                          component={UserQuestionnaire}
                        />
                        <Route component={PageNotFound} />
                      </Switch>
                    )}
                  </>
                )}
              </>
            </>
          )}
        </>
      )}
    </Suspense>
  );
};

export default AppRoutes;
