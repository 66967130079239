import { BehaviorSubject, Subject } from 'rxjs';

const subject = new BehaviorSubject('hi');
let newSub = new BehaviorSubject('test');




export const dashboardService = {
    sendMessage: (message)=> subject.next( message ),
    clearMessages: () => subject.next(),
    getMessage: () => subject.asObservable(),
   getData:()=>newSub.asObservable() ,
   getDataNext:(message1)=>newSub.next(message1)

};
