// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
//import "firebase/messaging";
import * as firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAAvdJGqIUyzi1ZQa5slRd4wp1Q1lxD4lQ",
  authDomain: "test-dap-7bf41.firebaseapp.com",
  projectId: "test-dap-7bf41",
  storageBucket: "test-dap-7bf41.appspot.com",
  messagingSenderId: "871759488774",
  appId: "1:871759488774:web:5229b534fdec4b17726087",
  measurementId: "G-KMR6HRWN1S",
};
// const messaging = firebase.messaging();
// if ("serviceWorker" in navigator) {   



//   navigator.serviceWorker
//     .register("../firebase-messaging-sw.js")
//     .then(function (registration) {
//       console.log("Service Worker Registered");
//       messaging.useServiceWorker(registration);
//     });
// }

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export default firebase;

//vapidkey: BIbL7Gpps77raj8-yrpcrmbLNR9B0kVTyFWACOEQsFtyFNay5r2ecJ9Jpub4Ifsr0t801qJQ6B-mCoYrk2b4adg
