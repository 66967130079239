import React, { useState, useEffect } from "react";
import { environment } from "../../environment";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Dropdown } from "react-bootstrap";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const MySwal = withReactContent(Swal);

const DeviceForAdmin = ({ history }) => {
  const [shipments, setShipment] = useState([]);
  const [deviceCount, setDeviceCount] = useState("");
  const [dap_tenants, setDapTenants] = useState([]);
  const [device_access_token, setAccessToken] = useState("");
  const [show, setShow] = useState(false);
  const [device_index, setDeviceIndex] = useState(0);
  const [deviceData, setDeviceData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (shipments) => {
    setDeviceData(shipments);
    console.log(shipments);
    setShow(true);
  };
  // const handleShow = (deviceId, i) => {
  //   let deviceIndex = devices.findIndex((o) => o._id === deviceId);
  //   console.log(deviceIndex);
  //   setDeviceIndex(deviceIndex);
  //   console.log(deviceId);
  //   if (deviceIndex === i) {
  //     setShow(true);
  //   } else {
  //     setShow(false);
  //   }

  //   console.log(device_index);
  // };

  useEffect(() => {
    getAllNodeDevices();
    axios
      .get(
        environment.apiUrl +
          "tenantInfos?pageSize=1000&page=0&sortProperty=createdTime&sortOrder=DESC",
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.data);
        setDapTenants(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const getAllNodeDevices = async () => {
    await axios
      .get(environment.dap_url + "shipmentApi/getAllShipment")
      .then((res) => {
        console.log("hgsfd");
        console.log(res);
        setShipment(res.data.reverse());
        setDeviceCount(res.data.length);
        console.log(res.data.length);
      })
      .catch((err) => {
        MySwal.fire({
          title: "Error in retriving Shipments.",
          icon: "warning",
        });
      });
  };
  const assignDevice = (tenant_id, device_id) => {
    MySwal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(function (result) {
      if (result.isConfirmed) {
        console.log(tenant_id.id);
        console.log(device_id);
        axios
          .put(environment.dap_url + "assignToTenant/" + device_id, {
            tenantId: tenant_id.id,
          })
          .then((res) => {
            MySwal.fire({
              title: <p>Device has been assigned successfully!</p>,
              type: "success",
            });
            getAllNodeDevices();
          })
          .catch((err) => {
            MySwal.fire({
              title: <p>Device couldn't be assigned at the moment</p>,
              type: "error",
            });
          });
      }
    });
  };

  const deleteHandler = (dataField) => {
    // delete the device from database
    console.log("deleting");
    console.log(dataField);
    // console.log("this", device);
    // console.log("fgh", device.deviceId);
    // console.log(device.deviceOwnerId);
    // console.log(device._id);
    // console.log(device?.TenantDetails[0]?.userId);
    MySwal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(function (result) {
      deleteShipment(dataField);
    });
  };
  const getAccessTokenForTenant = async (
    deviceId,
    deviceOwnerId,
    id,
    tenantId
  ) => {
    // http://core-ce.diaq.in/api/user/efac1770-4b5c-11ec-803e-45aeaa5f3440/token
    await axios
      .get(environment.apiUrl + "user/" + tenantId + "/token", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res?.data);
        deleteTenantDevice(
          deviceId,
          deviceOwnerId,
          tenantId,
          id,
          res?.data?.token
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTenantDevice = async (
    deviceId,
    deviceOwnerId,
    tenantId,
    id,
    token
  ) => {
    axios
      .delete(environment.apiUrl + "device/" + deviceId, {
        headers: {
          "X-Authorization": "Bearer " + token,
        },
      })
      .then((res) => {
        axios
          .delete(environment.dap_url + "dapDeleteDevice/" + id)
          .then(async (res) => {
            console.log(res.data.data);
            await getAllNodeDevices();

            MySwal.fire({
              title: <p>Device has been deleted!</p>,
              type: "success",
            });
            //history.push("/devices");

            // show devices in after delete
          })
          .catch((err) => {
            console.log(err);
            MySwal.fire({
              title: (
                <p>{err?.response?.data?.message || "Something went wrong!"}</p>
              ),
              type: "error",
            });
          });
      })
      .catch((err) => {
        MySwal.fire({
          title: (
            <p>{err?.response?.data?.message || "Something went wrong!"}</p>
          ),
          type: "error",
        });
      });
  };
  const TbDeleteDevice = async (deviceId, deviceOwnerId, id, tenantId) => {
    getAccessTokenForTenant(deviceId, deviceOwnerId, id, tenantId);
    // console.log(deviceOwnerId);
  };
  const deleteShipment = async (dataField) => {
    axios
      .delete(
        environment.dap_url + "shipmentApi/deleteShipment?_id=" + dataField
      )
      .then(async (res) => {
        console.log(res.data.data);
        MySwal.fire({
          title: <p>Shipment has been deleted!</p>,
          type: "success",
        });
        await getAllNodeDevices();
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          title: (
            <p>{err?.response?.data?.message || "Something went wrong!"}</p>
          ),
          type: "error",
        });
      });
  };

  const changeAccessTokenHandler = async (
    accessToken,
    deviceId,
    deviceOwnerId,
    id,
    tenantId,
    createdAt
  ) => {
    let createdTime = Date.parse(createdAt);
    console.log(createdTime);

    getToken(accessToken, deviceId, deviceOwnerId, id, tenantId, createdTime);
    console.log(accessToken, "accessToken");
    console.log(deviceId, "deviceId");
    console.log(deviceOwnerId, "deviceOwnerId");
    console.log(id, "id");
    console.log(tenantId, "tenantId");
  };

  const getToken = async (
    accessToken,
    deviceId,
    deviceOwnerId,
    id,
    tenantId,
    createdTime
  ) => {
    await axios
      .get(environment.apiUrl + "user/" + tenantId + "/token", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res?.data.token);
        changeAccessToken(
          accessToken,
          deviceId,
          deviceOwnerId,
          tenantId,
          id,
          res?.data?.token,
          createdTime
        );
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          title: <p>{err?.response?.data?.message || "Can't find token!"}</p>,
          type: "error",
        });
      });
  };

  const changeAccessToken = async (
    accessToken,
    deviceId,
    deviceOwnerId,
    tenantId,
    id,
    token,
    createdTime
  ) => {
    console.log(accessToken, "accessToken");
    console.log(deviceId, "deviceId");
    console.log(deviceOwnerId, "deviceOwnerId");
    console.log(id, "id");
    console.log(tenantId, "tenantId");
    console.log(createdTime, "tenant token");

    let objToPost = {};
    objToPost = {
      id: {
        id: deviceId,
      },
      createdTime: createdTime,
      deviceId: {
        entityType: "DEVICE",
        id: deviceId,
      },
      credentialsType: "ACCESS_TOKEN",
      credentialsId: accessToken,
      credentialsValue: null,
    };
    await axios
      .post(environment.apiUrl + "device/credentials", objToPost, {
        headers: {
          "X-Authorization": "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res?.data);
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          title: (
            <p>
              {err?.response?.data?.message || "Access Token change failed"}
            </p>
          ),
          type: "error",
        });
      });
  };

  const updateDeviceLocation = (deviceId) => {
    history.push(`/update-device-location/${deviceId}`);
    console.log(deviceId);
  };
  const columns = [
    {
      dataField: "consigneedetails[0].userFirstName",
      text: "Consignee",
      sort: true,
    },
    {
      dataField: "consignordetails[0].userFirstName",
      text: "Consignor",
      sort: true,
    },
    {
      dataField: "consignmentState",
      text: "State",
      sort: true,
    },
    {
      dataField: "consignmentCountry",
      text: "Country",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },

    // {
    //   dataField: "_id",
    //   text: "Change the Partner",
    //   sort: false,
    //   formatter: (dataField) => {
    //     return (
    //       <div>
    //         <Dropdown alignRight>
    //           <Dropdown.Toggle
    //             variant='btn btn-primary btn-sm'
    //             id='dropdownMenuSizeButton3'
    //           >
    //             <span>+ Change Partner</span>
    //           </Dropdown.Toggle>

    //           <>
    //             <div>
    //               <Dropdown.Menu>
    //                 {dap_tenants.map((tenant) => (
    //                   <Dropdown.Item
    //                     key={tenant.id}
    //                     onClick={() => assignDevice(tenant.id, dataField)}
    //                   >
    //                     <i className='icon-user text-primary'></i>
    //                     {tenant.name}
    //                   </Dropdown.Item>
    //                 ))}
    //               </Dropdown.Menu>
    //             </div>
    //           </>
    //         </Dropdown>
    //       </div>
    //     );
    //   },
    // },

    // {
    //   dataField: "_id",
    //   text: "Update Location",
    //   sort: false,
    //   formatter: (dataField) => {
    //     return (
    //       <div>
    //         <Button
    //           variant="primary"
    //           className="btn-sm"
    //           onClick={() => updateDeviceLocation(dataField)}
    //         >
    //           Update Location
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "_id",
      text: "Delete",
      sort: false,
      formatter: (dataField) => {
        return (
          <div>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => deleteHandler(dataField)}
            >
              Delete <i className="fa fa-trash-o"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div className="row" style={{ marginTop: "72px" }}>
        <div className="col-lg-12">
          <LinkContainer
            to={`/shipment/create`}
            style={{
              float: "right",
              //paddingTop: "18px",
              marginBottom: "20px",
            }}
          >
            <Button variant="light" className="btn-lg my-3">
              Add Shipment
            </Button>
          </LinkContainer>
        </div>
      </div>

      <div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Shipments</h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={shipments}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> {deviceData.deviceName} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mx-auto" show={show} onHide={handleClose}>
            <Form.Group controlId="AccessToken">
              <Form.Label> Access Token </Form.Label>
              <Form.Control
                type="text"
                //value={deviceData.accessToken}
                placeholder={deviceData.accessToken}
                onChange={(e) => setAccessToken(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
          </Form>

          <div className="my-2 d-flex justify-content-between align-items-center mx-auto">
            <div className=" mx-auto">
              <Button
                type="submit"
                className="btn btn-block btn-primary btn-sm font-weight-medium auth-form-btn"
                onClick={() => {
                  changeAccessTokenHandler(
                    device_access_token,
                    deviceData.deviceId,
                    deviceData._id,
                    deviceData.deviceOwnerId,
                    deviceData.TenantDetails[0]?.userId
                  );
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light btn-sm" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <h5 style={{ marginLeft: "5%" }}>
        Total number of Devices: {deviceCount}
      </h5>
    </div>
  );
};

export default DeviceForAdmin;
