import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
//import { createCustomer } from "../services/createCustomer";

import axios from "axios";
import { environment } from "../../environment";
//import Loader from "../Components/Loader";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Country, State, City } from "country-state-city";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

const MySwal = withReactContent(Swal);

const CreateCustomer = ({ history }) => {
  const [dap_title, setTitle] = useState("");
  const [dap_firstName, setFirstName] = useState("");
  const [dap_lastName, setLastName] = useState("");
  const [dap_country, setDCountry] = useState("");
  const [dap_state, setState] = useState("");
  const [dap_address, setAddress] = useState("");
  const [dap_email, setEmail] = useState("");
  const [dap_phone, setPhone] = useState("");
  const [dap_tenantId, setTenantId] = useState("");
  const [dap_countries, setDapCountries] = useState("");
  const [dap_states, setDapState] = useState("");
  const [dap_city, setDapCity] = useState([]);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  const [emailErrors, setEmailErrors] = useState({});
  const [nameErrors, setNameErrors] = useState({});
  const [phoneErrors, setPhoneErrors] = useState({});

  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   let userDetails = "";
  //   userDetails = localStorage.getItem("userDetails");
  //   userDetails = JSON.parse(userDetails);
  //   console.log("hyyy therer", userDetails.data.tenantId.id);
  //   setTenantId(userDetails.data.tenantId.id);
  // }, []);
  let countries = [];

  const formValidation = () => {
    const emailErrors = {};
    const nameErrors = {};
    const phoneErrors = {};
    let isValid = true;

    if (!dap_email) {
      emailErrors.noEmail = "* Email address required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(dap_email)) {
      emailErrors.invalidEmail = "* Email address is invalid";
      isValid = false;
    }
    if (!dap_firstName) {
      nameErrors.noName = "* First name is required";
      isValid = false;
    } else if (dap_firstName.trim().length < 2) {
      nameErrors.shortName = "* First name is too short";
      isValid = false;
    } else if (dap_firstName.trim().length > 30) {
      nameErrors.longName = "* First name is too long";
      isValid = false;
    }

    if (dap_phone) {
      if (dap_phone.trim().length < 10) {
        phoneErrors.shortNumber = "* Phone number should be 10 digits";
        isValid = false;
      }
      if (
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,1024}$/.test(
          dap_phone
        )
      ) {
        phoneErrors.shortNumber = "* Please enter valid phone number";
        isValid = false;
      }
    }

    setEmailErrors(emailErrors);

    setNameErrors(nameErrors);

    setPhoneErrors(phoneErrors);
    return isValid;
  };

  const selectCountry = (val) => {
    console.log("india");
    console.log(val);
    setDapCountries(val);
  };

  const selectRegion = (val) => {
    setDapState(val);
  };

  console.log(Country.getAllCountries());
  console.log(Country.getAllCountries());
  console.log(State.getAllStates());

  //setDapCountries(Country.getAllCountries());

  const submitHandler = (e) => {
    // axios
    //   .post(
    //     environment.apiUrl +
    //       "entityGroups/CUSTOMER/498843d0-0f9a-11ec-aeab-6338f3fa1c68/USER",
    //     { tenantId: dap_tenantId },
    //     {
    //       headers: {
    //         "X-Authorization": "Bearer " + localStorage.getItem("token"),
    //       },
    //     }
    //   )
    //   .then((res) => {})
    //   .catch((err) => {});
    e.preventDefault();
    const isValid = formValidation();

    if (isValid) {
      setLoading(true);
      axios
        .post(
          environment.apiUrl +
            // "user?sendActivationMail=true&entityGroupId=49942ab0-0f9a-11ec-aeab-6338f3fa1c68",
            "customer",

          {
            title: dap_title,
            name: dap_firstName,
            country: dap_countries,
            state: dap_states,
            address: dap_address,
            email: dap_email,
            phone: dap_phone,
          },

          // {
          //   email: dap_email,
          //   firstName: dap_firstName,
          //   lastName: dap_lastName,
          //   additionalInfo: {
          //     description: "any",
          //     defaultDashboardId: null,
          //     defaultDashboardFullscreen: false,
          //     homeDashboardId: null,
          //     homeDashboardHideToolbar: true,
          //   },
          //   authority: "CUSTOMER_USER",
          //   customerId: {
          //     entityType: "CUSTOMER",
          //     id: "498843d0-0f9a-11ec-aeab-6338f3fa1c68",
          //     //id: id,
          //   },
          // },
          {
            headers: {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data.data);
          MySwal.fire({
            title: <p>Customer has been successfully created!</p>,
            type: "success",
          });
          setLoader(false);
          history.push("/customers");
          // window.location.href = "/customers";
        })
        .catch((err) => {
          setLoading(false);
          MySwal.fire({
            title: (
              <p>
                {err?.response?.data?.message || "Customer creation failed"}
              </p>
            ),
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <div>
        <div className='row'>
          <div className='col-lg-3'></div>
          <div className='col-lg-6'>
            <Link to='/customers' className='btn btn-light my-3'>
              Go Back
            </Link>
          </div>
          <div className='col-lg-3'></div>
        </div>

        <div className='row'>
          <div className='col-lg-3'></div>
          <div className='col-md-6 grid-margin stretch-card'>
            <div className='card'>
              <div className='card-body'>
                <h1 className='text-center'>ADD CUSTOMER</h1>
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId='title'>
                    <Form.Label>* Title</Form.Label>
                    <Form.Control
                      type='title'
                      placeholder='Enter title'
                      value={dap_title}
                      onChange={(e) => setTitle(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='name'>
                    <Form.Label>* First Name</Form.Label>
                    <Form.Control
                      type='name'
                      placeholder='Enter First Name'
                      value={dap_firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    ></Form.Control>
                    <div>
                      {Object.keys(nameErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>{nameErrors[key]}</div>
                        );
                      })}
                    </div>
                  </Form.Group>

                  <Form.Group controlId='name'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type='name'
                      placeholder='Enter Last Name'
                      value={dap_lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <div className='row'>
                    <div className='col-6'>
                      <Form.Group controlId='name'>
                        <Form.Label>Country</Form.Label>
                        <div>
                          <CountryDropdown
                            value={dap_countries}
                            onChange={(val) => selectCountry(val)}
                            placeholder={dap_countries}
                            style={{
                              width: "100%",
                              border: "1px solid #ebedf2",
                              height: "42px",
                            }}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className='col-6'>
                      <Form.Group controlId='name'>
                        <Form.Label>State</Form.Label>
                        <div>
                          <RegionDropdown
                            country={dap_countries}
                            value={dap_states}
                            onChange={(val) => selectRegion(val)}
                            style={{
                              width: "100%",
                              border: "1px solid #ebedf2",
                              height: "42px",
                            }}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group controlId='address'>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type='address'
                      placeholder='Enter address'
                      value={dap_address}
                      onChange={(e) => setAddress(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='email'>
                    <Form.Label>* Email</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter email'
                      value={dap_email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                    <div>
                      {Object.keys(emailErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>{emailErrors[key]}</div>
                        );
                      })}
                    </div>
                  </Form.Group>

                  <Form.Group controlId='phone'>
                    <Form.Label>* Phone</Form.Label>
                    <Form.Control
                      type='phone'
                      placeholder='Enter phone'
                      value={dap_phone}
                      onChange={(e) => setPhone(e.target.value)}
                    ></Form.Control>
                    <div>
                      {Object.keys(phoneErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>{phoneErrors[key]}</div>
                        );
                      })}
                    </div>
                  </Form.Group>

                  <div className='mt-3'>
                    <Button
                      type='submit'
                      className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
                      disabled={loading}
                    >
                      ADD CUSTOMER
                      {loading && (
                        <i
                          className='fa fa-refresh fa-spin'
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span></span>}
                      {!loading && <span></span>}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className='col-lg-3'></div>
        </div>
      </div>
    </>
  );
};

export default CreateCustomer;
