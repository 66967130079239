import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
//import { createCustomer } from "../services/createCustomer";

import axios from "axios";
import { environment } from "../../environment";
//import Loader from "../Components/Loader";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    if (currentPassword) {
      if (newPassword === confirmPassword) {
        axios
          .post(
            environment.apiUrl + "auth/changePassword",
            { currentPassword: currentPassword, newPassword: newPassword },
            {
              headers: {
                "X-Authorization": "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            MySwal.fire({
              title: <p>Password has been changed successfully</p>,
              type: "success",
            });
          })
          .catch((err) => {
            MySwal.fire({
              title: <p>{err?.response?.data?.message || "Failed"}</p>,
              type: "error",
            });
          });
      } else {
        MySwal.fire({
          title: <p>Passwords Don't match</p>,
          type: "error",
        });
      }
    } else {
      MySwal.fire({
        title: <p>Please enter your password</p>,
        type: "error",
      });
    }

    // axios
    //   .post(
    //     environment.apiUrl + "auth/changePassword",
    //     { currentPassword: currentPassword, newPassword: newPassword },
    //     {
    //       headers: {
    //         "X-Authorization": "Bearer " + localStorage.getItem("token"),
    //       },
    //     }
    //   )
    //   .then(() => {
    //     console.log("password changed");
    //     MySwal.fire({
    //       title: <p>Password has been changed successfully</p>,
    //       type: "success",
    //     });
    //     window.alert("password changed");
    //   })
    //   .catch((err) => {
    //     MySwal.fire({
    //       title: <p>{err.message}</p>,
    //       type: "error",
    //     });
    //     console.log("error in changing password");
    //   });
  };
  return (
    <>
      <div className='row'>
        <div className='col-lg-3'></div>
        <div className='col-md-6 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <Form onSubmit={submitHandler}>
                <Form.Group controlId='title'>
                  <Form.Label>* Current Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Enter current password'
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='name'>
                  <Form.Label>* New Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Enter New Password'
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='name'>
                  <Form.Label>* Confirm Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Enter Password Again'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <div className='mt-3'>
                  <Button
                    type='submit'
                    className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
                  >
                    Change Password
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className='col-lg-3'></div>
      </div>
    </>
  );
};

export default ChangePassword;
