import React, { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../environment.js";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
//import { deleteDevice } from "../services/deleteDevice";
import { Dropdown } from "react-bootstrap";
//import { assignDevice } from "../services/assignDevice.js";

import Loader from "../Components/Loader.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Device = ({ history }) => {
  const [dap_devices, setDapDevices] = useState([]);
  const [dap_customers, setDapCustomers] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    axios
      .get(environment.apiUrl + `tenant/devices?pageSize=200&page=0`, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })

      .then((res) => {
        // const devices = res.data.data;
        setDapDevices(res.data.data);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(environment.apiUrl + `customers?pageSize=100&page=0`, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })

      .then((res) => {
        setDapCustomers(res.data.data);
      })
      .catch(function (error) {
        window.alert(error.message);
      });
  }, []);

  const assignDevice = (c_id, d_id) => {
    MySwal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(function (result) {
      if (result.isConfirmed) {
        axios
          .post(
            environment.apiUrl + "owner/CUSTOMER/" + c_id + "/DEVICE/" + d_id,
            {},
            {
              headers: {
                "X-Authorization": "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log(res.data.data);

            //update device in databse
            axios
              .put(
                environment.dap_url + "dapUpdateDevice/" + d_id,
                { deviceId: d_id },
                { deviceOwnerId: c_id },
                { new: true }
              )
              .then((res) => {
                console.log(res.data.data);
                MySwal.fire({
                  title: <p>Device has been assigned successfully!</p>,
                  type: "success",
                });
              })
              .catch((err) => {
                MySwal.fire({
                  title: <p>{err.response.data.message}</p>,
                  type: "error",
                });
              });
          })
          .catch((err) => {
            MySwal.fire({
              title: <p>{err.response.data.message}</p>,
              type: "error",
            });
          });
      }
    });
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(function (result) {
      if (result.isConfirmed) {
        axios
          .delete(environment.apiUrl + "device/" + id, {
            headers: {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            // delete the device from database
            axios
              .delete(environment.dap_url + "dapDeleteDevice/" + id)
              .then((res) => {
                console.log(res.data.data);
                MySwal.fire({
                  title: <p>Device has been deleted!</p>,
                  type: "success",
                });
                //history.push("/devices");

                // show devices in after delete
                axios
                  .get(
                    environment.apiUrl + `tenant/devices?pageSize=20&page=0`,
                    {
                      headers: {
                        "X-Authorization":
                          "Bearer " + localStorage.getItem("token"),
                      },
                    }
                  )

                  .then((res) => {
                    // const devices = res.data.data;
                    setDapDevices(res.data.data);
                    setLoader(false);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              })
              .catch((err) => {
                console.log(err);
                MySwal.fire({
                  title: <p>{err.response.data.message}</p>,
                  type: "error",
                });
              });
          })
          .catch((err) => {
            MySwal.fire({
              title: <p>{err.response.data.message}</p>,
              type: "error",
            });
          });
      }
    });
  };

  return loader ? (
    <Loader />
  ) : (
    <div>
      <>
        {/* <div className="row" style={{ marginTop: "72px" }}>
          <div className="col-lg-12">
            <LinkContainer
              to={`/device/create`}
              style={{
                // marginLeft: "91%",
                // verticalAlign: "bottom",
                // paddingTop: "18px",
                marginBottom: "20px",
                float: "right",
              }}
            >
              <Button variant="light" className="btn-sm">
                Create Device
              </Button>
            </LinkContainer>
          </div>
        </div> */}
        <div className='row'>
          {/* <div className="col-lg-2"></div> */}

          <div className='col-lg-12 grid-margin stretch-card'>
            <div className='card'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table table-striped table-bordered'>
                    <thead>
                      <tr>
                        <th> Name </th>
                        <th> Device Profile </th>
                        <th> Label </th>
                        <th> Assign Customer</th>
                        <th> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dap_devices.map((dap_device) => (
                        <tr key={dap_device.id.id}>
                          <td>{dap_device.name}</td>
                          <td>{dap_device.type}</td>
                          <td>{dap_device.label}</td>

                          <td>
                            <Dropdown alignRight>
                              <Dropdown.Toggle variant='transparent'>
                                <span className='btn'>+Assign Customer</span>
                              </Dropdown.Toggle>

                              <>
                                <div>
                                  <Dropdown.Menu>
                                    {dap_customers.map((dap_customer) => (
                                      <Dropdown.Item
                                        key={dap_customer.id.id}
                                        onClick={() =>
                                          assignDevice(
                                            dap_customer.id.id,
                                            dap_device.id.id
                                          )
                                        }
                                      >
                                        <i className='icon-user text-primary'></i>
                                        {dap_customer.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </div>
                              </>
                            </Dropdown>
                          </td>
                          <td>
                            <Button
                              variant='danger'
                              className='btn-sm'
                              onClick={() => deleteHandler(dap_device.id.id)}
                            >
                              delete <i className='fa fa-trash-o'></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-2"></div> */}
        </div>
      </>
    </div>
  );
};

export default Device;
