import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
// import i18n from "i18next";
// import { useTranslation, initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .use(LanguageDetector)

//   .init({
//    resources: {
//       en: {
//         translation: {
//           "Welcome to React": "Welcome to React and react-i18next",
//         },
//       },
//     },
//     //lng: document.querySelector("html").lang, // if you're using a language detector, do not define the lng option
//     fallbackLng: "en",
//     detection: {
//       order: ["htmlTag", "cookie", "localStorage", "path", "subdomain"],
//       caches: ["cookie"],
//     },
//   });

// function App() {
//   const { t } = useTranslation();

//   return <h2>{t("Welcome to React")}</h2>;
// }

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}
ReactDOM.render(
  <BrowserRouter basename="/">
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
