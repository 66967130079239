import React, { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../../environment.js";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
//import { deleteCustomer } from "../services/deleteCustomer.js";
import SweetAlert from "sweetalert2-react";
import "../loader.css";
import Loader from "../../Components/Loader.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const MySwal = withReactContent(Swal);

const ManageCustomerUsers = ({ history }) => {
  const [dap_customers, setCustomers] = useState([]);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(true);
  const [dap_cid, setCid] = useState([]);

  useEffect(() => {
    axios
      .get(
        environment.apiUrl + `customers?pageSize=500&page=0&sortOrder=DESC`,
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )

      .then((res) => {
        // const dap_customers = res.data.data;
        setCustomers(res.data.data.reverse());

        setLoader(false);
        setCid(res.data.data);
      })
      .catch((err) => {
        MySwal.fire({
          title: <p>{err?.response?.data?.message}</p>,
          type: "error",
        });
      });
  }, []);

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(function (result) {
      if (result.isConfirmed) {
        axios
          .delete(environment.apiUrl + "customer/" + id, {
            headers: {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log(res.data.data);
            MySwal.fire({
              title: <p>Customer has been successfully deleted!</p>,
              type: "success",
            });
            //history.push("/customers");
            axios
              .get(
                environment.apiUrl +
                  `customers?pageSize=100&page=0&sortOrder=DESC`,
                {
                  headers: {
                    "X-Authorization":
                      "Bearer " + localStorage.getItem("token"),
                  },
                }
              )

              .then((res) => {
                // const dap_customers = res.data.data;
                setCustomers(res.data.data.reverse());
                setLoader(false);
                setCid(res.data.data);
              })
              .catch((err) => {
                MySwal.fire({
                  title: (
                    <p>
                      {err?.response?.data?.message ||
                        "Customer deletion failed"}
                    </p>
                  ),
                  type: "error",
                });
              });
          })
          .catch((err) => {
            MySwal.fire({
              title: (
                <p>
                  {err?.response?.data?.message || "Customer deletion failed"}
                </p>
              ),
              type: "error",
            });
          });
      }
    });
  };

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "id.id",
      text: "Users",
      sort: false,
      formatter: (dataField) => {
        return (
          <div>
            <LinkContainer to={`/users/` + dataField}>
              <Button variant='light' className='btn-sm'>
                Users <i className='fa fa-user'></i>
              </Button>
            </LinkContainer>
          </div>
        );
      },
    },
    {
      dataField: "id.id",
      text: "Delete",
      sort: false,
      formatter: (dataField) => {
        return (
          <div>
            <Button
              variant='danger'
              className='btn-sm'
              onClick={() => deleteHandler(dataField)}
            >
              Delete <i className='fa fa-trash-o'></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return loader ? (
    <Loader />
  ) : (
    <div>
      <>
        <div className='row' style={{ marginTop: "2%" }}>
          <div className='col-lg-12'>
            <LinkContainer
              to={`/customer/create`}
              style={{
                float: "right",
                //paddingTop: "18px",
                marginBottom: "20px",
              }}
            >
              <Button variant='light' className='btn-lg my-3'>
                Create Customer
              </Button>
            </LinkContainer>
          </div>
        </div>

        <div>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <h4 className='card-title'>CUSTOMERS</h4>
                  <div className='row'>
                    <div className='col-12'>
                      <ToolkitProvider
                        keyField='id'
                        bootstrap4
                        data={dap_customers}
                        columns={columns}
                        search
                      >
                        {(props) => (
                          <div>
                            <div className='d-flex align-items-center'>
                              <p className='mb-2 mr-2'>Search in table:</p>
                              <SearchBar {...props.searchProps} />
                            </div>
                            <BootstrapTable
                              defaultSorted={defaultSorted}
                              pagination={paginationFactory()}
                              {...props.baseProps}
                              wrapperClasses='table-responsive'
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ManageCustomerUsers;
