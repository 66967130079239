import { BehaviorSubject, Subject } from "rxjs";

const subject = new BehaviorSubject("");
let newSub = new BehaviorSubject("");

export const locationService = {
  sendMessage: (message) => subject.next(message),
  clearMessages: () => subject.next(),
  getMessage: () => subject.asObservable(),
  getData: () => newSub.asObservable(),
  getDataNext: (message1) => newSub.next(message1),
};
