import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
//import { createDevice } from "../services/createDevice";
import Editable from "react-bootstrap-editable";
import axios from "axios";
import { environment } from "../../environment";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const AddDevice = () => {
  const [dap_label, setLabel] = useState("");
  const [dap_description, setDescription] = useState("");
  const [dap_name, setName] = useState("");
  const [dap_transport_type, setTrasportType] = useState("");
  const [dap_profileName, setProfileName] = useState("");
  const [loading, setLoading] = useState(false);

  //const [loader, setLoader] = useState(true);

  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();

    // createDevice(
    //   dap_name,
    //   dap_label,
    //   dap_transport_type,
    //   dap_description,
    //   dap_profileName
    // );
    let dap_objToPost = {
      name: dap_profileName,
      type: "DEFAULT",
      transportType: "DEFAULT",
      provisionType: "DISABLED",
      profileData: {
        configuration: {
          type: "DEFAULT",
        },
        transportConfiguration: {
          type: "DEFAULT",
        },
        alarms: null,
        provisionConfiguration: {
          type: "DISABLED",
        },
      },
    };

    axios
      .post(environment.apiUrl + "deviceProfile", dap_objToPost, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data);

        axios
          .post(
            environment.apiUrl + "device",
            {
              name: dap_name,
              label: dap_label,
              deviceProfileId: {
                entityType: "DEVICE_PROFILE",
                // id: "4bf465c0-e922-11eb-aeab-6338f3fa1c68",
                id: res.data.id.id,
              },
              additionalInfo: {
                gateway: false,
                overwriteActivityTime: false,
                description: dap_description,
              },
              customerId: null,
              transportType: dap_transport_type,
              type: "DEFAULT",
            },
            {
              headers: {
                "X-Authorization": "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log(res.data);

            axios
              .post(environment.dap_url + "dapAdddevice/dapCreateDevice", {
                deviceProfileId: res.data.id.id,
                tenantId: "req.body.tenantId",
                deviceName: dap_name,
                deviceProfileName: dap_name,
                deviceOwnerId: "req.body.deviceOwnerId",
              })
              .then((res) => {
                MySwal.fire({
                  title: <p>Device has been successfully created!</p>,
                  type: "success",
                });
                setLabel("");
                setDescription("");
                setName("");
                setTrasportType("");
                setProfileName("");
              })
              .catch((err) => {
                setLoading(false);
                console.log(err);
                MySwal.fire({
                  title: <p>{err?.response?.data?.message}</p>,
                  type: "error",
                });
              });
          })
          .catch((err) => {
            setLoading(false);
            MySwal.fire({
              title: <p>{err?.response?.data?.message}</p>,
              type: "error",
            });
          });
      })
      .catch((err) => {
        setLoading(false);
        MySwal.fire({
          title: <p>{err?.response?.data?.message}</p>,
          type: "error",
        });
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-3"></div>
        <Link to="/devices" className="btn btn-light my-3">
          Go Back
        </Link>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <h1 className="text-center">ADD DEVICE</h1>
              <div className="card-body">
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId="name">
                    <Form.Label>* Device Name</Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter Device Name"
                      value={dap_name}
                      onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="lastName">
                    <Form.Label>* Label</Form.Label>
                    <Form.Control
                      type="label"
                      placeholder="Enter Label"
                      value={dap_label}
                      onChange={(e) => setLabel(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <div className="form-group row">
                    <label className="col-6 col-lg-4 col-form-label">
                      Transport Type
                    </label>
                    <div className="col-6 col-lg-8 d-flex align-items-center">
                      <Editable
                        initialValue="default"
                        isValueClickable
                        mode="inline"
                        type="select"
                        options={["default", "MQTT"]}
                        value={dap_transport_type}
                        onChange={(e) => setTrasportType(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <Form.Group controlId="profile name">
                      <Form.Label>* Device Profile</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="Device Profile Name"
                        value={dap_profileName}
                        onChange={(e) => setProfileName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="description"
                      placeholder="Add Description"
                      value={dap_description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <div className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      disabled={loading}
                    >
                      ADD DEVICE
                      {loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span></span>}
                      {!loading && <span></span>}
                    </Button>
                  </div>
                </Form>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDevice;
