import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
//import { createDevice } from "../services/createDevice";
import Editable from "react-bootstrap-editable";
import axios from "axios";
import Select from "react-select";
import { environment } from "../../environment";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const AddDeviceSysAdmin = () => {
  const [dap_label, setLabel] = useState("");
  const [dap_description, setDescription] = useState("");
  const [access_token, setSetAccessToken] = useState("");
  const [dap_name, setName] = useState("");
  const [dap_transport_type, setTrasportType] = useState("");
  const [dap_profileName, setProfileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState([]);
  const [locationType, setLocationType] = useState("");

  let location_array = [];
  location_array = [
    {
      locationName: "Hospital",
      locationId: 1,
    },
    {
      locationName: "School",
      locationId: 2,
    },
    {
      locationName: "Industrial Office",
      locationId: 3,
    },
    {
      locationName: "Hospitality",
      locationId: 4,
    },

    {
      locationName: "None",
      locationId: 10,
    },
  ];

  const submitHandler = (e) => {
    e.preventDefault();
    if ((dap_name, dap_label)) {
      setLoading(true);
      axios
        .post(environment.dap_url + "dapAdddevice/dapCreateDevice", {
          deviceName: dap_name,
          deviceLabel: dap_label,
          deviceProfileName: dap_profileName,
          description: dap_description,
          deviceInstalledPlace: locationType,
          accessToken: access_token,
        })
        .then((res) => {
          setLoading(false);
          console.log(res);
          //changeLocation(locationType, res.data._id);
          console.log(res);
          MySwal.fire({
            title: <p>Device has been successfully created!</p>,
            type: "success",
          });
          setLabel("");
          setDescription("");
          setName("");
          setTrasportType("");
          setProfileName("");
          setLocationType("");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          console.log(err);
          MySwal.fire({
            title: (
              <p>{err?.response?.data?.message || "Device creation failed"}</p>
            ),
            type: "error",
          });
        });
    } else {
      MySwal.fire({
        title: <p>Please enter all values</p>,
        type: "error",
      });
    }
  };

  const setLocationHandler = (e, deviceId) => {
    console.log(e);
    console.log(e.id);
    console.log(e.value);
    if (e.id === 10) {
      setLocationType(null);
    } else {
      setLocationType(e.value);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-3"></div>
        <Link to="/devices" className="btn btn-light my-3">
          Go Back
        </Link>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <h1 className="text-center">ADD DEVICE</h1>
              <div className="card-body">
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId="name">
                    <Form.Label>* Device Name</Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter Device Name"
                      value={dap_name}
                      onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="lastName">
                    <Form.Label>* Label</Form.Label>
                    <Form.Control
                      type="label"
                      placeholder="Enter Label"
                      value={dap_label}
                      onChange={(e) => setLabel(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <div className="col-md-6">
                    <Form.Group controlId="profile name">
                      <Form.Label>Device Profile</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="Device Profile Name"
                        value={dap_profileName}
                        onChange={(e) => setProfileName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="desctption"
                      placeholder="Add Description"
                      value={dap_description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <div className="row">
                    <div className="col-lg-6">
                      <Form.Group controlId="name">
                        <Form.Label>Select Location Type </Form.Label>
                        <Select
                          options={location_array.map((element) => ({
                            id: element.locationId,
                            value: element.locationName,
                            label: element.locationName,
                          }))}
                          onChange={(e) => setLocationHandler(e)}
                          // defaultValue={dap_deviceList[0].name}
                          defaultValue="hi there"
                          placeholder={
                            locationType ? locationType : "set location"
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="description">
                        <Form.Label>Access Token</Form.Label>
                        <Form.Control
                          type="token"
                          placeholder="Add Token"
                          value={access_token}
                          onChange={(e) => setSetAccessToken(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      disabled={loading}
                    >
                      ADD DEVICE
                      {loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span></span>}
                      {!loading && <span></span>}
                    </Button>
                  </div>
                </Form>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDeviceSysAdmin;
