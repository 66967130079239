import React from "react";

import Weather from "./Weather";
//import "bootstrap/dist/css/bootstrap.min.css";
import Search from "./Search";
import axios from "axios";
import { environment } from "../../environment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

//api call api.openweathermap.org/data/2.5/weather?q=London,uk
// const API_key = "f9ea53184161994e54ce0fa644b25ed5";

class WeatherByCity extends React.Component {
  constructor() {
    super();
    this.state = {
      city: undefined,
      country: undefined,
      main: undefined,
      celsius: undefined,
      temp_max: undefined,
      temp_min: undefined,
      description: "",
      icon: "",
      error: false,
      co: "",
      no2: "",
      o3: "",
      pm2_5: "",
      pm10: "",
      so2: "",
      isHidden: true,
      humidity: 0,
      last_updated: "",
      pressure_in: 0,
      uv: 0,
      wind_dir: "",
      wind_kph: 0,
      wind_mph: 0,
      country: "",
      region: "",
      localtime: "",
    };
  }

  calCelsius(temp) {
    let cell = Math.floor(temp - 273.15);
    return cell;
  }

  getWeather = async (e) => {
    e.preventDefault();

    const city = e.target.elements.city.value;
    //const country = e.target.elements.country.value;

    // if (city) {
    //   const api_call = await fetch(
    //     `http://api.openweathermap.org/data/2.5/weather?q=${city},${country}&appid=${API_key}`
    //   );
    //   const response = await api_call.json();
    //   console.log(response);
    //   this.setState({
    //     //city: `${response.name},${response.sys.country}`,
    //     celsius: this.calCelsius(response.main.temp),
    //     temp_max: this.calCelsius(response.main.temp_max),
    //     temp_min: this.calCelsius(response.main.temp_min),
    //     description: response.weather[0].description,
    //     error: false,
    //   });
    // } else {
    //   this.setState({ error: true });
    // }
    if (city) {
      axios
        .post(environment.dap_url + "/outdoorPollution/getDataByLocation", {
          location: city,
        })
        .then((res) => {
          console.log("search in local db");
          console.log(res);
          console.log(res.data[0].PollutantData.current.temp_c);
          this.setState({
            city: res.data[0].location,
            celcius: this.calCelsius(res.data[0].PollutantData.current.temp_c),
            // celsius: this.calCelsius(response.main.temp),
            temp_max: res.data[0].PollutantData.current.temp_c,
            temp_min: res.data[0].PollutantData.current.temp_c,
            icon: res.data[0].PollutantData.current.condition.icon,
            description: res.data[0].PollutantData.current.condition.text,
            co: parseFloat(
              res.data[0].PollutantData.current.air_quality.co
            ).toFixed(2),
            no2: res.data[0].PollutantData.current.air_quality.no2.toFixed(2),
            o3: res.data[0].PollutantData.current.air_quality.o3.toFixed(2),
            pm2_5:
              res.data[0].PollutantData.current.air_quality.pm2_5.toFixed(2),
            pm10: res.data[0].PollutantData.current.air_quality.pm10.toFixed(2),
            so2: res.data[0].PollutantData.current.air_quality.so2.toFixed(2),
            humidity: res.data[0].PollutantData.current.humidity.toFixed(2),
            last_updated: res.data[0].PollutantData.current.last_updated,
            pressure_in:
              res.data[0].PollutantData.current.pressure_in.toFixed(2),
            uv: res.data[0].PollutantData.current.uv.toFixed(2),
            wind_dir: res.data[0].PollutantData.current.wind_dir,
            wind_kph: res.data[0].PollutantData.current.wind_kph.toFixed(2),
            wind_mph: res.data[0].PollutantData.current.wind_mph.toFixed(2),
            country: res.data[0].PollutantData.location.country,
            region: res.data[0].PollutantData.location.region,
            localtime: res.data[0].PollutantData.location.localtime,
            error: false,
            isHidden: false,
          });
        })
        .catch((err) => {
          console.log("search in api");
          axios
            .get(
              "https://api.weatherapi.com/v1/current.json?key=fee0b93daf7744fb85871040211108&q=" +
                city +
                "&aqi=yes"
            )
            .then((res) => {
              console.log(res);
              console.log(res.data[0].PollutantData.current.temp_c);
              this.setState({
                city: res.data[0].location,
                celcius: this.calCelsius(
                  res.data[0].PollutantData.current.temp_c
                ),
                // celsius: this.calCelsius(response.main.temp),
                temp_max: res.data[0].PollutantData.current.temp_c,
                temp_min: res.data[0].PollutantData.current.temp_c,
                icon: res.data[0].PollutantData.current.condition.icon,
                description: res.data[0].PollutantData.current.condition.text,
                co: parseFloat(
                  res.data[0].PollutantData.current.air_quality.co
                ).toFixed(4),
                no2: res.data[0].PollutantData.current.air_quality.no2.toFixed(
                  2
                ),
                o3: res.data[0].PollutantData.current.air_quality.o3.toFixed(2),
                pm2_5:
                  res.data[0].PollutantData.current.air_quality.pm2_5.toFixed(
                    2
                  ),
                pm10: res.data[0].PollutantData.current.air_quality.pm10.toFixed(
                  2
                ),
                so2: res.data[0].PollutantData.current.air_quality.so2.toFixed(
                  2
                ),
                humidity: res.data[0].PollutantData.current.humidity.toFixed(2),
                last_updated: res.data[0].PollutantData.current.last_updated,
                pressure_in:
                  res.data[0].PollutantData.current.pressure_in.toFixed(2),
                uv: res.data[0].PollutantData.current.uv.toFixed(2),
                wind_dir: res.data[0].PollutantData.current.wind_dir,
                wind_kph: res.data[0].PollutantData.current.wind_kph.toFixed(2),
                wind_mph: res.data[0].PollutantData.current.wind_mph.toFixed(2),
                country: res.data[0].PollutantData.location.country,
                region: res.data[0].PollutantData.location.region,
                localtime: res.data[0].PollutantData.location.localtime,
                error: false,
                isHidden: false,
              });
            })
            .catch((err) => {
              MySwal.fire({
                title: <p>Please enter valid location!</p>,
                type: "error",
              });
            });
        });
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    return (
      <div>
        <Search loadweather={this.getWeather} error={this.state.error} />
        {!this.state.isHidden && (
          <Weather
            city={this.state.city}
            country={this.state.country}
            temp_celsius={this.state.celsius}
            temp_max={this.state.temp_max}
            temp_min={this.state.temp_min}
            co={this.state.co}
            no2={this.state.no2}
            o3={this.state.o3}
            pm2_5={this.state.pm2_5}
            pm10={this.state.pm10}
            so2={this.state.so2}
            icon={this.state.icon}
            description={this.state.description}
            humidity={this.state.humidity}
            last_updated={this.state.last_updated}
            pressure_in={this.state.pressure_in}
            uv={this.state.uv}
            wind_dir={this.state.wind_dir}
            wind_kph={this.state.wind_kph}
            wind_mph={this.state.wind_mph}
            country={this.state.country}
            region={this.state.region}
            localtime={this.state.localtime}
          />
        )}
      </div>
    );
  }
}

export default WeatherByCity;
