import React, { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../../environment.js";
import { Button, Form, Modal } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Dropdown } from "react-bootstrap";
import Loader from "../../Components/Loader.js";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const MySwal = withReactContent(Swal);

const ListPartnerDevices = ({ history }) => {
  const [dap_devices, setDevices] = useState([]);
  const [dap_customers, setDapCustomers] = useState([]);
  const [defaultProfileDetails, setDefaultProfileId] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [show, setShow] = useState(false);
  const [deviceData, setDeviceData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (device) => {
    setDeviceData(device);
    console.log(device);
    setShow(true);
  };

  const getDefaultProfileName = async () => {
    await axios
      .get(environment.apiUrl + `deviceProfileInfo/default`, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })

      .then((res) => {
        console.log(res.data.id.id);
        setDefaultProfileId(res.data.id.id);
      })
      .catch(function (error) {
        console.log(error);
        window.alert("something wrong Happened");
      });
  };

  const getAllTenantdevices = async () => {
    let userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    await axios
      .get(
        environment.dap_url +
          `getDevicesByTenantId/` +
          userDetails.data.tenantId.id
      )

      .then((res) => {
        console.log(res.data);
        setDevices(res.data.reverse());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getDefaultProfileName();
    getAllTenantdevices();
    let userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    console.log(userDetails.data.id.id);

    axios
      .get(environment.apiUrl + `customers?pageSize=1000&page=0`, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })

      .then((res) => {
        console.log(res.data.data);
        setDapCustomers(res.data.data);
      })
      .catch(function (error) {
        window.alert(error.message);
      });
  }, []);
  const submitHandler = async (customer, deviceData, customerName) => {
    let responsedata;
    //console.log(deviceData, device, deviceName, deviceLabel);
    console.log(deviceData);
    console.log(defaultProfileDetails);
    console.log(customerName);
    setCustomerName(customerName);
    await axios
      .post(
        environment.apiUrl + "device",
        {
          name: deviceData.deviceName,
          label: deviceData.deviceLabel,
          deviceProfileId: {
            entityType: "DEVICE_PROFILE",
            // id: "4bf465c0-e922-11eb-aeab-6338f3fa1c68",
            id: defaultProfileDetails,
          },
          additionalInfo: {
            gateway: false,
            overwriteActivityTime: false,
            description: deviceData.description,
          },
          // customerId: null,
          // transportType: dap_transport_type,
          type: "DEFAULT",
        },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);

        console.log(deviceData);
        assignDevice(customer, deviceData, res.data);
      })
      .catch((err) => {
        console.log(err, "qwerty");
        console.log(err.status, "qwerty");
        console.log(err.response.data.message, "qwerty");

        // MySwal.fire({
        //   title: <p>{err?.response?.data?.message}</p>,
        //   type: "error",
        // });

        if (
          err?.response?.data?.message ===
          "Device with such name already exists!"
        ) {
          assignDeviceToOtherUser(customer, deviceData);
        } else {
          MySwal.fire({
            title: <p>{err.response.data.message}</p>,
            type: "error",
          });
        }
      });
  };

  const assignDeviceToOtherUser = async (customer, tbDeviceData) => {
    console.log(customer, tbDeviceData);
    await axios
      .post(
        // https://core-ce.diaq.in/api/customer/fac8ab30-2fd4-11ec-803e-45aeaa5f3440/device/34743ae0-51ac-11ec-82d8-1997fbc31aed
        environment.apiUrl +
          "customer/" +
          customer.id +
          "/device/" +
          tbDeviceData.deviceId,
        {},
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )

      .then((res) => {
        axios
          .put(environment.dap_url + "updateWithObjId/" + tbDeviceData._id, {
            deviceOwnerId: customer.id,
          })
          .then((res) => {
            console.log(res);
            getAllTenantdevices();
            MySwal.fire({
              title: <p>Device assigned successfully</p>,
              type: "error",
            });
          })
          .catch((err) => {
            console.log(err);
            MySwal.fire({
              title: <p>{err?.response?.data?.message}</p>,
              type: "error",
            });
          });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          title: <p>{err?.response?.data?.message}</p>,
          type: "error",
        });
      });
  };
  const updatedeviceinMongo = async (
    res,
    c_id,
    deviceData,
    tbDeviceData,
    deviceUserDetails
  ) => {
    await axios
      .put(environment.dap_url + "assignToCustomer/" + deviceData._id, {
        deviceId: tbDeviceData.id.id,
        deviceProfileId: tbDeviceData.deviceProfileId.id,
        tenantId: deviceData.tenantId,
        deviceProfileName: deviceData.deviceProfileName,
        deviceOwnerId: deviceUserDetails.customerId.id,
        accessToken: res.data.credentialsId,
      })
      .then((res) => {
        getAllTenantdevices();
        console.log(res.data.data);
        MySwal.fire({
          title: <p>Device has been assigned successfully</p>,
          type: "success",
        });
      })
      .catch((err) => {
        console.log(err, "zxcvbnm");
        MySwal.fire({
          title: <p>Failed to assign device.</p>,
          type: "error",
        });
      });
  };
  const fetchAccessToken = async (
    c_id,
    deviceData,
    tbDeviceData,
    deviceUserDetails
  ) => {
    await axios
      .get(
        environment.apiUrl + `device/` + tbDeviceData.id.id + `/credentials`,
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        updatedeviceinMongo(
          res,
          c_id,
          deviceData,
          tbDeviceData,
          deviceUserDetails
        );
      })
      .catch(function (error) {
        console.log(error);
        //window.alert("something wrong Happened");
      });
  };
  const assignDevice = (c_id, deviceData, tbDeviceData) => {
    console.log(tbDeviceData.id.id);

    //  in this function after creation of device in tb we will assign it to customer user it selected then we will fetch its accesstoken in credentials api call then we will update the details in mongo
    MySwal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(function (result) {
      if (result.isConfirmed) {
        axios
          .post(
            // https://core-ce.diaq.in/api/customer/fac8ab30-2fd4-11ec-803e-45aeaa5f3440/device/34743ae0-51ac-11ec-82d8-1997fbc31aed
            environment.apiUrl +
              "customer/" +
              c_id.id +
              "/device/" +
              tbDeviceData.id.id,
            {},
            {
              headers: {
                "X-Authorization": "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log(res.data.data);
            let deviceUserDetails = res.data;
            fetchAccessToken(c_id, deviceData, tbDeviceData, deviceUserDetails);
          })
          .catch((err) => {
            MySwal.fire({
              title: (
                <p>
                  {err?.response?.data?.message ||
                    "Device couldn't be assigned"}
                </p>
              ),
              type: "error",
            });
          });

        console.log(deviceData);
        console.log("customer", c_id.id);
        console.log("device", deviceData);
      }
    });
  };

  const updateDeviceLocation = (deviceId) => {
    history.push(`/update-device-location/${deviceId}`);
    console.log(deviceId);
  };

  const columns = [
    // {
    //   dataField: "createdAt",
    //   text: "Created At",
    //   sort: true,
    // },
    {
      dataField: "deviceName",
      text: "Name",
      sort: true,
    },
    {
      dataField: "deviceLabel",
      text: "Label",
      sort: true,
    },
    {
      dataField: "deviceInstalledPlace",
      text: "Installed Place",
      sort: true,
    },
    // {
    //   dataField: "isAssignedToCustomer",
    //   text: "Assigned to Customer?",
    //   sort: true,
    // },
    {
      dataField: `customerDetails[0].userFirstName`,
      text: "Assigned Customer",
      sort: true,
    },
    // {
    //   dataField: `customerDetails[0].userLastName`,
    //   text: "Customer Last Name",
    //   sort: true,
    // },

    {
      deviceId: "deviceId",
      device: "_id",
      deviceName: "deviceName",
      deviceLabel: "deviceLabel",
      text: "Update Device",
      sort: false,
      formatter: (deviceId, device, deviceName, deviceLabel) => {
        return (
          <div>
            <Button
              variant='primary'
              className='btn-sm'
              onClick={() => handleShow(device)}
            >
              Edit Name
            </Button>
          </div>
        );
      },
    },

    {
      dataField: "_id",
      text: "Update Location",
      sort: false,
      formatter: (dataField) => {
        return (
          <div>
            <Button
              variant='primary'
              className='btn-sm'
              onClick={() => updateDeviceLocation(dataField)}
            >
              Update Location
            </Button>
          </div>
        );
      },
    },
    {
      deviceId: "deviceId",
      device: "_id",
      deviceName: "deviceName",
      deviceLabel: "deviceLabel",
      text: "Assign Customer",
      sort: false,
      formatter: (deviceId, device, deviceName, deviceLabel) => {
        return (
          <div>
            <Dropdown>
              <Dropdown.Toggle
                variant='btn btn-primary btn-sm'
                id='dropdownMenuSizeButton3'
              >
                <span>+Assign Customer</span>
              </Dropdown.Toggle>

              <>
                <div>
                  <Dropdown.Menu>
                    {dap_customers.map((dap_customer) => (
                      <Dropdown.Item
                        key={dap_customer.id.id}
                        onClick={() =>
                          submitHandler(
                            dap_customer.id,

                            device,

                            dap_customer.name
                          )
                        }
                      >
                        <i className='icon-user text-primary'></i>
                        {dap_customer.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </div>
              </>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const changeNameHandler = (
    deviceName,
    deviceId,
    _id,
    deviceLabel,
    customerId,
    tenantId,
    profileId
  ) => {
    console.log(deviceName, deviceId, _id);
    axios
      .post(
        // https://core.diaq.in/api/device
        environment.apiUrl + "device",
        {
          id: {
            entityType: "DEVICE",
            id: deviceId,
          },

          tenantId: {
            entityType: "TENANT",
            id: tenantId,
          },
          customerId: {
            entityType: "CUSTOMER",
            id: customerId,
          },
          name: deviceName,
          type: "default",
          label: deviceLabel,
          deviceProfileId: {
            entityType: "DEVICE_PROFILE",
            id: profileId,
          },
        },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        updateDeviceInDB(_id, deviceName);
        //update device name in database
      })
      .catch((err) => {
        MySwal.fire({
          title: <p>{err?.response?.data?.message || "Operation failed"}</p>,
          type: "error",
        });
      });
  };

  const updateDeviceInDB = (id, deviceName) => {
    console.log(id, deviceName);
    axios
      .put(environment.dap_url + "deviceApi/updateDeviceName", {
        deviceId: id,
        deviceName: deviceName,
      })
      .then((res) => {
        MySwal.fire({
          title: <p>Device name saved!</p>,
          type: "success",
        });
        handleClose();
        getAllTenantdevices();
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          title: <p>{err?.response?.data?.message || "Operation failed"}</p>,
          type: "error",
        });
      });
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <>
      <div>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <h4 className='card-title'>DEVICES</h4>
                <div className='row'>
                  <div className='col-12'>
                    <ToolkitProvider
                      keyField='id'
                      bootstrap4
                      data={dap_devices}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className='d-flex align-items-center'>
                            <p className='mb-2 mr-2'>Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses='table-responsive'
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> {deviceData.deviceName} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className='mx-auto' show={show} onHide={handleClose}>
            <Form.Group controlId='AccessToken'>
              <Form.Label> Device Name </Form.Label>
              <Form.Control
                type='text'
                //value={deviceData.accessToken}
                placeholder={deviceData.deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
          </Form>

          <div className='my-2 d-flex justify-content-between align-items-center mx-auto'>
            <div className=' mx-auto'>
              <Button
                type='submit'
                className='btn btn-block btn-primary btn-sm font-weight-medium auth-form-btn'
                onClick={() => {
                  changeNameHandler(
                    deviceName,
                    deviceData.deviceId,
                    deviceData._id,
                    deviceData.deviceLabel,
                    deviceData.deviceOwnerId,
                    deviceData.tenantId,
                    deviceData.deviceProfileId
                  );
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='light btn-sm' onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListPartnerDevices;
