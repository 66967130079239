import React, { useState, useEffect } from "react";
import { environment } from "../../environment";
import axios from "axios";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const MySwal = withReactContent(Swal);

const Partners = () => {
  const [Partners, setPartners] = useState([]);
  const [partnerCount, setPartnerCount] = useState("");
  useEffect(() => {
    axios
      .get(environment.dap_url + "partnerApi/getAll")
      .then((res) => {
        console.log(res);
        setPartners(res.data.reverse());
        setPartnerCount(res.data.length);
        // console.log(res.data.length);
      })
      .catch((err) => {
        MySwal.fire({
          title: "Error in retriving device.",
          icon: "warning",
        });
      });
  }, []);

  const deleteHandler = (id) => {
    console.log(id);
    MySwal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(function (result) {
      if (result.isConfirmed) {
        axios
          .delete(environment.dap_url + "partnerApi/deleteById?_id=" + id)
          .then((res) => {
            // console.log(res.data.data);
            MySwal.fire({
              title: <p>Partner has been successfully deleted!</p>,
              type: "success",
            });
            //history.push("/customers");
          })
          .catch((err) => {
            MySwal.fire({
              title: (
                <p>
                  {err?.response?.data?.message || "Partner deletion failed"}
                </p>
              ),
              type: "error",
            });
          });
      }
    });
  };

  const columns = [
    // {
    //   dataField: "userId",
    //   text: "UserId",
    //   sort: true,
    // },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "userFirstName",
      text: "FirstName",
      sort: true,
    },
    {
      dataField: "userLastName",
      text: "LastName",
      sort: true,
    },
    // {
    //   dataField: "profileImageUrl",
    //   text: "ProfileImgUrl",
    //   sort: true,
    // },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
    },
    // {
    //   dataField: "tenantId",
    //   text: "TenantId",
    //   sort: true,
    // },
    // {
    //   dataField: "customerId",
    //   text: "CustomerId",
    //   sort: true,
    // },
    // {
    //   dataField: "pushNotificationId",
    //   text: "PushNotificationId",
    //   sort: true,
    // },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },

    {
      dataField: "_id",
      text: "Delete",
      sort: false,
      formatter: (dataField) => {
        return (
          <div>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => deleteHandler(dataField)}
            >
              Delete <i className="fa fa-trash-o"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div className="row" style={{ marginTop: "72px" }}>
        <div className="col-lg-12">
          <LinkContainer
            to={`/partner/create`}
            style={{
              float: "right",
              //paddingTop: "18px",
              marginBottom: "20px",
            }}
          >
            <Button variant="light" className="btn-lg">
              Add Partners
            </Button>
          </LinkContainer>
        </div>
      </div>
      {/* <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Tenants</h4>

              <div className='table-responsive'>
                <table className='table table-striped table-bordered'>
                  <thead>
                    <tr>
                      <th>Created Time</th>
                      <th>Title</th>
                      <th>Tenant Profile</th>
                      <th>Email</th>
                      <th>Country</th>
                      <th>City</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenants.map((tenant) => (
                      <tr key={tenant.id.id}>
                        <td>
                          {new Date(tenant.createdTime).getDate() +
                            "/" +
                            (new Date(tenant.createdTime).getMonth() + 1) +
                            "/" +
                            new Date(tenant.createdTime).getFullYear() +
                            " " +
                            new Date(tenant.createdTime).getHours() +
                            ":" +
                            new Date(tenant.createdTime).getMinutes()}{" "}
                          {new Date(tenant.createdTime).getHours() >= 12
                            ? "PM"
                            : "AM"}
                        </td>
                        <td>{tenant.title}</td>
                        <td>{tenant.tenantProfileName}</td>
                        <td>{tenant.email}</td>
                        <td>{tenant.country}</td>
                        <td>{tenant.city}</td>
                        <td>
                          <Button
                            variant='danger'
                            className='btn-sm'
                            onClick={() => deleteHandler(tenant.id.id)}
                          >
                            delete <i className='fa fa-trash-o'></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Partners</h4>
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={Partners}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h5 style={{ marginLeft: "5%" }}>
        Total number of Partners: {partnerCount}
      </h5>
    </div>
  );
};

export default Partners;
