import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { environment } from "../../environment";
import { Form, Button } from "react-bootstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const EditUserProfile = ({ history }) => {
  let dap_resImageUrl;
  // var dap_storedDetails;
  const [dap_address, setAddress] = useState("");
  const [dap_mobile, setMobile] = useState("");
  const [dap_description, setDescription] = useState("");
  const [dap_email, setEmail] = useState("");
  let userDetails;
  const [dap_profilePicUrl, setProfilePicUrl] = useState("");
  const [dap_userName, setUserDetails] = useState({ name: "", email: "" });
  //var fullName = "";
  const [selectedFile, setSelectedFile] = useState("");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    getUserDetails();
  }, []);
  const getUserDetails = async () => {
    // e.preventDefault()

    console.log(userDetails);
    await axios
      .get(environment.dap_url + "users/" + userDetails.data.id.id)
      .then((res) => {
        console.log(res);
        setEmail(res.data.email);
        setAddress(res.data.address);
        setMobile(res.data.phone);
        let profile_url = res.data.profileImageUrl;
        if (res.data.profileImageUrl) {
          setProfilePicUrl(environment.dap_imageUrl + profile_url);
        } else {
          setProfilePicUrl("../../assets/images/useravatar.png");
        }
        //setProfilePicUrl(res.data.profileImageUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeHandler = async (event) => {
    console.log(event.target.files[0].name);
    await setSelectedFile(event.target.files[0]);
    await setTimeout(function () {
      console.log(selectedFile);
    }, 9000);
  };
  // const handleSubmission = async () => {
  //   const formData = new FormData();

  //   formData.append("userPhoto", selectedFile);
  //   await axios
  //     .post(environment.dap_api_url + "fileUploadAPI/uploadFile", formData)
  //     .then((res) => {
  //       console.log(res.data.success);
  //       dap_resImageUrl = res.data.success;
  //       console.log(dap_resImageUrl);
  //       //handleUpdate();
  //       MySwal.fire({
  //         title: <p>profile updated successfully!</p>,
  //         type: "success",
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       MySwal.fire({
  //         title: <p>Profile couldn't be updated!</p>,
  //         type: "error",
  //       });
  //     });
  // };
  const handleUpdate = (e) => {
    e.preventDefault();
    console.log(dap_resImageUrl);
    userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    console.log(userDetails);
    let dap_objToUpdate = {
      address: dap_address,
      phone: dap_mobile,
    };
    axios
      .put(
        environment.dap_url + "editUser/" + userDetails.data.id.id,
        dap_objToUpdate
      )
      .then((res) => {
        console.log(res);
        //getUserDetails();
        // setShow(true);
        // setMessage("profile updated Successfully");
        MySwal.fire({
          title: <p>Profile updated successfully!</p>,
          type: "success",
        });
        setAddress("");
        setMobile("");
      })
      .catch((err) => {
        // setShow(true);
        // setMessage("profile couldn't be updated");
        MySwal.fire({
          title: <p>Profile couldn't be updated!</p>,
          type: "error",
        });
      });
  };
  return (
    <div>
      <div className='row'>
        <div className='col-lg-3'></div>
        <div className='col-6'>
          <div className='card'>
            <div className='card-body'>
              <div className='border-bottom  pb-4'>
                <div className='text-center'>
                  <img
                    src={dap_profilePicUrl}
                    alt='profile'
                    className='img-lg rounded-circle mb-3'
                  />
                  <div className='mb-3'>
                    {/* <div className="d-flex  justify-content-center">
                      <input onChange={changeHandler} type="file" />
                    </div> */}
                    {/* <button
                      className="btn btn-primary"
                      onClick={handleSubmission}
                      style={{ marginTop: "10px" }}
                    >
                      Add Image
                    </button> */}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 grid-margin stretch-card'>
                    <div className='card'>
                      <div className='card-body'>
                        <Form onSubmit={handleUpdate}>
                          <Form.Group controlId='title'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type='email'
                              placeholder='Enter Email'
                              disabled
                              value={dap_email}
                              onChange={(e) => setEmail(e.target.value)}
                            ></Form.Control>
                          </Form.Group>

                          <Form.Group controlId='address'>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Address'
                              value={dap_address}
                              onChange={(e) => setAddress(e.target.value)}
                            ></Form.Control>
                          </Form.Group>

                          <Form.Group controlId='mobile'>
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter Mobile No.'
                              value={dap_mobile}
                              onChange={(e) => setMobile(e.target.value)}
                            ></Form.Control>
                          </Form.Group>

                          <div className='mt-3'>
                            <Button
                              type='submit'
                              className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
                            >
                              Update
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3'></div>
      </div>
    </div>
  );
  // }
};

export default EditUserProfile;
