import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { Trans } from "react-i18next";
import { Button } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../../environment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { LinkContainer } from "react-router-bootstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const TenantDashboard = () => {
  const [notification, setNotification] = useState([]);
  const [shipments, setShipments] = useState([]);

  let partnerDetails
  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];
  //   alertArray = JSON.parse(localStorage.getItem("alertArray"));
  //   console.log(alertArray);
  //setNotification({ notification: alertArray });

  useEffect(() => {
    partnerDetails = JSON.parse(localStorage.getItem("partnerDetails"));
    console.log(partnerDetails, "aaaaaaaaaaaaaaaaaaa");
    // setInterval(sayHi, 5000);
    // getAlerts();
    getShipments(partnerDetails)
  }, []);

  const getShipments = (partnerDetails) => {
    console.log(partnerDetails, "aaaaaaaaaaaaaaaaaaa");

    axios
      .get(
        environment.dap_url +
        "shipmentApi/getByConsigneeIdWithLogs?consigneeId=" + partnerDetails._id

      )
      .then((res) => {
        //setNotification(alertArray);
        console.log(res.data, "1234466");
        setShipments(res.data.shipment)

      })
      .catch((err) => {

      });
  };
 
  const columns = [

    {
      dataField: "consignordetails[0].email",
      text: "Consigner Email",
      sort: true,
    },
    {
      dataField: "consignordetails[0].userFirstName",
      text: "Consigner First Name",
      sort: true,
    },

    {
      dataField: "consignordetails[0].userLastName",
      text: "Consigner Last Name",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
    },
    {
      dataField: "deviceList.count",
      text: "Created On",
      sort: true,
    },
    {
      dataField: "_id",
      text: "View",
      sort: false,
      formatter: (dataField) => {
        return (
          <div>
            <LinkContainer
              to={'shipmentDetails/' + dataField}
              style={{
                float: "right",
              }}
            >
              <Button variant='light' className='btn-sm my-3'>
                view
              </Button>
            </LinkContainer>
          </div>
        );
      },
    },
    // {
    //   dataField: "isAssignedToCustomer",
    //   text: "Assigned to Customer?",
    //   sort: true,
    // },
    // {
    //   dataField: `customerDetails[0].userFirstName`,
    //   text: "Assigned Customer",
    //   sort: true,
    // },
    // {
    //   dataField: `customerDetails[0].userLastName`,
    //   text: "Customer Last Name",
    //   sort: true,
    // },



    // {
    //   dataField: "_id",
    //   text: "Update Location",
    //   sort: false,

    // },
    // {
    //   deviceId: "deviceId",
    //   device: "_id",
    //   deviceName: "deviceName",
    //   deviceLabel: "deviceLabel",
    //   text: "Assign Customer",
    //   sort: false,

    // },
  ];
  return (
    <>
      <div className="row">
        <div className="col-12">
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={shipments}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <div className="d-flex align-items-center">
                  <p className="mb-2 mr-2">Search in table:</p>
                  <SearchBar {...props.searchProps} />
                </div>
                <BootstrapTable
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                  {...props.baseProps}
                  wrapperClasses="table-responsive"
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </>
  );
};

export default TenantDashboard;