import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
//import { createDevice } from "../services/createDevice";
import Editable from "react-bootstrap-editable";
import axios from "axios";
import { environment } from "../../environment";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

const MySwal = withReactContent(Swal);

const AddPartner = ({ history }) => {
  const [dap_title, setTitle] = useState("");
  const [dap_firstName, setFirstName] = useState("");
  const [dap_lastName, setLastName] = useState("");
  const [dap_address, setAddress] = useState("");
  const [dap_address2, setAddress2] = useState("");
  const [dap_email, setEmail] = useState("");
  const [dap_phone, setPhone] = useState("");
  const [dap_tenantId, setTenantId] = useState("");
  const [dap_city, setDapCity] = useState([]);
  const [dap_countries, setDapCountries] = useState("");
  const [dap_states, setDapState] = useState([]);
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [region, setRegion] = useState("");
  const [countrycode, setCountryCode] = useState("");
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [postcountry, setPostCountry] = useState("");
  const [poststate, setPostState] = useState("");
  const [postcity, setPostCity] = useState("");
  const [emailErrors, setEmailErrors] = useState({});
  const [nameErrors, setNameErrors] = useState({});
  const [phoneErrors, setPhoneErrors] = useState({});
  const [titleErrors, setTitleErrors] = useState({});

  let countries = [];
  let states = [];
  countries = Country.getAllCountries();
  states = State.getAllStates();
  const selectCountry = (e) => {
    setDapCountries(Country.getAllCountries());
    setDapState(State.getStatesOfCountry(e.id));
    setCountryCode(e.id);
    setPostCountry(e.label);
  };

  const selectRegion = (e) => {
    setDapCity(City.getCitiesOfState(countrycode, e.id));
    setPostState(e.label);
  };
  const selectCity = (e) => {
    setPostCity(e.label);
  };

  console.log(Country.getAllCountries());
  console.log(Country.getAllCountries());
  console.log(State.getAllStates());
  // console.log(City.getCitiesOfState());

  const formValidation = () => {
    const emailErrors = {};
    const nameErrors = {};
    const phoneErrors = {};
    const titleErrors = {};
    let isValid = true;

    if (!dap_title) {
      titleErrors.noTitle = "* Title is required";
      isValid = false;
    }

    if (!dap_email) {
      emailErrors.noEmail = "* Email Id required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(dap_email)) {
      emailErrors.invalidEmail = "* Email address is invalid";
      isValid = false;
    }
    if (!dap_firstName) {
      nameErrors.noName = "* First Name is required";
      isValid = false;
    } else if (dap_firstName.trim().length < 2) {
      nameErrors.shortName = "* First Name is too short";
      isValid = false;
    }
    if (!dap_phone) {
      phoneErrors.shortNumber = "* Phone number required";
      isValid = false;
    } else if (dap_phone.trim().length < 10) {
      phoneErrors.shortNumber = "* Phone number should be 10 digits";
      isValid = false;
    } else if (dap_phone.trim().length > 10) {
      phoneErrors.shortNumber = "* Phone number should be 10 digits";
      isValid = false;
    } else if (
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,1024}$/.test(
        dap_phone
      )
    ) {
      phoneErrors.shortNumber = "Phone number should be 10 digits";
      isValid = false;
    }

    setTitleErrors(titleErrors);
    setEmailErrors(emailErrors);

    setNameErrors(nameErrors);

    setPhoneErrors(phoneErrors);
    return isValid;
  };

  const submitHandler = (e) => {
    const isValid = formValidation();

    let userDetails = "";
    userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);

    e.preventDefault();

    if (isValid) {
      setLoading(true);
      axios
        .post(
          environment.apiUrl + "tenant",
          {
            title: dap_title,
            country: postcountry,
            state: poststate,
            city: postcity,
            address: dap_address,
            address2: dap_address2,
            zip: zip,
            phone: dap_phone,
            email: dap_email,
            additionalInfo: {},
          },
          {
            headers: {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data.id.id);

          axios
            .post(
              environment.apiUrl + "user?sendActivationMail=true",
              {
                email: dap_email,
                firstName: dap_firstName,
                lastName: dap_lastName,
                additionalInfo: {
                  description: "",
                  defaultDashboardId: null,
                  defaultDashboardFullscreen: false,
                  homeDashboardId: null,
                  homeDashboardHideToolbar: true,
                },
                authority: "TENANT_ADMIN",
                tenantId: {
                  entityType: "TENANT",
                  id: res.data.id.id,
                },
                customerId: {
                  entityType: "CUSTOMER",
                  id: userDetails.data.customerId.id,
                },
              },
              {
                headers: {
                  "X-Authorization": "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              // MySwal.fire({
              //   title: <p>Tenant has been successfully created!</p>,
              //   type: "success",
              // });
              createPartnerMongo(res.data);
            })
            .catch((err) => {
              setLoading(false);
              MySwal.fire({
                title: (
                  <p>
                    {err?.response?.data?.message || "Tenant creation failed"}
                  </p>
                ),
                type: "error",
              });
            });
        })
        .catch((err) => {
          // history.push(`/tenants`);
          setLoading(false);
          MySwal.fire({
            title: (
              <p>{err?.response?.data?.message || "Tenant creation failed"}</p>
            ),
            type: "error",
          });
        });
    }
  };

  const createPartnerMongo = (data) => {
    axios.post(environment.dap_url + "partnerApi/createPartner", {
      email: data.email,
      userId: data.id.id,
      userFirstName: data.firstName,
      profileImageUrl: "",
      userLastName: data.lastName,
      city: postcity,
      state: poststate,
      country: postcountry,
      address: dap_address,
      phone: dap_phone,
      tenantId: data.tenantId.id,
      customerId: data.customerId.id,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-3"></div>
        <Link to="/tenants" className="btn btn-light my-3">
          Go Back
        </Link>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-md-6  grid-margin stretch-card">
            <div className="card">
              <h1 className="text-center">ADD PARTNER</h1>
              <div className="card-body">
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId="name">
                    <Form.Label>* Title</Form.Label>
                    <Form.Control
                      type="title"
                      placeholder="Enter Title"
                      value={dap_title}
                      onChange={(e) => setTitle(e.target.value)}
                    ></Form.Control>
                    <div>
                      {Object.keys(titleErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>{titleErrors[key]}</div>
                        );
                      })}
                    </div>
                  </Form.Group>

                  <div className="row">
                    <div className="col-6">
                      <Form.Group controlId="FirstName">
                        <Form.Label>* First Name</Form.Label>
                        <Form.Control
                          type="firstname"
                          placeholder="Enter First Name"
                          value={dap_firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        ></Form.Control>
                        <div>
                          {Object.keys(nameErrors).map((key) => {
                            return (
                              <div style={{ color: "red" }}>
                                {nameErrors[key]}
                              </div>
                            );
                          })}
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-6">
                      <Form.Group controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="lastName"
                          placeholder="Enter Last Name"
                          value={dap_lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group controlId="name">
                    <Form.Label>* Email</Form.Label>
                    <Form.Control
                      type=""
                      placeholder="Enter Email"
                      value={dap_email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                    <div>
                      {Object.keys(emailErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>{emailErrors[key]}</div>
                        );
                      })}
                    </div>
                  </Form.Group>

                  <Form.Group controlId="name">
                    <Form.Label>* Phone</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone "
                      value={dap_phone}
                      onChange={(e) => setPhone(e.target.value)}
                    ></Form.Control>
                    <div>
                      {Object.keys(phoneErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>{phoneErrors[key]}</div>
                        );
                      })}
                    </div>
                  </Form.Group>

                  <div className="row">
                    <div className="col-4">
                      <Form.Group controlId="name">
                        <Form.Label>Country</Form.Label>
                        <Select
                          options={countries.map((element) => ({
                            id: element.isoCode,
                            value: element.name,
                            label: element.name,
                          }))}
                          onChange={(e) => selectCountry(e)}
                          // defaultValue={dap_deviceList[0].name}
                          defaultValue="hi there"
                          placeholder="Country"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group controlId="name">
                        <Form.Label>State</Form.Label>
                        <Select
                          options={dap_states.map((element) => ({
                            id: element.isoCode,
                            value: element.name,
                            label: element.name,
                          }))}
                          onChange={(e) => selectRegion(e)}
                          // defaultValue={dap_deviceList[0].name}
                          defaultValue="hi there"
                          placeholder="State"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-4">
                      <Form.Group controlId="name">
                        <Form.Label>City</Form.Label>
                        <Select
                          options={dap_city.map((element) => ({
                            id: element.isoCode,
                            value: element.name,
                            label: element.name,
                          }))}
                          onChange={(e) => selectCity(e)}
                          // defaultValue={dap_deviceList[0].name}
                          defaultValue="hi there"
                          placeholder="State"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <Form.Group controlId="address">
                        <Form.Label>Address Line 1</Form.Label>
                        <Form.Control
                          type="address"
                          placeholder="Enter address"
                          value={dap_address}
                          onChange={(e) => setAddress(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </div>

                    <div className="col-6">
                      <Form.Group controlId="address">
                        <Form.Label>Address Line 2</Form.Label>
                        <Form.Control
                          type="address"
                          placeholder="Enter address"
                          value={dap_address2}
                          onChange={(e) => setAddress2(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group controlId="zip">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control
                      type="zip"
                      placeholder="Enter postal code"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <div className="mt-3">
                    <Button
                      type="submit"
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      disabled={loading}
                    >
                      ADD Partner
                      {loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span></span>}
                      {!loading && <span></span>}
                    </Button>
                  </div>
                </Form>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPartner;
