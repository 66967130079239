import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { logout } from "../services/logout";
import { locationService } from "../services/locationChangeService";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { environment } from "../../environment";

import "../Screens/dashboard.css";
//import WeatherByCity from "./WeatherByCity";

const MySwal = withReactContent(Swal);

const languages = [
  {
    code: "jp",
    name: "日本",
    country_code: "jp",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];

const currentLanguageCode = localStorage.getItem("i18nextLng");

class Navbar extends Component {
  constructor() {
    super();
    this.state = { show: false };
    this.state = { notification: [] };

    this.sayHi = this.sayHi.bind(this);
    //this.t = useTranslation();
    this.user_name = "";
    this.authority = "";
    this.lat = 0;
    this.lon = 0;
    this.location = "";
    this.getDeviceCoordinate = this.getDeviceCoordinate.bind(this);
    //this.changeLang = this.changeLang.bind(this);
    //this.getTime = this.getTime.bind(this)
    //const { t } = useTranslation();
  }

  componentDidMount() {
    const currentLanguageCode = localStorage.getItem("i18next");

    // const { t } = useTranslation();

    let userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    let user_name = userDetails.data.firstName.charAt(0).toUpperCase()+userDetails.data.firstName.slice(1);
    let user_authority = userDetails.data.authority;
    console.log(userDetails);
    console.log(user_name);
    console.log(user_authority);
    this.setState({ user_name: user_name, authority: user_authority });
    if (user_authority == "CUSTOMER_USER") {
      //setInterval(this.sayHi, 5000);
      this.getAlerts();
    }

    this.getAlerts();

    if (user_authority === "CUSTOMER_USER") {
      setInterval(this.getDeviceCoordinate, 50000);
    }
  }

  getAlerts() {
    axios
      .get(
        environment.apiUrl +
          "alarms?endTime=" +
          Date.now() +
          "&page=1&pageSize=5&startTime=" +
          //(Date.now() - 10 * 60 * 60 * 5000),
          1641016740000,
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        //setNotification(alertArray);
        console.log(res.data.data);
        this.setState({ notification: res?.data?.data });
        //setNotification(res?.data?.data);
      })
      .catch((err) => {});
  }

  hideAlert(id) {
    console.log(id);

    axios
      .post(
        environment.apiUrl + "alarm/" + id,
        {},
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        //setNotification(alertArray);
        console.log(res.data);
        this.getAlerts();
      })
      .catch((err) => {});
  }

  getDeviceCoordinate() {
    let deviceCoordinate = JSON.parse(
      localStorage.getItem("latestTelemetryData")
    );
    if (deviceCoordinate) {
      let lat = deviceCoordinate.lat[0].value;
      let lon = deviceCoordinate.lon[0].value;
      console.log(deviceCoordinate);
      console.log("lat: ", lat);
      console.log("lon: ", lon);
      axios
        .get(
          // "https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=20.3492624&longitude=85.8078196&localityLanguage=en"
          "https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=" +
            lat +
            "&longitude=" +
            lon +
            "&localityLanguage=en"
        )
        .then((res) => {
          console.log(res);
          console.log(res.data.countryName);
          if (res.data) {
            let locality = res.data.localityInfo.administrative[4].name;
            this.setState({ location: locality });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  sayHi() {
    console.log("getting alert array");
    let alertArray = [];

    alertArray = JSON.parse(localStorage.getItem("alertArray"));
    //  console.log(alertArray);
    // if (alertArray) {
    //   this.setState({ notification: alertArray });
    // } else {
    //   console.log("can not get alert array");
    // }
  }
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  logoutHandler() {
    MySwal.fire({
      title: "Are you sure?",
      icon: "warning",
      text: "You have to login again once you logout!",
      showCancelButton: true,
      confirmButtonText: "Yes",
      //cancelButtonColor: "btn-light",
      //className: "Custom_Cancel",
    }).then(function (result) {
      if (result.isConfirmed) {
        logout();
      }
    });
    //logout()
  }

  changeLang(e, code) {
    e.preventDefault();
    i18next.changeLanguage(code);
  }

  render() {
    const { t } = this.props;
    //const { t } = useTranslation();
    return (
      <nav className='navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row'>
        <div
          className='text-center navbar-brand-wrapper d-flex align-items-center justify-content-center'
          style={{ background: "#4159CA" }}
        >
          <Link className='navbar-brand brand-logo' to='/dashboard'>
            {/* <img src={require("../../assets/images/logo.svg")} alt="logo" /> */}
            <h1 style={{ color: "white" }}></h1>
          </Link>
          <Link className='navbar-brand brand-logo-mini' to='/dashboard'>
            <img
              src='/assets/images/DAP_logo1.png'
              alt='logo'
              style={{ marginLeft: "15%" }}
            />
          </Link>
        </div>
        <div
          className='navbar-menu-wrapper d-flex align-items-stretch'
          style={{ background: "#ffff" }}
        >
          <button
            className='navbar-toggler navbar-toggler align-self-center'
            type='button'
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <span className='mdi mdi-menu'></span>
          </button>
          <ul className='navbar-nav navbar-nav-left'>
            <li className='nav-item d-none d-lg-flex'>
              <h3 style={{ fontFamily: "Verdana" }}>
                {t("welcome_message")}
                <span
                  style={{
                    color: "#3358d4",
                    fontFamily: "sans-serif",
                  }}
                >
                  {" "}
                  {this.state.user_name}
                </span>
              </h3>
            </li>
            {/* <h1 className="font-weight-normal mb-3">{t("welcome_message")}</h1> */}
          </ul>

          <ul className='navbar-nav navbar-nav-right'>
            {this.state.authority === "CUSTOMER_USER" ? (
              <li className='nav-item d-none d-lg-flex'>
                {" "}
                <p>
                  Device Location:{" "}
                  <span
                    style={{
                      color: "#3358d4",
                      fontFamily: "Times New Roman",
                    }}
                  >
                    {this.state.location}
                  </span>
                </p>
              </li>
            ) : (
              <li></li>
            )}
            <li className='nav-item d-none d-lg-flex'>
              <Dropdown>
                <Dropdown.Toggle variant='transparent' className='nav-link'>
                  <i className='fa fa-globe'></i>
                  Lang
                </Dropdown.Toggle>

                <Dropdown.Menu className='navbar-dropdown preview-list'>
                  {languages.map(({ code, name, country_code }) => (
                    <Dropdown.Item
                      key={country_code}
                      href='!#'
                      onClick={(e) => this.changeLang(e, code)}
                    >
                      <span
                        className={`flag-icon flag-icon-${country_code} mx-2`}
                        style={{
                          opacity: currentLanguageCode === code ? 0.5 : 1,
                        }}
                      ></span>
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className='nav-item'>
              <Dropdown>
                <Dropdown.Toggle
                  variant='transparent'
                  className='nav-link count-indicator'
                >
                  <i className='icon-bell mx-0'></i>
                  <span className='count'></span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='navbar-dropdown preview-list'>
                  {this.state.notification.length > 0 ? (
                    <>
                      <Dropdown.Item
                        href='!#'
                        onClick={(evt) => evt.preventDefault()}
                      >
                        <p className='mb-0 font-weight-normal float-left'>
                          <Trans>You have 4 new notifications</Trans>
                        </p>
                        <Link to='/alerts'>
                          <span className='badge badge-pill badge-warning float-right'>
                            View all
                          </span>
                        </Link>
                      </Dropdown.Item>
                    </>
                  ) : (
                    <>
                      <Dropdown.Item
                        href='!#'
                        onClick={(evt) => evt.preventDefault()}
                      >
                        <p className='mb-0 font-weight-normal float-left'>
                          <span>
                            <i
                              className='mdi mdi-comment-text-outline'
                              style={{ marginLeft: "7px" }}
                            ></i>
                          </span>
                          <Trans>You don't have any alerts!</Trans>
                        </p>
                      </Dropdown.Item>
                    </>
                  )}

                  {this.state.notification.length > 0 ? (
                    this.state.notification.map((i) => (
                      <Dropdown.Item
                        className='preview-item'
                        href='!#'
                        onClick={(evt) => evt.preventDefault()}
                        style={{
                          border: "1px solid #b87f7f3b",
                          height: "50px",
                        }}
                        key={i}
                      >
                        <div className='preview-thumbnail'>
                          <div className='preview-icon  bg-primary'>
                            <i
                              className='fa fa-bell fa-2x '
                              style={{ marginLeft: "7px" }}
                            ></i>
                          </div>
                        </div>

                        <p
                          className='preview-subject font-weight-medium text-dark'
                          style={{ marginLeft: "10px" }}
                        >
                          {i.name}

                          <button
                            type='button'
                            className='close'
                            data-dismiss='alert'
                            aria-label='Close'
                            onClick={() => this.hideAlert(i.id.id)}
                            //style={{ float: "right", marginLeft: "100px" }}
                            style={{
                              position: "absolute",
                              right: "32px",
                              opacity: "0.3",
                            }}
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </p>
                      </Dropdown.Item>
                    ))
                  ) : (
                    <p></p>
                  )}

                  {/* {() => {
                    if (this.state.notification.length > 0) {
                      // eslint-disable-next-line no-lone-blocks
                      {
                        this.state.notification.map((i) => (
                          <Dropdown.Item
                            className='preview-item alert alert-danger'
                            href='!#'
                            onClick={(evt) => evt.preventDefault()}
                            style={{ border: '0px', height: '50px' }}
                            key={i}
                          >
                            <div className='preview-thumbnail'>
                              <div className='preview-icon  bg-primary'>
                                <i
                                  className='mdi mdi-bell-ring'
                                  style={{ marginLeft: '7px' }}
                                ></i>
                              </div>
                            </div>
                            <div className='preview-item-content'>
                              <p className='preview-subject font-weight-medium text-dark'>
                                {i.name} value increased to {i.value} at time{' '}
                                {i.ts}
                              </p>
                            </div>
                          </Dropdown.Item>
                        ))
                      }
                    }
                  }} */}

                  {/* <Dropdown.Item
                    className='preview-item alert alert-light'
                    href='!#'
                    onClick={(evt) => evt.preventDefault()}
                    style={{ border: "0px", height: "50px" }}
                  >
                    <div className='preview-thumbnail '>
                      <div className='preview-icon bg-primary'>
                        <i
                          className='mdi mdi-comment-text-outline'
                          style={{ marginLeft: "7px" }}
                        ></i>
                      </div>
                    </div>
                    <div className='preview-item-content '>
                      <p className='preview-subject font-weight-medium text-dark'>
                        Device Hera Co2 level is High.
                      </p>
                    </div>
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className='nav-item'>
              <Dropdown>
                <Dropdown.Toggle
                  variant='transparent'
                  className='nav-link count-indicator'
                >
                  <i className='fa fa-envelope-o fa-2x'></i>
                  <span className='count'></span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='navbar-dropdown preview-list'>
                  <Dropdown.Item
                    href='!#'
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <p className='mb-0 font-weight-normal float-left'>
                      <Trans>You have 7 unread messages</Trans>
                    </p>
                    <Link to='/messages'>
                      <span className='badge badge-info badge-pill float-right'>
                        View all
                      </span>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='preview-item'
                    href='#/messages'
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className='preview-thumbnail'>
                      <img
                        src='https://via.placeholder.com/36'
                        alt='prfile-pic'
                        className='profile-pic'
                      />
                    </div>
                    <div className='preview-item-content flex-grow'>
                      <h6 className='preview-subject ellipsis font-weight-medium'>
                        DAP Support
                        <span className='float-right font-weight-light small-text'>
                          1 Minutes ago
                        </span>
                      </h6>
                      <p className='font-weight-light small-text'>
                        Recent Announcemnts from our Team!
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='preview-item'
                    onClick={(evt) => evt.preventDefault()}
                    href='/messages'
                  >
                    <div className='preview-thumbnail'>
                      <img
                        src='https://via.placeholder.com/36'
                        alt='prfile-pic'
                        className='profile-pic'
                      />
                    </div>
                    <div className='preview-item-content flex-grow'>
                      <h6 className='preview-subject ellipsis font-weight-medium'>
                        DAP Support
                        <span className='float-right font-weight-light small-text'>
                          15 Minutes ago
                        </span>
                      </h6>
                      <p className='font-weight-light small-text'>
                        Experience healthier breathing with our{" "}
                      </p>
                      <p className='font-weight-light small-text'>
                        {" "}
                        latest Features.
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='preview-item'
                    href='/messages'
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className='preview-thumbnail'>
                      <img
                        src='https://via.placeholder.com/36'
                        alt='prfile-pic'
                        className='profile-pic'
                      />
                    </div>
                    <div className='preview-item-content flex-grow'>
                      <h6 className='preview-subject ellipsis font-weight-medium'>
                        {" "}
                        DAP Support
                        <span className='float-right font-weight-light small-text'>
                          18 Minutes ago
                        </span>
                      </h6>
                      <p className='font-weight-light small-text'>
                        Confirm your account.
                      </p>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li className='nav-item'>
              <div
                className='nav-link border-0'
                onClick={this.logoutHandler}
                //onClick={() => this.setState({ show: true })}
                type='button'
                style={{ marginTop: "6px" }}
              >
                {/* <SweetAlert
                  show={this.state.show}
                  confirmBtnText='Ok'
                  cancelBtnText='Cancel'
                  showCancel
                  confirmBtnBsStyle='primary'
                  cancelBtnBsStyle='danger'
                  style={{ color: 'black' }}
                  title='Are you sure?'
                  onConfirm={this.logoutHandler}
                  onCancel={() => this.setState({ show: false })}
                >
                  You have to login again once you logout.
                </SweetAlert> */}
                <i className='icon-logout mx-0'></i>
              </div>
            </li>
          </ul>
          <button
            className='navbar-toggler navbar-toggler-right d-lg-none align-self-center'
            type='button'
            onClick={this.toggleOffcanvas}
          >
            <span className='mdi mdi-menu'></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default withTranslation()(Navbar);
