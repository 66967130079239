import React, { useState, useRef, useEffect } from "react";
import useSwr from "swr";
import GoogleMapReact from "google-map-react";
import useSuperCluster from "use-supercluster";
import { InfoWindow } from "react-google-maps";
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const Marker = ({ children }) => children;

const MasterMap = () => {
  //set map
  const mapRef = useRef();
  const [zoom, setZoom] = useState(10);
  const [bounds, setBounds] = useState(null);
  const [deviceCoordinates, setDeviceCoordinates] = useState([]);
  // load and format map
  // const url =
  // const {} = useSwr(url);

  useEffect(() => {
    let coordinates = [];
    coordinates = localStorage.getItem("deviceCoordinates");
    coordinates = JSON.parse(coordinates);
    console.log(coordinates);
    console.log(coordinates[1]);
    setDeviceCoordinates(coordinates);

    for (let i = 0; i < coordinates.length; i++) {
      for (let j = 1; j < coordinates.length; j++) {
        // console.log(
        calcCrow(
          coordinates[i].lat,
          coordinates[i].lon,
          coordinates[i].deviceName,
          coordinates[j].lat,
          coordinates[j].lon,
          coordinates[j].deviceName
        );
        // );
      }
    }
  }, []);

  const points = deviceCoordinates.map((device) => ({
    type: "Feature",
    properties: {
      cluster: false,
      deviceId: device.deviceId,
      category: "hospital",
    },
    geometry: {
      type: "Point",
      coordinates: [parseFloat(device.lon), parseFloat(device.lat)],
    },
  }));

  // get clusters
  const { clusters, supercluster } = useSuperCluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  console.log(clusters);

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  function calcCrow(lat1, lon1, device1, lat2, lon2, device2) {
    // var i=0,j=0;
    // var cluster[i][j];
    var cluster = [{}];

    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = (R * c).toFixed(1);

    var f = [[]],
      j = 0,
      i = 0;
    if (d > 4000) {
      cluster.push({
        lat: lat1,
        lon: lon1,
        d: d,
        device1: device1,
        device2: device2,
      });
      console.log(cluster);
    }

    return d;
  }

  // Converts numeric degrees to radians
  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  // render map

  return (
    <>
      <div style={{ height: "40vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDpEqauXWZEF99qOZuKNkZKmQwJJ01s2l4" }}
          defaultCenter={{ lat: 20.414, lng: 85.456 }}
          defaultZoom={6}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}
        >
          {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className='cluster-marker'
                    style={{
                      width: `${10 + (pointCount / points.length) * 20}px`,
                      height: `${10 + (pointCount / points.length) * 20}px`,
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <Marker
                key={cluster.properties.deviceId}
                lat={latitude}
                lng={longitude}
              >
                <div>
                  {" "}
                  <img
                    src='/assets/images/dap_location.png'
                    alt='device'
                  />{" "}
                </div>
              </Marker>
            );
          })}
          {deviceCoordinates.map((device) => (
            <Marker key={device.deviceId} lat={device.lat} lng={device.lon}>
              <div>
                {" "}
                <img src='/assets/images/dap_location.png' alt='device' />{" "}
                {/* <div style={{ backgroundColor: "white", color: "black" }}>
                  {" "}
                  {device.deviceName}
                </div> */}
                <div
                  style={{
                    backgroundColor: `yellow`,
                    opacity: 0.75,
                    padding: "50px",
                  }}
                >
                  <div id='map-canvas'>{device.deviceName}</div>
                </div>
              </div>
            </Marker>
          ))}
        </GoogleMapReact>
      </div>
    </>
  );
};

export default MasterMap;
