import React from "react";
import cogoToast from "cogo-toast";
import Alert from "react-bootstrap/Alert";
import { Trans } from "react-i18next";

const Messages = () => {
  return (
    <>
      <div className='row'>
        <div className='col-3'></div>
        <div className='col-md-6 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body' style={{ paddingTop: "7%" }}>
              <h4 className='card-title'>
                <Trans>Message Hub</Trans>
              </h4>
              <p className='card-description'></p>
              <Alert variant='info'>
                <p>
                  <Trans>
                    Experience healthier breathing with our latest Features.
                  </Trans>
                </p>
              </Alert>

              <Alert variant='info'>
                <p>
                  <Trans>
                    Move to a fully controlled healthy environment by
                    subscribing to our premium membership.
                  </Trans>
                </p>
              </Alert>
              <Alert variant='info'>
                <p>
                  <Trans>Please make sure the device is online.</Trans>
                </p>
              </Alert>
              <Alert variant='info'>
                <p>
                  <Trans>Your Account has been activated.</Trans>
                </p>
              </Alert>
              <Alert variant='info'>
                <p className='mb-0'>
                  <Trans>Confirm your account.</Trans>
                </p>
              </Alert>
              <Alert variant='info'>
                <p>
                  <Trans>You have been successfully registered to DAP.</Trans>
                </p>
              </Alert>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;
