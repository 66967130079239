import React from "react";

const Loader = () => {
  return (
    <div>
      <div
        className="grid-margin stretch-card align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="loader-demo-box">
          <div className="dot-opacity-loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
