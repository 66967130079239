import axios from "axios";
import React, { useState } from "react";
import { Form, Button, FormControl } from "react-bootstrap";
import { RadioGroup, Radio } from "react-radio-group";
import { Link } from "react-router-dom";
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";
import { environment } from "../../environment.js";
import "survey-core/survey.css";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const UserQuestionnaire = ({ history, match }) => {
  const [selected, setSelectedone] = useState("");
  const [allQuestions, setAllQuestions] = useState([]);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [question_index, setQuestionIndex] = useState(0);
  const [submitButton, setSubmitButton] = useState(true);
  const [feedbackId, setFeedbackId] = useState(1);

  // const [selected, setSelected] = useState<string>('biff');
  const [answers, setAnswers] = useState([]);
  const [item, setItem] = useState("");

  const json = {
    pages: [
      {
        name: "ranking",
        elements: [
          {
            type: "ranking",
            //name: "smartphone-features",
            title: "Rank the following in order of the duration?",
            isRequired: true,

            choices: [
              "Time spent in Office (Indoor)",
              "Time spent at Home(Indoor: Sleep, Meals etc. )",
              "Time spent travelling, sales etc (Outdoors)",
            ],
          },
        ],
      },
    ],
  };

  let survey = new Survey.Model(json);

  //window.survey = new Survey.Model(json);

  survey.onComplete.add(function (sender) {
    console.log(sender.data.question1);
    setRankingOption(sender.data.question1);
    // document.querySelector("#surveyResult").textContent =
    //   "Result JSON:\n" + JSON.stringify(sender.data, null, 3);
  });

  var questionCopy = [];
  let masterId = match.params.masterId;

  console.log(masterId);
  let user_id = "";
  user_id = match.params.userId;
  console.log(user_id);

  let checkboxArray = [];

  var objToPost = {};
  var answerObject = [];

  React.useEffect(() => {
    // if (masterId === 1) {
    //   checkIfResponseAdded();
    // } else if (masterId === 2) {
    //   getAllQuestions();
    // }
    checkIfResponseAdded();
  }, []);
  const checkIfResponseAdded = async () => {
    let userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);

    await axios
      .get(
        environment.dap_url +
          "questionnarieApi/checIfAnsSubmitted/" +
          userDetails.data.id.id
      )
      .then((res) => {
        // {"answerCount":3}
        console.log(res);

        if (res?.data?.answerCount1 == 0 && res?.data?.answerCount2 == 0) {
          getAllQuestions(1);
        } else {
          setShowAlert1(true);
          MySwal.fire({
            title: "You have already submitted the feedback!",
            icon: "info",
            confirmButtonText: "Go to dashboard",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              history.push("/dashboard");
            }
          });
        }
      })

      .catch((err) => setShowAlert2(true));
  };

  const getAllQuestions = async (id) => {
    if (id === 2) {
      setFeedbackId(2);
    }
    setSubmitButton(true);
    await axios
      .get(
        environment.dap_url + "questionnarieApi/getAllQuestionsByMasterId/" + id
      )
      .then((res) => {
        res.data.forEach((element) => {
          element.responseBoolean = null;
          element.answer = "";
          element.checkboxAnswer = [];
          element.checkboxList = [];
        });
        setAllQuestions(res.data);

        questionCopy = [...res.data];

        console.log(res.data, questionCopy);
        setAnswers(questionCopy);
      })
      .catch((err) => setShowAlert2(true));
  };

  const presentAlert = async () => {
    MySwal.fire({
      title: <p>Feedback Answer Submitted Successfully!</p>,
      type: "success",
    });
  };

  const setRankingOption = (op) => {
    allQuestions.forEach((element) => {
      if (element.questionTypeId === 6) {
        console.log(element._id);
        console.log(element.question);
      }
    });
    let obj = answers?.findIndex((o) => o._id === "620c9d2085f5ce1d80c9b951");
    console.log(obj);
    answers[obj].checkboxAnswer = op;
    console.log(answers);

    // let val= answers
    // setAllQuestions(...val)
    setAllQuestions([...answers]);
  };

  const setSelected = (e, item, op) => {
    console.log(e);
    console.log(item);
    console.log(op);
    item.answer = e;

    console.log(answers, item);
    setSelectedOption(e);

    let questionIndex = allQuestions.findIndex((o) => o._id === item._id);
    console.log(questionIndex);
    setQuestionIndex(questionIndex);

    let obj = answers?.findIndex((o) => o._id === item._id);
    console.log(obj);
    answers[obj].answer = op;
    console.log(answers);

    setAllQuestions([...answers]);
  };

  const setSelectedAnswer = (e, item, op) => {
    item.answer = e;

    console.log(answers, item);

    let obj = answers?.findIndex((o) => o._id === item._id);
    console.log(obj);
    answers[obj].answer = e;
    console.log(answers);

    // let val= answers
    // setAllQuestions(...val)
    setAllQuestions([...answers]);
  };

  const setBooleanResponse = (e, item, op) => {
    console.log(e, item, op);

    item.responseBoolean = e;
    // setAnswers()
    // setAllQuestions()
    // setAllQuestions()
    // allQuestions.forEach((element:any) => {
    console.log(answers, item);

    let obj = answers?.findIndex((o) => o._id === item._id);
    console.log(item._id);
    console.log(obj);

    if (e === "true") {
      answers[obj].response = "Yes";
      answers[obj].answer = "Yes";
      setSelectedOption("Yes");
      answers[obj].responseBoolean = true;
    } else {
      answers[obj].response = "No";
      answers[obj].answer = "No";
      setSelectedOption("No");
      answers[obj].responseBoolean = false;
    }

    console.log(answers);

    // let val= answers
    // setAllQuestions(...val)
    setAllQuestions([...answers]);
  };

  const setSelectedCheckbox = async (e, item, op) => {
    console.log(e.checked, item, op);
    console.log(e.value);
    console.log(e);
    console.log(answers, item);

    let obj = answers?.findIndex((o: any) => o._id === item._id);
    console.log(obj);
    if (e.checked == true) {
      answers[obj].checkboxAnswer.push(op);
      console.log(answers);
    } else {
      answers[obj].checkboxAnswer.pop(op);
      console.log(answers);
    }

    console.log(answers);

    // let val= answers
    // setAllQuestions(...val)
    setAllQuestions([...answers]);
    // setSelectedone(e)
  };

  const setCustomCheckboxInput = async (e, item, op) => {
    console.log(e.value);
    let obj = answers?.findIndex((o: any) => o._id === item._id);
    // console.log(obj);
    if (e.checked == true) {
      answers[obj].checkboxAnswer.push(op);
      console.log(answers);
    } else {
      answers[obj].checkboxAnswer.pop(op);
      console.log(answers);
    }

    console.log(answers);

    // let val= answers
    // setAllQuestions(...val)
    setAllQuestions([...answers]);
    // setSelectedone(e)
  };

  const setCustomOptionInput = async (e, item, op) => {};

  const calculateWeighatge = (users) => {
    let waitage = 0;
    users.forEach((element) => {
      if (element.answerDetails.length > 0) {
        if (element.questionTypeId == 1) {
          /*for yes no type question if yes then full waitage will be added */
          if (element.answerDetails[0].responseBoolean == true) {
            waitage += element.waitage;
          }
        } else if (element.questionTypeId == 2) {
          /*for option type question the respective waitage of that particular option will be added */
          if (element.answerOptions.length > 0) {
            element.answerOptions.forEach((item) => {
              let result = item.option.localeCompare(
                element.answerDetails[0].response
              ); /*for this compare response and fetch the option's waitage from question's answeroption array */
              console.log(result, item.waitage);
              if (result == 0) {
                waitage += item.waitage;
              }
            });
          }
        } else if (element.questionTypeId == 3) {
          /* for input based question if any input is there then full waitage will be given*/
          if (element.answerDetails[0].response) {
            waitage += element.waitage;
          }
        }
      }
    });
    // res.json({ totalResponseWaitage: waitage, answers: notes });
    console.log({ totalResponseWeightage: waitage });
  };

  const handleSubmit = async () => {
    console.log("all questions", allQuestions);
    let submit = false;
    let userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    console.log(userDetails);

    //calculateWeighatge(userDetails);

    allQuestions.forEach((element) => {
      if (element.answer.length > 0 || element.checkboxAnswer.length > 0) {
        submit = true;
        console.log(element.answer);
        objToPost = {
          questionId: element._id,
          questionMasterId: masterId,
          userId: user_id,
          responseCheckBox: element.checkboxAnswer,
          response: element.answer,
          responseBoolean: element.responseBoolean,

          isDeleted: false,
          isActive: true,
        };

        answerObject.push(objToPost);
      }
    });
    console.log(answerObject);

    console.log("after adding answer", allQuestions);

    // http://localhost:3000/questionnarieResponseApi/submitAnswer
    if (submit) {
      await axios
        .post(
          environment.dap_url + "questionnarieResponseApi/submitAnswer",
          answerObject
        )
        .then((res) => {
          console.log("success");
          console.log(res);
          setAllQuestions([]);
          if (feedbackId === 1) {
            Swal.fire({
              title: "<strong>Feedback <u>Saved</u></strong>",
              icon: "success",
              html: "Proceed to fill <b>Voluntary Feedback</b> ? ",
              showCloseButton: true,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                getAllQuestions(2);
              } else if (result.isDenied) {
                history.push("/dashboard");
              }
            });
          } else if (feedbackId === 2) {
            presentAlert();
          }

          setTimeout(() => {
            history.goBack();
          }, 1500);
          setSubmitButton(false);
        })
        .catch((err) => setShowAlert2(true));
    } else {
      console.log("fill all the fields");
    }
  };

  const goBack = () => {
    console.log("jkgsd");

    history.goBack();
  };
  return (
    <>
      <div style={{ margin: "5%" }}>
        <div className='col-lg-3'>
          <Link to={`/dashboard`} className='btn btn-light my-3'>
            <i className='mdi mdi-arrow-left'></i> Back
          </Link>
        </div>
        <div className='row'>
          <div className='col-lg-8'>
            {allQuestions.map((item, i) => (
              <div key={item?._id}>
                <div
                  style={{
                    marginLeft: "5px",
                    marginTop: "10%",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {++i}. {item.question}{" "}
                  {item.waitage ? (
                    <span>
                      Weightage: <strong>{item.waitage}</strong>
                    </span>
                  ) : null}
                </div>
                {item.questionTypeId == 2 ? (
                  <>
                    {item.answerOptions.map((op) => (
                      <div key={op.option} style={{ marginTop: "10px" }}>
                        <ul>
                          <Form.Check
                            value={op.option}
                            id={op.option}
                            type='radio'
                            aria-label='radio 1'
                            label={op.option}
                            // value={item?.answer}
                            onChange={(e) =>
                              setSelected(e.target.value, item, op.option)
                            }
                            checked={
                              item.answer === op.option &&
                              allQuestions.findIndex(
                                (o) => o._id === item._id
                              ) ===
                                i - 1
                            }
                          />
                        </ul>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {item.questionTypeId == 1 ? (
                      <>
                        {/* {item.answerOptions.map((option) => (
                          <Form
                            key={item.questionId}
                            style={{ marginTop: "10px" }}
                          >
                            <ul>
                              <Form.Check
                                value={option}
                                id={option}
                                type='radio'
                                aria-label='radio 1'
                                label={option}
                                //yes
                                // value={item?.answer}
                                onChange={(e) =>
                                  setBooleanResponse(
                                    e.target.value,
                                    item,
                                    item.questionTypeId
                                  )
                                }
                                // checked={
                                //   selectedOption === option &&
                                //   i - 1 === question_index
                                // }
                              />
                            </ul>
                          </Form>
                        ))} */}
                        <RadioGroup
                          name={item._id}
                          style={{ marginTop: "15px", marginBottom: "10px" }}
                          value={item?.responseBoolean}
                          id={item._id}
                        >
                          <Radio
                            value={true}
                            id='Yes'
                            onChange={(e) =>
                              setBooleanResponse(
                                e.target.value,
                                item,
                                item.questionTypeId
                              )
                            }
                            style={{ marginLeft: "10px" }}
                          />{" "}
                          Yes
                          <Radio
                            id='No'
                            value={false}
                            onChange={(e) =>
                              setBooleanResponse(
                                e.target.value,
                                item,
                                item.questionTypeId
                              )
                            }
                            style={{ marginLeft: "20px" }}
                          />{" "}
                          No
                        </RadioGroup>
                      </>
                    ) : (
                      <>
                        {" "}
                        {item.questionTypeId == 3 && (
                          <>
                            {" "}
                            <div className='col-lg-6'>
                              <Form.Group controlId='title'>
                                <Form.Label></Form.Label>
                                <Form.Control
                                  type='text'
                                  placeholder='Enter Answer'
                                  onChange={(e) =>
                                    setSelectedAnswer(
                                      e.target.value,
                                      item,
                                      item.questionTypeId
                                    )
                                  }
                                  style={{ marginTop: "10px" }}
                                ></Form.Control>
                              </Form.Group>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {item.questionTypeId == 4 && (
                  <>
                    {item.answerOptions.map((op) => (
                      <div key={op.option} style={{ marginTop: "10px" }}>
                        <ul>
                          <Form.Check
                            value={op.option}
                            type='checkbox'
                            label={op.option}
                            // value={item?.answer}
                            onChange={(e) =>
                              setSelectedCheckbox(e.target, item, op.option)
                            }
                            //checked={selected === op.option}
                          />
                        </ul>
                      </div>
                    ))}
                  </>
                )}

                {item.questionTypeId == 5 && (
                  <>
                    <Form>
                      {item.answerOptions.map((op) => (
                        <>
                          <div key={op.option} style={{ marginTop: "10px" }}>
                            <ul>
                              <Form.Check
                                value={op.option}
                                type='checkbox'
                                label={op.option}
                                id={op.option}
                                //value={item?.answer}
                                onChange={(e) =>
                                  setSelectedCheckbox(e.target, item, op.option)
                                }
                                //checked={selected === op.option}
                              />
                            </ul>
                          </div>
                        </>
                      ))}

                      <Form.Control
                        type='text'
                        placeholder='Free Writing'
                        onChange={(e) =>
                          setSelectedAnswer(
                            e.target.value,
                            item,
                            item.questionTypeId
                          )
                        }
                        style={{ marginTop: "10px" }}
                      ></Form.Control>
                    </Form>
                  </>
                )}

                {item.questionTypeId == 6 && (
                  <>
                    {/* {item.answerOptions.map((op) => (
                      <div key={op.option} style={{ marginTop: "10px" }}>
                        <ul>
                          <Form.Check
                            value={op.option}
                            type='checkbox'
                            label={op.option}
                            // value={item?.answer}
                            onChange={(e) =>
                              setSelected(e.target.value, item, op.option)
                            }
                            //checked={selected === op.option}
                          />
                        </ul>
                      </div>
                    ))} */}
                    <SurveyReact.Survey model={survey} />
                  </>
                )}
                {item.questionTypeId == 7 && (
                  <>
                    {item.answerOptions.map((op) => (
                      <>
                        <div key={op.option} style={{ marginTop: "10px" }}>
                          <ul>
                            <Form.Check
                              value={op.option}
                              type='radio'
                              id={op.option}
                              label={op.option}
                              // value={item?.answer}
                              onChange={(e) =>
                                setSelected(e.target.value, item, op.option)
                              }
                              checked={
                                item.answer === op.option &&
                                allQuestions.findIndex(
                                  (o) => o._id === item._id
                                ) ===
                                  i - 1
                              }
                            />
                          </ul>
                        </div>
                      </>
                    ))}
                    <Form.Control
                      type='text'
                      placeholder='Free Writing'
                      onChange={(e) =>
                        setSelectedAnswer(
                          e.target.value,
                          item,
                          item.questionTypeId
                        )
                      }
                      style={{ marginTop: "10px" }}
                    ></Form.Control>
                  </>
                )}
                {/*  */}
              </div>
            ))}
          </div>
        </div>
        {!showAlert1 && submitButton && (
          <Button
            class='button-block'
            fill='outline'
            style={{ marginTop: "3%" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </div>
    </>
  );
};

export default UserQuestionnaire;
