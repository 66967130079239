import axios from "axios";
import { environment } from "../../environment";

// import { environment } from "../../environment.js";

export const deviceCoordinateService = () => {
  console.log("jhg");
  let deviceList = [];
  let deviceCoordinateArray = [];
  let deviceZeroCoordinates = [];
  let devicesFromDb = [];

  deviceList = JSON.parse(localStorage.getItem("listDevices"));
  // console.log(deviceList);
  let token = localStorage.getItem("token");

  let userDetails = localStorage.getItem("userDetails");
  userDetails = JSON.parse(userDetails);
  axios
    .get(
      environment.dap_url +
        `getDevicesByTenantId/` +
        userDetails.data.tenantId.id
    )

    .then((res) => {
      console.log("jjk", res.data);
      devicesFromDb.push(res.data);
      console.log("jjk", devicesFromDb);
    })
    .catch(function (error) {
      console.log(error);
    });

  if (deviceList) {
    deviceList.forEach((element) => {
      console.log(element.name);
      axios
        .get(
          environment.apiUrl +
            "plugins/telemetry/DEVICE/" +
            element.id.id +
            "/values/timeseries?keys=pm25,iaq,pm10,co2,tvoc,temp,lat,lon,rh&startTs=" +
            (Date.now() - 24 * 60 * 60 * 1000) +
            "&endTs=" +
            Date.now() +
            "&interval=60000&limit=100",
          { headers: { "X-Authorization": "Bearer " + token } }
        )
        .then((res) => {
          console.log(res.data);

          if (res.data.lat && res.data.lon) {
            if (res.data.lat[0].value != 0) {
              deviceCoordinateArray.push({
                device_name: element.name,
                lat: res.data.lat[0].value,
                lon: res.data.lon[0].value,
              });
            } else if (res.data.lat[0].value === res.data.lon[0].value) {
              let i = 0;
              deviceZeroCoordinates.push({
                device_name: element.name,
                lat: res.data.lat[0].value,
                lon: res.data.lon[0].value,
              });
            }

            console.log(deviceCoordinateArray);
            console.log(deviceZeroCoordinates);
            localStorage.setItem(
              "deviceCoordinateArray",
              JSON.stringify(deviceCoordinateArray)
            );

            localStorage.setItem(
              "deviceZeroCoordinates",
              JSON.stringify(deviceZeroCoordinates)
            );
          }
        });
    });
  }
};
