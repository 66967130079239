import React, { useState, useEffect } from "react";
import { environment } from "../../environment";
import axios from "axios";
import { Form, Button, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const MySwal = withReactContent(Swal);

const UserFeedback = ({ history }) => {
  const [customers, setCustomers] = useState([]);
  const [email, setEmail] = useState("");
  const [customerCount, setCustomerCount] = useState("");
  const [totalWeightage, setTotalWeightage] = useState([{}]);
  let totalResponseWaitageArray = [{}];
  let ResponseWaitageArray = [];
  let customersArray = [];
  let master_dropdown = [];
  master_dropdown = [
    {
      questionMasterName: "Customer Feedback",
      questionMasterId: 1,
    },
    {
      questionMasterName: "Voluntary Feedback(Before using purifier)",
      questionMasterId: 2,
    },
    {
      questionMasterName: "Voluntary Feedback(After using purifier)",
      questionMasterId: 3,
    },
  ];

  useEffect(() => {
    axios
      .post(environment.dap_url + "users/getUsersByfilters", {
        filters: { userType: "CUSTOMER_USER" },
      })
      .then((res) => {
        console.log(res.data);
        setCustomers(res.data);
        customersArray.push(res.data);
        console.log(res.data.length);
        setCustomerCount(res.data.length);
        //getTotalWeighatge(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getTotalWeighatge = (users) => {
    console.log(users);
    users.forEach((user) => {
      axios
        .get(
          environment.dap_url +
            "questionnarieApi/getAllQuestionsWithAnswerAndWeightage/" +
            user.userId
        )
        .then((res) => {
          console.log(res.data.totalResponseWaitage);
          totalResponseWaitageArray.push({
            _id: user._id,
            responseWeightage: res.data.totalResponseWaitage,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
    console.log(customersArray);
    customersArray.push(totalResponseWaitageArray);
    console.log(totalResponseWaitageArray);
    console.log(customersArray);
    setTotalWeightage(totalResponseWaitageArray);
  };

  const viewResponse = (userId, masterId) => {
    console.log(userId, masterId);
    history.push(`/viewResponse/${userId}/${masterId}`);
  };

  const columns = [
    // {
    //   dataField: "_id",
    //   text: "ID",
    //   sort: true,
    // },
    {
      dataField: "userFirstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "userLastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "userId",
      text: "Customer Feedback",
      sort: false,
      formatter: (dataField) => {
        return (
          <div>
            <Dropdown alignRight>
              <Dropdown.Toggle variant='transparent'>
                <span className='btn'>
                  Select Feedback Type<i className='mdi mdi-chevron-down'></i>{" "}
                </span>
              </Dropdown.Toggle>

              <>
                <div>
                  <Dropdown.Menu>
                    {master_dropdown.map((element) => (
                      <Dropdown.Item
                        key={element.questionMasterId}
                        onClick={() =>
                          viewResponse(dataField, element.questionMasterId)
                        }
                      >
                        {element.questionMasterName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </div>
              </>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <>
      {/* <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body' style={{ paddingTop: "5%" }}>
              <h4 className='card-title'>Customers</h4>
              <div className='table-responsive'>
                <table className='table table-striped table-bordered'>
                  <thead>
                    <tr>
                      <th>Email </th>
                      <th>First Name</th>
                      <th> Last Name </th>
                      <th> Total Response Index</th>
                      <th> View Customer Feedback </th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((customer) => (
                      <>
                        <tr key={customer._id}>
                          <td>{customer.email}</td>
                          <td>{customer.userFirstName}</td>
                          <td>{customer.userLastName}</td>
                          <td>0</td>

                          <td>
                            <Dropdown alignRight>
                              <Dropdown.Toggle
                                variant='btn btn-primary btn-sm'
                                id='dropdownMenuSizeButton3'
                              >
                                <span>Select Feedback Type</span>
                              </Dropdown.Toggle>

                              <>
                                <div>
                                  <Dropdown.Menu>
                                    {master_dropdown.map((element) => (
                                      <Dropdown.Item
                                        key={element.questionMasterId}
                                        onClick={() =>
                                          viewResponse(
                                            customer.userId,
                                            element.questionMasterId
                                          )
                                        }
                                      >
                                        {element.questionMasterName}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </div>
                              </>
                            </Dropdown>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <h4 className='card-title'>CUSTOMERS</h4>
                <div className='row'>
                  <div className='col-12'>
                    <ToolkitProvider
                      keyField='id'
                      bootstrap4
                      data={customers}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <div className='d-flex align-items-center'>
                            <p className='mb-2 mr-2'>Search in table:</p>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            {...props.baseProps}
                            wrapperClasses='table-responsive'
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h5 style={{ marginLeft: "5%" }}>
        Total number of Customers: {customerCount}
      </h5>
    </>
  );
};

export default UserFeedback;
