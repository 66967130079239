import axios from "axios";
import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
//import { createUser } from "../services/createUser";
import { Link } from "react-router-dom";
import { environment } from "../../environment";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const AddUser = ({ history, match }) => {
  const [dap_firstName, setFirstName] = useState("");
  const [dap_lastName, setLastName] = useState("");
  const [dap_description, setDescription] = useState("");
  const [dap_email, setEmail] = useState("");
  const [dap_groupEntityId, setGroupEntityId] = useState("");
  const [loading, setLoading] = useState(false);

  //const [loader, setLoader] = useState(true);

  const [emailErrors, setEmailErrors] = useState({});
  const [nameErrors, setNameErrors] = useState({});

  const c_id = localStorage.getItem("c_id");

  const dap_user_id = match.params.id;
  const [dap_tenantId, setTenantId] = useState("");

  useEffect(() => {
    let userDetails = "";
    userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    console.log("hyyy therer", userDetails.data.tenantId.id);
    setTenantId(userDetails.data.tenantId.id);

    axios
      .get(
        environment.apiUrl + "entityGroups/CUSTOMER/" + dap_user_id + "/USER",
        // { dap_tenantId },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log("group entity", res.data[2].id.id);
        setGroupEntityId(res.data[2].id.id);
      })
      .catch((err) => {});
  }, []);

  const formValidation = () => {
    const emailErrors = {};
    const nameErrors = {};
    let isValid = true;

    if (!dap_email) {
      emailErrors.noEmail = "* Email Id required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(dap_email)) {
      emailErrors.invalidEmail = "* Email address is invalid";
      isValid = false;
    }
    if (!dap_firstName) {
      nameErrors.noName = "* First Name is required";
      isValid = false;
    } else if (dap_firstName.trim().length < 2) {
      nameErrors.shortName = "* Name is too short";
      isValid = false;
    } else if (dap_firstName.trim().length > 30) {
      nameErrors.shortName = "* Name is too long";
      isValid = false;
    }
    setEmailErrors(emailErrors);

    setNameErrors(nameErrors);
    return isValid;
  };

  const submitHandler = (e) => {
    const isValid = formValidation();

    e.preventDefault();

    if (isValid) {
      setLoading(true);
      axios
        .post(
          //"user?sendActivationMail=true",
          environment.apiUrl +
            "user?sendActivationMail=true&entityGroupId=" +
            dap_groupEntityId,
          {
            email: dap_email,
            firstName: dap_firstName,
            lastName: dap_lastName,
            additionalInfo: {
              description: dap_description,
              defaultDashboardId: null,
              defaultDashboardFullscreen: false,
              homeDashboardId: null,
              homeDashboardHideToolbar: true,
            },
            authority: "CUSTOMER_USER",
            customerId: {
              entityType: "CUSTOMER",
              id: dap_user_id,
            },
          },
          {
            headers: {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data.data);
          MySwal.fire({
            title: <p>User has been successfully created!</p>,
            type: "success",
          });
          history.push(`/users/${dap_user_id}`);
        })
        .catch((err) => {
          setLoading(false);
          MySwal.fire({
            title: (
              <p>{err?.response?.data?.message || "User creation failed"}</p>
            ),
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <div>
        <div className='row' style={{ marginTop: "72px", padding: "20px" }}>
          <div className='col-lg-3'></div>
          <div className='col-lg-6'>
            <Link to={`/users/${dap_user_id}`} className='btn btn-light my-3'>
              Go Back
            </Link>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3'></div>
          <div className='col-md-6 grid-margin stretch-card'>
            <div className='card'>
              <div className='card-body'>
                <h1 className='text-center'>ADD USER</h1>
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId='title'>
                    <Form.Label>* Email</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter Email'
                      value={dap_email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                    <div>
                      {Object.keys(emailErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>{emailErrors[key]}</div>
                        );
                      })}
                    </div>
                  </Form.Group>

                  <Form.Group controlId='fistName'>
                    <Form.Label>* First Name</Form.Label>
                    <Form.Control
                      type='firstname'
                      placeholder='Enter First Name'
                      value={dap_firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    ></Form.Control>
                    <div>
                      {Object.keys(nameErrors).map((key) => {
                        return (
                          <div style={{ color: "red" }}>{nameErrors[key]}</div>
                        );
                      })}
                    </div>
                  </Form.Group>

                  <Form.Group controlId='lastName'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type='lastName'
                      placeholder='Enter Last Name'
                      value={dap_lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='description'>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type='desctption'
                      placeholder='Add Description'
                      value={dap_description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <div className='mt-3'>
                    <Button
                      type='submit'
                      className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
                      disabled={loading}
                    >
                      ADD USER
                      {loading && (
                        <i
                          className='fa fa-refresh fa-spin'
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {loading && <span></span>}
                      {!loading && <span></span>}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className='col-lg-3'></div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
